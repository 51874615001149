import React, { FunctionComponent } from 'react';
import { Table } from 'rsuite-table5';
import 'rsuite-table5/dist/css/rsuite-table.css';
import './styles.css';

interface ITable {
  data: any;
  children: React.ReactNode;
  count: number;
  rowClick?: (data: any, event?: any) => void;
  rowClassName?: any;
  height?: number;
  headerHeight?: number;
  rowHeight?: number;
  defaultExpandAllRows?: boolean;
  renderRowExpanded?: any;
  rowExpandedHeight?: number;
}

const List5: FunctionComponent<ITable> = ({
  children,
  data,
  rowClick,
  rowClassName,
  height,
  headerHeight,
  rowHeight,
  defaultExpandAllRows,
  renderRowExpanded,
  rowExpandedHeight,
}) => {
  let rowClassNameSet: any = rowClick ? `tablePointer` : '';
  if (rowClassName !== undefined) {
    rowClassNameSet = rowClassName;
  }

  let autoHeight = true;
  let customHeight = 200;
  if (height !== undefined) {
    autoHeight = false;
    customHeight = height;
  }

  return (
    //@ts-ignore
    <Table
      autoHeight={autoHeight}
      height={customHeight}
      headerHeight={headerHeight === undefined ? 56 : headerHeight}
      rowHeight={rowHeight === undefined ? 56 : rowHeight}
      data={data}
      onRowClick={rowClick}
      rowClassName={rowClassNameSet}
      rowExpandedHeight={rowExpandedHeight ?? 100}
      defaultExpandAllRows={defaultExpandAllRows ?? false}
      renderRowExpanded={renderRowExpanded ?? undefined}
    >
      {children}
    </Table>
  );
};

export default List5;
