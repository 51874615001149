import { IApp, ILoading, IMenu, IHeader, IFilter, IConfirmModal, ILoadingScreen } from './models';

export const appState: IApp = {
  logged: undefined,
};

export const loadingState: ILoading = {
  isLoading: false,
};

export const menuState: IMenu = {
  id: '',
  name: '',
  locale: '',
  positions: [],
};

export const headerState: IHeader = {
  name: '',
  subName: '',
  nameUrl: '',
  subNameUrl: '',
};

export const filterState: IFilter = {
  handleToggleFilters: null,
};

export const confirmModalState: IConfirmModal = {
  open: false,
  question: '',
  callback: null,
};

export const loadingScreenState: ILoadingScreen = {
  show: false,
};
