import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IWarehouseAvailabilityData, IWarehouseAvailabilityFilters } from 'utils/models';
import { defaultFilterOrderState, defaultFilterPaginateState } from 'utils/states';
import WarehouseConnection from 'utils/connections/warehouse';
import WhiteCard from 'global/atoms/WhiteCard';
import List from 'global/table/List';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import Filters from 'global/filters/Filters';
import Pagination from 'global/pagination/Pagination';
import Select from 'global/atoms/Select';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import Spinner from 'global/atoms/Spinner/Spinner';
import { handleClearFilters, handleSubmitAndSaveFilters, loadFilters } from '../../../../utils/filterHelper';

interface IWarehouseList {}

interface IFilters extends IWarehouseAvailabilityFilters {
  groupId: string;
}

const WarehouseList: FunctionComponent<IWarehouseList> = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IWarehouseAvailabilityData | null | 'error'>(null);
  const [form, setForm] = useState<{
    items: any;
    brands: any;
    itemGroups: any;
  }>({
    brands: {},
    items: {},
    itemGroups: {},
  });
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<boolean>(false);

  const defaultFilters = {
    brandId: '',
    itemId: '',
    groupId: '',
    ...defaultFilterOrderState,
    ...defaultFilterPaginateState,
  };
  const [filters, setFilters] = useState<IFilters>(loadFilters(defaultFilters));

  useEffect(() => {
    WarehouseConnection.availabilityFilterForm(id).then((data) => {
      setLoading(false);
      setForm(data.data);
    });
  }, []);

  const loadData = () => {
    WarehouseConnection.availabilityList(id, filters).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  };

  useEffect(() => loadData(), [filters.requestPaginate]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
  }, [reloadData]);

  const handleToggleFilters = () => {
    setToggleFilters((state) => !state);
  };

  if (loading) return <Spinner />;

  if (data === null || data === 'error' || form.brands.options === undefined) return null;

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column flexGrow={1} minWidth={300} align="left">
            <HeaderCell depth={0} width={0}>
              Produkt
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="name" />
          </Column>
          <Column width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Grupa
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="itemGroup" />
          </Column>
          <Column align="left">
            <HeaderCell depth={0} width={0}>
              Dostępność
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="availability" />
          </Column>
          <Column align="left">
            <HeaderCell depth={0} width={0}>
              Przychód
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="incoming" />
          </Column>
          <Column align="left">
            <HeaderCell depth={0} width={0}>
              Rozchód
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="outcoming" />
          </Column>
          <Column align="left">
            <HeaderCell depth={0} width={0}>
              Strata
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="lost" />
          </Column>
        </List>
      </WhiteCard>
      <Filters
        handleToggleFilters={handleToggleFilters}
        toggleFilters={toggleFilters}
        filtersState={filters}
        setFilters={setFilters}
        handleSubmit={handleSubmitAndSaveFilters.bind(null, setReloadData, filters)}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, setReloadData)}
      >
        <Select
          placeholder={'Produkt'}
          name={'itemId'}
          value={filters.itemId}
          options={form.items.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Marka'}
          name={'brandId'}
          value={filters.brandId}
          options={form.brands.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Grupa'}
          name={'groupId'}
          value={filters.groupId}
          options={form.itemGroups.options}
          state={filters}
          setState={setFilters}
        />
      </Filters>
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
    </>
  );
};

export default WarehouseList;
