import React, { FunctionComponent } from 'react';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
import history from './utils/history';
import Layout from './global/layout/Layout';
import Projects from 'views/projects/Projects';
import Dashboard from './views/dashboard/Dashboard';
import Timetables from 'views/timetables/Timetables';
import Products from './views/products/Products';
import Datasets from './views/datasets/Datasets';
import Superadmin from './views/superadmin/Superadmin';
import Settlements from './views/settlements/Settlements';
import Admin from 'views/admin/Admin';
import Templates from './views/templates/Templates';
import { ClearCacheProvider } from 'react-clear-cache';
import VisitReviews from './views/visitReviews/VisitReviews';

interface IApp {}

const App: FunctionComponent<IApp> = () => {
  return (
    <>
      <ClearCacheProvider auto={true}>
        <Router history={history}>
          <Layout>
            <Switch>
              <Route exact path={'/'}>
                <Redirect to={'/projects'} />
              </Route>
              <Route path={'/dashboard'} component={Dashboard} />
              <Route path={'/projects'} component={Projects} />
              <Route path={'/timetables'} component={Timetables} />
              <Route path={'/templates'} component={Templates} />
              <Route path={'/products'} component={Products} />
              <Route path={'/datasets'} component={Datasets} />
              <Route path={'/visit-reviews'} component={VisitReviews} />
              <Route path={'/settlements'} component={Settlements} />
              <Route path={'/admin'} component={Admin} />
              <Route path={'/superadmin'} component={Superadmin} />
            </Switch>
          </Layout>
        </Router>
      </ClearCacheProvider>
    </>
  );
};

export default App;
