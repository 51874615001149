import React, { FunctionComponent } from 'react';
import ButtonOutlined from '../global/atoms/ButtonOutlined';
import Modal from '../global/atoms/Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { IRoot } from '../redux/models';
import Button from '../global/atoms/Button';
import { TOGGLE_CONFIRM_MODAL } from '../redux/actions';
import { confirmModalState } from '../redux/states';

const ConfirmModal: FunctionComponent = () => {
  const confirmModalData = useSelector((state: IRoot) => state.confirmModal);
  const dispatch = useDispatch();
  const handleConfirm = () => {
    dispatch({ type: TOGGLE_CONFIRM_MODAL, payload: confirmModalState });
    confirmModalData.callback();
  };
  const handleClose = () => {
    dispatch({ type: TOGGLE_CONFIRM_MODAL, payload: confirmModalState });
  };

  return (
    <>
      <Modal isOpen={confirmModalData.open}>
        {typeof confirmModalData.question === 'string' ? (
          <p style={{ fontSize: '1.1rem', color: 'rgba(19, 19, 21, 0.5)', fontWeight: 500 }}>
            {confirmModalData.question}
          </p>
        ) : (
          <>{confirmModalData.question}</>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button onClick={handleConfirm}>Potwierdź</Button>
          <ButtonOutlined onClick={handleClose} style={{ marginLeft: '10px' }}>
            Anuluj
          </ButtonOutlined>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmModal;
