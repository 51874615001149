import React, { FunctionComponent, useEffect } from 'react';
import styles from './layout.module.scss';
import Nav from './components/Nav';
import { Toaster } from 'react-hot-toast';
import Header from './components/Header';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import ConfirmModal from '../../utils/ConfirmModal';
import LockedLoadingScreen from './components/LockedLoadingScreen';
import LayoutPortal from './components/LayoutPortal';

interface ILayout {}

const Layout: FunctionComponent<ILayout> = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch({ type: 'SET_FILTER', payload: null });
  }, [pathname]);

  return (
    <>
      <div className={styles.gridPageContainer}>
        <Nav />
        <div className={styles.appContainer} id="content">
          <Header />
          <div style={{ marginTop: '4rem' }}>{children}</div>
        </div>
        <Toaster
          position={'bottom-right'}
          toastOptions={{
            className: '',
            style: {
              minHeight: '60px',
              height: 'auto',
              width: '300px',
              textAlign: 'center',
            },
          }}
        />
        <ConfirmModal />
      </div>
      <LockedLoadingScreen />
      <LayoutPortal />
    </>
  );
};

export default Layout;
