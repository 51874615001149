import React, { FunctionComponent, useEffect, useState } from 'react';
import { handleToast } from 'utils/helpers';
import { defaultFilterOrderState, defaultFilterPaginateState, selectViewResponseState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import { IHeader, IPagination, ISelectViewResponse, ISort } from 'utils/models';
import WhiteCard from 'global/atoms/WhiteCard';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import BadgeStatus from 'global/atoms/badge/BadgeStatus';
import IconButton from 'global/atoms/IconButton';
import List from 'global/table/List';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import Input from 'global/atoms/Input';
import DayPickerInputCustom from 'global/atoms/DayPickerInputCustom';
import Select from 'global/atoms/Select';
import { handleClearFilters, handleSubmitAndSaveFilters, handleToggleFilters, loadFilters } from 'utils/filterHelper';
import Spinner from 'global/atoms/Spinner/Spinner';
import HeaderButtons from '../../../../global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from '../../../../global/atoms/ButtonFilters';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import {
  BatchActionButton,
  SingleActionButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import StoreDocumentsConnection from '../../../../utils/connections/storeDocuments';

interface IDocumentData {
  deliveryDate: string;
  documentDate: string;
  documentId: string;
  documentNumber: string;
  saleType: any;
  location: any;
  paymentDate: string;
  paymentType: string;
  positions: any;
  user: any;
}

interface IDocumentsData {
  columns: any;
  header: IHeader;
  data: Array<IDocumentData>;
  count: number;
}

export interface IDocumentsFilters {
  documentNumber: string;
  userId: string;
  saleTypeId: string;
  locationId: string;
  dateFrom: number;
  dateTo: number;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

const StoreDocumentsList: FunctionComponent = () => {
  const [data, setData] = useState<IDocumentsData | null>(null);
  const defaultFilters: IDocumentsFilters = {
    dateFrom: 0,
    dateTo: 0,
    documentNumber: '',
    saleTypeId: '',
    locationId: '',
    userId: '',
    ...defaultFilterOrderState,
    ...defaultFilterPaginateState,
  };
  const [filters, setFilters] = useState<IDocumentsFilters>(loadFilters(defaultFilters));
  const [reloadData, setReloadData] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [form, setForm] = useState<{
    saleTypes: ISelectViewResponse;
    locations: ISelectViewResponse;
    users: ISelectViewResponse;
  }>({
    saleTypes: selectViewResponseState,
    locations: selectViewResponseState,
    users: selectViewResponseState,
  });
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  useEffect(() => {
    StoreDocumentsConnection.filterForm(id).then((data) => {
      setForm(data.data);
    });
  }, []);

  const loadData = () => {
    StoreDocumentsConnection.list(id, filters).then((data) => {
      setData(data.data);
    });
  };

  useEffect(() => loadData(), [filters.requestPaginate]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
  }, [reloadData]);

  const handleOpenDocument = (documentId: string) => {
    history.push(`/projects/${id}/store-documents/${documentId}/details`);
  };

  if (data === null) return <Spinner />;

  const handleCancelSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(StoreDocumentsConnection.batchDelete(id, toDelete)).then(() => {
      setChosenToDelete([]);
      loadData();
    });
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters.bind(null, setToggleFilters)} />
        <BatchActionButton
          chosenRows={chosenToDelete}
          callback={handleCancelSelected}
          title={'Anuluj dokumenty'}
          message={'Czy napewno anulować? '}
        />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox
                data={data.data}
                state={chosenToDelete}
                setState={setChosenToDelete}
                keyIdName={'documentId'}
              />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                  selectedId={rowData.documentId}
                />
              )}
            </Cell>
          </Column>
          <Column flexGrow={2} width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Numer
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="documentNumber">
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/projects/${id}/store-documents/${rowData.documentId}/details`}>
                  {rowData.documentNumber}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2} width={250} align="left">
            <HeaderCell depth={0} width={0}>
              Nabywca
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return <span>{rowData.location.name}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Typ
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <BadgeStatus color={rowData.status.color}>{rowData.saleType.name}</BadgeStatus>}
            </Cell>
          </Column>
          <Column minWidth={150} align="left">
            <HeaderCell depth={0} width={0}>
              Wystawił
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return <span>{rowData.user.fullName}</span>;
              }}
            </Cell>
          </Column>
          <Column align="left" width={120}>
            <HeaderCell depth={0} width={0}>
              Data
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="documentDate" />
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ActionsContainer>
                  <IconButton icon={'arrowRightBig'} onClick={handleOpenDocument.bind(null, rowData.documentId)} />
                  <SingleActionButton
                    callback={handleCancelSelected.bind(null, rowData.documentId)}
                    message={'Czy napewno anulować? '}
                  />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters.bind(null, setToggleFilters)}
        handleSubmit={handleSubmitAndSaveFilters.bind(null, setReloadData, filters)}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, setReloadData)}
      >
        <Input
          value={filters.documentNumber}
          placeholder={'Numer'}
          name={'documentNumber'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Lokalizacja'}
          name={'locationId'}
          value={filters.locationId}
          options={form.locations.options}
          state={filters}
          setState={setFilters}
        />
        <DayPickerInputCustom
          value={filters.dateFrom}
          placeholder={'Data od'}
          name={'dateFrom'}
          setState={setFilters}
          state={filters}
        />
        <DayPickerInputCustom
          value={filters.dateTo}
          placeholder={'Data do'}
          name={'dateTo'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Wystawił'}
          name={'userId'}
          value={filters.userId}
          options={form.users.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Rodzaj dokumentu'}
          name={'saleTypeId'}
          value={filters.saleTypeId}
          options={form.saleTypes.options}
          state={filters}
          setState={setFilters}
        />
      </Filters>
    </>
  );
};

export default StoreDocumentsList;
