import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import StoreDocumentsConnection from '../../../../utils/connections/storeDocuments';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import { Col, Row } from 'react-grid-system';
import Input from '../../../../global/atoms/Input';
import Select from '../../../../global/atoms/Select';
import { ISelectViewResponse } from '../../../../utils/models';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import SeparatorLine from '../../../../global/atoms/separators/SeparatorLine';
import { getAddressFullName } from '../../../../utils/helpers';
import { createSummaryRowData, getDocumentPositionsBody } from '../documents/DocumentDetails';
import styles from '../orders/styles.module.scss';

interface IStoreDocumentDetailsForm {
  model: any;
  saleTypes: ISelectViewResponse;
}

const StoreDocumentDetails: FunctionComponent = () => {
  const [form, setForm] = useState<IStoreDocumentDetailsForm | null>(null);
  const { id, documentId } = useParams<{ id: string; documentId: string }>();
  const [positions, setPositions] = useState<Array<any> | null>(null);

  useEffect(() => {
    StoreDocumentsConnection.editForm(documentId, id).then((response) => {
      const dataTableDisplay = response.data.model.positions;
      dataTableDisplay.push(createSummaryRowData(response.data.model));
      setPositions(dataTableDisplay);
      setForm(response.data);
    });
  }, []);

  if (form === null || positions === null) return <Spinner />;
  return (
    <>
      <WhiteCard>
        <Row>
          <Col xs={4}>
            <Input value={form.model.documentNumber} placeholder={'Numer'} name={'documentNumber'} disabled={true} />
          </Col>
          <Col xs={4}>
            <Input value={form.model.user.fullName} placeholder={'Wystawił'} name={'user'} disabled={true} />
          </Col>
          <Col xs={4}>
            <Select
              placeholder={'Rodzaj dokumentu'}
              name={'saleTypeId'}
              value={form.model.saleType.id}
              options={form.saleTypes.options}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <Row>
          <Col xs={4}>
            <Input
              value={form.model.documentDate}
              placeholder={'Data wystawienia'}
              name={'documentDate'}
              disabled={true}
            />
          </Col>
          <Col xs={8}>
            <Input
              value={getAddressFullName(form.model.location.address)}
              placeholder={'Adres'}
              name={'address'}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <Row>
          <Col xs={12}>
            <Input value={form.model.description} placeholder={'Uwagi'} name={'description'} disabled={true} />
          </Col>
        </Row>
        <SeparatorLine size={4} />

        {getDocumentPositionsBody(positions)}

        <SeparatorLine size={2} className={styles.separator} />
      </WhiteCard>
    </>
  );
};

export default StoreDocumentDetails;
