import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import routes from 'routes/superadmin';

interface ISuperadmin {}

const Superadmin: FunctionComponent<ISuperadmin> = () => {
  return (
    <>
      {routes.map(({ path, Component }, key) => (
        <Route exact path={path} key={key} component={Component} />
      ))}
    </>
  );
};

export default Superadmin;
