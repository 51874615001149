import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId, handleToast } from '../helpers';
import { IDocumentsFilters } from '../../views/projects/components/documents/DocumentsList';

const DocumentsConnection = {
  list: (projectId: string, data: IDocumentsFilters) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  filterForm: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  editForm: (documentId: string, projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/${documentId}/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  removeForm: (data: { documents: Array<{ id: string }> }, projectId: string) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents`,
      {
        headers: getAuthHeader(),
        data,
      },
    );
  },
  batchDelete: (projectId: string, data: Array<{ id: string }>) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${projectId}/sales/documents`,
      {
        headers: { ...getAuthHeader() },
        data: { documents: data },
      },
    );
  },
  invoicesForm: (projectId: string, documents: string[]) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/invoices/form`,
      {
        documents: documents,
      },
    );
  },
  invoicesZip: (projectId: string, documents: string[]): any => {
    return handleToast(
      axios.post(
        `${
          process.env.REACT_APP_API_URL
        }/organizations/${getOrganizationId()}/project/${projectId}/sales/documents/invoices`,
        {
          documents: documents.map((documentId) => ({ documentId: documentId })),
        },
        {
          headers: getAuthHeader(),
          responseType: 'blob',
        },
      ),
    ).then((response) => {
      const csvURL = window.URL.createObjectURL(response.data);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', 'faktury.zip');
      tempLink.click();
    });
  },
};

export default DocumentsConnection;
