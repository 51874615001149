import React, { FunctionComponent, useState } from 'react';
import { ICreateChain } from 'utils/models';
import { createChainState } from 'utils/states';
import { useHistory } from 'react-router-dom';
import { handleToast } from 'utils/helpers';
import ChainsConnection from 'utils/connections/chains';
import ChainsUpdate from 'global/form/ChainsUpdate';

interface IChainsAdd {}

const ChainsAdd: FunctionComponent<IChainsAdd> = () => {
  const [data, setData] = useState<ICreateChain>(createChainState);
  const [errors, setErrors] = useState<any>({});
  const history = useHistory();

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    handleToast(ChainsConnection.createChain(data), setErrors).then(() => history.push('/datasets/networks'));
  };

  return <ChainsUpdate handleSubmit={handleSubmit} data={data} setData={setData} errors={errors} />;
};

export default ChainsAdd;
