import React, { FunctionComponent, useEffect, useState } from 'react';
import { handleSetErrors, handleToast } from 'utils/helpers';
import { IActivityAdd } from 'utils/models';
import ActivitiesConnection from 'utils/connections/activities';
import { addActivityState } from 'utils/states';
import Form from 'global/form/Form';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import ButtonGoBack from 'global/atoms/ButtonGoBack';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Select from 'global/atoms/Select';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IActivityAddComponent {
  handleDisableAddNew: () => void;
  projectId: string;
  taskId: string;
  setData: any;
  setPicked: any;
}

const ActivityAdd: FunctionComponent<IActivityAddComponent> = ({
  handleDisableAddNew,
  projectId,
  taskId,
  setData,
  setPicked,
}) => {
  const [form, setForm] = useState<any | 'loading'>('loading');
  const [state, setState] = useState<IActivityAdd>(addActivityState);
  const [errors, setErrors] = useState<any | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    ActivitiesConnection.getFormAddActivity(projectId, taskId).then((data) => {
      setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
      setState({ ...addActivityState, activityTypeId: data.data.activityTypes.value });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(ActivitiesConnection.createActivityForTask(projectId, taskId, state))
      .then((response) => {
        setPicked(response.data.activityId);
        ActivitiesConnection.getFormActivitiesEdit(projectId, taskId)
          .then((data) => {
            setData(data.data);
            data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
          })
          .then(() => handleDisableAddNew());
      })
      .catch((err) => handleSetErrors(err, setErrors));
  };

  if (form === 'loading') return <Spinner />;

  return (
    <div className={styles.wrapperActivityAd}>
      {
        <div style={{ paddingBottom: '24px' }}>
          <Form handleSubmit={handleSubmit}>
            <Input
              type={'text'}
              placeholder={'Nazwa'}
              name={'name'}
              value={state.name}
              setState={setState}
              state={state}
              errors={errors}
            />
            <Select
              placeholder={'Typ aktywności'}
              name={'activityTypeId'}
              value={state.activityTypeId}
              options={form.activityTypes.options}
              disabled={false}
              state={state}
              setState={setState}
              errors={errors}
            />
            <Select
              placeholder={'Grupa'}
              name={'activityGroupId'}
              value={state.activityGroupId}
              options={form.activityGroups.options}
              disabled={false}
              state={state}
              setState={setState}
              errors={errors}
            />
            <Textarea
              placeholder={'Opis'}
              name={'description'}
              value={state.description}
              setState={setState}
              state={state}
              errors={errors}
            />
            <ActionsContainer>
              <ButtonSubmit />
              <ButtonGoBack onClick={handleDisableAddNew} />
            </ActionsContainer>
          </Form>
        </div>
      }
    </div>
  );
};

export default ActivityAdd;
