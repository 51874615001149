import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateLocation } from 'utils/models';
import WhiteCard from 'global/atoms/WhiteCard';
import ProjectsConnection from 'utils/connections/projects';
import { useHistory, useParams } from 'react-router-dom';
import { createLocationState } from 'utils/states';
import { getAddressFullName, handleSetErrors, handleToast, useQuery } from 'utils/helpers';
import RoleUsers from './RoleUsers';
import Form from 'global/form/Form';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Select from 'global/atoms/Select';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import { ReactComponent as SpinnerIcon } from 'assets/svg/spinner.svg';
import SeparatorEmpty from '../../../../../global/atoms/separators/SeparatorEmpty';
import Questions from '../../taskActivitiesEdit/Questions';
import AddressSelectSection from '../../../../datasets/components/locationAdd/AddressSelectSection';
import AddressesList from '../../../../datasets/components/addressesList/AddressesList';
import { ISelectAddressViewData } from '../../../../datasets/components/locationAdd/LocationAdd';

interface IEditLocation {}

const EditLocation: FunctionComponent<IEditLocation> = () => {
  const [data, setData] = useState<ICreateLocation>(createLocationState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any>('loading');
  const [selectAddressViewData, setSelectAddressViewData] = useState<ISelectAddressViewData | null>(null);
  const { id, locationId } = useParams<{ id: string; locationId: string }>();
  const history = useHistory();
  const query = useQuery();

  const loadForm = (): Promise<any> => {
    return ProjectsConnection.getFormEditLocation(id, locationId).then((data) => {
      setData((state) => ({
        ...state,
        name: data.data.model.name,
        description: data.data.model.description,
        locationTypeId: data.data.model.locationType.id,
        nip: data.data.model.nip,
        email: data.data.model.email,
        contactPerson: data.data.model.contactPerson,
        code: data.data.model.code,
        networkId: data.data.model.network.id,
        addressId: {
          id: data.data.model.address?.id,
          name: getAddressFullName(data.data.model.address),
        },
        payerAddressId: {
          id: data.data.model.payerAddress?.id,
          name: getAddressFullName(data.data.model.payerAddress),
        },
        payerName: data.data.model.payerName,
        recipientAddressId: {
          id: data.data.model.recipientAddress?.id,
          name: getAddressFullName(data.data.model.recipientAddress),
        },
        recipientName: data.data.model.recipientName,
      }));
      setForm(data.data);
    });
  };

  useEffect(() => {
    loadForm();
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    data.locationQuestions = {
      all: true,
      assignedCount: form.assignedQuestions.data.length,
      assigned: form.assignedQuestions.data,
      unassigned: form.unassignedQuestions.data,
    };

    handleToast(ProjectsConnection.updateProjectLocation(id, locationId, data))
      .then(() => {
        history.push(`/projects/${id}/edit/3`);
      })
      .catch((err) => handleSetErrors(err, setErrors));
  };

  const handleSubmitUsers = () => {
    handleToast(
      ProjectsConnection.assignUsersToLoaction(id, locationId, {
        roles: form.locationRoles.data.map((item: any) => ({
          roleId: item.roleId,
          assigned: item.users.assigned.map((assigned: any) => ({ id: assigned.id })),
          unassigned: item.users.unassigned.map((unassigned: any) => ({ id: unassigned.id })),
        })),
      }),
    ).then(() => {
      if (query.get('goBack')) {
        history.push(`/${query.get('goBack')}`);
      } else {
        history.push(`/projects/${id}/edit/3`);
      }
    });
  };

  if (form === 'loading') return <SpinnerIcon />;

  const handleCloseAddressSelect = () => {
    setSelectAddressViewData(null);
  };

  if (selectAddressViewData !== null) {
    return (
      <AddressesList
        handleSetAddress={selectAddressViewData.handleSetAddress}
        handleClose={handleCloseAddressSelect}
        selectedAddressId={selectAddressViewData.selectedAddressId}
        selectedAddressName={selectAddressViewData.selectedAddressName}
      />
    );
  }

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Opis'}
            name={'description'}
            value={data.description}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Sieć'}
            name={'networkId'}
            value={data.networkId}
            options={form.networks.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Typ lokalizacji'}
            name={'locationTypeId'}
            value={data.locationTypeId}
            options={form.locationTypes.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Osoba kontaktowa'}
            name={'contactPerson'}
            value={data.contactPerson}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input type={'text'} placeholder={'Kod'} name={'code'} value={data.code} state={data} setState={setData} />
          <Input
            type={'text'}
            placeholder={'Email'}
            name={'email'}
            value={data.email}
            state={data}
            setState={setData}
            errors={errors}
          />
          <AddressSelectSection
            errors={errors}
            setData={setData}
            data={data}
            setSelectAddressViewData={setSelectAddressViewData}
            saveExitCallback={loadForm}
          />
          <SeparatorEmpty />
          <Questions
            unassigned={form.unassignedQuestions.data}
            assigned={form.assignedQuestions.data}
            setForm={setForm}
            title={'Pytania do lokalizacji'}
            defaultValueEdit={true}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
      <WhiteCard style={{ marginTop: '24px' }} padding={true}>
        {form.locationRoles.data.map((item: any) => (
          <RoleUsers
            key={item.roleId}
            roleId={item.roleId}
            roleName={item.roleName}
            assigned={item.users.assigned}
            unassigned={item.users.unassigned}
            setState={setForm}
          />
        ))}
        <ActionsContainer>
          <ButtonSubmit onClick={handleSubmitUsers} form={form} />
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default EditLocation;
