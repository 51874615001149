import React, { FunctionComponent, useState } from 'react';
import styles from '../../views/projects/components/editProject/locations/styles.module.scss';
import Input from '../atoms/Input';
import { IProject, IRole, IUserMultiSelectEntity } from '../../utils/models';
import Select from '../atoms/Select';

interface IUserMultiSelectList {
  data: Array<IUserMultiSelectEntity>;
  checked?: Array<any>;
  editView: boolean;
  handleToggleCheckbox?: (event: any) => void;
  handleToggleMainCheckbox?: () => void;
  column: string;
  counter: number;
  projects?: Array<IProject>;
  roles?: Array<IRole>;
}

const UserMultiSelectList: FunctionComponent<IUserMultiSelectList> = (props) => {
  const [state, setState] = useState<{
    selectProject: string;
    selectRole: string;
    search: string;
    filteredCounter: number;
  }>({
    selectProject: '',
    selectRole: '',
    search: '',
    filteredCounter: props.counter,
  });

  const handleChangeSearch = (event: any) => {
    setState({ ...state, search: event.currentTarget.value });
  };

  const classForCheckedCheckbox = () => {
    let cn = styles.checkbox;
    if (props.checked?.length) {
      cn += ' ' + styles.checked;
    }
    return cn;
  };

  const classForCheckedCheckbox2 = ({ id }: any) => {
    let cn = styles.checkbox2;
    if (props.checked?.includes(id)) {
      cn += ' ' + styles.checked;
    }
    return cn;
  };

  const getFilteredData = () => {
    let filterResult = props.data;

    if (state.search === '' && state.selectRole === '' && state.selectProject === '') {
      return props.data;
    }

    if (state.selectProject !== '') {
      filterResult = filterResult.filter((item) => {
        return item.projectId === state.selectProject;
      });
    }

    if (state.selectRole !== '') {
      filterResult = filterResult.filter((item) => {
        return item.roleIds.indexOf(state.selectRole) >= 0;
      });
    }

    if (state.search !== '') {
      filterResult = filterResult.filter((item) => {
        const nameLowercase = item.username.toLowerCase();
        const searchLowercase = state.search.toLowerCase();
        return nameLowercase.indexOf(searchLowercase) >= 0;
      });
    }

    // setState({...state, filteredCounter: filterResult.length});
    return filterResult;
  };

  return (
    <div style={{ marginBottom: '25px' }}>
      <div style={{ width: '100%' }}>
        <Select
          name={'selectProject'}
          options={
            props.projects
              ? [{ id: '', name: 'Dowolny projekt' }].concat(
                  props.projects.map((p: IProject) => {
                    return { id: p.id, name: p.name };
                  }),
                )
              : []
          }
          placeholder={'Projekt'}
          value={state.selectProject}
          state={state}
          setState={setState}
        />
        <br />

        <Select
          name={'selectRole'}
          options={
            props.roles
              ? [{ id: '', name: 'Dowolna grupa' }].concat(
                  props.roles.map((r: IRole) => {
                    return { id: r.roleId, name: r.roleName };
                  }),
                )
              : []
          }
          placeholder={'Grupa'}
          value={state.selectRole}
          state={state}
          setState={setState}
        />
        <br />
      </div>

      <div className={styles.usersListWrapper}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {props.editView && <span className={classForCheckedCheckbox()} onClick={props.handleToggleMainCheckbox} />}
          <span className={styles.columnAndCounter}>
            {props.column} ({props.counter})
          </span>
        </div>

        <Input
          style={{ maxWidth: '200px' }}
          value={state.search}
          placeholder={'Szukaj'}
          onChange={handleChangeSearch}
          name={'search'}
        />
      </div>
      <div className={styles.wrapperUserDataList}>
        {getFilteredData().map((item: any) => {
          return (
            <div className={styles.user} key={item.id}>
              <div className={styles.wrapper}>
                {props.editView && (
                  <span
                    data-id={item.id}
                    onClick={props.handleToggleCheckbox}
                    className={classForCheckedCheckbox2(item)}
                  />
                )}
                {item.username}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserMultiSelectList;
