import React, { FunctionComponent, useEffect, useState } from 'react';
import { IAddTask, IAddTimeTable } from 'utils/models';
import WhiteCard from 'global/atoms/WhiteCard';
import TasksConnection from 'utils/connections/tasks';
import { deepClone, handleSetErrors, handleToast } from 'utils/helpers';
import { createTaskState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import Form from 'global/form/Form';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import Textarea from 'global/atoms/Textarea';
import EditTimeTable from '../timeTable/EditTimeTable';
import TimeTableConnection from 'utils/connections/timetable';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IAddTaskComponent {}

const AddTask: FunctionComponent<IAddTaskComponent> = () => {
  const [data, setData] = useState<IAddTask>(deepClone(createTaskState));
  const [form, setForm] = useState<any | 'loading'>('loading');
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<any>(null);
  const history = useHistory();

  const getDefaultTaskStatus = (form: any) => {
    return form.taskStatuses.options.find((taskStatus: any) => taskStatus.isDefault).id;
  };

  useEffect(() => {
    TasksConnection.getFormAddTask(id).then((response) => {
      const form = response.data;
      setForm(form);
      setData({ ...data, taskStatusId: getDefaultTaskStatus(form) });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const dataClone = { ...data };
    const expirationDays: any = dataClone.expirationDays;
    dataClone.expirationDays = parseInt(expirationDays);

    handleToast(TasksConnection.createTaskForProject(id, { ...dataClone }), setErrors).then((responseData) => {
      handleSaveTimeTable(responseData.data.taskId);
    });
  };

  const handleSaveTimeTable = (taskId: string) => {
    const addTimeTableData: IAddTimeTable = {
      default: true,
      taskId: taskId,
      projectId: id,
      type: data.timeTable.type,
      userId: null,
      locationId: null,
      manualSettings: data.timeTable.manualSettings,
      recurringSettings: data.timeTable.recurringSettings,
      singleSettings: data.timeTable.singleSettings,
      isApproved: data.timeTable.isApproved,
    };

    TimeTableConnection.createTimeTable(addTimeTableData)
      .then(() => history.push(`/projects/${id}/tasks`))
      .catch((err) => handleSetErrors(err, setErrors));
  };

  if (form === 'loading') return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Opis'}
            name={'description'}
            value={data.description}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Status zadania'}
            name={'taskStatusId'}
            value={data.taskStatusId}
            options={form.taskStatuses.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'number'}
            name="expirationDays"
            value={data.expirationDays}
            placeholder={'Okres ważności [dni]'}
            state={data}
            setState={setData}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
      <WhiteCard marginTop={true}>
        <EditTimeTable parentData={data} parentSetData={setData} />
      </WhiteCard>
    </>
  );
};

export default AddTask;
