import React, { FunctionComponent } from 'react';
import Select from 'react-select';
import styles from '../atoms.module.scss';

interface IMultiSelectProps {
  value: any;
  onChange: any;
  options: Array<any>;
  placeholder: string;
  name: string;
  disabled?: boolean;
  debugData?: any;
}

const SelectMulti: FunctionComponent<IMultiSelectProps> = ({
  value,
  onChange,
  options,
  placeholder,
  name,
  disabled = false,
  debugData,
}) => {
  let optionsRefactored = options;
  if (options[0]?.id !== undefined) {
    optionsRefactored = [];
    options.map((data) => {
      optionsRefactored.push({ value: data.id, label: data.name });
    });
  }

  return (
    <>
      <div className={styles.inputContainer} data-debug={debugData}>
        <Select
          name={name}
          placeholder={''}
          styles={{
            container: (styles) => {
              return {
                ...styles,
                width: '100%',
              };
            },
            control: (styles, { isFocused }) => ({
              ...styles,
              width: '100%',
              border: isFocused ? '1px solid #ffaf38' : '1px solid rgba(93, 92, 92, 0.16)',
              boxShadow: 'none',
              outline: 'none',
              borderRadius: '8px',
              color: '#131315',
              fontFamily: 'Poppins, sans-serif',
              fontSize: '1rem',
              letterSpacing: '0.4px',
              padding: '0 8px',
              cursor: 'pointer',
              ':hover': {
                border: '1px solid rgba(93, 92, 92, 0.16)',
              },
            }),
            option: (styles, { isSelected, isFocused }) => ({
              ...styles,
              color: isSelected ? '#ffffff' : '#131315',
              backgroundColor: isSelected ? '#ffaf38' : isFocused ? 'rgba(255, 175, 56, 0.3)' : '#ffffff',
              cursor: 'pointer',
              ':hover': {
                backgroundColor: isSelected ? '#ffaf38' : 'rgba(255, 175, 56, 0.3)',
              },
            }),
          }}
          value={value}
          onChange={onChange}
          options={optionsRefactored}
          isMulti={true}
          isDisabled={disabled}
        />
        <label className={styles.inputSelectLabel} htmlFor={name}>
          {placeholder}
        </label>
      </div>
    </>
  );
};

export default SelectMulti;
