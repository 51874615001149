import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import Input from '../../../../global/atoms/Input';
import Button from '../../../../global/atoms/Button';
import Section from '../../../../global/atoms/section/Section';
import { ISelectAddressViewData } from './LocationAdd';
import { ICreateLocation } from '../../../../utils/models';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import { chosenAddressDataType } from '../addressesList/AddressesList';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import AddressEditPopupButton from '../addressAdd/AddressEditPopupButton';

interface IAddressSelectSection {
  errors: any;
  data: ICreateLocation;
  setData: any;
  setSelectAddressViewData: (data: ISelectAddressViewData | null) => any;
  saveExitCallback?: () => Promise<any>;
}

const AddressSelectSection: FunctionComponent<IAddressSelectSection> = ({
  data,
  setData,
  errors,
  setSelectAddressViewData,
  saveExitCallback,
}) => {
  const getAddressDataByType = (type: string, addressData: chosenAddressDataType) => {
    return {
      ...data,
      [type]: addressData,
    };
  };

  const handleSetAddress = (addressData: chosenAddressDataType) => {
    setData(getAddressDataByType('addressId', addressData));
    setSelectAddressViewData(null);
  };

  const handleSetPayerAddress = (addressData: chosenAddressDataType) => {
    setData(getAddressDataByType('payerAddressId', addressData));
    setSelectAddressViewData(null);
  };

  const handleSetRecipientAddressId = (addressData: chosenAddressDataType) => {
    setData(getAddressDataByType('recipientAddressId', addressData));
    setSelectAddressViewData(null);
  };

  const getAssignAddressButton = (handleClick: any, selectedAddressId: string, selectedAddressName: string) => {
    return (
      <Button
        onClick={() =>
          setSelectAddressViewData({
            handleSetAddress: handleClick,
            selectedAddressId: selectedAddressId,
            selectedAddressName: selectedAddressName,
          })
        }
      >
        Przypisz adres
      </Button>
    );
  };

  return (
    <>
      <div>
        <Input value={data.addressId.name} placeholder={'Adres'} name={'addressId'} disabled={true} errors={errors} />
        <SeparatorEmpty />
        <ActionsContainer align={'left'}>
          {getAssignAddressButton(handleSetAddress, data.addressId.id, data.addressId.name)}
          {saveExitCallback !== undefined && data.addressId.id !== '' && (
            <AddressEditPopupButton addressId={data.addressId.id} saveExitCallback={saveExitCallback} />
          )}
        </ActionsContainer>
      </div>
      <Input placeholder={'NIP'} name={'nip'} value={data.nip} state={data} setState={setData} errors={errors} />
      <Section title={'Opcje sprzedaży'}>
        <Row>
          <Col xs={6}>
            <div>
              <Input
                value={data.payerName}
                placeholder={'Nazwa nabywcy'}
                name={'payerName'}
                errors={errors}
                state={data}
                setState={setData}
              />
            </div>
          </Col>
          <Col xs={6}>
            <div>
              <Input
                value={data.recipientName}
                placeholder={'Nazwa odbiorcy'}
                name={'recipientName'}
                errors={errors}
                state={data}
                setState={setData}
              />
            </div>
          </Col>
        </Row>
        <SeparatorEmpty />
        <Row>
          <Col xs={6}>
            <div>
              <Input
                value={data.payerAddressId.name}
                placeholder={'Adres nabywcy'}
                name={'payerAddressId'}
                disabled={true}
                errors={errors}
              />
              <SeparatorEmpty />
              <ActionsContainer align={'left'}>
                {getAssignAddressButton(handleSetPayerAddress, data.payerAddressId.id, data.payerAddressId.name)}
                {saveExitCallback !== undefined && data.payerAddressId.id !== '' && (
                  <AddressEditPopupButton addressId={data.payerAddressId.id} saveExitCallback={saveExitCallback} />
                )}
              </ActionsContainer>
            </div>
          </Col>
          <Col xs={6}>
            <div>
              <Input
                value={data.recipientAddressId.name}
                placeholder={'Adres odbiorcy'}
                name={'recipientAddressId'}
                disabled={true}
                errors={errors}
              />
              <SeparatorEmpty />
              <ActionsContainer align={'left'}>
                {getAssignAddressButton(
                  handleSetRecipientAddressId,
                  data.recipientAddressId.id,
                  data.recipientAddressId.name,
                )}
                {saveExitCallback !== undefined && data.recipientAddressId.id && (
                  <AddressEditPopupButton addressId={data.recipientAddressId.id} saveExitCallback={saveExitCallback} />
                )}
              </ActionsContainer>
            </div>
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default AddressSelectSection;
