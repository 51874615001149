import React, { FunctionComponent } from 'react';
import Select from '../Select';
import CSS from 'csstype';
import styles from '../atoms.module.scss';

interface ISelectHourProps {
  name: string;
  state?: any;
  setState?: any;
  disabled?: boolean;
  value?: string | number | null | Array<any>;
  style?: CSS.Properties;
}

const SelectTime: FunctionComponent<ISelectHourProps> = ({ name, state, setState, value, disabled, style }) => {
  const valueString = String(value);
  const checkIsTimeValue = (value: any) => {
    if (typeof value !== 'string') {
      return false;
    }
    const valueParts = value.split(':');
    return valueParts.length === 2;
  };

  const hourOptions = [];
  for (let i = 0; i <= 23; i++) {
    const label = i < 10 ? '0' + i : `${i}`;
    hourOptions.push({ id: label, name: `${label}` });
  }

  const minuteOptions = [];
  for (let i = 0; i <= 60; i += 15) {
    let label = i < 10 ? '0' + i : `${i}`;
    if (label === '60') {
      label = '59';
    }

    minuteOptions.push({ id: label, name: `${label}` });
  }

  const handleChangeHour = (object: any) => {
    let time;
    if (checkIsTimeValue(valueString)) {
      const timeParts = valueString.split(':');
      time = `${object.value}:${timeParts[1]}`;
    } else {
      time = `${object.value}:00`;
    }

    setState({ ...state, [name]: time });
  };

  const handleChangeMinute = (object: any) => {
    let time;
    if (checkIsTimeValue(valueString)) {
      const timeParts = valueString.split(':');
      time = `${timeParts[0]}:${object.value}`;
    } else {
      time = `00:${object.value}`;
    }

    setState({ ...state, [name]: time });
  };

  let hour = null;
  let minute = null;
  if (checkIsTimeValue(valueString)) {
    const timeParts = valueString.split(':');
    hour = timeParts[0];
    minute = timeParts[1];
  }

  return (
    <div className={styles.timeSelectContainer}>
      <Select
        placeholder={'Godzina'}
        name={name}
        options={hourOptions}
        state={state}
        setState={setState}
        value={hour}
        disabled={disabled}
        style={style}
        onChange={handleChangeHour}
      />
      <Select
        placeholder={'Minuta'}
        name={name}
        options={minuteOptions}
        state={state}
        setState={setState}
        value={minute}
        disabled={disabled}
        style={style}
        onChange={handleChangeMinute}
      />
    </div>
  );
};

export default SelectTime;
