import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId, handleToast } from '../helpers';
import { IReportTemplateData } from '../../views/projects/components/reports/templates/BaseTemplate';
import { IReportGenerateData } from '../../views/projects/components/reports/Reports';
import { AxiosPromise } from 'axios';

const ReportTemplateConnection = {
  saveTemplate: (data: IReportTemplateData) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/report_template`, data);
  },
  updateTemplate: (templateId: string, data: IReportTemplateData) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/report_template/${templateId}`,
      data,
    );
  },
  getTemplateById: (templateId: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/report_template/${templateId}`,
    );
  },
  getGenerateReportViewData: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/report_template/view/generate_report/${projectId}`,
    );
  },
  getFile: (fileName: string, data: IReportGenerateData) => {
    handleToast(
      axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/report/generate`, data, {
        headers: getAuthHeader(),
        responseType: 'blob',
      }),
    ).then((response) => {
      const csvURL = window.URL.createObjectURL(response.data);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', fileName);
      tempLink.click();
    });
  },
  getAsyncReportResults: (projectId: string, reportType: number): AxiosPromise => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/report/${projectId}/latest-async-results/${reportType}`,
    );
  },
  generateAsyncReport: (data: IReportGenerateData): AxiosPromise => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/report/generate-async`,
      data,
    );
  },
  getAsyncFile: (reportResultId: string, fileName: string) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/report/file/${reportResultId}`, {
        headers: getAuthHeader(),
        responseType: 'blob',
      })
      .then((response) => {
        const csvURL = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', fileName);
        tempLink.click();
      });
  },
};

export default ReportTemplateConnection;
