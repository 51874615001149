import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import TasksConnection from 'utils/connections/tasks';
import { useHistory, useParams } from 'react-router-dom';
import { getProjectTasksState } from 'utils/states';
import { IGetProjectTasks, ITasks } from 'utils/models';
import Filters from 'global/filters/Filters';
import Pagination from 'global/pagination/Pagination';
import List from 'global/table/List';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import arrow from 'assets/svg/arrow_down_small.svg';
import toast from 'react-hot-toast';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import Spinner from 'global/atoms/Spinner/Spinner';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import IconButton from '../../../../global/atoms/IconButton';
import { handleClearFilters } from '../../../../utils/filterHelper';
import BadgeStatus from '../../../../global/atoms/badge/BadgeStatus';
import { DeleteSingleButton } from '../../../../utils/toggleToDeleteHelper';

interface ITasksList {}

const TasksList: FunctionComponent<ITasksList> = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<ITasks | null | 'error'>(null);
  const [filters, setFilters] = useState<IGetProjectTasks>(getProjectTasksState);
  const [form, setForm] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [toggleFilters, setToggleFilters] = useState(false);
  const history = useHistory();
  const [reloadData, setReloadData] = useState<boolean>(false);

  useEffect(() => {
    TasksConnection.getFiltersForTasks(id).then((data) => {
      setLoading(false);
      setForm(data.data);
    });
  }, []);

  const loadData = () => {
    TasksConnection.getTasksForProject(id, filters).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  };

  useEffect(() => loadData(), [filters.requestPaginate]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
  }, [reloadData]);

  const handleToggleSort = (event: any) => {
      if (filters.requestOrder.field === event.currentTarget.dataset.field) {
        setFilters({
          ...filters,
          requestOrder: {
            field: filters.requestOrder.field,
            order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
          },
        });
      } else {
        setFilters({
          ...filters,
          requestOrder: {
            field: event.currentTarget.dataset.field,
            order: 'asc',
          },
        });
      }
    },
    handleToggleFilters = () => {
      setToggleFilters((state) => !state);
    },
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      TasksConnection.getTasksForProject(id, {
        ...filters,
        requestPaginate: { page: 1, limit: filters.requestPaginate.limit },
      }).then((data) => {
        setLoading(false);
        setToggleFilters(false);
        setData(data.data);
        setFilters((state: IGetProjectTasks) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleGoToEdit = (taskId: string) => {
      history.push(`/projects/${id}/tasks/${taskId}/edit`);
    },
    handleGoToTask = (taskId: string) => {
      history.push(`/projects/${id}/tasks/${taskId}`);
    };

  const removeTask = (taskId: string, taskName: string) => {
    TasksConnection.deleteTask(id, taskId).then(() => {
      toast.success(`Usunąłeś zadanie o id: ${taskName}`);
      loadData();
    });
  };

  if (loading) return <Spinner />;

  if (data === null) return null;

  if (data === 'error') return <span>Błąd wczytywania danych</span>;

  return (
    <>
      <HeaderButtons>
        <ButtonAddNew form={form} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column flexGrow={1} minWidth={200} align="left">
            <HeaderCell depth={0} width={0}>
              <span
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={handleToggleSort}
                data-field={'name'}
              >
                Nazwa
                {filters.requestOrder.field === 'name' &&
                  (filters.requestOrder.order === 'asc' ? (
                    <img src={arrow} alt={'sort'} />
                  ) : (
                    <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
                  ))}
              </span>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/projects/${id}/tasks/${rowData.id}`}>
                  {rowData.name}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column width={150} align="center">
            <HeaderCell depth={0} width={0}>
              Status
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <BadgeStatus color={rowData.taskStatus.color}>{rowData.taskStatus.name}</BadgeStatus>}
            </Cell>
          </Column>
          <Column width={150} align="center">
            <HeaderCell depth={0} width={0}>
              Lokalizacje
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="locationsCount" />
          </Column>
          <Column width={150} align="center">
            <HeaderCell depth={0} width={0}>
              Użytkownicy
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="usersCount" />
          </Column>
          <Column width={120} verticalAlign={'middle'} resizable={true} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <ActionsContainer gapSize={0}>
                  <IconButton icon={'arrowRightBig'} onClick={handleGoToTask.bind(null, rowData.id)} />
                  <IconButton icon={'settings'} onClick={handleGoToEdit.bind(null, rowData.id)} />
                  <DeleteSingleButton callback={removeTask.bind(null, rowData.id, rowData.name)} form={form} />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters.bind(null, setToggleFilters)}
        handleSubmit={handleSubmitFilters.bind(null, setReloadData)}
        handleClear={handleClearFilters.bind(null, setFilters, getProjectTasksState, setReloadData)}
      />
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
    </>
  );
};

export default TasksList;
