import React, { FunctionComponent } from 'react';
import ReactProgressBar from '@ramonak/react-progress-bar';
import styles from './atoms.module.scss';

interface IProgressBarProps {
  completed: number;
}

const ProgressBar: FunctionComponent<IProgressBarProps> = ({ completed }) => {
  return (
    <>
      <ReactProgressBar className={styles.customProgressBar} completed={completed} />
    </>
  );
};

export default ProgressBar;
