import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import ProjectsConnection from 'utils/connections/projects';
import { IGetProjects, IProjects } from 'utils/models';
import { useHistory } from 'react-router-dom';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import { getProjectsState } from 'utils/states';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import List from 'global/table/List';
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import Spinner from 'global/atoms/Spinner/Spinner';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import {
  DeleteSelectedButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import { handleToast } from '../../../../utils/helpers';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import IconButton from '../../../../global/atoms/IconButton';

interface IProjectsList {}

const ProjectsList: FunctionComponent<IProjectsList> = () => {
  const [data, setData] = useState<IProjects | null | 'error'>(null);
  const [filters, setFilters] = useState<IGetProjects>(getProjectsState);
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [filtersForm, setFiltersForm] = useState<any>('loading');
  const history = useHistory();
  const dispatch = useDispatch();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  useEffect(() => {
    ProjectsConnection.getFormFilterProjects().then((data) => {
      setFiltersForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  const reloadData = () => {
    ProjectsConnection.getProjects(filters).then((data) => {
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  };

  useEffect(() => {
    setFilters((state: IGetProjects) => ({
      ...state,
      requestPaginate: {
        page: 1,
        limit: state.requestPaginate.limit,
      },
    }));

    reloadData();
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    reloadData();
  }, [filters.requestPaginate.page]);

  const handleToggleFilters = () => setToggleFilters((state: boolean) => !state),
    handleClearFilters = () => setFilters(getProjectsState),
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      ProjectsConnection.getProjects({
        ...filters,
        requestPaginate: {
          page: 1,
          limit: filters.requestPaginate.limit,
        },
      }).then((data) => {
        setToggleFilters(false);
        setData(data.data);
        setFilters((state: IGetProjects) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleGoToEdit = (projectId: string) => {
      history.push(`/projects/${projectId}/edit/1`);
    },
    handleGoToProject = (projectId: string) => {
      history.push(`/projects/${projectId}/visits`);
    };

  if (data === null || filtersForm === 'loading') return <Spinner />;

  if (data === 'error') return <div>Błąd pobierania danych</div>;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(ProjectsConnection.batchDelete(toDelete)).then(() => {
      setChosenToDelete([]);
      reloadData();
    });
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
        <ButtonAddNew form={filtersForm} />
        <DeleteSelectedButton chosenToDelete={chosenToDelete} callback={handleDeleteSelected} form={filtersForm} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox data={data.data} state={chosenToDelete} setState={setChosenToDelete} />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox state={chosenToDelete} setState={setChosenToDelete} selectedId={rowData.id} />
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} minWidth={200} align="left">
            <HeaderCell depth={0} width={0}>
              Nazwa
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <>
                  <ButtonListRedirect redirectUrl={`/projects/${rowData.id}/visits`}>{rowData.name}</ButtonListRedirect>
                </>
              )}
            </Cell>
          </Column>
          <Column width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Klient
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <span>{rowData.customer.name}</span>}
            </Cell>
          </Column>
          <Column width={150} align="right">
            <HeaderCell depth={0} width={0}>
              Status
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <span
                  className={styles.status}
                  style={{ color: rowData.projectStatus.color, borderColor: rowData.projectStatus.color }}
                >
                  {rowData.projectStatus.name}
                </span>
              )}
            </Cell>
          </Column>
          <Column width={120} verticalAlign={'middle'} resizable={true} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <ActionsContainer gapSize={0}>
                  <IconButton icon={'arrowRightBig'} onClick={handleGoToProject.bind(null, rowData.id)} />
                  <IconButton icon={'settings'} onClick={handleGoToEdit.bind(null, rowData.id)} />
                  <DeleteSingleButton callback={handleDeleteSelected.bind(null, rowData.id)} form={filtersForm} />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters}
      >
        <Input
          value={filters.name}
          placeholder={'Nazwa'}
          name={'name'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Klient'}
          name={'customerId'}
          value={filters.customerId}
          options={filtersForm.customers.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Status'}
          name={'projectStatusId'}
          value={filters.projectStatusId}
          options={filtersForm.projectStatuses.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.startDate}
          placeholder={'Data rozpoczęcia'}
          name={'startDate'}
          type={'date'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.endDate}
          placeholder={'Data zakończenia'}
          name={'endDate'}
          type={'date'}
          setState={setFilters}
          state={filters}
        />
      </Filters>
    </>
  );
};

export default ProjectsList;
