import React, { FunctionComponent } from 'react';
import styles from '../pagination/styles.module.scss';

interface IPaginationProps {
  countPages: number;
  setPage: any;
  currentPage: number;
}

const Pagination: FunctionComponent<IPaginationProps> = ({ currentPage, setPage, countPages }) => {
  const handleChangePage = (page: number) => setPage(page);
  const getPageButtonComponent = (i: number, customName?: string) => {
    return (
      <span
        key={i}
        onClick={handleChangePage.bind(null, i)}
        data-page={i}
        className={currentPage === i ? styles.activePageSimpleVersion : styles.pageSimpleVersion}
      >
        {customName !== undefined ? customName : i}
      </span>
    );
  };
  const getPages = () => {
    const pageComponents = [];

    const startFrom = currentPage - 5 > 0 ? currentPage - 5 : 1;
    const displayLimit = 10;
    let countAdded = 0;
    let lastAddedPageNumber = 0;

    if (startFrom > 1) {
      pageComponents.push(getPageButtonComponent(1, '1 ...'));
    }

    for (let i = startFrom; i <= countPages; i++) {
      pageComponents.push(getPageButtonComponent(i));

      countAdded++;
      if (countAdded >= displayLimit) {
        break;
      }

      lastAddedPageNumber = i;
    }

    if (lastAddedPageNumber < countPages) {
      pageComponents.push(getPageButtonComponent(countPages, `... ${countPages}`));
    }

    return pageComponents;
  };
  return (
    <>
      <div style={{ maxWidth: '100%' }}>{getPages()}</div>
    </>
  );
};

export default Pagination;
