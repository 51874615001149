import React, { FunctionComponent } from 'react';
import LegendCircle, { LegendCircleColorsType } from './LegendCircle';
import { MANUAL_TYPE, RECURRING_TYPE, SINGLE_TYPE } from '../EditTimeTable';
import { IAddTimeTableRecurringSettings } from '../../../../../utils/models';

interface ITablePreviewBodyButtonProps {
  timeTablesDisplay: Array<any>;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

const TablePreviewBodyButton: FunctionComponent<ITablePreviewBodyButtonProps> = ({
  timeTablesDisplay,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  let size: 1 | 2 | 3 = 1;
  if (timeTablesDisplay.length > 1 && timeTablesDisplay.length <= 5) {
    size = 2;
  } else if (timeTablesDisplay.length > 5) {
    size = 3;
  }

  const types: Array<number> = [];
  let isApproved = true;
  timeTablesDisplay.map((data: any) => {
    if (!types.includes(data.type)) {
      types.push(data.type);
    }

    if (!data.isApproved) {
      isApproved = false;
    }
  });

  let color: LegendCircleColorsType = 'blue';
  if (types.length === 1 && types[0] === SINGLE_TYPE) {
    color = 'blue';
  } else if (types.length === 1 && types[0] === MANUAL_TYPE) {
    color = 'blue';
  } else if (types.length === 1 && types[0] === RECURRING_TYPE) {
    const recurringTypes: Array<number> = [];
    timeTablesDisplay[0].recurringSettings.map((recurringSetting: IAddTimeTableRecurringSettings) => {
      recurringTypes.push(recurringSetting.type);
    });
    if (recurringTypes.length === 1 && recurringTypes[0] === 1) {
      color = 'orange';
    } else if (recurringTypes.length === 1 && recurringTypes[0] === 2) {
      color = 'green';
    } else if (recurringTypes.length === 1 && recurringTypes[0] === 4) {
      color = 'purple';
    } else if (recurringTypes.length > 1) {
      color = 'mixed';
    }
  } else if (types.length > 1) {
    color = 'mixed';
  }

  return (
    <>
      <LegendCircle
        redBorder={!isApproved}
        color={color}
        size={size}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </>
  );
};

export default TablePreviewBodyButton;
