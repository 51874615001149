import React, { FunctionComponent, useRef } from 'react';
import { IReviewPhotoSimple, IReviewVisit } from '../../../utils/models';
import styles from './styles.module.scss';

interface IPhotoReviewThumb {
  photoReview(ps: IReviewPhotoSimple, visit: IReviewVisit, state: IPhotoThumbState): void;
  visit: IReviewVisit;
  state: IPhotoThumbState;
  reviewPhotoSimple: IReviewPhotoSimple;
  reference: React.MutableRefObject<null>;
}

export interface IPhotoThumbState {
  hasKPIFeedback: boolean;
  hasSLAFeedback: boolean;
}

export const srcPlaceholder = '/jpeg/placeholder.jpeg';
const PhotoReviewThumb: FunctionComponent<IPhotoReviewThumb> = (props) => {
  if (props.reviewPhotoSimple.value.value === '') props.reviewPhotoSimple.value.value = null;
  const containerScrollPos = useRef(0);

  const handleOnMouseDown = () => {
    containerScrollPos.current = document.getElementsByClassName(
      `visit-thumbs-container-${props.visit.id}`,
    )[0].children[0].children[0].children[0].scrollLeft;
  };

  const handleOnMouseUp = () => {
    // hacky but stable
    const currentScrollLeft = document.getElementsByClassName(`visit-thumbs-container-${props.visit.id}`)[0].children[0]
      .children[0].children[0].scrollLeft;
    if (containerScrollPos.current === currentScrollLeft) {
      props.photoReview(props.reviewPhotoSimple, props.visit, props.state);
    } else {
      console.log('scroll perform - skip review modal');
    }
  };

  return (
    <div className={`${styles.photoReviewThumbWrapper}`} onMouseDown={handleOnMouseDown} onMouseUp={handleOnMouseUp}>
      <div className={styles.thumbTypeMark}>
        {props.state.hasKPIFeedback ? <div className={styles.active}>KPI</div> : <div>KPI</div>}
        {props.state.hasSLAFeedback ? <div className={styles.active}>SLA</div> : <div>SLA</div>}
      </div>
      <div className={styles.photoReviewImageWrapper}>
        <img ref={props.reference} src={props.reviewPhotoSimple.value.value ?? srcPlaceholder} alt={'thumbnail'} />
      </div>
    </div>
  );
};

export default PhotoReviewThumb;
