import React from 'react';
import BaseComponent from '../../../../global/atoms/BaseComponent';
import { IBaseComponentProps } from '../../../../utils/models';
import { deepClone, parseTimeForBackend } from '../../../../utils/helpers';
import { DayModifiers } from 'react-day-picker/types/Modifiers';
import DayPickerCustom from '../../../../global/atoms/DayPickerCustom';

interface IEditTimeTableManualSettingsProps extends IBaseComponentProps {
  parentData: any;
  parentSetData: any;
}

export default class EditTimeTableManualSettings extends BaseComponent<IEditTimeTableManualSettingsProps, any> {
  dayClickHandle(day: Date, data: DayModifiers) {
    const parentDataCloned = deepClone(this.props.parentData);
    const dateSeconds = parseTimeForBackend(day.getTime());

    if (data?.selected) {
      parentDataCloned.timeTable.manualSettings.days = parentDataCloned.timeTable.manualSettings.days.filter(
        (dayNumber: number) => dayNumber !== dateSeconds,
      );
    } else {
      parentDataCloned.timeTable.manualSettings.days.push(dateSeconds);
    }

    this.props.parentSetData({ ...parentDataCloned });
  }

  renderView(): JSX.Element {
    const getSelectedDays = () => {
      const selectedDaysObjects: Array<Date> = [];
      this.props.parentData.timeTable.manualSettings.days.map((day: number) => {
        selectedDaysObjects.push(new Date(day * 1000));
      });
      return selectedDaysObjects;
    };
    return <DayPickerCustom selectedDays={getSelectedDays()} onDayClick={this.dayClickHandle.bind(this)} />;
  }
}
