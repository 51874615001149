import { default as axios } from '../AxiosHelper';
import { getOrganizationId } from '../helpers';
import {
  IPhotoReviewFeedback,
  IPhotoReviewFormState,
  IVisitPhotoReviewEntity,
  IPhotoReviewRequestData,
} from '../models';
import { AxiosResponse } from 'axios';

const baseUrl = `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/visits`;

interface IPhotoReviewData {
  result: IVisitPhotoReviewEntity[];
  paginateResult: any;
}

const VisitReviewsConnection = {
  getPhotoReviewData: (data: IPhotoReviewRequestData): Promise<AxiosResponse<IPhotoReviewData>> => {
    return axios.post(baseUrl + '/photoQuestionAnswers', data);
  },
  getPhotoReviewFilter: (): Promise<AxiosResponse<any>> => {
    return axios.get(baseUrl + '/photoQuestionAnswers/filter/form');
  },
  submitPhotoReview: (questionAnswerId: string, data: IPhotoReviewFormState): Promise<AxiosResponse<any>> => {
    return axios.post(`${baseUrl}/questionAnswer/${questionAnswerId}/feedback`, data);
  },
  submitPhotoReviewEdit: (
    questionAnswerId: string,
    feedbackId: string,
    data: IPhotoReviewFormState,
  ): Promise<AxiosResponse<any>> => {
    return axios.patch(`${baseUrl}/questionAnswer/${questionAnswerId}/feedback/${feedbackId}`, data);
  },
  getFullSizePhoto: (
    visitQuestionAnswerId: string,
  ): Promise<
    AxiosResponse<{
      id: string;
      value: string;
    }>
  > => {
    return axios.get(`${baseUrl}/question/${visitQuestionAnswerId}/photo`, undefined, 'getFullSizePhoto');
  },
  downloadPhotoUrl: (visitQuestionAnswerId: string): string => {
    // todo: nie spójne urle...
    return `${
      process.env.REACT_APP_API_URL
    }/organizations/${getOrganizationId()}/visits/question/${visitQuestionAnswerId}/photo/download`;
  },
  getFeedbackForm: (visitQuestionAnswerId: string): Promise<AxiosResponse<IPhotoReviewFeedback[]>> => {
    return axios.get(
      `${baseUrl}/photoQuestionAnswers/${visitQuestionAnswerId}/feedback/form`,
      undefined,
      'getFeedbackQuestions',
    );
  },
  getFeedbackAnswers: (visitQuestionAnswerId: string): Promise<AxiosResponse<IPhotoReviewFeedback[]>> => {
    return axios.get(`${baseUrl}/photoQuestionAnswers/${visitQuestionAnswerId}/feedback`);
  },
};

export default VisitReviewsConnection;
