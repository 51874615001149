import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { AxiosResponse } from 'axios';
import { IGetProjectLocations, ILocationPaginated, IVisitCreate, IVisitForm } from '../models';

const baseUrl = `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects`;
const VisitsConnection = {
  getForm: (projectId: string): Promise<AxiosResponse<IVisitForm>> => {
    return axios.get(`${baseUrl}/${projectId}/visits/form`, { headers: getAuthHeader() });
  },
  getLocations: (projectId: string, requestData: IGetProjectLocations): Promise<AxiosResponse<ILocationPaginated>> => {
    return axios.post(
      `${baseUrl}/${projectId}/locations/assigned/list`,
      requestData,
      {
        headers: getAuthHeader(),
      },
      'getLocations',
    );
  },

  createVisit: (projectId: string, data: IVisitCreate): Promise<AxiosResponse> => {
    return axios.post(`${baseUrl}/${projectId}/visits`, data, {
      headers: getAuthHeader(),
    });
  },
};

export default VisitsConnection;
