import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import previous from '../../assets/svg/arrow_left.svg';
import next from '../../assets/svg/arrow_right.svg';
import SelectPagination from '../atoms/SelectPagination';

interface IPagination {
  count: number;
  page: string;
  limit: string;
  setState: (state: any) => void;
  maxLimitOptions?: number;
  style?: any;
  allowLimitChange?: boolean;
}

const Pagination: FunctionComponent<IPagination> = ({
  count,
  limit,
  page,
  setState,
  maxLimitOptions,
  style,
  allowLimitChange = true,
}) => {
  const handleChangeRows = (event: any) => {
    setState((state: any) => ({
      ...state,
      requestPaginate: { limit: parseInt(event.target.value), page: state.requestPaginate.page },
    }));
  };

  const handleChangePage = (event: any) => {
    setState((state: any) => ({
      ...state,
      requestPaginate: { page: parseInt(event.target.dataset.page), limit: state.requestPaginate.limit },
    }));
  };

  const handlePreviousPage = () => {
    if (parseInt(page) > 1) {
      setState((state: any) => ({
        ...state,
        requestPaginate: { limit: state.requestPaginate.limit, page: parseInt(page) - 1 },
      }));
    }
  };

  const handleNextPage = () => {
    if (parseInt(page) < Math.ceil(count / parseInt(limit))) {
      setState((state: any) => ({
        ...state,
        requestPaginate: { limit: state.requestPaginate.limit, page: parseInt(page) + 1 },
      }));
    }
  };

  const generatePages = () => {
    const temp = [];
    temp.push(<img onClick={handlePreviousPage} className={styles.img} src={previous} alt="previous" />);
    if (Math.ceil(count / parseInt(limit)) > 6) {
      if (parseInt(page) === 1 || parseInt(page) === 2) {
        for (let i = 1; i <= 3; i++) {
          temp.push(
            <span
              key={i}
              onClick={handleChangePage}
              data-page={i}
              className={parseInt(page) === i ? styles.activePage : styles.page}
            >
              {i}
            </span>,
          );
        }
        temp.push(
          <span key={'more'} className={styles.page}>
            ...
          </span>,
          <span
            key={Math.ceil(count / parseInt(limit))}
            onClick={handleChangePage}
            data-page={Math.ceil(count / parseInt(limit))}
            className={parseInt(page) === Math.ceil(count / parseInt(limit)) ? styles.activePage : styles.page}
          >
            {Math.ceil(count / parseInt(limit))}
          </span>,
        );
      } else if (
        parseInt(page) === Math.ceil(count / parseInt(limit)) ||
        parseInt(page) === Math.ceil(count / parseInt(limit)) - 1
      ) {
        temp.push(
          <span
            key={1}
            onClick={handleChangePage}
            data-page={1}
            className={parseInt(page) === 1 ? styles.activePage : styles.page}
          >
            {1}
          </span>,
          <span key={'more'} className={styles.page}>
            ...
          </span>,
        );
        for (let i = Math.ceil(count / parseInt(limit)) - 2; i <= Math.ceil(count / parseInt(limit)); i++) {
          temp.push(
            <span
              key={i}
              onClick={handleChangePage}
              data-page={i}
              className={parseInt(page) === i ? styles.activePage : styles.page}
            >
              {i}
            </span>,
          );
        }
      } else if (parseInt(page) === 3) {
        for (let i = 1; i <= 4; i++) {
          temp.push(
            <span
              key={i}
              onClick={handleChangePage}
              data-page={i}
              className={parseInt(page) === i ? styles.activePage : styles.page}
            >
              {i}
            </span>,
          );
        }
        temp.push(
          <span key={'more'} className={styles.page}>
            ...
          </span>,
          <span
            key={Math.ceil(count / parseInt(limit))}
            onClick={handleChangePage}
            data-page={Math.ceil(count / parseInt(limit))}
            className={parseInt(page) === Math.ceil(count / parseInt(limit)) ? styles.activePage : styles.page}
          >
            {Math.ceil(count / parseInt(limit))}
          </span>,
        );
      } else if (parseInt(page) === Math.ceil(count / parseInt(limit)) - 2) {
        temp.push(
          <span
            key={1}
            onClick={handleChangePage}
            data-page={1}
            className={parseInt(page) === 1 ? styles.activePage : styles.page}
          >
            {1}
          </span>,
          <span key={'more'} className={styles.page}>
            ...
          </span>,
        );
        for (let i = Math.ceil(count / parseInt(limit)) - 3; i <= Math.ceil(count / parseInt(limit)); i++) {
          temp.push(
            <span
              key={i}
              onClick={handleChangePage}
              data-page={i}
              className={parseInt(page) === i ? styles.activePage : styles.page}
            >
              {i}
            </span>,
          );
        }
      } else {
        temp.push(
          <span
            key={1}
            onClick={handleChangePage}
            data-page={1}
            className={parseInt(page) === 1 ? styles.activePage : styles.page}
          >
            {1}
          </span>,
          <span key={'more'} className={styles.page}>
            ...
          </span>,
        );
        for (let i = parseInt(page) - 1; i <= parseInt(page) + 1; i++) {
          temp.push(
            <span
              key={i}
              onClick={handleChangePage}
              data-page={i}
              className={parseInt(page) === i ? styles.activePage : styles.page}
            >
              {i}
            </span>,
          );
        }
        temp.push(
          <span key={'more'} className={styles.page}>
            ...
          </span>,
          <span
            key={Math.ceil(count / parseInt(limit))}
            onClick={handleChangePage}
            data-page={Math.ceil(count / parseInt(limit))}
            className={parseInt(page) === Math.ceil(count / parseInt(limit)) ? styles.activePage : styles.page}
          >
            {Math.ceil(count / parseInt(limit))}
          </span>,
        );
      }
    } else {
      for (let i = 1; i <= Math.ceil(count / parseInt(limit)); i++) {
        temp.push(
          <span
            key={i}
            onClick={handleChangePage}
            data-page={i}
            className={parseInt(page) === i ? styles.activePage : styles.page}
          >
            {i}
          </span>,
        );
      }
    }

    temp.push(<img onClick={handleNextPage} className={styles.img} src={next} alt="previous" />);
    return temp;
  };

  const limitNumber = parseInt(limit);
  const limitRows = limitNumber < 5 ? '10' : limit;

  const getLimitOptions = () => {
    const limitOptions = [
      { id: '5', name: '5' },
      { id: '10', name: '10' },
      { id: '20', name: '20' },
      { id: '30', name: '30' },
      { id: '40', name: '40' },
      { id: '50', name: '50' },
      { id: '100', name: '100' },
      { id: '200', name: '200' },
      { id: '300', name: '300' },
    ];
    const limitOptionsAvailable: Array<any> = [];
    limitOptions.map((option) => {
      const limitNumber = parseInt(option.name);
      if (maxLimitOptions === undefined || limitNumber <= maxLimitOptions) {
        limitOptionsAvailable.push(option);
      }
    });

    return limitOptionsAvailable;
  };

  return (
    <div className={styles.paginationContainer} style={style ?? {}}>
      {allowLimitChange ? (
        <SelectPagination
          name={'limit'}
          onChange={handleChangeRows}
          options={getLimitOptions()}
          placeholder={'Limit'}
          value={limitRows}
        />
      ) : (
        <div>&nbsp;</div>
      )}

      <div className={styles.center}>
        Pokaż {parseInt(page) * parseInt(limit) + 1 - parseInt(limit)} -{' '}
        {count < parseInt(page) * parseInt(limit) ? count : parseInt(page) * parseInt(limit)} z {count}
        {generatePages()}
      </div>
    </div>
  );
};

export default Pagination;
