import React, { FunctionComponent, useEffect, useState } from 'react';
import ActionsContainer from '../../../../../global/atoms/ActionsContainer';
import Button from '../../../../../global/atoms/Button';
import { confirmModal } from '../../../../../redux/store';
import { handleToast } from '../../../../../utils/helpers';
import ListingConnection from '../../../../../utils/connections/listing';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import CheckBox from '../../../../../global/atoms/checkbox/CheckBox';
import SeparatorEmpty from '../../../../../global/atoms/separators/SeparatorEmpty';
import styles from './styles.module.scss';
import List from '../../../../../global/table/List';
import Spinner from '../../../../../global/atoms/Spinner/Spinner';
import Modal from '../../../../../global/atoms/Modal/Modal';
import ButtonOutlined from '../../../../../global/atoms/ButtonOutlined';

interface IListingNetworkEdit {
  activityId: string;
  listingType: 'product' | 'question';
  parentSetState: any;
}

interface IProductEntity {
  id: string;
  name: string;
}

interface IQuestionEntity {
  id: string;
  name: string;
}

interface IUnifiedEntity {
  id: string;
  name: string;
}

interface INetwork {
  id: string;
  name: string;
}

interface IViewData {
  entities: Array<IProductEntity | IQuestionEntity>;
  networks: Array<INetwork>;
}

interface IProductListingSingle {
  networkId: string;
  networkName: string;
  productId: string;
  productName: string;
  activityId: string;
}

interface IQuestionListingSingle {
  networkId: string;
  networkName: string;
  questionId: string;
  questionName: string;
  activityId: string;
}

interface IUniversalListing {
  networkId: string;
  networkName: string;
  entityId: string;
  entityName: string;
  activityId: string;
}

export type UniversalListing = Array<IUniversalListing>;

const ListingNetworkEdit: FunctionComponent<IListingNetworkEdit> = (data) => {
  const [state, setState] = useState<'edit' | null>(null);
  const [viewData, setViewData] = useState<IViewData | null>(null);
  const [existingListing, setExistingListing] = useState<UniversalListing>([]);
  const [modalState, setModalState] = useState<boolean>(true);

  const getUniversalisting = (l: IProductListingSingle | IQuestionListingSingle): IUniversalListing => {
    return {
      networkId: l.networkId,
      networkName: l.networkName,
      entityId: 'productId' in l ? l.productId : l.questionId,
      entityName: 'productName' in l ? l.productName : l.questionName,
      activityId: l.activityId,
    };
  };

  useEffect(() => {
    const tmp = [];
    if (data.listingType === 'question') {
      tmp.push(
        ListingConnection.getEditQuestionForm(data.activityId),
        ListingConnection.getNetworkQuestionList(data.activityId),
      );
    } else if (data.listingType === 'product') {
      tmp.push(
        ListingConnection.getEditItemForm(data.activityId),
        ListingConnection.getNetworkItemList(data.activityId),
      );
    }

    Promise.all(tmp).then((responses) => {
      setViewData(responses[0].data);

      const uels: UniversalListing = [];
      responses[1].data.forEach((e: IProductListingSingle | IQuestionListingSingle) => {
        uels.push(getUniversalisting(e));
      });
      setExistingListing(uels);

      setState('edit');
    });
  }, []);

  if (!state || !viewData) return <Spinner />;

  const addListingData = (
    listingToUpdate: UniversalListing,
    network: INetwork,
    entity: IProductEntity | IQuestionEntity,
  ): UniversalListing => {
    listingToUpdate.push({
      networkId: network.id,
      networkName: network.name,
      entityId: entity.id,
      entityName: entity.name,
      activityId: data.activityId,
    });

    return listingToUpdate;
  };

  const handleClose = () => {
    data.parentSetState('default');
    setModalState(false);
  };

  const handleSave = (): void => {
    if (data.listingType === 'question') {
      handleToast(ListingConnection.saveQuestionListing(data.activityId, existingListing)).then(() => {
        handleClose();
      });
    } else if (data.listingType === 'product') {
      handleToast(ListingConnection.saveProductListing(data.activityId, existingListing)).then(() => {
        handleClose();
      });
    }
  };

  const isCheckedFullNetwork = (networkId: string): boolean => {
    const foundSelectedByNetwork = existingListing.filter((data) => data.networkId === networkId);
    return foundSelectedByNetwork.length === 0;
  };

  const isRejectedFullNetwork = (networkId: string): boolean => {
    const foundSelectedByNetwork = existingListing.filter((data) => data.networkId === networkId);
    return foundSelectedByNetwork.length === viewData.entities.length;
  };

  const isCheckedFullEntity = (eId: string): boolean => {
    const found = existingListing.filter((data) => data.entityId === eId);
    return found.length === 0;
  };

  const isRejectedFullEntity = (eId: string): boolean => {
    const found = existingListing.filter((data) => data.entityId === eId);
    return found.length === viewData.networks.length;
  };

  const handleSelectAllByEntity = (entity: IUnifiedEntity): void => {
    if (isRejectedFullEntity(entity.id)) {
      const newListing = existingListing.filter((data) => data.entityId !== entity.id);
      setExistingListing(newListing);
      return;
    }
    const newListing = existingListing.filter((data) => data.entityId !== entity.id);
    viewData.networks.map((network) => {
      addListingData(newListing, network, entity);
    });
    setExistingListing(newListing);
  };

  const handleUpdateListing = (network: INetwork, obj: IUnifiedEntity): void => {
    if (isRejected(network.id, obj.id)) {
      const listingUpdated = existingListing.filter((data) => {
        return data.networkId !== network.id || data.entityId !== obj.id;
      });
      setExistingListing(listingUpdated);
      return;
    }

    const listingUpdated = [...existingListing];
    addListingData(listingUpdated, network, obj);
    setExistingListing(listingUpdated);
    return;
  };

  const handleSelectAllByNetwork = (network: INetwork): void => {
    if (isRejectedFullNetwork(network.id)) {
      const newListing = existingListing.filter((data) => data.networkId !== network.id);
      setExistingListing(newListing);
      return;
    }

    const newListing = existingListing.filter((data) => data.networkId !== network.id);
    viewData.entities.map((entity) => {
      addListingData(newListing, network, entity);
    });
    setExistingListing(newListing);
  };

  const isRejected = (networkId: string, entityId: string) => {
    return existingListing.find((data) => data.networkId === networkId && data.entityId === entityId) !== undefined;
  };

  return (
    <Modal key={'modal-' + data.listingType} isOpen={modalState} inlineStyles={{ maxWidth: '95vw' }}>
      <div style={{ width: '93vw' }}>
        <List data={viewData.networks} count={viewData.networks.length} headerHeight={300} rowHeight={35}>
          <Column width={300} align="left" fixed={'left'}>
            <HeaderCell depth={0} width={0}>
              &nbsp;
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData: IUnifiedEntity) => (
                <div key={'network_' + rowData.id}>
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      paddingLeft: '30px',
                      position: 'relative',
                    }}
                    title={rowData.name}
                  >
                    <CheckBox
                      isChecked={isCheckedFullNetwork(rowData.id)}
                      onClick={handleSelectAllByNetwork.bind(null, rowData)}
                      style={{ position: 'absolute', left: 0, top: 1 }}
                    />
                    {rowData.name}
                  </div>
                </div>
              )}
            </Cell>
          </Column>
          {viewData.entities.map((entity) => {
            return (
              <Column width={60} align="left" key={entity.id}>
                <HeaderCell depth={0} width={0}>
                  <div style={{ height: '300px', padding: '12px 0' }}>
                    <CheckBox
                      isChecked={isCheckedFullEntity(entity.id)}
                      onClick={handleSelectAllByEntity.bind(null, entity)}
                      style={{ position: 'absoulte' }}
                    />
                    <SeparatorEmpty />
                    <div title={entity.name} className={styles.entityHeader}>
                      <span className={styles.verticalText}>{entity.name}</span>
                    </div>
                  </div>
                </HeaderCell>
                <Cell width={100} depth={0}>
                  {(rowData: IUnifiedEntity) => (
                    <CheckBox
                      onClick={handleUpdateListing.bind(null, rowData, entity)}
                      isChecked={!isRejected(rowData.id, entity.id)}
                      style={{ position: 'initial' }}
                    />
                  )}
                </Cell>
              </Column>
            );
          })}
        </List>
      </div>
      <ActionsContainer style={{ marginTop: '30px' }}>
        <ButtonOutlined onClick={handleClose}>Zamknij</ButtonOutlined>
        <Button onClick={confirmModal('Czy jesteś pewien?', handleSave)}>Zapisz</Button>
      </ActionsContainer>
    </Modal>
  );
};

export default ListingNetworkEdit;
