import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import TranslationsConnection from 'utils/connections/translations';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Form from 'global/form/Form';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import ActionsContainer from 'global/atoms/ActionsContainer';
import { useDispatch } from 'react-redux';
import Spinner from 'global/atoms/Spinner/Spinner';

interface ITranslationsEdit {}

const TranslationsEdit: FunctionComponent<ITranslationsEdit> = () => {
  const [data, setData] = useState<any | 'loading'>('loading');
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    TranslationsConnection.getFormEditTranslation(id).then((data) => {
      setData({
        locale: data.data.model.locale,
        field: data.data.model.field,
        hint: data.data.model.hint,
        text: data.data.model.text,
      });
      // setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(TranslationsConnection.updateTranslation(id, data), setErrors).then(() =>
      history.push('/superadmin/translations'),
    );
  };

  if (data === 'loading') return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Język (kod)'}
            name={'locale'}
            value={data.locale}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Pole'}
            name={'field'}
            value={data.field}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Podpowiedź'}
            name={'hint'}
            value={data.hint}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Treść'}
            name={'text'}
            value={data.text}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default TranslationsEdit;
