import React from 'react';
import BaseComponent from '../BaseComponent';

export default class RadioHorizontalGroup extends BaseComponent<any, any> {
  renderView(): JSX.Element {
    return (
      <div
        style={{
          display: 'grid',
          gap: '10px',
          width: '100%',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
