import React, { FunctionComponent } from 'react';
import TimeTableDetails from '../details/TimeTableDetails';

interface ITimetables {}

const Timetables: FunctionComponent<ITimetables> = () => {
  return <TimeTableDetails type={'task'} />;
};

export default Timetables;
