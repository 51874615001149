import { SET_FILTER } from '../actions';
import { filterState } from '../states';

const headerReducer = (state = filterState, action: any) => {
  switch (action.type) {
    case SET_FILTER:
      return action.payload;
    default:
      return state;
  }
};

export default headerReducer;
