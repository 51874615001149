import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ICreateProject } from 'utils/models';
import { createProjectState } from 'utils/states';
import ProjectsConnection from 'utils/connections/projects';
import { handleSetErrors, handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

interface IAddProject {}

const AddProject: FunctionComponent<IAddProject> = () => {
  const [data, setData] = useState<ICreateProject>(createProjectState);
  const [form, setForm] = useState<any>('loading');
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    ProjectsConnection.getFormCreateProject()
      .then((data) => {
        setForm(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
      })
      .catch((err) => handleSetErrors(err, setErrors));
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(ProjectsConnection.createProject(data), setErrors).then((data) => {
      console.log(data);
      history.push(`/projects/${data.data.projectId}/edit/1`);
    });
  };

  if (form === 'loading') return null;

  return (
    <>
      <WhiteCard padding={true}>
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapperFields}>
            <Input
              style={{ gridColumn: 'span 2' }}
              type={'text'}
              name={'name'}
              value={data.name}
              placeholder={'Nazwa'}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Select
              placeholder={'Klient'}
              disabled={false}
              name={'customerId'}
              value={data.customerId}
              options={form.customers.options}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Select
              placeholder={'Status'}
              disabled={false}
              name={'projectStatusId'}
              value={data.projectStatusId}
              options={form.projectStatuses.options}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Input
              type={'date'}
              name={'startDate'}
              value={data.startDate}
              placeholder={'Data rozpoczęcia'}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Input
              type={'date'}
              name={'endDate'}
              value={data.endDate}
              placeholder={'Data zakończenia'}
              setState={setData}
              state={data}
              errors={errors}
            />
          </div>
          <ActionsContainer style={{ marginTop: '16px' }}>
            <ButtonSubmit />
          </ActionsContainer>
        </form>
      </WhiteCard>
    </>
  );
};

export default AddProject;
