export const findErrorByName = (name: string, errors: any): string => {
  if (errors[name] !== undefined) {
    return errors[name];
  }

  for (const key in errors) {
    const item = errors[key];
    for (const itemKey in item) {
      if (item[name] !== undefined && itemKey === name) {
        return item[name];
      }
    }
  }

  return '';
};
