import React, { FunctionComponent } from 'react';
import styles from '../atoms.module.scss';
import AsyncSelect from 'react-select/async';
import { controlStyles, optionStyles } from '../Select';

interface ISelectAsync {
  onChange?: any;
  value: string | number | null | Array<any>;
  disabled?: boolean;
  placeholder: string;
  error?: string | undefined;
  loadOptionsAjax: any;
  setState?: any;
  state?: any;
}

const SelectAsync: FunctionComponent<ISelectAsync> = ({
  onChange,
  value,
  disabled,
  placeholder,
  error,
  loadOptionsAjax,
}) => {
  return (
    <div className={styles.inputContainer}>
      <AsyncSelect
        loadOptions={loadOptionsAjax}
        onChange={onChange}
        value={value}
        placeholder={''}
        isDisabled={disabled}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            ...controlStyles(isFocused),
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            ...optionStyles(isSelected, isFocused),
          }),
        }}
      />
      <label className={styles.inputSelectLabel}>{placeholder}</label>
      {error && <span className={styles.errors}>{error}</span>}
    </div>
  );
};

export default SelectAsync;
