import React, { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from './Button';
import { DISABLED_ADD_NEW_BUTTON } from '../../utils/disabledElementsConstants';

interface IButtonAddNew {
  redirectUrl?: string;
  form?: any;
}

const ButtonAddNew: FunctionComponent<IButtonAddNew> = ({ redirectUrl, form }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleAddItem = () => {
    const url = redirectUrl !== undefined ? redirectUrl : `${pathname}/add`;
    history.push(url);
  };

  return (
    <Button disabled={form?.disabledElements?.includes(DISABLED_ADD_NEW_BUTTON)} onClick={handleAddItem}>
      Dodaj nowy
    </Button>
  );
};

export default ButtonAddNew;
