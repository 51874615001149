import React, { FunctionComponent } from 'react';
import Button from './Button';
import { DISABLED_FORM_SAVE_BUTTON } from '../../utils/disabledElementsConstants';

interface IButtonSubmit {
  onClick?: any;
  name?: string;
  form?: any;
  className?: string;
  attrName?: string;
  disabled?: boolean;
}

const ButtonSubmit: FunctionComponent<IButtonSubmit> = ({
  onClick,
  name = '',
  form,
  className,
  attrName,
  disabled = false,
}) => {
  return (
    <Button
      attrName={attrName}
      className={className ?? ''}
      onClick={onClick}
      type={onClick ? 'button' : 'submit'}
      disabled={form?.disabledElements?.includes(DISABLED_FORM_SAVE_BUTTON) || disabled}
    >
      {name ? name : 'Zapisz'}
    </Button>
  );
};

export default ButtonSubmit;
