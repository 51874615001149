import React, { FunctionComponent } from 'react';
import styles from '../atoms.module.scss';
import hintIcon from '../../../assets/svg/hint.svg';

const AlertInfo: FunctionComponent<any> = ({ children }) => {
  return (
    <div className={styles.alertInfo}>
      <img className={styles.alertIcon} src={hintIcon} alt={'Informacja'} />
      <span className={styles.alertText}>{children}</span>
    </div>
  );
};

export default AlertInfo;
