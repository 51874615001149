import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from '../editProject/access/styles.module.scss';
import settings from '../../../../assets/svg/edit.svg';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';
import Input from '../../../../global/atoms/Input';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import SelectMulti from '../../../../global/atoms/select/SelectMulti';
import Pagination from '../../../../global/atoms/Pagination';
import { ILocationData } from './Locations';

interface ILocationsList {
  data: Array<ILocationData>;
  setData: any;
  column: string;
  handleGoToEdit: any;
  editable?: boolean;
  networks: Array<any>;
  assigned: boolean;
  reloadData: boolean;
  setReloadData: any;
}

const LIMIT = 15;

const LocationsList: FunctionComponent<ILocationsList> = ({
  data,
  setData,
  column,
  handleGoToEdit,
  editable = true,
  networks,
  assigned,
  reloadData,
  setReloadData,
}) => {
  const [search, setSearch] = useState<string>('');
  const [selectedNetworks, setSelectedNetworks] = useState<Array<any> | null>(null);
  const [filteredData, setFilteredData] = useState<Array<ILocationData>>([]);
  const [page, setPage] = useState<number>(1);

  const clearSelected = () => {
    const dataClone = [...data];
    dataClone.map((item, index) => {
      if (item.assigned === assigned) {
        dataClone[index].checked = false;
      }
    });

    setData(dataClone);
  };

  const getDataByType = () => {
    const dataByType: Array<ILocationData> = [];
    data.map((item) => {
      if (item.assigned === assigned) {
        dataByType.push(item);
      }
    });

    return dataByType;
  };

  useEffect(() => {
    if (selectedNetworks !== null || search.length > 0) {
      const selectedNetworksIds: Array<string> = [];
      if (selectedNetworks !== null) {
        selectedNetworks.map((item) => {
          selectedNetworksIds.push(item.value);
        });
      }

      const filteredData = getDataByType().filter((item: any) => {
        const nameLowerCase = item.name.toLowerCase();
        let includesSearchQuery = true;
        if (search !== '') {
          includesSearchQuery = nameLowerCase.includes(search.toLowerCase());
        }

        let includesSelectedNetwork = true;
        if (selectedNetworksIds.length > 0) {
          includesSelectedNetwork = selectedNetworksIds.includes(item.networkId);
        }

        return includesSearchQuery && includesSelectedNetwork;
      });

      setFilteredData(filteredData);
      clearSelected();
    } else {
      setFilteredData(getDataByType());
    }

    setReloadData(false);
  }, [search, selectedNetworks, reloadData]);

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value);
    setPage(1);
  };

  const getDataSlicedByPage = () => {
    const startSliceFrom = (page - 1) * LIMIT;
    return filteredData.slice(startSliceFrom, startSliceFrom + LIMIT);
  };

  const getCountPages = () => Math.ceil(filteredData.length / LIMIT);

  const handleChangeNetwork = (data: any) => {
    setSelectedNetworks(data);
    setPage(1);
  };

  const getCountSelected = () => {
    let countSelected = 0;
    data.map((item) => {
      if (item.checked && item.assigned === assigned) {
        countSelected++;
      }
    });

    return countSelected;
  };

  const setAllSelected = () => {
    const dataClone = [...data];
    filteredData.map((item) => {
      dataClone[item.index].checked = true;
    });

    setData(dataClone);
  };

  const handleToggleAll = () => {
    if (getCountSelected() > 0) {
      clearSelected();
    } else {
      setAllSelected();
    }
  };

  const handleToggleSingle = (id: string) => {
    const dataClone = [...data];
    dataClone.map((item, index) => {
      if (item.id === id) {
        dataClone[index].checked = !dataClone[index].checked;
      }
    });

    setData(dataClone);
  };

  const checkIsSelected = (locationId: string) => {
    const locationData = data.find((item) => item.id === locationId);
    return locationData !== undefined ? locationData.checked : false;
  };

  return (
    <>
      <div style={{ marginBottom: '25px' }}>
        {column}
        <SeparatorEmpty size={2} />
        <div>
          <SelectMulti
            value={selectedNetworks}
            onChange={handleChangeNetwork}
            options={networks}
            placeholder={'Wybierz sieć'}
            name={'network'}
          />
          <SeparatorEmpty />
          <Input value={search} placeholder={'Szukaj'} name={'search'} onChange={handleChangeSearch} />
        </div>
        <SeparatorEmpty />
        <div className={styles.userListWrapper}>
          <CheckBox
            isChecked={filteredData.length === getCountSelected() && getCountSelected() > 0}
            onClick={handleToggleAll}
          >
            Zaznaczone ({getCountSelected()})
          </CheckBox>
          <span>Znaleziono ({filteredData.length})</span>
        </div>
        <SeparatorEmpty />
        <div className={styles.wrapperSortableLocations}>
          {getDataSlicedByPage().map((item: any) => {
            return (
              <div className={styles.user} key={item.id}>
                <div className={styles.wrapper}>
                  <>
                    <CheckBox
                      id={item.id}
                      isChecked={checkIsSelected(item.id)}
                      onClick={handleToggleSingle.bind(null, item.id)}
                    >
                      {item.name}
                    </CheckBox>
                  </>
                </div>
                {editable && (
                  <img
                    src={settings}
                    style={{ cursor: 'pointer' }}
                    alt={'Idź do'}
                    data-id={item.id}
                    onClick={handleGoToEdit}
                  />
                )}
              </div>
            );
          })}
        </div>
        <SeparatorEmpty />
        {getCountPages() > 1 && <Pagination countPages={getCountPages()} setPage={setPage} currentPage={page} />}
      </div>
    </>
  );
};

export default LocationsList;
