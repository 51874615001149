import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import WhiteCard from 'global/atoms/WhiteCard';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import InstancesConnection from 'utils/connections/instances';
import { Cell, HeaderCell } from 'rsuite-table5';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import BadgeStatus from 'global/atoms/badge/BadgeStatus';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import Spinner from 'global/atoms/Spinner/Spinner';
import ActionsContainer from '../../../../../global/atoms/ActionsContainer';
import {
  DeleteSelectedButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../../utils/toggleToDeleteHelper';
import { deepClone, handleToast } from '../../../../../utils/helpers';
import ButtonListRedirect from '../../../../../global/atoms/ButtonListRedirect';
import IconButton from '../../../../../global/atoms/IconButton';
import { handleClearFilters, loadFilters } from '../../../../../utils/filterHelper';
import FiltersStorage from '../../../../../utils/FiltersStorage';
import { IFiltersVisitsList } from '../../../../../utils/models';
import DayPickerInputCustom from '../../../../../global/atoms/DayPickerInputCustom';
import SeparatorLine from '../../../../../global/atoms/separators/SeparatorLine';
import Button from '../../../../../global/atoms/Button';
import List5 from '../../../../../global/table/List5';
import Column5 from '../../../../../global/table/Column5';
import { visitsListFilterDefault } from '../../../../../utils/states';
import VisitsModalAdd from '../visitsAdd/VisitsModalAdd';

const VisitsList: FunctionComponent = () => {
  const [data, setData] = useState<any>({ data: [] });
  const [loading, setLoading] = useState(true);

  const defaultFilters = deepClone(visitsListFilterDefault);

  const [filters, setFilters] = useState<IFiltersVisitsList>(loadFilters(defaultFilters));
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [filtersForm, setFiltersForm] = useState<any>('loading');
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [modalAddOpen, setModalAddOpen] = useState<boolean>(false);

  useEffect(() => {
    InstancesConnection.getFiltersInstancesList(id).then((data) => {
      setLoading(false);
      setFiltersForm(data.data);
    });
  }, []);

  const loadData = () => {
    const filtersClone = { ...filters };
    filtersClone.projectId = id;
    InstancesConnection.getInstances(id, filtersClone).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  };

  useEffect(() => {
    loadData();
    setFilters((state: any) => ({
      ...state,
      requestPaginate: {
        page: 1,
        limit: state.requestPaginate.limit,
      },
    }));
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    loadData();
  }, [filters.requestPaginate.page, filters.requestOrder]);

  const handleToggleFilters = () => {
      setToggleFilters((state: boolean) => !state);
    },
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      const filtersClone = { ...filters };
      filtersClone.projectId = id;
      const filtersStorage = new FiltersStorage();
      filtersStorage.save(filtersClone);

      InstancesConnection.getInstances(id, {
        ...filtersClone,
        requestPaginate: { page: 1, limit: filtersClone.requestPaginate.limit },
      }).then((data) => {
        setLoading(false);
        setToggleFilters(false);
        setData(data.data);
        setFilters((state: any) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleEdit = (visitId: string) => {
      history.push(`/projects/${id}/visits/${visitId}/edit`);
    };

  if (loading) return <Spinner />;

  if (filtersForm === 'loading') return null;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(InstancesConnection.batchDelete(id, toDelete)).then(() => {
      setChosenToDelete([]);
      loadData();
    });
  };

  const getUserRolesByRole = (): Array<any> => {
    return filtersForm.users.options.filter((user: any) => user.roleId === filters.groupId);
  };

  const DOMheaderButtons = (
    <HeaderButtons>
      <ButtonFilters onClick={handleToggleFilters} />
      <Button onClick={() => setModalAddOpen(true)}>Dodaj wizytę poza planem</Button>
      <DeleteSelectedButton chosenToDelete={chosenToDelete} callback={handleDeleteSelected} form={filtersForm} />
    </HeaderButtons>
  );

  const DOMFilters = (
    <Filters
      toggleFilters={toggleFilters}
      handleSubmit={handleSubmitFilters}
      handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, loadData)}
      handleToggleFilters={handleToggleFilters}
    >
      <Select
        placeholder={'Lokalizacja'}
        name={'locationId'}
        value={filters.locationId}
        options={filtersForm.locations.options}
        disabled={false}
        setState={setFilters}
        state={filters}
      />
      <Input
        value={filters.locationName}
        placeholder={'Lokalizacja nazwa'}
        name={'locationName'}
        setState={setFilters}
        state={filters}
      />
      <Input value={filters.nip} placeholder={'Nip'} name={'nip'} setState={setFilters} state={filters} />
      <Select
        placeholder={'Sieć'}
        name={'networkId'}
        value={filters.networkId}
        options={filtersForm.networks.options}
        state={filters}
        setState={setFilters}
      />
      <Select
        placeholder={'Grupa'}
        name={'groupId'}
        value={filters.groupId}
        options={filtersForm.projectMerchRoles.options}
        state={filters}
        setState={setFilters}
      />
      <Select
        placeholder={'Grupa -> Użytkownik'}
        name={'userRoleId'}
        value={filters.userRoleId}
        options={getUserRolesByRole()}
        state={filters}
        setState={setFilters}
      />
      <SeparatorLine />
      <Select
        placeholder={'Status'}
        name={'statusId'}
        value={filters.statusId}
        options={filtersForm.visitStatus.options}
        state={filters}
        setState={setFilters}
      />
      <DayPickerInputCustom
        value={filters.startDate}
        placeholder={'Data od'}
        name={'startDate'}
        setState={setFilters}
        state={filters}
      />

      <DayPickerInputCustom
        value={filters.endDate}
        placeholder={'Data do'}
        name={'endDate'}
        setState={setFilters}
        state={filters}
      />
      <SeparatorLine />
      <Input name={'street'} value={filters.street} placeholder={'Ulica'} setState={setFilters} state={filters} />
      <Input name={'city'} value={filters.city} placeholder={'Miasto'} setState={setFilters} state={filters} />
      <Input name={'community'} value={filters.community} placeholder={'Gmina'} setState={setFilters} state={filters} />
      <Input name={'district'} value={filters.district} placeholder={'Powiat'} setState={setFilters} state={filters} />
      <Select
        placeholder={'Województwo'}
        name={'voivodeshipId'}
        value={filters.voivodeshipId}
        options={filtersForm.voivodeships.options}
        state={filters}
        setState={setFilters}
      />
    </Filters>
  );

  return (
    <>
      {DOMheaderButtons}
      <WhiteCard mobileTransparent={true} padding={false}>
        <List5 data={data.data} count={data.data.length} rowExpandedHeight={220}>
          <Column5 align={'left'} width={40} verticalAlign={'top'}>
            <HeaderCell width={0}>
              <ToggleAllCheckbox data={data.data} state={chosenToDelete} setState={setChosenToDelete} />
            </HeaderCell>
            <Cell width={100}>
              {(rowData) => (
                <ToggleSingleCheckbox state={chosenToDelete} setState={setChosenToDelete} selectedId={rowData.id} />
              )}
            </Cell>
          </Column5>

          <Column5 align="left" minWidth={300} flexGrow={1}>
            <HeaderCell depth={0} width={0}>
              Lokalizacja
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="locationName">
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/projects/${id}/visits/${rowData.id}/edit`}>
                  {rowData.locationName}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column5>

          <Column5 resizable={true} align="left" width={350}>
            <HeaderCell depth={0} width={0}>
              Raportujący
            </HeaderCell>
            <Cell width={100} depth={0} height={55} dataKey="reportingUserName" />
          </Column5>

          <Column5 verticalAlign={'middle'} align="left" width={150}>
            <HeaderCell depth={0} width={0}>
              Sieć
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="networkName" />
          </Column5>

          <Column5 resizable={true} align="left" width={150}>
            <HeaderCell depth={0} width={0}>
              Data
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="startDate" />
          </Column5>

          <Column5 resizable={true} width={150} align="right">
            <HeaderCell depth={0} width={0}>
              Status
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => {
                return <BadgeStatus color={rowData.status.color}>{rowData.status.name}</BadgeStatus>;
              }}
            </Cell>
          </Column5>

          <Column5 align="right" width={90} fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <ActionsContainer>
                  <IconButton onClick={handleEdit.bind(null, rowData.id)} icon={'settings'} />
                  <DeleteSingleButton callback={handleDeleteSelected.bind(null, rowData.id)} form={filtersForm} />
                </ActionsContainer>
              )}
            </Cell>
          </Column5>
        </List5>
      </WhiteCard>

      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
      {DOMFilters}

      <VisitsModalAdd open={modalAddOpen} setOpen={setModalAddOpen} visitAddedCB={loadData} />
    </>
  );
};

export default VisitsList;
