import React, { FunctionComponent } from 'react';
import ButtonOutlined from './ButtonOutlined';
import { useHistory } from 'react-router-dom';

interface IButtonGoBack {
  onClick?: any;
}

const ButtonGoBack: FunctionComponent<IButtonGoBack> = ({ onClick }) => {
  const history = useHistory();

  const handleClick = () => history.goBack();

  return (
    <ButtonOutlined onClick={onClick ? onClick : handleClick} type={'button'}>
      Powrót
    </ButtonOutlined>
  );
};

export default ButtonGoBack;
