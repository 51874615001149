import React, { FunctionComponent } from 'react';
import Checkbox from '../global/atoms/Checkbox';
import Button from '../global/atoms/Button';
import { confirmModal } from '../redux/store';
import IconButton from '../global/atoms/IconButton';
import { DISABLED_DELETE_SELECTED_BUTTON, DISABLED_FORM_DELETE_BUTTON } from './disabledElementsConstants';

interface IToggleSingleCheckbox {
  state: any;
  setState: any;
  selectedId: string;
}

export const ToggleSingleCheckbox: FunctionComponent<IToggleSingleCheckbox> = ({ state, setState, selectedId }) => {
  const handleToggleItem = (itemId: string) => {
    if (state.includes(itemId)) {
      setState(state.filter((item: string) => item !== itemId));
    } else {
      setState([...state, itemId]);
    }
  };
  return (
    <>
      <Checkbox isChecked={state.includes(selectedId)} onClick={handleToggleItem.bind(null, selectedId)} />
    </>
  );
};

interface IToggleAllCheckbox {
  state: any;
  setState: any;
  data: any;
  keyIdName?: string;
}

export const ToggleAllCheckbox: FunctionComponent<IToggleAllCheckbox> = ({
  state,
  setState,
  data,
  keyIdName = 'id',
}) => {
  const handleToggleAllItems = () => {
    if (data === null) {
      return;
    }

    if (state.length) {
      setState([]);
    } else {
      setState(data.map((item: any) => item[keyIdName]));
    }
  };
  return (
    <>
      <Checkbox isChecked={state.length > 0 && state.length === data.length} onClick={handleToggleAllItems} />
    </>
  );
};

interface IDeleteSelectedButton {
  callback: any;
  chosenToDelete: Array<string>;
  form?: any;
}

export const DeleteSelectedButton: FunctionComponent<IDeleteSelectedButton> = ({ callback, chosenToDelete, form }) => {
  const isDisabled = !chosenToDelete.length || form?.disabledElements?.includes(DISABLED_DELETE_SELECTED_BUTTON);
  return (
    <>
      <Button
        onClick={confirmModal(`Czy usunąć zaznaczone (${chosenToDelete.length})?`, callback)}
        disabled={isDisabled}
      >
        Usuń zaznaczone
      </Button>
    </>
  );
};

interface IBatchActionButton {
  callback: any;
  chosenRows: Array<string>;
  title: string;
  message: string;
}

export const ActionButton: FunctionComponent<IBatchActionButton> = ({ callback, title, message }) => {
  return (
    <>
      <Button onClick={confirmModal(message, callback)}>{title}</Button>
    </>
  );
};

export const BatchActionButton: FunctionComponent<IBatchActionButton> = ({ callback, chosenRows, title, message }) => {
  return (
    <>
      <Button onClick={confirmModal(message + ` (${chosenRows.length})?`, callback)} disabled={!chosenRows.length}>
        {title}
      </Button>
    </>
  );
};

interface IDeleteSingleButton {
  callback: any;
  form?: any;
}

export const DeleteSingleButton: FunctionComponent<IDeleteSingleButton> = ({ callback, form }) => {
  return (
    <>
      <IconButton
        icon={'trash'}
        onClick={confirmModal('Czy usunąć?', callback)}
        disabled={form?.disabledElements?.includes(DISABLED_FORM_DELETE_BUTTON)}
      />
    </>
  );
};

interface ISingleActionButton {
  callback: any;
  message: string;
}

export const SingleActionButton: FunctionComponent<ISingleActionButton> = ({ callback, message }) => {
  return (
    <>
      <IconButton icon={'trash'} onClick={confirmModal(message, callback)} />
    </>
  );
};
