import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';

const InstancesConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getInstances: (projectId: string, data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/visits/list`,
      data,
    );
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createInstance: (projectId: string, data: any) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/visits`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateInstance: (projectId: string, id: string, data: any) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/visits/${id}`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  removeInstances: (data: { visits: Array<{ id: string }> }) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/visits`, {
      headers: getAuthHeader(),
      data: data,
    });
  },
  getFiltersInstancesList: (projectId: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/visits/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getFormEditInstance: (projectId: string, id: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/visits/${id}/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  batchDelete: (projectId: string, data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/visits`, {
      headers: { ...getAuthHeader() },
      data: { visits: data },
    });
  },
  getVisitImage: (visitQuestionAnswerId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/visits/question/${visitQuestionAnswerId}/photo`,
    );
  },
};

export default InstancesConnection;
