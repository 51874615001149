import React, { FunctionComponent, useState } from 'react';
import Select from '../../../../global/atoms/Select';
import Input from '../../../../global/atoms/Input';
import Button from '../../../../global/atoms/Button';

interface ISelectCommunity {
  form: any;
  data: any;
  setData: any;
  errors: any;
  state: any;
  setState: any;
}

const SelectCommunity: FunctionComponent<ISelectCommunity> = ({ form, errors, setData, data, setState, state }) => {
  const [localState, setLocalState] = useState<{
    newInputVisible: boolean;
  }>({
    newInputVisible: false,
  });

  const handleShowNewInput = () => {
    setState({ ...state, ...{ newCommunityName: '' } });
    setLocalState({ ...localState, ...{ newInputVisible: !localState.newInputVisible } });
  };

  return (
    <>
      {!localState.newInputVisible && (
        <Select
          placeholder={'Gmina'}
          name={'community'}
          value={data.community}
          options={form.communities.options}
          disabled={false}
          state={data}
          setState={setData}
          errors={errors}
          limit={100}
        />
      )}
      <div style={{ display: 'block' }}>
        {localState.newInputVisible && (
          <Input
            type={'text'}
            placeholder={'Nowa gmina'}
            name={'newCommunityName'}
            value={state.newCommunityName}
            state={state}
            setState={setState}
            errors={errors}
          />
        )}
        <Button onClick={handleShowNewInput}>{localState.newInputVisible ? 'Anuluj' : 'Dodaj nowy'}</Button>
      </div>
    </>
  );
};

export default SelectCommunity;
