import React, { FunctionComponent, useEffect } from 'react';
import Input from '../../../../global/atoms/Input';
import Select from '../../../../global/atoms/Select';

interface IEditUserCommonFieldsProps {
  data: any;
  setData: any;
  errors: any;
  form: any;
}

const EditUserCommonFields: FunctionComponent<IEditUserCommonFieldsProps> = ({ data, setData, errors, form }) => {
  const handleChangeDetails = (name: string, event: any) => {
    const value = event.currentTarget.value;
    const userDetails = { ...data.userDetails };
    userDetails[name] = value;
    setData({ ...data, userDetails: userDetails });
  };

  const getDefaultUserStatus = () => {
    return form.userStatuses.options.find((userStatus: any) => userStatus.isDefault).id;
  };

  useEffect(() => {
    setData({ ...data, userStatusId: data.userStatusId === '' ? getDefaultUserStatus() : data.userStatusId });
  }, []);

  return (
    <>
      <Input
        placeholder={'Imię'}
        name={'firstName'}
        value={data.firstName}
        state={data}
        setState={setData}
        errors={errors}
      />
      <Input
        placeholder={'Nazwisko'}
        name={'lastName'}
        value={data.lastName}
        state={data}
        setState={setData}
        errors={errors}
      />
      <Input
        placeholder={'Login'}
        name={'username'}
        value={data.username}
        state={data}
        setState={setData}
        errors={errors}
      />
      <Select
        placeholder={'Status'}
        name={'userStatusId'}
        value={data.userStatusId}
        options={form.userStatuses.options}
        disabled={false}
        state={data}
        setState={setData}
        errors={errors}
      />
      <Select
        placeholder={'Klient'}
        name={'customerId'}
        value={data.customerId}
        options={form.customers.options}
        disabled={false}
        state={data}
        setState={setData}
        errors={errors}
      />
      <Input
        placeholder={'E-mail'}
        name={'email'}
        value={data.userDetails.email}
        state={data}
        setState={setData}
        errors={errors}
        onChange={handleChangeDetails.bind(null, 'email')}
      />
      <Input
        placeholder={'Telefon'}
        name={'phone'}
        value={data.userDetails.phone}
        state={data}
        setState={setData}
        errors={errors}
        onChange={handleChangeDetails.bind(null, 'phone')}
      />
      <Input
        placeholder={'Pesel'}
        name={'pesel'}
        value={data.userDetails.pesel}
        state={data}
        setState={setData}
        errors={errors}
        onChange={handleChangeDetails.bind(null, 'pesel')}
      />
      <Input
        placeholder={'Dowód osobisty'}
        name={'idCard'}
        value={data.userDetails.idCard}
        state={data}
        setState={setData}
        errors={errors}
        onChange={handleChangeDetails.bind(null, 'idCard')}
      />
    </>
  );
};

export default EditUserCommonFields;
