import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateLocation } from 'utils/models';
import { createLocationState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import LocationsConnection from 'utils/connections/locations';
import { getAddressFullName, handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import Form from 'global/form/Form';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Select from 'global/atoms/Select';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Spinner from 'global/atoms/Spinner/Spinner';
import AddressSelectSection from '../locationAdd/AddressSelectSection';
import AddressesList from '../addressesList/AddressesList';
import { ISelectAddressViewData } from '../locationAdd/LocationAdd';

interface ILocationEdit {}

const LocationEdit: FunctionComponent<ILocationEdit> = () => {
  const [data, setData] = useState<ICreateLocation>(createLocationState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any>('loading');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [selectAddressViewData, setSelectAddressViewData] = useState<ISelectAddressViewData | null>(null);

  const loadForm = (): Promise<any> => {
    return LocationsConnection.getFormEditLocation(id).then((data) => {
      setData((state) => ({
        ...state,
        name: data.data.model.name,
        description: data.data.model.description,
        networkId: data.data.model.network.id,
        locationTypeId: data.data.model.locationType.id,
        nip: data.data.model.nip,
        email: data.data.model.email,
        contactPerson: data.data.model.contactPerson,
        code: data.data.model.code,
        addressId: {
          id: data.data.model.address?.id,
          name: getAddressFullName(data.data.model.address),
        },
        payerAddressId: {
          id: data.data.model.payerAddress?.id,
          name: getAddressFullName(data.data.model.payerAddress),
        },
        payerName: data.data.model.payerName,
        recipientAddressId: {
          id: data.data.model.recipientAddress?.id,
          name: getAddressFullName(data.data.model.recipientAddress),
        },
        recipientName: data.data.model.recipientName,
      }));
      setForm(data.data);
    });
  };

  useEffect(() => {
    loadForm();
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    handleToast(LocationsConnection.editLocation(data, id), setErrors).then(() => history.push('/datasets/locations'));
  };

  if (form === 'loading') return <Spinner />;

  const handleCloseAddressSelect = () => {
    setSelectAddressViewData(null);
  };

  if (selectAddressViewData !== null) {
    return (
      <AddressesList
        handleSetAddress={selectAddressViewData.handleSetAddress}
        handleClose={handleCloseAddressSelect}
        selectedAddressId={selectAddressViewData.selectedAddressId}
        selectedAddressName={selectAddressViewData.selectedAddressName}
      />
    );
  }

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Opis'}
            name={'description'}
            value={data.description}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Sieć'}
            name={'networkId'}
            value={data.networkId}
            options={form.networks.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Typ lokalizacji'}
            name={'locationTypeId'}
            value={data.locationTypeId}
            options={form.locationTypes.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Kod'}
            name={'code'}
            value={data.code}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Email'}
            name={'email'}
            value={data.email}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Osoba do kontaktu'}
            name={'contactPerson'}
            value={data.contactPerson}
            state={data}
            setState={setData}
            errors={errors}
          />
          <AddressSelectSection
            errors={errors}
            setData={setData}
            data={data}
            setSelectAddressViewData={setSelectAddressViewData}
            saveExitCallback={loadForm}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default LocationEdit;
