import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import Actions from '../../../projects/components/projectsList/Actions';
import { useHistory } from 'react-router-dom';

interface IActivitiesListActivity {
  name: string;
  id: string;
}

const ActivitiesListActivity: FunctionComponent<IActivitiesListActivity> = ({ name, id }) => {
  const history = useHistory();
  return (
    <div
      className={`${styles.containerProject}`}
      onClick={() => {
        history.push(`/datasets/activity/${id}/edit`);
      }}
    >
      <div className={`${styles.colProject} ${styles.colName}`}>
        <span>{name}</span>
      </div>
      <span className={styles.colActions}>
        <Actions />
      </span>
    </div>
  );
};

export default ActivitiesListActivity;
