import React, { FunctionComponent, useEffect, useState } from 'react';
import CheckBox from '../../../../../global/atoms/checkbox/CheckBox';
import { IFeedbackDetails, IPhotoReviewFeedback } from '../../../../../utils/models';
import stylesAtom from '../../../../../global/atoms/atoms.module.scss';
import { deepClone } from '../../../../../utils/helpers';

interface ISLAReviewForm {
  identifier: string;
  stateIndex: number;
  statesRef: React.MutableRefObject<IPhotoReviewFeedback[]>;
  errors?: any;
  readOnly?: boolean;
}

const SLAReviewForm: FunctionComponent<ISLAReviewForm> = (props) => {
  interface IPosState {
    checked: boolean;
    feedbackId: string;
    questionId: string;
    questionName: string;
    questionType: string;
  }

  const [posState, setPosState] = useState<Array<IPosState>>([]);

  useEffect(() => {
    let feedbackDetails = props.statesRef.current[props.stateIndex].feedbackDetails;
    feedbackDetails = feedbackDetails.map((q) => ({
      ...q,
      value: q.value === '1' ? '1' : '0',
    }));
    feedbackDetails = feedbackDetails.sort((a, b) =>
      a.feedbackQuestion.name > b.feedbackQuestion.name
        ? 1
        : b.feedbackQuestion.name > a.feedbackQuestion.name
        ? -1
        : 0,
    );

    const tmp: Array<IPosState> = [];
    feedbackDetails.forEach((q: IFeedbackDetails) => {
      tmp.push({
        checked: q.value === '1',
        feedbackId: q.id,
        questionId: q.feedbackQuestion.id,
        questionName: q.feedbackQuestion.name,
        questionType: q.feedbackQuestion.questionType,
      });
    });

    props.statesRef.current[props.stateIndex].feedbackDetails = feedbackDetails;
    setPosState(tmp);
  }, []);

  if (props.statesRef.current[props.stateIndex].feedbackDetails.length === 0) return <></>;

  const handleTogglePos = (item: IPosState) => {
    if (props.readOnly) return false;

    item.checked = !item.checked;
    const fbItemIndex = props.statesRef.current[props.stateIndex].feedbackDetails.findIndex(
      (obj) => obj.feedbackQuestion.id === item.questionId,
    );
    props.statesRef.current[props.stateIndex].feedbackDetails[fbItemIndex].value = item.checked ? '1' : '0';

    const tmp = deepClone(posState);
    tmp[posState.findIndex((obj) => obj.questionId === item.questionId)] = item;
    setPosState(tmp);
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {posState.map((item) => {
        return (
          <CheckBox
            key={`${item.questionId}_${props.readOnly ? 'readOnly' : ''}`}
            isChecked={item.checked}
            onClick={handleTogglePos.bind(null, item)}
          >
            {item.questionName}
          </CheckBox>
        );
      })}
      {props.errors && props.errors.positions && (
        <div className={stylesAtom.errors}>{props.errors.positions[0].value}</div>
      )}
    </div>
  );
};

export default SLAReviewForm;
