import React, { FunctionComponent, useEffect, useState } from 'react';
import { IGetProducts, IProducts } from 'utils/models';
import ProductsConnection from 'utils/connections/products';
import { getProductsState } from 'utils/states';
import Filters from 'global/filters/Filters';
import Pagination from 'global/pagination/Pagination';
import WhiteCard from 'global/atoms/WhiteCard';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import arrow from 'assets/svg/arrow_down_small.svg';
import settings from 'assets/svg/settings.svg';
import List from 'global/table/List';
import { useHistory } from 'react-router-dom';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import Spinner from 'global/atoms/Spinner/Spinner';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import {
  DeleteSelectedButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import { deepClone, handleToast } from '../../../../utils/helpers';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import { handleClearFilters, loadFilters } from '../../../../utils/filterHelper';
import FiltersStorage from '../../../../utils/FiltersStorage';

interface IProductsList {}

const ProductsList: FunctionComponent<IProductsList> = () => {
  const [data, setData] = useState<IProducts | null>(null);
  const defaultFilters = deepClone(getProductsState);
  const [filters, setFilters] = useState<IGetProducts>(loadFilters(defaultFilters));
  const [form, setForm] = useState<any | 'loading'>('loading');
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  useEffect(() => {
    ProductsConnection.getFiltersProducts().then((data) => {
      setLoading(false);
      setForm(data.data);
    });
  }, []);

  const reloadData = () => {
    ProductsConnection.getProducts(filters).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  };

  useEffect(() => {
    setFilters((state: IGetProducts) => ({
      ...state,
      requestPaginate: {
        page: 1,
        limit: state.requestPaginate.limit,
      },
    }));

    reloadData();
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    reloadData();
  }, [filters.requestPaginate.page, filters.requestOrder]);

  const handleToggleSort = (event: any) => {
      if (filters.requestOrder.field === event.currentTarget.dataset.field) {
        setFilters({
          ...filters,
          requestOrder: {
            field: filters.requestOrder.field,
            order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
          },
        });
      } else {
        setFilters({
          ...filters,
          requestOrder: {
            field: event.currentTarget.dataset.field,
            order: 'asc',
          },
        });
      }
    },
    handleToggleFilters = () => {
      setToggleFilters((state) => !state);
    },
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      const filtersStorage = new FiltersStorage();
      filtersStorage.save(filters);

      ProductsConnection.getProducts({
        ...filters,
        requestPaginate: {
          page: 1,
          limit: filters.requestPaginate.limit,
        },
      }).then((data) => {
        setLoading(false);
        setToggleFilters(false);
        setData(data.data);
        setFilters((state: IGetProducts) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleEdit = (event: any) => {
      history.push(`/products/product/${event.currentTarget.dataset.id}/edit`);
    };

  if (loading) return <Spinner />;
  if (data === null || form === 'loading') return null;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(ProductsConnection.batchDelete(toDelete)).then(() => {
      setChosenToDelete([]);
      reloadData();
    });
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
        <ButtonAddNew form={form} />
        <DeleteSelectedButton chosenToDelete={chosenToDelete} callback={handleDeleteSelected} form={form} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox data={data.data} state={chosenToDelete} setState={setChosenToDelete} />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox state={chosenToDelete} setState={setChosenToDelete} selectedId={rowData.id} />
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} minWidth={300} align="left">
            <HeaderCell depth={0} width={0}>
              <span
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={handleToggleSort}
                data-field={'name'}
              >
                Nazwa
                {filters.requestOrder.field === 'name' &&
                  (filters.requestOrder.order === 'asc' ? (
                    <img src={arrow} alt={'sort'} />
                  ) : (
                    <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
                  ))}
              </span>
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/products/product/${rowData.id}/edit`}>
                  {rowData.name}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Klient
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => <span>{rowData.customer.name}</span>}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Grupa
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => <span>{rowData.itemGroup.name}</span>}
            </Cell>
          </Column>
          <Column width={125} align="left">
            <HeaderCell depth={0} width={0}>
              Kategoria
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => <span>{rowData.itemCategory?.name}</span>}
            </Cell>
          </Column>
          <Column width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Marka
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => {
                return <span>{rowData.brand.name}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Netto
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.amount).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              VAT
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.tax).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Brutto
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.amountGross).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <ActionsContainer>
                  <img
                    src={settings}
                    style={{ cursor: 'pointer' }}
                    alt={'Idź do'}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                  <DeleteSingleButton callback={handleDeleteSelected.bind(null, rowData.id)} form={form} />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, reloadData)}
      >
        <Input
          value={filters.name}
          placeholder={'Nazwa'}
          name={'name'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.gtin}
          placeholder={'GTIN'}
          name={'gtin'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Kategoria'}
          name={'itemCategoryId'}
          value={filters.itemCategoryId}
          options={form.itemCategories.options}
          disabled={false}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Marka'}
          name={'brandId'}
          value={filters.brandId}
          options={form.brands.options}
          disabled={false}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Klient'}
          name={'customerId'}
          value={filters.customerId}
          options={form.customers.options}
          disabled={false}
          state={filters}
          setState={setFilters}
        />
      </Filters>
    </>
  );
};

export default ProductsList;
