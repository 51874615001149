import React, { FunctionComponent, useEffect } from 'react';
import styles from './styles.module.scss';
import { IFormMaker } from '../../utils/models';
import IconButton from '../atoms/IconButton';
import close from '../../assets/svg/close.svg';
import ButtonOutlined from '../atoms/ButtonOutlined';
import ButtonSubmit from '../atoms/ButtonSubmit';
import FiltersStorage from '../../utils/FiltersStorage';

interface IUserListFilters {
  handleToggleFilters?: () => void;
  toggleFilters: boolean;
  filtersState?: any;
  setFilters?: any;
  header?: { name: string };
  filters?: Array<IFormMaker>;
  actions?: Array<IFormMaker>;
  handleSubmit: (event: any) => void;
  handleClear: () => void;
  children?: any;
}

const Filters: FunctionComponent<IUserListFilters> = ({
  toggleFilters,
  handleSubmit,
  handleClear,
  handleToggleFilters,
  children,
}) => {
  useEffect(() => {
    const filtersStorage = new FiltersStorage();
    const filtersFromStorage = filtersStorage.get();
    if (filtersFromStorage !== null && handleToggleFilters !== undefined) {
      handleToggleFilters();
    }
  }, []);
  return (
    <div className={`${styles.filtersContainer} ${toggleFilters ? styles.filterShown : styles.filtersHidden}`}>
      <div className={styles.filtersHeadline}>
        <span>Filtry</span>
        <IconButton src={close} onClick={handleToggleFilters} />
      </div>
      <form
        style={{ display: 'flex', flexDirection: 'column', rowGap: '20px', marginTop: '24px' }}
        onSubmit={handleSubmit}
      >
        {children}
        <div style={{ display: 'flex', justifyContent: 'flex-end', columnGap: '16px' }}>
          <ButtonSubmit name={'Szukaj'} />
          <ButtonOutlined type={'button'} onClick={handleClear}>
            Wyczyść
          </ButtonOutlined>
        </div>
      </form>
    </div>
  );
};

export default Filters;
