import React, { FunctionComponent, useState } from 'react';
import BaseTemplate, { IBaseTemplateState } from './BaseTemplate';

interface IState extends IBaseTemplateState {}

const LocationsTemplate: FunctionComponent = () => {
  const getSellerOptions = (nipRequired = true) => {
    const options = [
      {
        name: 'name',
        label: 'Nazwa',
        active: false,
      },
      {
        name: 'street',
        label: 'Ulica',
        active: false,
      },
      {
        name: 'houseNumber',
        label: 'Numer budynku',
        active: false,
      },
      {
        name: 'apartmentNumber',
        label: 'Numer mieszkania',
        active: false,
      },
      {
        name: 'postcode',
        label: 'Kod pocztowy',
        active: false,
      },
      {
        name: 'city',
        label: 'Miasto',
        active: false,
      },
      {
        name: 'community',
        label: 'Gmina',
        active: false,
      },
      {
        name: 'district',
        label: 'Powiat',
        active: false,
      },
      {
        name: 'voivodeship',
        label: 'Województwo',
        active: false,
      },
    ];

    if (nipRequired) {
      options.push({
        name: 'nip',
        label: 'Nip',
        active: false,
      });
    }

    return options;
  };

  const [state, setState] = useState<IState>({
    templateName: '',
    columnsData: [
      {
        label: 'Dane punktu',
        name: 'location',
        active: false,
        options: [
          {
            name: 'id',
            label: 'Id lokalizacji',
            active: false,
          },
          {
            name: 'code',
            label: 'Kod lokalizacji',
            active: false,
          },
          {
            name: 'network',
            label: 'Sieć',
            active: false,
          },
          {
            name: 'type',
            label: 'Typ lokalizacji',
            active: false,
          },
          {
            name: 'supervisor',
            label: 'Supervisor',
            active: false,
          },
          {
            name: 'reportingUser',
            label: 'Wykonawca',
            active: false,
          },
          {
            name: 'createdAt',
            label: 'Data utworzenia',
            active: false,
          },
          ...getSellerOptions(),
        ],
      },
      {
        label: 'Dane nabywcy',
        name: 'payer',
        active: false,
        options: getSellerOptions(false),
      },
      {
        label: 'Dane odbiorcy',
        name: 'recipient',
        active: false,
        options: getSellerOptions(false),
      },
    ],
  });
  return (
    <>
      <BaseTemplate state={state} setState={setState} type={3} />
    </>
  );
};

export default LocationsTemplate;
