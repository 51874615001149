import React, { FunctionComponent } from 'react';
import CSS from 'csstype';
import styles from './atoms.module.scss';

interface IButtonOutlined {
  style?: CSS.Properties;
  onClick?: () => void;
  type?: 'submit' | 'button' | 'reset' | undefined;
  disabled?: boolean;
}

const ButtonOutlined: FunctionComponent<IButtonOutlined> = ({
  children,
  style,
  onClick,
  type = 'button',
  disabled = false,
}) => {
  let onCLickEvent: any = onClick;
  let classNames = styles.buttonOutlined;
  if (disabled) {
    onCLickEvent = null;
    classNames = styles.buttonOutlinedDisabled;
  }
  return (
    <button onClick={onCLickEvent} type={type} className={classNames} style={style} disabled={disabled}>
      {children}
    </button>
  );
};

export default ButtonOutlined;
