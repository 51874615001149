import React from 'react';
import BaseComponent from '../../../../global/atoms/BaseComponent';
import { IBaseComponentProps, IAddTimeTableRecurringSettings } from '../../../../utils/models';
import EditTimeTableSingleRecurringSetting from './EditTimeTableSingleRecurringSetting';
import Button from '../../../../global/atoms/Button';
import { recurringSettingsState } from '../../../../utils/states';
import SeparatorLine from '../../../../global/atoms/separators/SeparatorLine';
import { deepClone } from '../../../../utils/helpers';

interface IEditTimeTableRecurringSettingsProps extends IBaseComponentProps {
  parentData: any;
  parentSetData: any;
}

export default class EditTimeTableRecurringSettings extends BaseComponent<IEditTimeTableRecurringSettingsProps, any> {
  addNewRecurringSettingHandle() {
    const recurringSettingsUpdate = [...this.props.parentData.timeTable.recurringSettings];
    recurringSettingsUpdate.push(recurringSettingsState);

    const parentDataUpdate = deepClone(this.props.parentData);
    parentDataUpdate.timeTable.recurringSettings = recurringSettingsUpdate;

    this.props.parentSetData({ ...parentDataUpdate });
  }

  renderView(): JSX.Element {
    return (
      <>
        {this.props.parentData.timeTable.recurringSettings.map(
          (data: IAddTimeTableRecurringSettings, index: number) => {
            return (
              <>
                <EditTimeTableSingleRecurringSetting
                  id={index}
                  key={index}
                  recurringSetting={data}
                  parentData={this.props.parentData}
                  parentSetData={this.props.parentSetData}
                />
                {this.props.parentData.timeTable.recurringSettings.length - 1 !== index && <SeparatorLine />}
              </>
            );
          },
        )}
        <Button onClick={this.addNewRecurringSettingHandle.bind(this)}>Dodaj harmonogram</Button>
      </>
    );
  }
}
