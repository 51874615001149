import React, { FunctionComponent } from 'react';
import { Column } from 'rsuite-table5';
import 'rsuite-table/dist/css/rsuite-table.css';
import './styles.css';

interface IColumn5 {
  children: React.ReactNode;
  width?: number;
  align?: 'left' | 'right' | 'center';
  verticalAlign?: 'top' | 'middle' | 'bottom';
  minWidth?: number;
  flexGrow?: number;
  fixed?: boolean | 'left' | 'right';
  resizable?: boolean;
  rowSpan?: any;
  colSpan?: number;
  rowExpandedHeight?: number;
}

const Column5: FunctionComponent<IColumn5> = (preset) => {
  return (
    <Column
      width={preset.width}
      align={preset.align}
      fixed={preset.fixed}
      flexGrow={preset.flexGrow}
      minWidth={preset.minWidth ?? 1}
      onResize={undefined}
      resizable={preset.resizable ?? false}
      sortable={false}
      treeCol={false}
      verticalAlign={preset.verticalAlign ?? 'top'}
      colSpan={preset.colSpan ?? 1}
      rowSpan={preset.rowSpan ?? 1}
      // @ts-ignore
      rowExpandedHeight={preset.rowExpandedHeight ?? 100}
    >
      {preset.children}
    </Column>
  );
};

export default Column5;
