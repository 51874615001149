import React, { FunctionComponent, useEffect, useState } from 'react';
import { getOrganizationId, handleToast } from 'utils/helpers';
import { defaultFilterOrderState, defaultFilterPaginateState, selectViewResponseState } from 'utils/states';
import DocumentsConnection from 'utils/connections/documents';
import { useHistory, useParams } from 'react-router-dom';
import { IHeader, IPagination, ISelectViewResponse, ISort } from 'utils/models';
import WhiteCard from 'global/atoms/WhiteCard';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import BadgeStatus from 'global/atoms/badge/BadgeStatus';
import IconButton from 'global/atoms/IconButton';
import List from 'global/table/List';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import Input from 'global/atoms/Input';
import DayPickerInputCustom from 'global/atoms/DayPickerInputCustom';
import Select from 'global/atoms/Select';
import { handleClearFilters, handleSubmitAndSaveFilters, handleToggleFilters, loadFilters } from 'utils/filterHelper';
import Spinner from 'global/atoms/Spinner/Spinner';
import HeaderButtons from '../../../../global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from '../../../../global/atoms/ButtonFilters';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import {
  BatchActionButton,
  SingleActionButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import Button from '../../../../global/atoms/Button';

interface IDocumentData {
  deliveryDate: string;
  documentDate: string;
  documentId: string;
  documentNumber: string;
  saleType: any;
  location: any;
  paymentDate: string;
  paymentType: string;
  positions: any;
  user: any;
}

interface IDocumentsData {
  columns: any;
  header: IHeader;
  data: Array<IDocumentData>;
  count: number;
}

export interface IDocumentsFilters {
  documentNumber: string;
  userId: string;
  saleTypeId: string;
  paymentTypeId: string;
  locationId: string;
  payerId: string;
  recipientId: string;
  sellerId: string;
  dateFrom: string;
  dateTo: string;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

const DocumentsList: FunctionComponent = () => {
  const [data, setData] = useState<IDocumentsData | null>(null);
  const defaultFilters: IDocumentsFilters = {
    dateFrom: '',
    dateTo: '',
    documentNumber: '',
    saleTypeId: '',
    locationId: '',
    payerId: '',
    recipientId: '',
    sellerId: '',
    paymentTypeId: '',
    userId: '',
    ...defaultFilterOrderState,
    ...defaultFilterPaginateState,
  };
  const [filters, setFilters] = useState<IDocumentsFilters>(loadFilters(defaultFilters));
  const [reloadData, setReloadData] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [form, setForm] = useState<{
    saleTypes: ISelectViewResponse;
    locations: ISelectViewResponse;
    payers: ISelectViewResponse;
    recipients: ISelectViewResponse;
    sellers: ISelectViewResponse;
    paymentTypes: ISelectViewResponse;
    users: ISelectViewResponse;
  }>({
    saleTypes: selectViewResponseState,
    locations: selectViewResponseState,
    payers: selectViewResponseState,
    recipients: selectViewResponseState,
    sellers: selectViewResponseState,
    paymentTypes: selectViewResponseState,
    users: selectViewResponseState,
  });
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  useEffect(() => {
    DocumentsConnection.filterForm(id).then((data) => {
      setForm(data.data);
    });
  }, []);

  const loadData = () => {
    const filtersClone = { ...filters };
    filtersClone.dateFrom = `${filtersClone.dateFrom}`;
    filtersClone.dateTo = `${filtersClone.dateTo}`;

    DocumentsConnection.list(id, filtersClone).then((data) => {
      setData(data.data);
      setChosenToDelete([]);
    });
  };

  useEffect(() => loadData(), [filters.requestPaginate]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
  }, [reloadData]);

  const handleOpenDocument = (documentId: string) => {
    history.push(`/projects/${id}/documents/${documentId}/details`);
  };

  if (data === null) return <Spinner />;

  const handleCancelSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(DocumentsConnection.batchDelete(id, toDelete)).then(() => {
      setChosenToDelete([]);
      loadData();
    });
  };

  const handleOpenInvoice = (documentId: string) => {
    const url = `${
      process.env.REACT_APP_API_URL
    }/organizations/${getOrganizationId()}/project/${id}/sales/documents/${documentId}/invoice`;
    window.open(url, '_blank')?.focus();
  };

  const handleGetInvoicesZip = () => {
    DocumentsConnection.invoicesZip(id, chosenToDelete);
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters.bind(null, setToggleFilters)} />
        <BatchActionButton
          chosenRows={chosenToDelete}
          callback={handleCancelSelected}
          title={'Anuluj dokumenty'}
          message={'Czy napewno anulować? '}
        />
        <Button onClick={handleGetInvoicesZip}>Pobierz faktury zip</Button>
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox
                data={data.data}
                state={chosenToDelete}
                setState={setChosenToDelete}
                keyIdName={'documentId'}
              />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox
                  state={chosenToDelete}
                  setState={setChosenToDelete}
                  selectedId={rowData.documentId}
                />
              )}
            </Cell>
          </Column>
          <Column flexGrow={2} width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Numer
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="documentNumber">
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/projects/${id}/documents/${rowData.documentId}/details`}>
                  {rowData.documentNumber}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2} width={250} align="left">
            <HeaderCell depth={0} width={0}>
              Nabywca
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return <span>{rowData.location.name}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Netto
            </HeaderCell>
            <Cell width={70} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.amount).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column width={80} align="left">
            <HeaderCell depth={0} width={0}>
              Vat
            </HeaderCell>
            <Cell width={80} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.tax).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Brutto
            </HeaderCell>
            <Cell width={80} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.amountGross).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Typ
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <BadgeStatus color={rowData.status.color}>{rowData.saleType.name}</BadgeStatus>}
            </Cell>
          </Column>
          <Column minWidth={150} align="left">
            <HeaderCell depth={0} width={0}>
              Wystawił
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return <span>{rowData.user.fullName}</span>;
              }}
            </Cell>
          </Column>
          <Column align="left" width={120}>
            <HeaderCell depth={0} width={0}>
              Data
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="documentDate" />
          </Column>
          <Column width={180} align="left">
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ActionsContainer>
                  <Button
                    disabledTitle={'Faktura w trakcie generowania'}
                    disabled={rowData.invoicePath === null}
                    onClick={handleOpenInvoice.bind(null, rowData.documentId)}
                  >
                    FV
                  </Button>
                  <IconButton icon={'arrowRightBig'} onClick={handleOpenDocument.bind(null, rowData.documentId)} />
                  <SingleActionButton
                    callback={handleCancelSelected.bind(null, rowData.documentId)}
                    message={'Czy napewno anulować? '}
                  />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters.bind(null, setToggleFilters)}
        handleSubmit={handleSubmitAndSaveFilters.bind(null, setReloadData, filters)}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, setReloadData)}
      >
        <Input
          value={filters.documentNumber}
          placeholder={'Numer'}
          name={'documentNumber'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Lokalizacja'}
          name={'locationId'}
          value={filters.locationId}
          options={form.locations.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Płatnik'}
          name={'payerId'}
          value={filters.payerId}
          options={form.payers.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Odbiorca'}
          name={'recipientId'}
          value={filters.recipientId}
          options={form.recipients.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Sprzedawca'}
          name={'sellerId'}
          value={filters.sellerId}
          options={form.sellers.options}
          state={filters}
          setState={setFilters}
        />
        <DayPickerInputCustom
          value={filters.dateFrom}
          placeholder={'Data od'}
          name={'dateFrom'}
          setState={setFilters}
          state={filters}
        />
        <DayPickerInputCustom
          value={filters.dateTo}
          placeholder={'Data do'}
          name={'dateTo'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Wystawił'}
          name={'userId'}
          value={filters.userId}
          options={form.users.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Rodzaj dokumentu'}
          name={'saleTypeId'}
          value={filters.saleTypeId}
          options={form.saleTypes.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Rodzaj płatności'}
          name={'paymentTypeId'}
          value={filters.paymentTypeId}
          options={form.paymentTypes.options}
          state={filters}
          setState={setFilters}
        />
      </Filters>
    </>
  );
};

export default DocumentsList;
