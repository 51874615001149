import React, { FunctionComponent, useLayoutEffect, useRef, useState } from 'react';
import styles from './nav.module.scss';
import { useSelector } from 'react-redux';
import { IRoot } from '../../../redux/models';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import IconButton from '../../atoms/IconButton';
import hamburgerIcon from '../../../assets/svg/menu_open.svg';
import MainRouteName from '../../atoms/MainRouteName';
import User from '../../user/User';
import { clickOutsideMenu } from '../../../utils/helpers';
import close from '../../../assets/svg/close.svg';
import logo from '../../../assets/images/logo.png';
import OrganizationsConnection from '../../../utils/connections/organizations';

interface INav {}

const Nav: FunctionComponent<INav> = () => {
  const menu = useSelector((state: IRoot) => state.menu);
  const location = useLocation();
  const history = useHistory();
  const ref = useRef(null);
  const [toggleNav, setToggleNav] = useState<boolean>(false);
  const [logoOrganization, setLogoOrganization] = useState<string>('');

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1366) {
        setToggleNav(false);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    OrganizationsConnection.getLogo().then((response) => {
      setLogoOrganization(response.data.logo);
    });

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const handleToggleNav = () => {
    setToggleNav((state) => !state);
  };

  const handleLinkTo = (event: any) => {
    if (!event.currentTarget.dataset.url) {
      return;
    }
    history.push(event.currentTarget.dataset.url);
    setToggleNav(false);
  };

  clickOutsideMenu(ref, setToggleNav);

  const getLogo = () => {
    let logoSet = logo;
    if (logoOrganization !== '') {
      logoSet = logoOrganization;
    }
    return (
      <a href="/">
        <img src={logoSet} alt={'logo'} className={styles.logo} />
      </a>
    );
  };

  if (location.pathname.includes('/superadmin')) {
    return (
      <header className={styles.navContainer}>
        <div className={styles.mobileVisible}>
          <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
        </div>
        <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
          <div className={styles.mobileVisibleMenu}>
            <span>Menu</span>
            <IconButton src={close} onClick={handleToggleNav} />
          </div>
          {getLogo()}

          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '28px' }}>
            <ul className={styles.list} style={{ borderRight: '1px solid rgba(19, 19, 21, 0.16)' }}>
              {menu.positions.map((item) => {
                return (
                  <>
                    <li
                      className={`${styles.element} ${
                        location.pathname.includes(item.url) ? styles.elementActive : styles.elementInactive
                      }`}
                      key={item.url}
                      data-url={item.url}
                      onClick={handleLinkTo}
                    >
                      {location.pathname.includes(item.url) ? (
                        <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                      ) : (
                        <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                      )}
                      <div className={styles.tooltip}>
                        <p>{item.hint}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
            <ul className={styles.list}>
              {menu.positions.map((item) => {
                if (item.url === '/superadmin') {
                  return item.positions.map((subItem) => {
                    return (
                      <li
                        className={`${styles.element} ${
                          location.pathname.includes(subItem.url) ? styles.elementActive : styles.elementInactive
                        }`}
                        key={subItem.url}
                        data-url={`${subItem.url}`}
                        onClick={handleLinkTo}
                      >
                        {subItem.name}
                      </li>
                    );
                  });
                }
              })}
            </ul>
          </div>
        </nav>
        <div className={styles.mobileVisible}>
          <MainRouteName />
        </div>
        <div className={styles.mobileVisible}>
          <User />
        </div>
      </header>
    );
  }

  if (location.pathname.includes('/products')) {
    return (
      <header className={styles.navContainer}>
        <div className={styles.mobileVisible}>
          <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
        </div>
        <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
          <div className={styles.mobileVisibleMenu}>
            <span>Menu</span>
            <IconButton src={close} onClick={handleToggleNav} />
          </div>
          {getLogo()}

          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '28px' }}>
            <ul className={styles.list} style={{ borderRight: '1px solid rgba(19, 19, 21, 0.16)' }}>
              {menu.positions.map((item) => {
                return (
                  <>
                    <li
                      className={`${styles.element} ${
                        location.pathname.includes(item.url) ? styles.elementActive : styles.elementInactive
                      }`}
                      key={item.url}
                      data-url={item.url}
                      onClick={handleLinkTo}
                    >
                      {location.pathname.includes(item.url) ? (
                        <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                      ) : (
                        <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                      )}
                      <div className={styles.tooltip}>
                        <p>{item.hint}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
            <ul className={styles.list}>
              {menu.positions.map((item) => {
                if (item.url === '/products') {
                  return item.positions.map((subItem) => {
                    return (
                      <li
                        className={`${styles.element} ${
                          location.pathname.includes(subItem.url) ? styles.elementActive : styles.elementInactive
                        }`}
                        key={subItem.url}
                        data-url={`${subItem.url}`}
                        onClick={handleLinkTo}
                      >
                        {subItem.name}
                      </li>
                    );
                  });
                }
              })}
            </ul>
          </div>
        </nav>
        <div className={styles.mobileVisible}>
          <MainRouteName />
        </div>
        <div className={styles.mobileVisible}>
          <User />
        </div>
      </header>
    );
  }

  if (location.pathname.includes('/datasets')) {
    return (
      <header className={styles.navContainer}>
        <div className={styles.mobileVisible}>
          <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
        </div>
        <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
          <div className={styles.mobileVisibleMenu}>
            <span>Menu</span>
            <IconButton src={close} onClick={handleToggleNav} />
          </div>
          {getLogo()}

          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '28px' }}>
            <ul className={styles.list} style={{ borderRight: '1px solid rgba(19, 19, 21, 0.16)' }}>
              {menu.positions.map((item) => {
                return (
                  <>
                    <li
                      className={`${styles.element} ${
                        location.pathname.includes(item.url) ? styles.elementActive : styles.elementInactive
                      }`}
                      key={item.url}
                      data-url={item.url}
                      onClick={handleLinkTo}
                    >
                      {location.pathname.includes(item.url) ? (
                        <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                      ) : (
                        <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                      )}
                      <div className={styles.tooltip}>
                        <p>{item.hint}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
            <ul className={styles.list}>
              {menu.positions.map((item) => {
                if (item.url === '/datasets') {
                  return item.positions.map((subItem) => {
                    return (
                      <li
                        className={`${styles.element} ${
                          location.pathname.includes(subItem.url) ? styles.elementActive : styles.elementInactive
                        }`}
                        key={subItem.url}
                        data-url={`${subItem.url}`}
                        onClick={handleLinkTo}
                      >
                        {subItem.name}
                      </li>
                    );
                  });
                }
              })}
            </ul>
          </div>
        </nav>
        <div className={styles.mobileVisible}>
          <MainRouteName />
        </div>
        <div className={styles.mobileVisible}>
          <User />
        </div>
      </header>
    );
  }

  if (location.pathname.includes('/admin')) {
    return (
      <header className={styles.navContainer}>
        <div className={styles.mobileVisible}>
          <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
        </div>
        <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
          <div className={styles.mobileVisibleMenu}>
            <span>Menu</span>
            <IconButton src={close} onClick={handleToggleNav} />
          </div>
          {getLogo()}

          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '28px' }}>
            <ul className={styles.list} style={{ borderRight: '1px solid rgba(19, 19, 21, 0.16)' }}>
              {menu.positions.map((item) => {
                return (
                  <>
                    <li
                      className={`${styles.element} ${
                        location.pathname.includes(item.url) ? styles.elementActive : styles.elementInactive
                      }`}
                      key={item.url}
                      data-url={item.url}
                      onClick={handleLinkTo}
                    >
                      {location.pathname.includes(item.url) ? (
                        <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                      ) : (
                        <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                      )}
                      <div className={styles.tooltip}>
                        <p>{item.hint}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
            <ul className={styles.list}>
              {menu.positions.map((item) => {
                if (item.url === '/admin') {
                  return item.positions.map((subItem) => {
                    return (
                      <li
                        className={`${styles.element} ${
                          location.pathname.includes(subItem.url) ? styles.elementActive : styles.elementInactive
                        }`}
                        key={subItem.url}
                        data-url={`${subItem.url}`}
                        onClick={handleLinkTo}
                      >
                        {subItem.name}
                      </li>
                    );
                  });
                }
              })}
            </ul>
          </div>
        </nav>
        <div className={styles.mobileVisible}>
          <MainRouteName />
        </div>
        <div className={styles.mobileVisible}>
          <User />
        </div>
      </header>
    );
  }

  if (location.pathname.includes('/projects/add')) {
    return (
      <header className={styles.navContainer}>
        <div className={styles.mobileVisible}>
          <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
        </div>
        <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
          <div className={styles.mobileVisibleMenu}>
            <span>Menu</span>
            <IconButton src={close} onClick={handleToggleNav} />
          </div>
          {getLogo()}

          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '28px' }}>
            <ul className={styles.list} style={{ borderRight: '1px solid rgba(19, 19, 21, 0.16)' }}>
              {menu.positions.map((item) => {
                return (
                  <>
                    <li
                      className={`${styles.element} ${
                        location.pathname.includes(item.url) ? styles.elementActive : styles.elementInactive
                      }`}
                      key={item.url}
                      data-url={item.url}
                      onClick={handleLinkTo}
                    >
                      {location.pathname.includes(item.url) ? (
                        <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                      ) : (
                        <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                      )}
                      <div className={styles.tooltip}>
                        <p>{item.hint}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
            {menu.positions.map((item) => {
              if (item.url === '/projects') {
                return item.positions.map((subItem) => {
                  if (subItem.url === '/projects/add') {
                    return (
                      <div key={subItem.name}>
                        <ul className={styles.list}>
                          {subItem.positions.map((route) => {
                            return (
                              <li
                                className={`${styles.element} ${
                                  1 === route.number ? styles.elementActive : styles.elementInactive
                                }`}
                                key={`${subItem.url}/${route.number}`}
                                data-url={''}
                                onClick={handleLinkTo}
                              >
                                {route.name}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  }
                });
              }
            })}
          </div>
        </nav>
        <div className={styles.mobileVisible}>
          <MainRouteName />
        </div>
        <div className={styles.mobileVisible}>
          <User />
        </div>
      </header>
    );
  }

  if (matchPath(location.pathname, { path: '/projects/:id/edit' })) {
    //@ts-ignore
    const { params: projectParams } = matchPath(location.pathname, { path: '/projects/:id/edit' });
    const { id } = projectParams;
    return (
      <header className={styles.navContainer}>
        <div className={styles.mobileVisible}>
          <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
        </div>
        <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
          <div className={styles.mobileVisibleMenu}>
            <span>Menu</span>
            <IconButton src={close} onClick={handleToggleNav} />
          </div>
          {getLogo()}

          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '28px' }}>
            <ul className={styles.list} style={{ borderRight: '1px solid rgba(19, 19, 21, 0.16)' }}>
              {menu.positions.map((item) => {
                return (
                  <>
                    <li
                      className={`${styles.element} ${
                        location.pathname.includes(item.url) ? styles.elementActive : styles.elementInactive
                      }`}
                      key={item.url}
                      data-url={item.url}
                      onClick={handleLinkTo}
                    >
                      {location.pathname.includes(item.url) ? (
                        <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                      ) : (
                        <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                      )}
                      <div className={styles.tooltip}>
                        <p>{item.hint}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
            <ul className={styles.list}>
              {menu.positions.map((item) => {
                if (item.url === '/projects') {
                  return item.positions.map((subItem) => {
                    if (subItem.url === '/projects/add') {
                      return subItem.positions.map((route) => {
                        return (
                          <li
                            className={`${styles.element} ${
                              location.pathname.includes(`/projects/${id}/edit/${route.number}`)
                                ? styles.elementActive
                                : styles.elementInactive
                            }`}
                            key={`/projects/${id}/edit/${route.number}`}
                            data-url={`/projects/${id}/edit/${route.number}`}
                            onClick={handleLinkTo}
                          >
                            {route.name}
                          </li>
                        );
                      });
                    }
                  });
                }
              })}
            </ul>
          </div>
        </nav>
        <div className={styles.mobileVisible}>
          <MainRouteName />
        </div>
        <div className={styles.mobileVisible}>
          <User />
        </div>
      </header>
    );
  }

  if (matchPath(location.pathname, { path: '/projects/:id' })) {
    //@ts-ignore
    const { params: projectParams } = matchPath(location.pathname, { path: '/projects/:id/' });
    const { id } = projectParams;
    return (
      <header className={styles.navContainer}>
        <div className={styles.mobileVisible}>
          <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
        </div>
        <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
          <div className={styles.mobileVisibleMenu}>
            <span>Menu</span>
            <IconButton src={close} onClick={handleToggleNav} />
          </div>
          {getLogo()}

          <div style={{ width: '100%', display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '28px' }}>
            <ul className={styles.list} style={{ borderRight: '1px solid rgba(19, 19, 21, 0.16)' }}>
              {menu.positions.map((item) => {
                return (
                  <>
                    <li
                      className={`${styles.element} ${
                        item.url === '/projects' ? styles.elementActive : styles.elementInactive
                      }`}
                      key={item.url}
                      data-url={item.url}
                      onClick={handleLinkTo}
                    >
                      {item.url === '/projects' ? (
                        <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                      ) : (
                        <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                      )}
                      <div className={styles.tooltip}>
                        <p>{item.hint}</p>
                      </div>
                    </li>
                  </>
                );
              })}
            </ul>
            <ul className={styles.list}>
              {menu.positions.map((item) => {
                if (item.url === '/projects') {
                  return item.positions.map((subItem) => {
                    if (subItem.url === '/projects/project') {
                      return subItem.positions.map((route) => {
                        return (
                          <li
                            className={`${styles.element} ${
                              location.pathname.includes(`/projects/${id}${route.url}`)
                                ? styles.elementActive
                                : styles.elementInactive
                            }`}
                            key={`/projects/${id}${route.url}`}
                            data-url={`/projects/${id}${route.url}`}
                            onClick={handleLinkTo}
                          >
                            {route.name}
                          </li>
                        );
                      });
                    }
                  });
                }
              })}
            </ul>
          </div>
        </nav>
        <div className={styles.mobileVisible}>
          <MainRouteName />
        </div>
        <div className={styles.mobileVisible}>
          <User />
        </div>
      </header>
    );
  }

  return (
    <header className={styles.navContainer}>
      <div className={styles.mobileVisible}>
        <IconButton onClick={handleToggleNav} src={hamburgerIcon} />
      </div>
      <nav ref={ref} className={`${styles.nav} ${toggleNav ? styles.navVisible : null}`}>
        <div className={styles.mobileVisibleMenu}>
          <span>Menu</span>
          <IconButton src={close} onClick={handleToggleNav} />
        </div>
        {getLogo()}
        <ul className={styles.list}>
          {menu.positions.map((item) => {
            return (
              <>
                <li
                  className={`${styles.element} ${
                    location.pathname.includes(item.url) ? styles.elementActive : styles.elementInactive
                  }`}
                  key={item.url}
                  data-url={item.url}
                  onClick={handleLinkTo}
                >
                  {location.pathname.includes(item.url) ? (
                    <img className={styles.icon} src={`${item.iconPath}_active.svg`} alt={item.name} />
                  ) : (
                    <img className={styles.icon} src={`${item.iconPath}.svg`} alt={item.name} />
                  )}
                  {item.name}
                </li>
              </>
            );
          })}
        </ul>
      </nav>
      <div className={styles.mobileVisible}>
        <MainRouteName />
      </div>
      <div className={styles.mobileVisible}>
        <User />
      </div>
    </header>
  );
};

export default Nav;
