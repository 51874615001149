import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { ICreateChain, IGetChains } from '../models';
import { AxiosPromise } from 'axios';

const ChainsConnection = {
  getChains: (data: IGetChains) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createChain: (data: ICreateChain) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks`, data, {
      headers: getAuthHeader(),
    });
  },
  updateChain: (id: string, data: ICreateChain) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks/${id}`, data, {
      headers: getAuthHeader(),
    });
  },

  getChainsFiltersForm: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getChainsFormAdd: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks/form`, {
      headers: getAuthHeader(),
    });
  },
  getChainsFormEdit: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks`, {
      headers: { ...getAuthHeader() },
      data: { networks: data },
    });
  },
  getFilterForm: (): AxiosPromise => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/networks/filter/form`);
  },
};

export default ChainsConnection;
