import React, { FunctionComponent } from 'react';
import CSS from 'csstype';
import styles from './atoms.module.scss';
import Select from 'react-select';
import { findErrorByName } from '../../utils/formErrorsHelper';

interface ISelect {
  style?: CSS.Properties;
  placeholder: string;
  name: string;
  onChange?: any;
  value?: string | number | null | Array<any>;
  options: Array<{
    id: string | number;
    name: string;
  }>;
  multiple?: boolean;
  disabled?: boolean;
  setState?: any;
  state?: any;
  errors?: any;
  debugData?: any;
  limit?: number;
  filterOptionsCustom?: any;
}

export const controlStyles = (isFocused: boolean) => {
  return {
    height: '40px',
    width: '100%',
    border: isFocused ? '1px solid #ffaf38' : '1px solid rgba(93, 92, 92, 0.16)',
    boxShadow: 'none',
    outline: 'none',
    borderRadius: '8px',
    color: '#131315',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
    letterSpacing: '0.4px',
    padding: '0 8px',
    cursor: 'pointer',
    ':hover': {
      border: '1px solid rgba(93, 92, 92, 0.16)',
    },
  };
};

export const optionStyles = (isSelected: boolean, isFocused: boolean) => {
  return {
    color: isSelected ? '#ffffff' : '#131315',
    backgroundColor: isSelected ? '#ffaf38' : isFocused ? 'rgba(255, 175, 56, 0.3)' : '#ffffff',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: isSelected ? '#ffaf38' : 'rgba(255, 175, 56, 0.3)',
    },
  };
};

const SelectComponent: FunctionComponent<ISelect> = ({
  style,
  placeholder,
  value,
  options,
  name,
  disabled,
  setState,
  state,
  onChange,
  errors,
  multiple = false,
  debugData,
  limit,
  filterOptionsCustom,
}) => {
  const handleChange = (object: any) => {
    setState({ ...state, [name]: object.value });
  };

  const optionsParsed = options.map((item) => ({ value: item.id, label: item.name }));
  let i = 0;

  let filterOptions = (data: any, query: any) => data.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
  if (limit !== undefined) {
    filterOptions = (data: any, query: any) =>
      data.label.toLowerCase().indexOf(query.toLowerCase()) >= 0 && i++ < limit;
  }

  if (filterOptionsCustom !== undefined) {
    filterOptions = filterOptionsCustom;
  }

  return (
    <div className={styles.inputContainer} style={style} data-debug={debugData}>
      <Select
        filterOption={filterOptions}
        onInputChange={() => {
          i = 0;
        }}
        isMulti={multiple}
        onChange={onChange ?? handleChange}
        name={name}
        options={optionsParsed}
        placeholder={''}
        value={options.map((item) => {
          if (item.id === value) return { value: item.id, label: item.name };
        })}
        isDisabled={disabled}
        styles={{
          control: (styles, { isFocused }) => ({
            ...styles,
            ...controlStyles(isFocused),
          }),
          option: (styles, { isSelected, isFocused }) => ({
            ...styles,
            ...optionStyles(isSelected, isFocused),
          }),
        }}
      />
      <label className={styles.inputSelectLabel} htmlFor={name}>
        {placeholder}
      </label>
      {errors && <span className={styles.errors}>{findErrorByName(name, errors)}</span>}
    </div>
  );
};

export default SelectComponent;
