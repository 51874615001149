import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import {ICreateNotification, IEditNotification, IGetNotifications} from '../models';

const baseUrl = `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/notifications`;

const NotificationsConnection = {
  getNotifications: (data: IGetNotifications): Promise<any> => {
    // console.log(data, getAuthHeader());
    return axios.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getNotification: (id: string): Promise<any> => {
    return axios.get(`${baseUrl}/${id}`, {
      headers: getAuthHeader(),
    });
  },
  createNotification: (data: ICreateNotification): Promise<any> => {
    return axios.post(`${baseUrl}`, data, {
      headers: getAuthHeader(),
    });
  },
  editNotification: (data: IEditNotification): Promise<any> => {
    return axios.put(`${baseUrl}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFilterForm: (): Promise<any> => {
    return axios.get(`${baseUrl}/filter/form`);
  },
  getForm: (): Promise<any> => {
    return axios.get(`${baseUrl}/form`);
  },
  downloadFileUrl: (id: string, type: 'iconPath'|'attachment'): string => {
    const user = localStorage.getItem('user');
    if (!user) return '';
    return `${baseUrl}/files/${id}/${type === 'iconPath'? 'icon': 'attachment'}?access_token=${JSON.parse(user??'').token}`;
  },
  getFileBlob: (id: string, type: 'iconPath'|'attachment') => {
    return axios.get(`${baseUrl}/files/${id}/${type === 'iconPath'? 'icon': 'attachment'}`, {
      headers: getAuthHeader(),
      responseType: "blob"
    });
  }
};

export default NotificationsConnection;
