import React, { FunctionComponent, useState } from 'react';
import styles from '../editProject/access/styles.module.scss';
import settings from '../../../../assets/svg/edit.svg';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import drag from '../../../../assets/svg/drag.svg';
import Input from '../../../../global/atoms/Input';

const DragHandle = SortableHandle(() => <img style={{ marginRight: '12px', cursor: 'pointer' }} src={drag} />);

const SortableList = SortableContainer(({ children }: { children: React.ReactNode }) => <ul>{children}</ul>);

const SortableItem = SortableElement(
  ({
    pickedId,
    itemId,
    handleToggleCheckbox,
    checked,
    name,
    editView,
    editable,
    handleSetPicked,
    draggable,
  }: {
    pickedId: string;
    itemId: string;
    handleToggleCheckbox?: (event: any) => void;
    checked?: Array<any>;
    name: string;
    editView: boolean;
    editable?: boolean;
    handleSetPicked?: (event: any) => void;
    draggable: boolean;
  }) => (
    <div className={styles.user}>
      <div
        className={styles.wrapper}
        style={{
          fontWeight: pickedId === itemId ? 700 : 'inherit',
        }}
      >
        {editView && (
          <span
            data-id={itemId}
            onClick={handleToggleCheckbox}
            className={styles.checkbox2}
            style={{
              backgroundColor: checked?.includes(itemId) ? '#ffaf38' : '',
            }}
          />
        )}
        {draggable && <DragHandle />}
        <span style={{ width: 'auto' }}>{name}</span>
      </div>
      {pickedId !== itemId && editable && handleSetPicked && (
        <img src={settings} style={{ cursor: 'pointer' }} data-id={itemId} onClick={handleSetPicked} alt={'Idź do'} />
      )}
    </div>
  ),
);

interface IQuestionsList {
  data: any;
  checked?: Array<any>;
  editView: boolean;
  handleToggleCheckbox?: (event: any) => void;
  handleToggleMainCheckbox?: () => void;
  column: string;
  counter: string;
  editable?: boolean;
  handleSetPicked?: (event: any) => void;
  pickedId: string;
  draggable: boolean;
  onSortEnd?: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
}

const QuestionsList: FunctionComponent<IQuestionsList> = ({
  data,
  editView,
  handleToggleCheckbox,
  handleToggleMainCheckbox,
  checked,
  column,
  counter,
  editable = true,
  handleSetPicked,
  pickedId,
  draggable,
  onSortEnd,
}) => {
  const [search, setSearch] = useState<string>('');

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value);
  };

  const classForCheckbox = () => {
    let cn = styles.checkbox;
    if (checked?.length) {
      cn += ' ' + styles.checkboxIsChecked;
    }
    return cn;
  };

  const classForCheckbox2 = ({ id }: any) => {
    let cn = styles.checkbox2;
    if (checked?.includes(id)) {
      cn += ' ' + styles.checkboxIsChecked;
    }
    return cn;
  };

  const getFilteredData = () => {
    if (search === '') {
      return data;
    }

    return data.filter((item: any) => {
      const nameLowercase = item.questionName.toLowerCase();
      const searchLowercase = search.toLowerCase();
      return nameLowercase.indexOf(searchLowercase) >= 0;
    });
  };

  return (
    <div>
      <div className={styles.wrapperFields}>
        <div className={styles.activitySectionHeader}>
          {editView && <span className={classForCheckbox()} onClick={handleToggleMainCheckbox} />}
          <span className={styles.columnAndCounter}>
            {column} ({counter})
          </span>
        </div>
        <Input
          style={{
            maxWidth: '200px',
          }}
          value={search}
          placeholder={'Szukaj'}
          onChange={handleChangeSearch}
          name={'search'}
        />
      </div>
      <div className={styles.wrapperSortable}>
        <SortableList onSortEnd={onSortEnd} useDragHandle={true}>
          {getFilteredData().map((item: any, idx: number) => {
            if (search !== '') {
              return (
                <div className={styles.user} key={item.id}>
                  <div
                    className={styles.wrapper}
                    style={{
                      fontWeight: pickedId === item.id ? 700 : 'inherit',
                    }}
                  >
                    {editView && (
                      <span data-id={item.id} onClick={handleToggleCheckbox} className={classForCheckbox2(item)} />
                    )}
                    {item.questionName}
                  </div>
                  {pickedId !== item.id && editable && handleSetPicked && (
                    <img
                      src={settings}
                      style={{ cursor: 'pointer' }}
                      data-id={item.id}
                      onClick={handleSetPicked}
                      alt={'Idź do'}
                    />
                  )}
                </div>
              );
            } else if (!search) {
              return (
                <SortableItem
                  editView={editView}
                  key={item.id}
                  index={idx}
                  editable={editable}
                  checked={checked}
                  handleSetPicked={handleSetPicked}
                  handleToggleCheckbox={handleToggleCheckbox}
                  itemId={item.id}
                  name={item.questionName}
                  pickedId={pickedId}
                  draggable={draggable}
                />
              );
            }
          })}
        </SortableList>
      </div>
    </div>
  );
};

export default QuestionsList;
