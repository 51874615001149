import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from '../editProject/access/styles.module.scss';
import left from '../../../../assets/svg/left_big.svg';
import right from '../../../../assets/svg/right_big.svg';
import LocationsList from './LocationsList';
import { useHistory, useParams } from 'react-router-dom';
import Heading from '../../../../global/heading/Heading';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import ActivitiesConnection from '../../../../utils/connections/activities';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';

interface ILocations {
  activityId: string;
  setForm: any;
  form: any;
  setIsSaveButtonVisible: any;
}

export interface ILocationData {
  checked: boolean;
  assigned: boolean;
  id: string;
  name: string;
  networkId: string;
  index: number;
}

const Locations: FunctionComponent<ILocations> = ({ setForm, activityId, form, setIsSaveButtonVisible }) => {
  const [data, setData] = useState<Array<ILocationData> | null>(null);
  const { id, taskId } = useParams<{ id: string; taskId: string }>();
  const [reloadData, setReloadData] = useState<boolean>(false);
  const history = useHistory();

  const updateToSaveLocations = (dataParam: Array<ILocationData>) => {
    const assignedLocationsIds: Array<string> = [];
    dataParam.map((item) => {
      if (item.assigned) {
        assignedLocationsIds.push(item.id);
      }
    });
    setForm({ ...form, assignedLocations: assignedLocationsIds });
  };

  useEffect(() => {
    Promise.all([
      ActivitiesConnection.getActivityAssignedLocations(id, taskId, activityId),
      ActivitiesConnection.getActivityUnassignedLocations(id, taskId, activityId),
    ]).then((response) => {
      const locationDataMerge: Array<ILocationData> = [];
      let index = 0;
      response[0].data.map((item: any) => {
        locationDataMerge[index] = {
          id: item.id,
          networkId: item.networkId,
          name: item.name,
          checked: false,
          assigned: true,
          index: index,
        };
        index++;
      });
      response[1].data.map((item: any) => {
        locationDataMerge.push({
          id: item.id,
          networkId: item.networkId,
          name: item.name,
          checked: false,
          assigned: false,
          index: index,
        });
        index++;
      });
      setData(locationDataMerge);
      setIsSaveButtonVisible(true);
      updateToSaveLocations(locationDataMerge);
    });
  }, []);

  if (data === null) return <Spinner style={{ position: 'relative' }} />;

  const handleAssign = () => {
    const dataClone = [...data];
    dataClone.map((item, index) => {
      if (item.checked && !item.assigned) {
        dataClone[index].assigned = true;
      }

      dataClone[index].checked = false;
    });

    setData(dataClone);
    setReloadData(true);
    updateToSaveLocations(dataClone);
  };

  const handleUnassigned = () => {
    const dataClone = [...data];
    dataClone.map((item, index) => {
      if (item.checked && item.assigned) {
        dataClone[index].assigned = false;
      }

      dataClone[index].checked = false;
    });

    setData(dataClone);
    setReloadData(true);
    updateToSaveLocations(dataClone);
  };

  const handleGoToEdit = (event: any) => {
    history.push(
      `/projects/${id}/edit/3/${event.currentTarget.dataset.id}?goBack=projects/${id}/tasks/${taskId}/activities-edit?picked=${activityId}`,
    );
  };

  return (
    <div>
      <Heading title={'Lokalizacje'} />
      <SeparatorEmpty />
      <div className={styles.usersGrid}>
        <LocationsList
          column={'Przypisane'}
          handleGoToEdit={handleGoToEdit}
          networks={form.networks.options}
          data={data}
          setData={setData}
          assigned={true}
          reloadData={reloadData}
          setReloadData={setReloadData}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <button
            style={{ marginBottom: '12px', backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
            type={'button'}
            onClick={handleAssign}
          >
            <img src={left} />
          </button>
          <button
            style={{ backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
            type={'button'}
            onClick={handleUnassigned}
          >
            <img src={right} />
          </button>
        </div>
        <LocationsList
          data={data}
          column={'Nieprzypisane'}
          handleGoToEdit={handleGoToEdit}
          networks={form.networks.options}
          setData={setData}
          assigned={false}
          reloadData={reloadData}
          setReloadData={setReloadData}
        />
      </div>
    </div>
  );
};

export default Locations;
