import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { ICreateProduct, IGetProducts } from '../models';

const createUpdateData = (data: ICreateProduct) => {
  return {
    ...data,
    ...{
      name: data.name,
      price: {
        amount: Number(data.amount),
        currency: data.currency,
      },
      dimension: {
        length: Number(data.length),
        width: Number(data.width),
        height: Number(data.height),
      },
    },
  };
};
const ProductsConnection = {
  getProducts: (data: IGetProducts) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createProduct: (data: ICreateProduct) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items`,
      createUpdateData(data),
      {
        headers: getAuthHeader(),
      },
    );
  },
  updateProduct: (id: string, data: ICreateProduct) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items/${id}`,
      createUpdateData(data),
      {
        headers: getAuthHeader(),
      },
    );
  },
  getFiltersProducts: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddProduct: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditProduct: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items`, {
      headers: { ...getAuthHeader() },
      data: { items: data },
    });
  },
};

export default ProductsConnection;
