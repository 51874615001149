import React, { FunctionComponent } from 'react';
import SeparatorEmpty from './separators/SeparatorEmpty';

const ButtonsOverListContainer: FunctionComponent = ({ children }) => {
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>{children}</div>
      <SeparatorEmpty />
    </>
  );
};

export default ButtonsOverListContainer;
