import React, { FunctionComponent, useEffect, useState } from 'react';
import { IActivities, IFiltersForm } from 'utils/models';
import ActivitiesConnection from 'utils/connections/activities';
import { filtersFormState, getActivitesState } from 'utils/states';
import Filters from 'global/filters/Filters';
import Pagination from 'global/pagination/Pagination';
import ActivitiesListHeader from './ActivitiesListHeader';
import WhiteCard from 'global/atoms/WhiteCard';
import ActivitiesListActivity from './ActivitiesListActivity';
import { SET_LOADING } from 'redux/actions';
import { useDispatch } from 'react-redux';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IActivitiesList {}

const ActivitiesList: FunctionComponent<IActivitiesList> = () => {
  const [data, setData] = useState<IActivities | null>(null);
  const [filters, setFilters] = useState(getActivitesState);
  const [form, setForm] = useState<IFiltersForm>(filtersFormState);
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: { isLoading: true } });
    Promise.all([
      ActivitiesConnection.getActivities(getActivitesState).then((data) => {
        setLoading(false);
        setData(data.data);
      }),
      ActivitiesConnection.getFormFiltersActivities().then((data) => {
        setLoading(false);
        setForm(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
      }),
    ])
      .then(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      });
  }, []);

  const handleToggleFilters = () => {
      setToggleFilters((state) => !state);
    },
    handleSubmitFilters = (event: any) => {
      event.preventDefault();
      alert('filtruj');
    },
    handleClearFilters = () => {
      setFilters((state) => ({ ...state, ...getActivitesState }));
    };

  if (loading) return <Spinner />;

  if (data === null) return null;

  return (
    <>
      <WhiteCard mobileTransparent={true}>
        <ActivitiesListHeader />
        {data.data.map((item) => (
          <ActivitiesListActivity id={item.id} key={item.id} name={item.name} />
        ))}
      </WhiteCard>
      <Filters
        handleToggleFilters={handleToggleFilters}
        toggleFilters={toggleFilters}
        filtersState={filters}
        setFilters={setFilters}
        header={form.header}
        filters={form.fields}
        actions={form.actions}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters}
      />
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
    </>
  );
};

export default ActivitiesList;
