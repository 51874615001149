import React, { FunctionComponent } from 'react';
import Select from '../../../../../global/atoms/Select';
import { ITimeTableDetailsFiltersState, ITimeTableDetailsViewDataState } from './TimeTableDetails';
import AlertInfo from '../../../../../global/atoms/alert/AlertInfo';
import SeparatorEmpty from '../../../../../global/atoms/separators/SeparatorEmpty';

interface IFiltersProps {
  viewData: ITimeTableDetailsViewDataState;
  setFilters: any;
  filters: ITimeTableDetailsFiltersState;
}

const FiltersTimeTableDetails: FunctionComponent<IFiltersProps> = ({ viewData, setFilters, filters }) => {
  const handleChangeUser = (data: { label: string; value: any }) => {
    setFilters({ ...filters, ...{ userId: data.value } });
  };

  const handleChangeLocation = (data: { label: string; value: any }) => {
    setFilters({ ...filters, ...{ locationId: data.value } });
  };

  const handleChangeNetwork = (data: { label: string; value: any }) => {
    setFilters({ ...filters, ...{ networkId: data.value } });
  };

  const handleChangeProject = (data: { label: string; value: any }) => {
    setFilters({ ...filters, ...{ projectId: data.value } });
  };

  const handleChangeTask = (data: { label: string; value: any }) => {
    setFilters({ ...filters, ...{ taskId: data.value } });
  };

  return (
    <>
      {viewData.projects !== undefined && (
        <>
          <AlertInfo>Musisz wybrać minimum jeden filtr</AlertInfo>
          <SeparatorEmpty size={2} />
          <Select
            placeholder={'Projekt'}
            name={'projectId'}
            value={filters.projectId}
            options={viewData.projects.options}
            onChange={handleChangeProject}
          />
        </>
      )}
      {viewData.tasks !== undefined && (
        <Select
          placeholder={'Zadanie'}
          name={'taskId'}
          value={filters.taskId}
          options={viewData.tasks.options}
          onChange={handleChangeTask}
        />
      )}
      <Select
        placeholder={'Raportujący'}
        name={'userId'}
        value={filters.userId}
        options={viewData.users.options}
        onChange={handleChangeUser}
      />
      <Select
        placeholder={'Lokalizacja'}
        name={'locationId'}
        value={filters.locationId}
        options={viewData.locations.options}
        onChange={handleChangeLocation}
      />
      <Select
        placeholder={'Sieć'}
        name={'networkId'}
        value={filters.networkId}
        options={viewData.networks.options}
        onChange={handleChangeNetwork}
      />
    </>
  );
};

export default FiltersTimeTableDetails;
