import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import { getUserId, handleToast, parseTimeForBackend } from '../../../../utils/helpers';
import WarehouseConnection from '../../../../utils/connections/warehouse';
import { useHistory, useParams } from 'react-router-dom';
import Select from '../../../../global/atoms/Select';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import Section from '../../../../global/atoms/section/Section';
import Input from '../../../../global/atoms/Input';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import AlertInfo from '../../../../global/atoms/alert/AlertInfo';
import { ISelect } from '../../../../utils/models';
import DayPickerInputCustom from '../../../../global/atoms/DayPickerInputCustom';

export interface IWarehouseAddData {
  userId: string;
  userFromId: string;
  userToId: string;
  userRoleFromId: string;
  userRoleToId: string;
  description: string;
  type: string;
  positions: Array<any>;
  groupType: string;
  operationDate: number;
}

const ROLE_CLIENT = 'CLIENT';
const ROLE_CENTRAL = 'CENTRAL';
//const ROLE_MERCH = 'MERCH';
const ROLE_ADMIN = 'ADMIN';
const ACCEPTANCE_ROLES = [ROLE_CLIENT, ROLE_CENTRAL, ROLE_ADMIN];

const TYPE_INCOMING = 'INCOMING';
const TYPE_OUTCOMING = 'OUTCOMING';
const TYPE_LOST = 'LOST';

const WarehouseAdd: FunctionComponent = () => {
  const getDefaultState = () => {
    return {
      type: '',
      userId: getUserId(),
      userFromId: '',
      description: '',
      positions: [],
      userRoleFromId: '',
      userRoleToId: '',
      userToId: '',
      groupType: '',
      operationDate: 0,
    };
  };
  const [data, setData] = useState<IWarehouseAddData>(getDefaultState());
  const [form, setForm] = useState<
    | {
        users: ISelect;
        userStoreOperationTypes: any;
        itemGroups: any;
        usersRole: ISelect;
      }
    | 'loading'
  >('loading');
  const [items, setItems] = useState<Array<any>>([]);
  const { id } = useParams<{ id: string }>();
  const [errors, setErrors] = useState<any>(null);
  const history = useHistory();
  const [reloadItems, setReloadItems] = useState<boolean>(false);

  useEffect(() => {
    handleToast(WarehouseConnection.getFormAddWarehouse(id)).then((data) => {
      const formData: any = { ...data.data };
      formData.users.options = formData.users.options.map((item: any) => ({
        ...item,
        ...{ id: item.id, name: item.fullName },
      }));

      formData.itemGroups.options = formData.itemGroups.options.map((item: any) => ({
        ...item,
        ...{ id: item.type, name: item.name },
      }));

      formData.userStoreOperationTypes.options = formData.userStoreOperationTypes.options.map((item: any) => ({
        ...{ id: item.type, name: item.name },
      }));

      setForm(data.data);
    });
  }, []);

  useEffect(() => {
    if (data.userRoleFromId !== '' && data.userFromId !== '' && data.userToId !== '' && reloadItems) {
      handleToast(WarehouseConnection.getItems(id, data.userFromId, data.userRoleFromId)).then((response) => {
        setItems(response.data.availabilityEntryItems.data);
      });
      setReloadItems(false);
    }
  }, [reloadItems, data]);

  if (form === 'loading') return <Spinner />;

  const getRolesOptions = (): Array<any> => {
    const acceptanceRoleOptions = form.usersRole.options.filter((item: any) => {
      return ACCEPTANCE_ROLES.includes(item.type);
    });
    switch (data.type) {
      case TYPE_INCOMING:
        return acceptanceRoleOptions;
      case TYPE_OUTCOMING:
        return acceptanceRoleOptions;
      case TYPE_LOST:
        return acceptanceRoleOptions;
      default:
        return form.usersRole.options;
    }
  };

  const handleChangeUserFrom = (object: any) => {
    setData({ ...data, ...{ userFromId: object.value } });
    setReloadItems(true);
  };

  const handleChangeUserTo = (object: any) => {
    setData({ ...data, ...{ userToId: object.value } });
    setReloadItems(true);
  };

  const handleChangeAvailability = (object: any) => {
    const itemId = object.target.dataset.id;
    let amount = parseInt(object.target.value);
    if (isNaN(amount) || amount < 0) {
      amount = 0;
    }

    const item = items.filter((itemLoop) => itemLoop.id === itemId)[0];
    if (item.availability < amount && getSelectedRoleFrom().type !== ROLE_CLIENT) {
      amount = item.availability;
    }

    const positions = data.positions.filter((itemLoop) => itemLoop.itemId !== itemId);
    positions.push({
      itemId: itemId,
      amount: amount,
    });

    setData({ ...data, positions: positions });
  };

  const getItemMoveAmount = (itemId: string) => {
    const items = data.positions.filter((itemLoop) => itemLoop.itemId === itemId);
    if (items.length <= 0) {
      return 0;
    }
    return items[0].amount;
  };

  const handleChangeGroup = (object: any) => {
    setData({
      ...data,
      groupType: object.value,
      positions: [],
    });
  };

  const getItemsFilteredByGroupType = (): Array<any> => {
    if (data.groupType === '') {
      return items;
    }

    return items.filter((itemLoop) => itemLoop.itemGroup === data.groupType);
  };

  const handleSave = () => {
    WarehouseConnection.save(id, data)
      .then(() => history.push(`/projects/${id}/warehouse`))
      .catch((err) => setErrors(err.response.data.errors));
  };

  const getUsersByRoleType = (roleId: string) => {
    if (roleId === '') {
      return [];
    }
    const role: any = form.usersRole.options.find((item: any) => {
      return item.id === roleId;
    });
    return form.users.options.filter((item: any) => {
      return item.type === role.type;
    });
  };

  const getSelectedRoleFrom = (): any => {
    return form.usersRole.options.find((item: any) => {
      return item.id === data.userRoleFromId;
    });
  };

  const handleChangeOperationType = (object: any) => {
    const operationType = object.value;
    const defaultState = getDefaultState();
    defaultState.type = operationType;
    const dateNow = new Date();
    defaultState.operationDate = parseTimeForBackend(dateNow.getTime());
    setData(defaultState);
    setItems([]);
  };

  return (
    <>
      <WhiteCard padding={true}>
        <AlertInfo>Przyjęcie towaru na magazyn może wykonać użytkownik z grupy: Klient, Centrala</AlertInfo>
        <SeparatorEmpty />
        <Row>
          <Col xs={12}>
            <Select
              placeholder={'Typ operacji'}
              name={'type'}
              value={data.type}
              options={form.userStoreOperationTypes.options}
              state={data}
              setState={setData}
              errors={errors}
              onChange={handleChangeOperationType}
            />
          </Col>
        </Row>
        {data.type !== '' && (
          <>
            <SeparatorEmpty size={1.5} />
            <Row>
              <Col xs={6}>
                <Select
                  placeholder={'Rola'}
                  name={'userRoleFromId'}
                  value={data.userRoleFromId}
                  options={getRolesOptions()}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={6}>
                <Select
                  placeholder={'Od kogo'}
                  name={'userFromId'}
                  value={data.userFromId}
                  options={getUsersByRoleType(data.userRoleFromId)}
                  state={data}
                  setState={setData}
                  errors={errors}
                  onChange={handleChangeUserFrom}
                  disabled={data.userRoleFromId === ''}
                />
              </Col>
            </Row>
            <SeparatorEmpty size={1.5} />
            <Row>
              <Col xs={6}>
                <Select
                  placeholder={'Rola'}
                  name={'userRoleToId'}
                  value={data.userRoleToId}
                  options={getRolesOptions()}
                  state={data}
                  setState={setData}
                  errors={errors}
                />
              </Col>
              <Col xs={6}>
                <Select
                  placeholder={'Do kogo'}
                  name={'userToId'}
                  value={data.userToId}
                  options={getUsersByRoleType(data.userRoleToId)}
                  state={data}
                  setState={setData}
                  errors={errors}
                  onChange={handleChangeUserTo}
                  disabled={data.userRoleToId === ''}
                />
              </Col>
            </Row>
            <SeparatorEmpty size={1.5} />
            <Row>
              <Col xs={12}>
                <DayPickerInputCustom
                  name={'operationDate'}
                  value={data.operationDate}
                  placeholder={'Data operacji'}
                  state={data}
                  setState={setData}
                  disabled={true}
                />
              </Col>
            </Row>
            <SeparatorEmpty size={1.5} />
            <Row>
              <Col xs={12}>
                <Select
                  placeholder={'Grupa'}
                  name={'groupType'}
                  value={data.groupType}
                  options={form.itemGroups.options}
                  state={data}
                  setState={setData}
                  errors={errors}
                  onChange={handleChangeGroup}
                />
              </Col>
            </Row>
            <SeparatorEmpty size={1.5} />
            <Row>
              <Col xs={12}>
                <Section title={'Produkty'}>
                  {getItemsFilteredByGroupType().map((item, index) => {
                    return (
                      <div key={index}>
                        <Row>
                          <Col xs={6}>
                            <Input
                              disabled={true}
                              type={'text'}
                              placeholder={'Nazwa'}
                              name={'name'}
                              value={item.name}
                            />
                          </Col>
                          {data.type !== TYPE_INCOMING && (
                            <Col xs={3}>
                              <Input
                                disabled={true}
                                type={'text'}
                                placeholder={'Stan'}
                                name={'name'}
                                value={item.availability}
                              />
                            </Col>
                          )}
                          <Col xs={3}>
                            <Input
                              type={'number'}
                              placeholder={'Do przesunięcia'}
                              name={'name'}
                              value={getItemMoveAmount(item.id)}
                              onChange={handleChangeAvailability}
                              id={item.id}
                              disabled={item.availability <= 0 && data.type !== TYPE_INCOMING}
                            />
                          </Col>
                        </Row>
                        {items.length - 1 !== index && <SeparatorEmpty />}
                      </div>
                    );
                  })}
                </Section>
              </Col>
            </Row>
            <SeparatorEmpty size={1.5} />
            <ActionsContainer>
              <ButtonSubmit onClick={handleSave} form={form} />
            </ActionsContainer>
          </>
        )}
      </WhiteCard>
    </>
  );
};

export default WarehouseAdd;
