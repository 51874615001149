import React, { FunctionComponent, useEffect, useState } from 'react';
import left from '../../assets/svg/left_big.svg';
import right from '../../assets/svg/right_big.svg';
import { Col, Row } from 'react-grid-system';
import styles from './styles.module.scss';
import UserConnection from '../../utils/connections/user';
import UserMultiSelectList from './UserMultiSelectList';
import { IProject, IRole, IUserMultiSelectEntity } from '../../utils/models';

interface IUserMultiSelect {
  projectId?: string;
  projects?: Array<IProject>;
  roles?: Array<IRole>;
  assignedUserIds: Array<string>;
  setAssignedUserIds: React.Dispatch<React.SetStateAction<string[]>>;
  disabled?: boolean;
}

const UserMultiSelect: FunctionComponent<IUserMultiSelect> = (props) => {
  const [assignedUsers, setAssignedUsers] = useState<Array<IUserMultiSelectEntity>>([]);
  const [unAssignedUsers, setUnAssignedUsers] = useState<Array<IUserMultiSelectEntity>>([]);
  const [checked, setChecked] = useState<{ assigned: Array<string>; unassigned: Array<string> }>({
    assigned: [],
    unassigned: [],
  });

  useEffect(() => {
    UserConnection.getUsersForMultiselect().then((res) => {
      const imse: Array<IUserMultiSelectEntity> = res.data.result ?? [];
      setUnAssignedUsers(imse);

      // fill assignedUsers with view ready data
      if (props.assignedUserIds && props.assignedUserIds.length) {
        // @ts-ignore
        const au: Array<IUserMultiSelectEntity> = imse.filter((u) => props.assignedUserIds.indexOf(u.id) >= 0) ?? [];
        if (au.length) setAssignedUsers(au);
      }
    });
  }, []);

  const handleAssign = () => {
    const tmp = unAssignedUsers.filter((u) => checked.unassigned.includes(u.id));
    const res: Array<IUserMultiSelectEntity> = [];
    const ids: Array<string> = [];

    assignedUsers.concat(tmp).forEach((elm) => {
      if (!ids.includes(elm.id)) {
        res.push(elm);
        ids.push(elm.id);
      }
    });

    setAssignedUsers(res);
    props.setAssignedUserIds(ids);
  };

  const handleUnAssign = () => {
    const tmp = assignedUsers.filter((u) => !checked.assigned.includes(u.id));
    setAssignedUsers(tmp);

    const tmp2 = props.assignedUserIds.filter((uid) => !checked.assigned.includes(uid));
    props.setAssignedUserIds(tmp2);
  };

  const handleToggleCheckbox = (event: any, assigned: boolean) => {
    const eid: string = event.currentTarget.dataset.id;

    if (assigned) {
      if (checked.assigned.includes(eid)) {
        setChecked({
          assigned: checked.assigned.filter((item) => item !== eid),
          unassigned: checked.unassigned,
        });
      } else {
        setChecked({ ...checked, assigned: [...checked.assigned, eid] });
      }
    } else {
      if (checked.unassigned.includes(eid)) {
        setChecked({
          assigned: checked.assigned,
          unassigned: checked.unassigned.filter((item) => item !== eid),
        });
      } else {
        setChecked({ ...checked, unassigned: [...checked.unassigned, eid] });
      }
    }
  };

  const handleToggleMainCheckbox = (assigned: boolean) => {
    if (assigned) {
      if (checked.assigned.length) {
        setChecked({
          ...checked,
          assigned: [],
        });
      } else {
        setChecked({ ...checked, assigned: assignedUsers.map((item: any) => item.id) });
      }
    } else {
      if (checked.unassigned.length) {
        setChecked({
          ...checked,
          unassigned: [],
        });
      } else {
        setChecked({ ...checked, unassigned: unAssignedUsers.map((item: any) => item.id) });
      }
    }
  };

  return (
    <Row>
      <Col md={5} xs={12}>
        <UserMultiSelectList
          projects={props.projects}
          roles={props.roles}
          handleToggleCheckbox={(event) => {
            handleToggleCheckbox(event, true);
          }}
          handleToggleMainCheckbox={() => {
            handleToggleMainCheckbox(true);
          }}
          editView={true}
          checked={checked.assigned}
          data={assignedUsers}
          counter={assignedUsers.length}
          column={'Przypisani'}
        />
      </Col>

      {props.disabled === true ? (
        <></>
      ) : (
        <>
          <Col md={2} xs={12} style={{ textAlign: 'center' }}>
            <div className={styles.moveEntityWrapper}>
              <button
                type={'button'}
                className={styles.moveEntityButton}
                style={{ marginBottom: '12px' }}
                onClick={handleAssign}
              >
                <img src={left} alt={'leftArrow'} />
              </button>
              <button type={'button'} className={styles.moveEntityButton} onClick={handleUnAssign}>
                <img src={right} alt={'rightArrow'} />
              </button>
            </div>
          </Col>

          <Col md={5} xs={12}>
            <UserMultiSelectList
              projects={props.projects}
              roles={props.roles}
              handleToggleCheckbox={(event) => {
                handleToggleCheckbox(event, false);
              }}
              handleToggleMainCheckbox={() => {
                handleToggleMainCheckbox(false);
              }}
              editView={true}
              checked={checked.unassigned}
              data={unAssignedUsers}
              counter={unAssignedUsers.length}
              column={''}
            />
          </Col>
        </>
      )}
    </Row>
  );
};

export default UserMultiSelect;
