import React from 'react';
import { Redirect } from 'react-router-dom';
import ActivitiesAdd from 'views/datasets/components/activitiesAdd/ActivitiesAdd';
import ActivitiesEdit from 'views/datasets/components/activitiesEdit/ActivitiesEdit';
import ActivitiesList from 'views/datasets/components/activitiesList/ActivitiesList';
import AddressAdd from 'views/datasets/components/addressAdd/AddressAdd';
import AddressesList from 'views/datasets/components/addressesList/AddressesList';
import ChainsAdd from 'views/datasets/components/chainsAdd/ChainsAdd';
import ChainsEdit from 'views/datasets/components/chainsEdit/ChainsEdit';
import ChainsList from 'views/datasets/components/chainsList/ChainsList';
import LocationAdd from 'views/datasets/components/locationAdd/LocationAdd';
import LocationEdit from 'views/datasets/components/locationEdit/LocationEdit';
import QuestionsAdd from 'views/datasets/components/questionsAdd/QuestionsAdd';
import QuestionsEdit from 'views/datasets/components/questionsEdit/QuestionsEdit';
import LocationsList from 'views/datasets/components/locationsList/LocationsList';
import QuestionsList from 'views/datasets/components/questionsList/QuestionsList';

export default [
  { path: '/datasets', breadcrumb: null, Component: () => <Redirect to="/datasets/addresses" /> },
  { path: '/datasets/locations', breadcrumb: 'Lokalizacje', Component: LocationsList },
  { path: '/datasets/locations/add', breadcrumb: 'Dodaj lokalizację', Component: LocationAdd },
  { path: '/datasets/locations/:id', breadcrumb: null },
  { path: '/datasets/locations/:id/edit', breadcrumb: 'Edytuj lokalizację', Component: LocationEdit },
  { path: '/datasets/addresses', breadcrumb: 'Adresy', Component: AddressesList },
  { path: '/datasets/addresses/add', breadcrumb: 'Dodaj adres', Component: AddressAdd },
  { path: '/datasets/addresses/:id', breadcrumb: null },
  { path: '/datasets/addresses/:id/edit', breadcrumb: 'Edytuj adres', Component: AddressAdd },
  { path: '/datasets/activity', breadcrumb: 'Aktywności', Component: ActivitiesList },
  { path: '/datasets/activity/add', breadcrumb: 'Dodaj aktywność', Component: ActivitiesAdd },
  { path: '/datasets/activity/:id', breadcrumb: null },
  { path: '/datasets/activity/:id/edit', breadcrumb: 'Edytuj aktywność', Component: ActivitiesEdit },
  { path: '/datasets/networks', breadcrumb: 'Sieci', Component: ChainsList },
  { path: '/datasets/networks/add', breadcrumb: 'Dodaj sieć', Component: ChainsAdd },
  { path: '/datasets/networks/:id', breadcrumb: null },
  { path: '/datasets/networks/:id/edit', breadcrumb: 'Edytuj sieć', Component: ChainsEdit },
  { path: '/datasets/questions', breadcrumb: 'Pytania', Component: QuestionsList },
  { path: '/datasets/questions/add', breadcrumb: 'Dodaj pytanie', Component: QuestionsAdd },
  { path: '/datasets/questions/:id', breadcrumb: null },
  { path: '/datasets/questions/:id/edit', breadcrumb: 'Edytuj pytanie', Component: QuestionsEdit },
];
