import React, { FunctionComponent } from 'react';
import styles from '../atoms.module.scss';
import arrowLeft from '../../../assets/svg/arrow_left.svg';
import arrowRight from '../../../assets/svg/arrow_right.svg';

export interface ISelectQuarterState {
  quarter: number;
  year: number;
}

interface ISelectQuarterProps {
  selected: ISelectQuarterState;
  onChange: (quarter: number, year: number) => void;
}

const SelectQuarter: FunctionComponent<ISelectQuarterProps> = ({ onChange, selected }) => {
  const quarters: any = {
    0: 'I kwartał',
    1: 'II kwartał',
    2: 'III kwartał',
    3: 'IV kwartał',
  };

  const handleClickNext = () => {
    const stateClone = { ...selected };
    if (stateClone.quarter >= 3) {
      stateClone.quarter = 0;
      stateClone.year++;
    } else {
      stateClone.quarter++;
    }
    onChange(stateClone.quarter, stateClone.year);
  };

  const handleClickPrevious = () => {
    const stateClone = { ...selected };
    if (stateClone.quarter <= 0) {
      stateClone.quarter = 3;
      stateClone.year--;
    } else {
      stateClone.quarter--;
    }
    onChange(stateClone.quarter, stateClone.year);
  };

  return (
    <div className={styles.selectMonth}>
      <img onClick={handleClickPrevious} className={styles.selectMonthButton} alt={'Poprzedni'} src={arrowLeft} />
      <span>
        {quarters[selected.quarter]} {selected.year}
      </span>
      <img onClick={handleClickNext} className={styles.selectMonthButton} alt={'Następny'} src={arrowRight} />
    </div>
  );
};

export default SelectQuarter;
