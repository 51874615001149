import React, { CSSProperties, FunctionComponent, useRef } from 'react';
import close from '../../assets/svg/close-accent.svg';
import styles from './atoms.module.scss';

interface IImageFormSelectProps {
  name: string;
  state: any;
  setState: any;
  label?: string;
}

const ImageFormSelect: FunctionComponent<IImageFormSelectProps> = ({ name, state, setState, label }) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const handleChange = (event: any) => {
    const file = event.currentTarget.files[0];
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      function () {
        // convert image file to base64 string
        const imageBase64 = reader.result;
        setState({ ...state, [name]: imageBase64 });
      },
      false,
    );
    reader.readAsDataURL(file);
  };

  const handleSelectFileTrigger = () => {
    // @ts-ignore: Object is possibly 'null'.
    inputFile?.current.click();
  };

  const handleRemoveImage = (event: any) => {
    event.stopPropagation();
    setState({ ...state, [name]: '' });
  };

  const stylesContainer: CSSProperties = {};
  if (state[name] !== '') {
    stylesContainer.backgroundImage = `url(${state[name]})`;
  }
  return (
    <>
      <div onClick={handleSelectFileTrigger} className={styles.imageFormSelectContainer} style={stylesContainer}>
        {state[name] === '' && <span className={styles.imageFormSelectLabel}>{label}</span>}
        {state[name] !== '' && (
          <div className={styles.imageFormSelectRemoveButton}>
            <img width={18} height={18} src={close} alt="close" onClick={handleRemoveImage} />
          </div>
        )}
      </div>
      <input
        ref={inputFile}
        type={'file'}
        accept={'image/*'}
        name={name}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ImageFormSelect;
