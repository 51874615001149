import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import ReportTemplateConnection from '../../../../utils/connections/reportTemplate';
import { useParams } from 'react-router-dom';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import ProgressBar from '../../../../global/atoms/ProgressBar';
import IconButton from '../../../../global/atoms/IconButton';
import List from '../../../../global/table/List';

interface IAsyncReportResults {
  reportType: number;
  reloadReports: boolean;
  setReloadReports: any;
}

interface IReportResult {
  id: string;
  filePath: string;
  templateName: string;
  progress: number;
  createdAt: string;
  fileName: string;
}

const AsyncReportResults: FunctionComponent<IAsyncReportResults> = ({
  reportType,
  setReloadReports,
  reloadReports,
}) => {
  const { id } = useParams<{ id: string }>();
  const [reports, setReports] = useState<Array<IReportResult>>([]);
  const interval = useRef<any>(null);

  const loadLatestReports = () => {
    ReportTemplateConnection.getAsyncReportResults(id, reportType).then((response) => {
      setReports(response.data);
    });
  };

  useEffect(() => {
    loadLatestReports();
  }, []);

  useEffect(() => {
    if (reloadReports) {
      loadLatestReports();
      setReloadReports(false);
    }
  }, [reloadReports]);

  useEffect(() => {
    loadLatestReports();
    interval.current = setInterval(() => {
      loadLatestReports();
    }, 10000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleDownloadFile = (reportResultId: string, fileName: string) => {
    ReportTemplateConnection.getAsyncFile(reportResultId, fileName);
  };

  return (
    <>
      <WhiteCard>
        <List data={reports} count={reports.length}>
          <Column minWidth={180} flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              Szablon
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="templateName" />
          </Column>
          <Column width={180} align="left">
            <HeaderCell depth={0} width={0}>
              Progres
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="progress">
              {(rowData) => <ProgressBar completed={rowData.progress} />}
            </Cell>
          </Column>
          <Column width={180} align="left">
            <HeaderCell depth={0} width={0}>
              Data utworzenia
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="createdDate" />
          </Column>
          <Column width={120} align="left">
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell width={120} depth={0} dataKey="progress">
              {(rowData: IReportResult) => (
                <>
                  {rowData.progress >= 100 && rowData.filePath !== '' && (
                    <IconButton
                      onClick={handleDownloadFile.bind(null, rowData.id, rowData.fileName)}
                      icon={'arrowRightBig'}
                    />
                  )}
                  {rowData.progress >= 100 && rowData.filePath === '' && <span>Brak danych</span>}
                </>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
    </>
  );
};

export default AsyncReportResults;
