import React, { FunctionComponent, useState } from 'react';
import styles from '../editProject/access/styles.module.scss';
import Input from '../../../../global/atoms/Input';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import drag from '../../../../assets/svg/drag.svg';

const DragHandle = SortableHandle(() => <img style={{ marginRight: '12px', cursor: 'pointer' }} src={drag} />);

const SortableList = SortableContainer(({ children }: { children: React.ReactNode }) => <ul>{children}</ul>);

const SortableItem = SortableElement(
  ({
    itemId,
    handleToggleCheckbox,
    checked,
    name,
    editView,
    draggable,
  }: {
    itemId: string;
    handleToggleCheckbox?: (event: any) => void;
    checked?: Array<any>;
    name: string;
    editView: boolean;
    editable?: boolean;
    handleSetPicked?: (event: any) => void;
    draggable: boolean;
  }) => (
    <div className={styles.user}>
      <div className={styles.wrapper}>
        {editView && (
          <span
            data-id={itemId}
            onClick={handleToggleCheckbox}
            className={styles.checkbox2}
            style={{
              backgroundColor: checked?.includes(itemId) ? '#ffaf38' : '',
            }}
          />
        )}
        {draggable && <DragHandle />}
        <span style={{ width: 'auto' }}>{name}</span>
      </div>
    </div>
  ),
);

interface IItemsList {
  data: any;
  checked?: Array<any>;
  editView: boolean;
  handleToggleCheckbox?: (event: any) => void;
  handleToggleMainCheckbox?: () => void;
  column: string;
  counter: string;
  onSortEnd: any;
  draggable: boolean;
}

const ItemsList: FunctionComponent<IItemsList> = ({
  data,
  editView,
  handleToggleCheckbox,
  handleToggleMainCheckbox,
  checked,
  column,
  counter,
  onSortEnd,
  draggable,
}) => {
  const [search, setSearch] = useState<string>('');

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value);
  };

  const classForCheckbox = () => {
    let cn = styles.checkbox;
    if (checked?.length) {
      cn += ' ' + styles.checkboxIsChecked;
    }
    return cn;
  };

  const getFilteredData = () => {
    if (search === '') {
      return data;
    }

    return data.filter((item: any) => {
      const nameLowercase = item.name.toLowerCase();
      const searchLowercase = search.toLowerCase();
      return nameLowercase.indexOf(searchLowercase) >= 0;
    });
  };

  return (
    <div style={{ marginBottom: '25px' }}>
      <div className={styles.wrapperFields}>
        <div className={styles.activitySectionHeader}>
          {editView && <span className={classForCheckbox()} onClick={handleToggleMainCheckbox} />}
          <span className={styles.columnAndCounter}>
            {column} ({counter})
          </span>
        </div>
        <Input
          style={{
            maxWidth: '200px',
          }}
          value={search}
          placeholder={'Szukaj'}
          onChange={handleChangeSearch}
          name={'search'}
        />
      </div>
      <div className={styles.wrapperSortable}>
        <SortableList onSortEnd={onSortEnd} useDragHandle={true}>
          {getFilteredData().map((item: any, index: number) => {
            return (
              <SortableItem
                editView={editView}
                key={item.id}
                index={index}
                checked={checked}
                handleToggleCheckbox={handleToggleCheckbox}
                itemId={item.id}
                name={item.name}
                draggable={draggable && search === ''}
              />
            );
          })}
        </SortableList>
      </div>
    </div>
  );
};

export default ItemsList;
