import React from 'react';
import BaseComponent from '../../../../global/atoms/BaseComponent';
import { IBaseComponentProps } from '../../../../utils/models';
import { Col, Row } from 'react-grid-system';
import Input from '../../../../global/atoms/Input';
import { deepClone, parseDateForBackend, parseDateForFrontend, parseTimeForBackend } from '../../../../utils/helpers';

interface IEditTimeTableSingleSettingsProps extends IBaseComponentProps {
  parentData: any;
  parentSetData: any;
}

export default class EditTimeTableSingleSettings extends BaseComponent<IEditTimeTableSingleSettingsProps, any> {
  startDateChangeHandle(event: any) {
    const parentDataCloned = deepClone(this.props.parentData);
    parentDataCloned.timeTable.singleSettings.startDate = parseDateForBackend(event.currentTarget.value);
    this.props.parentSetData({ ...parentDataCloned });
  }

  renderView(): JSX.Element {
    const getDateInput = (dateNumber: number) => {
      return dateNumber === 0 ? parseTimeForBackend(new Date().getTime()) : dateNumber;
    };

    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={8} lg={4}>
            <Input
              onChange={this.startDateChangeHandle.bind(this)}
              value={parseDateForFrontend(getDateInput(this.props.parentData.timeTable.singleSettings.startDate))}
              placeholder={'Data wygenerowania zadania'}
              type={'date'}
              name={'start'}
            />
          </Col>
        </Row>
      </>
    );
  }
}
