import React, { FunctionComponent } from 'react';
import styles from './atoms.module.scss';
import tick from '../../assets/svg/checkmark.svg';
import mixed from '../../assets/svg/checkmark_mixed.svg';

interface ICheckbox {
  isChecked: boolean;
  isMixed?: boolean;
  id?: any;
  onClick: (event: any) => void;
  className?: any;
  style?: any;
  data?: any;
}

const Checkbox: FunctionComponent<ICheckbox> = ({
  id,
  isChecked,
  onClick,
  isMixed = false,
  className,
  style,
  data,
}) => {
  let classNames = isChecked ? styles.checkboxActive : styles.checkbox;
  if (className !== undefined) {
    classNames += ' ' + className;
  }

  const customStyles = {
    ...style,
    ...{
      cursor: 'pointer',
    },
  };

  if (isMixed)
    return (
      <span className={classNames} onClick={onClick} style={customStyles} data-data={data} data-id={id}>
        {isChecked ? <img src={mixed} alt="tick" /> : null}
      </span>
    );

  return (
    <span className={classNames} data-data={data} data-id={id} onClick={onClick} style={customStyles}>
      {isChecked && <img src={tick} alt="tick" />}
    </span>
  );
};

export default Checkbox;
