import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import styles from '../editProject/access/styles.module.scss';
import left from '../../../../assets/svg/left_big.svg';
import right from '../../../../assets/svg/right_big.svg';
import QuestionsList from './QuestionsList';
import QuestionForm from './QuestionForm';
import { arrayMove } from 'react-sortable-hoc';
import Heading from '../../../../global/heading/Heading';

interface IQuestions {
  setForm: Dispatch<SetStateAction<any>>;
  unassigned: any;
  assigned: any;
  title?: string;
  defaultValueEdit?: boolean;
  type?: 'default' | 'project';
}

const Questions: FunctionComponent<IQuestions> = ({
  setForm,
  unassigned,
  assigned,
  title = 'Pytania',
  defaultValueEdit = false,
  type = 'default',
}) => {
  const [checked, setChecked] = useState<{ assigned: Array<string>; unassigned: Array<string> }>({
    assigned: [],
    unassigned: [],
  });
  const [picked, setPicked] = useState<any>(null);

  const handleToggleAssigned = (event: any) => {
      if (checked.assigned.includes(event.currentTarget.dataset.id)) {
        setChecked({
          ...checked,
          assigned: checked.assigned.filter((item) => item !== event.currentTarget.dataset.id),
        });
      } else {
        setChecked({ ...checked, assigned: [...checked.assigned, event.currentTarget.dataset.id] });
      }
    },
    handleToggleAllAssigned = () => {
      if (checked.assigned.length) {
        setChecked({
          ...checked,
          assigned: [],
        });
      } else {
        setChecked({ ...checked, assigned: assigned.map((item: any) => item.id) });
      }
    },
    handleToggleUnassigned = (event: any) => {
      if (checked.unassigned.includes(event.currentTarget.dataset.id)) {
        setChecked({
          ...checked,
          unassigned: checked.unassigned.filter((item) => item !== event.currentTarget.dataset.id),
        });
      } else {
        setChecked({ ...checked, unassigned: [...checked.unassigned, event.currentTarget.dataset.id] });
      }
    },
    handleToggleAllUnassigned = () => {
      if (checked.unassigned.length) {
        setChecked({
          ...checked,
          unassigned: [],
        });
      } else {
        setChecked({ ...checked, unassigned: unassigned.map((item: any) => item.id) });
      }
    },
    handleAssign = () => {
      setForm((state: any) => {
        return {
          ...state,
          unassignedQuestions: {
            ...state.unassignedQuestions,
            data: state.unassignedQuestions.data.filter((item: any) => !checked.unassigned.includes(item.id)),
          },
          assignedQuestions: {
            ...state.assignedQuestions,
            data: [
              ...state.assignedQuestions.data,
              ...state.unassignedQuestions.data.filter((item: any) => checked.unassigned.includes(item.id)),
            ],
          },
        };
      });
      setChecked({ ...checked, unassigned: [] });
    },
    handleUnassign = () => {
      setForm((state: any) => {
        return {
          ...state,
          assignedQuestions: {
            ...state.assignedQuestions,
            data: state.assignedQuestions.data.filter((item: any) => !checked.assigned.includes(item.id)),
          },
          unassignedQuestions: {
            ...state.unassignedQuestions,
            data: [
              ...state.unassignedQuestions.data,
              ...state.assignedQuestions.data.filter((item: any) => checked.assigned.includes(item.id)),
            ],
          },
        };
      });
      setChecked({ ...checked, assigned: [] });
    },
    handleSetPicked = (event: any) => {
      if (!picked) {
        for (const question of assigned) {
          if (question.id === event.currentTarget.dataset.id) {
            setPicked(question);
          }
        }
      } else {
        if (picked.id === event.currentTarget.dataset.id) {
          setPicked(null);
        } else {
          for (const question of assigned) {
            if (question.id === event.currentTarget.dataset.id) {
              setPicked(question);
            }
          }
        }
      }
    },
    handleRemovePicked = () => {
      setPicked(null);
    },
    onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      setForm((state: any) => {
        return {
          ...state,
          assignedQuestions: {
            ...state.assignedQuestions,
            data: arrayMove(state.assignedQuestions.data, oldIndex, newIndex),
          },
        };
      });
    };

  return (
    <div>
      <Heading title={title} margin={'0'} />
      <div className={styles.usersGrid}>
        <QuestionsList
          handleToggleCheckbox={handleToggleAssigned}
          handleToggleMainCheckbox={handleToggleAllAssigned}
          editView={true}
          checked={checked.assigned}
          data={assigned}
          column={'Przypisane'}
          counter={assigned.length}
          handleSetPicked={handleSetPicked}
          pickedId={picked ? picked.id : ''}
          draggable={true}
          onSortEnd={onSortEnd}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          {!picked && (
            <>
              <button
                style={{ marginBottom: '12px', backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
                type={'button'}
                onClick={handleAssign}
              >
                <img src={left} />
              </button>
              <button
                style={{ backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
                onClick={handleUnassign}
                type={'button'}
              >
                <img src={right} />
              </button>
            </>
          )}
        </div>
        {picked ? (
          <QuestionForm
            question={picked}
            assigned={assigned}
            removePicked={handleRemovePicked}
            setForm={setForm}
            defaultValueEdit={defaultValueEdit}
            type={type}
          />
        ) : (
          <QuestionsList
            draggable={false}
            editable={false}
            handleToggleCheckbox={handleToggleUnassigned}
            handleToggleMainCheckbox={handleToggleAllUnassigned}
            editView={true}
            checked={checked.unassigned}
            data={unassigned}
            column={'Nieprzypisane'}
            counter={unassigned.length}
            pickedId={''}
          />
        )}
      </div>
    </div>
  );
};

export default Questions;
