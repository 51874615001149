import React, { FunctionComponent, useEffect, useState } from 'react';
import ProjectsConnection from '../../../../utils/connections/projects';
import { IGetProjectProducts, IProjectProducts, ISelectViewResponse } from '../../../../utils/models';
import { defaultFilterOrderState, defaultFilterPaginateState, selectViewResponseState } from '../../../../utils/states';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import List from '../../../../global/table/List';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import IconButton from '../../../../global/atoms/IconButton';
import Pagination from '../../../../global/pagination/Pagination';
import Filters from '../../../../global/filters/Filters';
import {
  handleClearFilters,
  handleSubmitAndSaveFilters,
  handleToggleFilters,
  loadFilters,
} from '../../../../utils/filterHelper';
import ButtonFilters from '../../../../global/atoms/ButtonFilters';
import HeaderButtons from '../../../../global/atoms/headerButtons/HeaderButtons';
import Input from '../../../../global/atoms/Input';
import Select from '../../../../global/atoms/Select';
import SwitchButtonHorizontalGroup from '../../../../global/atoms/switchButton/SwitchButtonHorizontalGroup';
import SwitchButton from '../../../../global/atoms/switchButton/SwitchButton';
import ButtonsOverListContainer from '../../../../global/atoms/ButtonsOverListContainer';
import Button from '../../../../global/atoms/Button';
import { BatchActionButton, ToggleAllCheckbox, ToggleSingleCheckbox } from '../../../../utils/toggleToDeleteHelper';
import { handleToast } from '../../../../utils/helpers';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';

const TYPE_ASSIGNED = 'assigned';
const TYPE_UNASSIGNED = 'unassigned';

const Products: FunctionComponent = () => {
  const [data, setData] = useState<IProjectProducts | null>(null);
  const defaultFilters: IGetProjectProducts = {
    customerId: '',
    name: '',
    brandId: '',
    gtin: '',
    itemUnitId: '',
    itemVatRateId: '',
    itemGroupId: '',
    ...defaultFilterOrderState,
    ...defaultFilterPaginateState,
  };
  const [filters, setFilters] = useState<IGetProjectProducts>(loadFilters(defaultFilters));
  const [form, setForm] = useState<{
    brands: ISelectViewResponse;
    itemGroups: ISelectViewResponse;
    itemUnits: ISelectViewResponse;
    itemVatRates: ISelectViewResponse;
    customers: ISelectViewResponse;
  }>({
    brands: selectViewResponseState,
    itemGroups: selectViewResponseState,
    itemUnits: selectViewResponseState,
    itemVatRates: selectViewResponseState,
    customers: selectViewResponseState,
  });
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<boolean>(false);
  type IViewTypes = 'assigned' | 'unassigned';
  const [viewType, setViewType] = useState<IViewTypes>(TYPE_ASSIGNED);
  const [chosen, setChosen] = useState<Array<string>>([]);

  useEffect(() => {
    ProjectsConnection.getFiltersFormProducts().then((data) => {
      setForm(data.data);
    });
  }, []);

  const loadData = () => {
    let promise;
    if (viewType === TYPE_ASSIGNED) {
      promise = ProjectsConnection.getAssignedProducts(id, filters);
    } else {
      promise = ProjectsConnection.getUnassignedProducts(id, filters);
    }

    promise.then((data) => {
      setData(data.data);
    });
  };

  useEffect(() => loadData(), [filters.requestPaginate, viewType]);
  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
  }, [reloadData]);

  if (data === null) return <Spinner />;

  const handleChangeView = (type: IViewTypes) => {
    setViewType(type);
  };

  const handleMoveProducts = (type: IViewTypes) => {
    const data = chosen.map((item) => ({ itemId: item }));
    let promise;
    if (type === TYPE_ASSIGNED) {
      promise = ProjectsConnection.unassignProductsFromProject(id, data);
    } else {
      promise = ProjectsConnection.assignProductsToProject(id, data);
    }
    handleToast(promise).then(() => {
      setChosen([]);
      loadData();
    });
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters.bind(null, setToggleFilters)} />

        {viewType === TYPE_ASSIGNED && (
          <>
            <BatchActionButton
              chosenRows={chosen}
              callback={handleMoveProducts.bind(null, TYPE_ASSIGNED)}
              title={'Odłącz'}
              message={'Czy odłączyć produkty '}
            />
          </>
        )}
        {viewType === TYPE_UNASSIGNED && (
          <>
            <Button disabled={chosen.length <= 0} onClick={handleMoveProducts.bind(null, TYPE_UNASSIGNED)}>
              Przypisz
            </Button>
            <Button style={{ width: 'auto' }} onClick={() => history.push('/products/product/add')}>
              Dodaj nowy
            </Button>
          </>
        )}
      </HeaderButtons>
      <ButtonsOverListContainer>
        <SwitchButtonHorizontalGroup>
          <SwitchButton isChecked={viewType === TYPE_ASSIGNED} onClick={handleChangeView.bind(null, TYPE_ASSIGNED)}>
            Przypisane
          </SwitchButton>
          <SwitchButton isChecked={viewType === TYPE_UNASSIGNED} onClick={handleChangeView.bind(null, TYPE_UNASSIGNED)}>
            Nieprzypisane
          </SwitchButton>
        </SwitchButtonHorizontalGroup>
      </ButtonsOverListContainer>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox data={data.data} state={chosen} setState={setChosen} />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <ToggleSingleCheckbox state={chosen} setState={setChosen} selectedId={rowData.id} />}
            </Cell>
          </Column>
          <Column flexGrow={1} width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Nazwa
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/products/product/${rowData.id}/edit`}>
                  {rowData.name}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Klient
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => <span>{rowData.customer.name}</span>}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Grupa
            </HeaderCell>
            <Cell width={0} depth={0}>
              {(rowData) => <span>{rowData.itemGroup.name}</span>}
            </Cell>
          </Column>
          <Column width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Marka
            </HeaderCell>
            <Cell width={70} depth={0}>
              {(rowData) => {
                return <span>{rowData.brand.name}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Netto
            </HeaderCell>
            <Cell width={70} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.amount).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Vat
            </HeaderCell>
            <Cell width={80} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.tax).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Brutto
            </HeaderCell>
            <Cell width={80} depth={0}>
              {(rowData) => {
                return <span>{Number(rowData.price.amountGross).toFixed(2)}</span>;
              }}
            </Cell>
          </Column>
          <Column fixed="right" width={70} align="left">
            <HeaderCell depth={0} width={0}>
              &nbsp;
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ActionsContainer>
                  <IconButton icon={'settings'} onClick={() => history.push(`/products/product/${rowData.id}/edit`)} />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters.bind(null, setToggleFilters)}
        handleSubmit={handleSubmitAndSaveFilters.bind(null, setReloadData, filters)}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, setReloadData)}
      >
        <Input value={filters.name} placeholder={'Nazwa'} name={'name'} setState={setFilters} state={filters} />
        <Input value={filters.gtin} placeholder={'GTIN'} name={'gtin'} setState={setFilters} state={filters} />
        <Select
          placeholder={'Jednostka'}
          name={'itemUnitId'}
          value={filters.itemUnitId}
          options={form.itemUnits.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Grupa'}
          name={'itemGroupId'}
          value={filters.itemGroupId}
          options={form.itemGroups.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Vat'}
          name={'itemVatRateId'}
          value={filters.itemVatRateId}
          options={form.itemVatRates.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Klient'}
          name={'customerId'}
          value={filters.customerId}
          options={form.customers.options}
          state={filters}
          setState={setFilters}
        />
      </Filters>
    </>
  );
};

export default Products;
