import React, { FunctionComponent, useEffect, useState } from 'react';
import { IGetAssignedLocations, IGetProjectLocations } from 'utils/models';
import ProjectsConnection from 'utils/connections/projects';
import { useHistory, useParams } from 'react-router-dom';
import WhiteCard from 'global/atoms/WhiteCard';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import { getProjectsLocationsState } from 'utils/states';
import Button from 'global/atoms/Button';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import Spinner from 'global/atoms/Spinner/Spinner';
import SwitchButtonHorizontalGroup from '../../../../global/atoms/switchButton/SwitchButtonHorizontalGroup';
import SwitchButton from '../../../../global/atoms/switchButton/SwitchButton';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import List from '../../../../global/table/List';
import { getAddressFullName } from '../../../../utils/helpers';
import IconButton from '../../../../global/atoms/IconButton';
import Checkbox from '../../../../global/atoms/Checkbox';
import Input from '../../../../global/atoms/Input';
import Select from '../../../../global/atoms/Select';
import ButtonsOverListContainer from '../../../../global/atoms/ButtonsOverListContainer';
import {
  handleClearFilters,
  handleSubmitAndSaveFilters,
  handleToggleFilters,
  loadFilters,
} from '../../../../utils/filterHelper';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import styles from '../projectsList/styles.module.scss';
import { ActionButton, BatchActionButton } from '../../../../utils/toggleToDeleteHelper';
import LocationsOperations from './LocationsOperations';
import SeparatorLine from '../../../../global/atoms/separators/SeparatorLine';

interface IProjectLocationsComponent {}

const VIEW_ASSIGNED = 'VIEW_ASSIGNED';
const VIEW_UNASSIGNED = 'VIEW_UNASSIGNED';

const Locations: FunctionComponent<IProjectLocationsComponent> = () => {
  const [data, setData] = useState<IGetAssignedLocations | null>(null);
  const [filters, setFilters] = useState<IGetProjectLocations>(loadFilters(getProjectsLocationsState));
  const [form, setForm] = useState<any | 'loading'>('loading');
  const [chosenLocations, setChosenLocations] = useState<Array<string>>([]);
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [view, setView] = useState<'VIEW_ASSIGNED' | 'VIEW_UNASSIGNED'>(VIEW_ASSIGNED);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [reloadData, setReloadData] = useState<boolean>(false);
  const [showOperations, setShowOperations] = useState<boolean>(false);

  useEffect(() => {
    ProjectsConnection.getFormFilterLocations(id).then((data) => setForm(data.data));
  }, []);

  const loadLocations = () => {
    if (view === VIEW_ASSIGNED) {
      ProjectsConnection.getAssignedLocations(id, filters).then((data) => {
        setData(data.data);
      });
    } else if (view === VIEW_UNASSIGNED) {
      ProjectsConnection.getUnassignedLocations(id, filters).then((data) => {
        setData(data.data);
      });
    }
  };

  useEffect(() => loadLocations(), [filters.requestPaginate, view]);

  useEffect(() => {
    if (reloadData) {
      loadLocations();
      setReloadData(false);
    }
  }, [reloadData]);

  const handleChangeView = (event: any) => {
      setView(event.currentTarget.dataset.id);
    },
    handleToggleLocation = (locationId: string) => {
      if (chosenLocations.includes(locationId)) {
        setChosenLocations(chosenLocations.filter((item: string) => item !== locationId));
      } else {
        setChosenLocations([...chosenLocations, locationId]);
      }
    },
    handleToggleAllLocations = () => {
      if (data !== null) {
        if (chosenLocations.length) {
          setChosenLocations([]);
        } else {
          setChosenLocations(data.data.map((item) => item.id));
        }
      }
    },
    handleAssignLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.assignLocations(id, chosenLocations).then(() => {
        setChosenLocations([]);
        loadLocations();
      });
    },
    handleUnassignLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.unassignLocations(id, chosenLocations).then(() => {
        setChosenLocations([]);
        loadLocations();
      });
    },
    handleDisableLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.disableLocations(id, chosenLocations).then(() => {
        setChosenLocations([]);
        loadLocations();
      });
    },
    handleEnableLocations = () => {
      if (!chosenLocations.length) return;
      ProjectsConnection.enableLocations(id, chosenLocations).then(() => {
        setChosenLocations([]);
        loadLocations();
      });
    },
    handleEnableAllLocations = () => {
      ProjectsConnection.enableAllLocations(id).then(() => {
        loadLocations();
      });
    },
    handleDetachAllLocations = () => {
      ProjectsConnection.detachAllLocations(id).then(() => {
        loadLocations();
      });
    };

  if (data === null || form === 'loading') return <Spinner />;

  const getReportingUsersHtml = (users: Array<any>) => {
    if (users === undefined) {
      return '';
    }

    return users.map((item: any) => (
      <>
        {item.name}
        <br />
      </>
    ));
  };

  const handleChangeToEdit = (locationId: string) => {
    history.push(`3/${locationId}`);
  };

  const getUsersByRole = (): Array<any> => {
    return form.users.options.filter((user: any) => user.roleId === filters.roleId);
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters.bind(null, setToggleFilters)} />
        {view === VIEW_ASSIGNED && (
          <>
            <BatchActionButton
              chosenRows={chosenLocations}
              callback={handleEnableLocations}
              title={'Oznacz, raportowana'}
              message={'Czy zmienić status lokalizacji '}
            />
            <BatchActionButton
              chosenRows={chosenLocations}
              callback={handleDisableLocations}
              title={'Oznacz, nieraportowana'}
              message={'Czy zmienić status lokalizacji '}
            />
            <ActionButton
              chosenRows={chosenLocations}
              callback={handleEnableAllLocations}
              title={'Przywróć wszystkie'}
              message={'Czy zmienić status wszystkich lokalizacji projektu na raportowane?'}
            />
            <BatchActionButton
              chosenRows={chosenLocations}
              callback={handleUnassignLocations}
              title={'Odłącz zaznaczone'}
              message={'Czy odłączyć lokalizacje '}
            />
            <ActionButton
              chosenRows={chosenLocations}
              callback={handleDetachAllLocations}
              title={'Odłącz wszystkie'}
              message={'Czy odłączyć wszystkie lokalizacje projektu?'}
            />
          </>
        )}
        {view === VIEW_UNASSIGNED && (
          <>
            <Button disabled={!chosenLocations.length} onClick={handleAssignLocations}>
              Przypisz
            </Button>
            {/*<Button style={{ width: 'auto' }} onClick={() => history.push('/datasets/locations/add')}>*/}
            {/*  Dodaj nowy*/}
            {/*</Button>*/}
          </>
        )}
      </HeaderButtons>
      <ButtonsOverListContainer>
        <SwitchButtonHorizontalGroup>
          <SwitchButton isChecked={view === VIEW_ASSIGNED} id={VIEW_ASSIGNED} onClick={handleChangeView}>
            Przypisane
          </SwitchButton>
          <SwitchButton isChecked={view == VIEW_UNASSIGNED} id={VIEW_UNASSIGNED} onClick={handleChangeView}>
            Nieprzypisane
          </SwitchButton>
        </SwitchButtonHorizontalGroup>
        {view === VIEW_ASSIGNED && <Button onClick={() => setShowOperations(!showOperations)}>Operacje</Button>}
      </ButtonsOverListContainer>
      {showOperations && view === VIEW_ASSIGNED && (
        <LocationsOperations selectedLocations={chosenLocations} setReloadData={setReloadData} />
      )}
      <WhiteCard mobileTransparent={true} padding={false} marginTop={view === VIEW_ASSIGNED && showOperations}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <Checkbox
                isChecked={chosenLocations.length > 0 && chosenLocations.length === data.data.length}
                onClick={handleToggleAllLocations}
              />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <Checkbox
                  isChecked={chosenLocations.includes(rowData.id)}
                  onClick={handleToggleLocation.bind(null, rowData.id)}
                />
              )}
            </Cell>
          </Column>

          {view === VIEW_ASSIGNED && (
            <Column width={60} align="left">
              <HeaderCell depth={0} width={0}>
                R/N
              </HeaderCell>
              <Cell width={100} depth={0}>
                {(rowData) => (
                  <span
                    className={styles.status}
                    style={{
                      color: rowData.isActive ? '#26D554' : '#9AA4AA',
                      borderColor: rowData.isActive ? '#26D554' : '#9AA4AA',
                    }}
                  >
                    {rowData.isActive ? 'R' : 'N'}
                  </span>
                )}
              </Cell>
            </Column>
          )}

          <Column flexGrow={1} minWidth={300} align="left">
            <HeaderCell depth={0} width={0}>
              Nazwa
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <ButtonListRedirect redirectUrl={`3/${rowData.id}`}>{rowData.name}</ButtonListRedirect>}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Sieć
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <span>{rowData.network.name}</span>}
            </Cell>
          </Column>
          <Column width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Miasto
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <span>{rowData.address.city.name}</span>}
            </Cell>
          </Column>
          <Column width={300} align="left">
            <HeaderCell depth={0} width={0}>
              Adres
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <span>{getAddressFullName(rowData.address)}</span>}
            </Cell>
          </Column>
          {view === VIEW_ASSIGNED && (
            <Column width={200} align="left">
              <HeaderCell depth={0} width={0}>
                Raportujący
              </HeaderCell>
              <Cell width={100} depth={0}>
                {(rowData) => <span>{getReportingUsersHtml(rowData.users)}</span>}
              </Cell>
            </Column>
          )}
          {view === VIEW_ASSIGNED && (
            <Column width={60} align="left">
              <HeaderCell depth={0} width={0}>
                &nbsp;
              </HeaderCell>
              <Cell width={100} depth={0}>
                {(rowData) => <IconButton icon={'settings'} onClick={handleChangeToEdit.bind(null, rowData.id)} />}
              </Cell>
            </Column>
          )}
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        handleToggleFilters={handleToggleFilters.bind(null, setToggleFilters)}
        toggleFilters={toggleFilters}
        handleSubmit={handleSubmitAndSaveFilters.bind(null, setReloadData, filters)}
        handleClear={handleClearFilters.bind(null, setFilters, getProjectsLocationsState, setReloadData)}
      >
        <Input value={filters.name} placeholder={'Nazwa'} name={'name'} setState={setFilters} state={filters} />
        <Input value={filters.nip} placeholder={'Nip'} name={'nip'} setState={setFilters} state={filters} />
        <Input value={filters.street} placeholder={'Ulica'} name={'street'} setState={setFilters} state={filters} />
        <Input value={filters.city} placeholder={'Miasto'} name={'city'} setState={setFilters} state={filters} />
        <Input
          value={filters.community}
          placeholder={'Gmina'}
          name={'community'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.district}
          placeholder={'Powiat'}
          name={'district'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Województwo'}
          name={'voivodeshipId'}
          value={filters.voivodeshipId}
          options={form.voivodeships.options}
          disabled={false}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Sieć'}
          name={'networkId'}
          value={filters.networkId}
          options={form.networks.options}
          state={filters}
          setState={setFilters}
        />
        <SeparatorLine size={2} />
        <Select
          placeholder={'Grupa'}
          name={'roleId'}
          value={filters.roleId}
          options={form.projectMerchRoles.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Użytkownik'}
          name={'reportingUserId'}
          value={filters.reportingUserId}
          options={getUsersByRole()}
          state={filters}
          setState={setFilters}
        />
      </Filters>
    </>
  );
};

export default Locations;
