import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import Select from '../../../../global/atoms/Select';
import { Col, Row } from 'react-grid-system';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import Button from '../../../../global/atoms/Button';
import { handleToast } from '../../../../utils/helpers';
import VisitsReportConnection from '../../../../utils/connections/visitsReport';
import { useHistory, useParams } from 'react-router-dom';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import LatestReports from './LatestReports';
import SelectVisitsList, { getDefaultFiltersState, IFilters, MAX_VISITS_RESPONSE } from './SelectVisitsList';
import { toast } from 'react-hot-toast';

export interface IVisitsReportSave {
  templateId: string;
  startDate: string;
  endDate: string;
  projectId: string;
  format: 'xlsx' | 'csv';
  filters: IFilters;
  selectedVisits: string[];
}

const VisitsReport: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<IVisitsReportSave>({
    templateId: '',
    startDate: '',
    endDate: '',
    projectId: id,
    format: 'xlsx',
    filters: getDefaultFiltersState(id),
    selectedVisits: [],
  });
  const [form, setForm] = useState<any>(null);
  const history = useHistory();
  const [chosenVisits, setChosenVisits] = useState<Array<string>>([]);
  const [reloadReports, setReloadReports] = useState<boolean>(false);
  const [visitsCount, setVisitsCount] = useState<number>(0);
  const [filters, setFilters] = useState<IFilters>(getDefaultFiltersState(id));

  useEffect(() => {
    handleToast(VisitsReportConnection.getGenerateReportViewData(id)).then((response) => {
      if (response.data.templates.options.length > 0) {
        setState({
          ...state,
          ...{
            templateId: response.data.templates.options[0].id,
          },
        });
      }
      setForm(response.data);
    });
  }, []);

  const handleEditTemplate = () => {
    history.push(`/projects/${id}/reports-visits/template/edit/${state.templateId}`);
  };

  const handleAddTemplate = () => {
    history.push(`/projects/${id}/reports-visits/template/add`);
  };

  const handleGenerateReport = () => {
    if (state.templateId === '') {
      return false;
    }

    if (visitsCount <= MAX_VISITS_RESPONSE && chosenVisits.length <= 0) {
      toast.error('Nie wybrano wizyt');
      return false;
    }

    const stateClone = { ...state };
    stateClone.filters = filters;
    stateClone.selectedVisits = chosenVisits;
    handleToast(VisitsReportConnection.saveReport(stateClone)).then(() => {
      setReloadReports(true);
    });
  };

  if (form === null) return <></>;

  return (
    <>
      <SelectVisitsList
        form={form}
        chosenVisits={chosenVisits}
        setChosenVisits={setChosenVisits}
        setVisitsCount={setVisitsCount}
        visitsCount={visitsCount}
        filters={filters}
        setFilters={setFilters}
      />
      <SeparatorEmpty />
      <WhiteCard padding={true}>
        <Row style={{ zIndex: 101, position: 'relative' }}>
          <Col xs={3}>
            <Select
              placeholder={'Wybierz szablon'}
              name={'templateId'}
              value={state.templateId}
              options={form.templates.options}
              setState={setState}
              state={state}
            />
          </Col>
          <Col xs={3}>
            <ActionsContainer>
              <Button onClick={handleEditTemplate}>Edytuj</Button>
              <Button onClick={handleAddTemplate}>Dodaj nowy</Button>
            </ActionsContainer>
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <Row>
          <Col xs={3}>
            <ActionsContainer>
              <Button onClick={handleGenerateReport}>Wygeneruj raport</Button>
              <Select
                style={{ zIndex: 100 }}
                placeholder={'Format'}
                name={'format'}
                value={state.format}
                options={[
                  { id: 'xlsx', name: 'xlsx' },
                  // { id: 'csv', name: 'csv' },
                ]}
                setState={setState}
                state={state}
              />
            </ActionsContainer>
          </Col>
        </Row>
      </WhiteCard>
      <SeparatorEmpty />
      <LatestReports reloadReports={reloadReports} setReloadReports={setReloadReports} />
    </>
  );
};

export default VisitsReport;
