import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

interface IOrderLink {
  projectId: string;
  id: string;
  name: string;
}

const OrderLink: FunctionComponent<IOrderLink> = ({ name, projectId, id }) => {
  const history = useHistory();
  return (
    <span
      onClick={() => {
        history.push(`/projects/${projectId}/orders/${id}/details`);
      }}
    >
      {name}
    </span>
  );
};

export default OrderLink;
