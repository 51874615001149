import axios, { AxiosRequestConfig, Canceler } from 'axios';
import { getAuthHeader, handleErrors } from './helpers';
let cancelPreviousReq: Array<{ cancelTag: string; cancel: Canceler }> = [];

const getCustomConfig = () => {
  return {
    headers: getAuthHeader(),
  };
};

const cancelTokenApply = (cancelTag: string | undefined, configCustom: AxiosRequestConfig): AxiosRequestConfig => {
  if (cancelTag) {
    if (cancelPreviousReq.length && cancelPreviousReq.find((p) => p.cancelTag === cancelTag) !== undefined) {
      cancelPreviousReq.find((p) => p.cancelTag === cancelTag)?.cancel();
      cancelPreviousReq = cancelPreviousReq.filter((p) => p.cancelTag !== cancelTag);
    }
    configCustom.cancelToken = new axios.CancelToken(function executor(c) {
      cancelPreviousReq.push({ cancelTag: cancelTag, cancel: c });
    });
  }
  return configCustom;
};

const AxiosHelper = {
  get: (url: string, config?: AxiosRequestConfig, cancelTag?: string) => {
    let configCustom: AxiosRequestConfig = config === undefined ? getCustomConfig() : config;
    configCustom = cancelTokenApply(cancelTag, configCustom);
    return axios.get(url, configCustom).catch(handleErrors);
  },
  delete: (url: string, config?: AxiosRequestConfig) => {
    const configCustom: AxiosRequestConfig = config === undefined ? getCustomConfig() : config;
    return axios.delete(url, configCustom).catch(handleErrors);
  },
  post: (url: string, data?: any, config?: AxiosRequestConfig, cancelTag?: string) => {
    let configCustom: AxiosRequestConfig = config === undefined ? getCustomConfig() : config;
    configCustom = cancelTokenApply(cancelTag, configCustom);

    return axios.post(url, data, configCustom).catch(handleErrors);
  },
  put: (url: string, data?: any, config?: AxiosRequestConfig) => {
    const configCustom: AxiosRequestConfig = config === undefined ? getCustomConfig() : config;
    return axios.put(url, data, configCustom).catch(handleErrors);
  },
  patch: (url: string, data?: any, config?: AxiosRequestConfig) => {
    const configCustom: AxiosRequestConfig = config === undefined ? getCustomConfig() : config;
    return axios.patch(url, data, configCustom).catch(handleErrors);
  },
};

export default AxiosHelper;
