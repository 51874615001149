import React, { FunctionComponent, useState } from 'react';
import Button from '../../../../global/atoms/Button';
import ReactDOM from 'react-dom';
import stylesLayout from '../../../../global/layout/layout.module.scss';
import AddressAdd from './AddressAdd';
import styles from './styles.module.scss';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import commonStyles from '../../../../global/common.module.scss';
import { handleToast } from '../../../../utils/helpers';

interface IAddressEditPopup {
  addressId: string;
  saveExitCallback?: () => Promise<any>;
}

type componentState = 'default' | 'loading' | 'editAddress';

const AddressEditPopupButton: FunctionComponent<IAddressEditPopup> = ({ addressId, saveExitCallback }) => {
  const [state, setState] = useState<componentState>('default');

  const handleOpenEditAddress = () => {
    setState('editAddress');
  };

  const handleClose = () => {
    setState('default');
  };

  const closeEditAndReloadForm = () => {
    if (saveExitCallback === undefined) {
      setState('default');
    } else {
      handleToast(saveExitCallback()).then(() => {
        setState('default');
      });
    }
  };

  return (
    <>
      {state === 'editAddress' && (
        <>
          {ReactDOM.createPortal(
            <>
              <div className={stylesLayout.blackOverlay}>
                <div className={commonStyles.popupActionsContainer}>
                  <ActionsContainer>
                    <Button onClick={handleClose}>Zamknij</Button>
                  </ActionsContainer>
                </div>
                <div className={styles.addressPopupContainer}>
                  <AddressAdd addressId={addressId} saveExitCallback={closeEditAndReloadForm} />
                </div>
              </div>
            </>,
            document.getElementById('layout-portal')!,
          )}
        </>
      )}
      <Button onClick={handleOpenEditAddress}>Edytuj</Button>
    </>
  );
};

export default AddressEditPopupButton;
