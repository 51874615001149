import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { IDocumentsFilters } from '../../views/projects/components/storeDocuments/StoreDocumentsList';

const StoreDocumentsConnection = {
  list: (projectId: string, data: IDocumentsFilters) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/store_documents/list`,
      data,
    );
  },
  filterForm: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/store_documents/filter/form`,
    );
  },
  editForm: (documentId: string, projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/store-documents/${documentId}/form`,
    );
  },
  batchDelete: (projectId: string, data: Array<{ id: string }>) => {
    return axios.delete(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/store-documents`,
      {
        headers: { ...getAuthHeader() },
        data: { documents: data },
      },
    );
  },
};

export default StoreDocumentsConnection;
