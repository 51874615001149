import { IFilterPaginate } from './models';
import FiltersStorage from './FiltersStorage';
import { deepClone } from './helpers';
import React from 'react';

export const getPaginateState = (page = 1, limit = 10): IFilterPaginate => {
  return {
    requestPaginate: {
      page: page,
      limit: limit,
    },
  };
};

export const handleToggleFilters = (setToggleFilters: any) => {
  setToggleFilters((state: any) => !state);
};

export const handleSubmitFilters = (setReloadData: any, event: any) => {
  event.preventDefault();
  setReloadData(true);
};

export const handleSubmitAndSaveFilters = (setReloadData: any, filters: any, event: any) => {
  event.preventDefault();

  const filtersStorage = new FiltersStorage();
  filtersStorage.save(filters);

  setReloadData(true);
};

export const handleSubmitAndSaveFiltersCustomKey = (
  setReloadData: any,
  filters: any,
  customKey: string,
  event: any,
) => {
  event.preventDefault();

  const filtersStorage = new FiltersStorage();
  filtersStorage.save(filters, customKey);

  setReloadData(true);
};

export const handleClearFilters = (
  setFilters: React.Dispatch<React.SetStateAction<any>>,
  defaultFilters: any,
  setReloadData: any,
  customKey?: string,
) => {
  clearFiltersAndReload(setFilters, defaultFilters, setReloadData, customKey);
};

export const handleClearFiltersCustomCacheKey = (
  setFilters: any,
  defaultFilters: any,
  setReloadData: any,
  customKey: string,
) => {
  clearFiltersAndReload(setFilters, defaultFilters, setReloadData, customKey);
};

const clearFiltersAndReload = (
  setFilters: React.Dispatch<React.SetStateAction<any>>,
  defaultFilters: any,
  setReloadData: any,
  customKey?: string,
) => {
  const filtersStorage = new FiltersStorage();
  filtersStorage.remove(customKey);
  const filtersClone = deepClone(defaultFilters);
  setFilters(filtersClone);
  setReloadData(true);
};

export const loadFilters = (defaultFilters: any, customKey?: string) => {
  const filtersStorage = new FiltersStorage();
  let filters = defaultFilters;
  const filtersFromStorage = filtersStorage.get(customKey);
  if (filtersFromStorage !== null) {
    filters = filtersFromStorage;
  }
  return filters;
};
