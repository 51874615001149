import React, { FunctionComponent, useRef } from 'react';
import axios from 'axios';
import {getAuthHeader} from '../../utils/helpers';
import Button from './Button';
import {toast} from "react-hot-toast";

interface ISingleFileUploadProps {
  saveUrl: string;
  savedFileCallback: any;
  label: string;
  disabled?: boolean;
  maxFileSizeMB?: number;
  handleError?: (e: any) => void;
}

const FileUpload: FunctionComponent<ISingleFileUploadProps> = ({
  saveUrl,
  savedFileCallback,
  label,
  disabled = false,
  maxFileSizeMB = -1, // no limit
  handleError
}) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: any) => {
    for (const key in event.target.files) {
      const data = new FormData();
      const item = event.target.files[key];
      if (!(item instanceof File)) {
        continue;
      }

      if(!handleError) {
        handleError = (errs) => { console.warn(errs);};
      }

      data.append('file', item);

      toast.promise(
        axios.post(saveUrl, data, { headers: getAuthHeader() }),
  {
          loading: 'Wysyłanie pliku...',
          success: 'Sukces!',
          error: (err) => {
            try {
              return err.response.data.message;
            } catch(e) {
              // nothing
            }
            return 'Błąd, nie przesłano pliku!';
          },
        }
      ).then(res => {
        savedFileCallback(res.data);
      })

    }
  };

  const handleSelectFilesTrigger = () => {
    // @ts-ignore: Object is possibly 'null'.
    inputFile?.current.click();
  };

  return (
    <>
      <input ref={inputFile} style={{ display: 'none' }} type={'file'} multiple={true} onChange={handleFileUpload} />
      <Button disabled={disabled} onClick={handleSelectFilesTrigger}>
        {label}
      </Button>
      {maxFileSizeMB != -1 && (
        <>
          &nbsp;<small>Maksymalny rozmiar pliku: {maxFileSizeMB}MB</small>
        </>
      )}
    </>
  );
};

export default FileUpload;
