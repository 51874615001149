import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { handleToast, questionMapper } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import InstancesConnection from 'utils/connections/instances';
import Form from 'global/form/Form';
import styles from './styles.module.scss';
import Input from 'global/atoms/Input';
import Button from 'global/atoms/Button';
import Heading from 'global/heading/Heading';
import Spinner from 'global/atoms/Spinner/Spinner';
import SeparatorEmpty from '../../../../../global/atoms/separators/SeparatorEmpty';
import { DISABLED_FORM_SAVE_BUTTON } from '../../../../../utils/disabledElementsConstants';
import DocumentLink from './DocumentLink';
import OrderLink from './OrderLink';
import StoreDocumentLink from './StoreDocumentLink';

const returnConstraints = (questionType: string, min: number, max: number) => {
  switch (questionType) {
    case 'PHOTO_QUESTION':
      if (min > 0 && max < min) return <span className={styles.errors}>Minimalna ilość zdjęć: {min}</span>;
      else if (min > 0 && max > min)
        return (
          <span className={styles.errors}>
            Wymagana ilość zdjęć: {min} - {max}
          </span>
        );
      else if (min > 0 && (max == 0 || max === min))
        return <span className={styles.errors}>Wymagana ilość zdjęć: {min}</span>;
      else if (min == 0 && max > min) return <span className={styles.errors}>Maksymalna ilość zdjęć: {max}</span>;
      else return null;
    case 'TEXT_QUESTION' || 'TEXTAREA_QUESTION':
      if (max > 0) return <span className={styles.errors}>Maksymalna ilość znaków {max}</span>;
      else return null;
    default:
      return null;
  }
};

interface IVisitsEdit {}

export const BOOLEAN_QUESTION = 'BOOLEAN_QUESTION';
export const DICTIONARY_QUESTION = 'DICTIONARY_QUESTION';
export const DICTIONARY_MULTIPLE_ANSWERS_QUESTION = 'DICTIONARY_MULTIPLE_ANSWERS_QUESTION';
export const TIME_QUESTION = 'TIME_QUESTION';
export const DATE_QUESTION = 'DATE_QUESTION';
export const TEXT_QUESTION = 'TEXT_QUESTION';

const TYPE_DEFAULT_QUESTION = 'TYPE_DEFAULT_QUESTION';
const TYPE_LOCATION_QUESTION = 'TYPE_LOCATION_QUESTION';

export const getValue = (question: any) => {
  if (question.questionType === DICTIONARY_MULTIPLE_ANSWERS_QUESTION) {
    const getLabel = (id: string) => {
      let label = '';
      question.options.map((data: any) => {
        if (data.id === id) {
          label = data.name;
        }
      });
      return label;
    };
    const multipleAnswersState: Array<any> = [];
    question.values.map((data: any) => {
      multipleAnswersState.push({
        value: data.value,
        label: getLabel(data.value),
        refQuestionAnswerId: data.refQuestionAnswerId,
      });
    });

    return multipleAnswersState;
  }

  if (question.questionType === 'PHOTO_QUESTION') {
    // @ts-ignore
    return { url: question.values };
  }

  // @ts-ignore
  return question.values.length ? question.values[0].value : '';
};

const VisitsEdit: FunctionComponent<IVisitsEdit> = () => {
  const [data, setData] = useState<any | 'loading'>('loading');
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | 'loading'>('loading');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { id, visitId } = useParams<{ id: string; visitId: string }>();
  const [state, setState] = useState<any>('loading');

  useEffect(() => {
    InstancesConnection.getFormEditInstance(id, visitId).then((data) => {
      setLoading(false);
      setData({
        taskName: data.data.model.taskName,
        reportingUserName: data.data.model.reportingUserName,
        users: data.data.model.users,
        locationName: data.data.model.locationName,
        networkName: data.data.model.networkName,
        startDate: data.data.model.startDate,
        statusName: data.data.model.status.name,
        expirationDays: data.data.model.expirationDays,
        documents: data.data.model.documents,
        orders: data.data.model.orders,
        storeDocuments: data.data.model.storeDocuments,
      });
      setForm(data.data);
      const state = {};

      const setStatePositionsData = (positions: Array<any>) => {
        for (const item of positions) {
          for (const subitem of item.positions) {
            if (subitem.positions && subitem.positions.length) {
              for (const subsubitem of subitem.positions) {
                // @ts-ignore
                state[subsubitem.question.refQuestionId] = getValue(subsubitem.question);
              }
            } else {
              // @ts-ignore
              state[subitem.question.refQuestionId] = getValue(subitem.question);
            }
          }
        }
      };

      setStatePositionsData(data.data.model.positions);
      setStatePositionsData(data.data.model.locationQuestions);

      setState(state);
    });
  }, []);

  if (loading) return <Spinner />;

  const getVisitQuestionIdToVisitQuestionAnswerIdMap = () => {
    const map: any = {};
    for (const item of form.model.positions) {
      for (const subitem of item.positions) {
        if (subitem.positions && subitem.positions.length) {
          for (const subsubitem of subitem.positions) {
            map[subsubitem.question.refQuestionId] =
              subsubitem.question.values[0]?.refQuestionAnswerId !== undefined
                ? subsubitem.question.values[0]?.refQuestionAnswerId
                : '';
          }
        } else {
          map[subitem.question.refQuestionId] =
            subitem.question.values[0]?.refQuestionAnswerId !== undefined
              ? subitem.question.values[0]?.refQuestionAnswerId
              : '';
        }
      }
    }
    return map;
  };

  const getQuestionDataByRefQuestionId = (
    refQuestionId: string,
    positions: Array<any>,
    returnFormat: 'question' | 'itemAndPositions' = 'question',
  ): any => {
    let question: any = undefined;
    let item: any = undefined;
    let itemPositions: any = undefined;

    positions.map((data1: any) => {
      if (data1.question !== '' && data1.question.refQuestionId === refQuestionId) {
        question = data1.question;
        item = data1;
        itemPositions = positions;
      }
      data1.positions.map((data2: any) => {
        if (data2.question !== '' && data2.question.refQuestionId === refQuestionId) {
          question = data2.question;
          item = data2;
          itemPositions = data1.positions;
        }
        data2.positions.map((data3: any) => {
          if (data3.question !== '' && data3.question.refQuestionId === refQuestionId) {
            question = data3.question;
            item = data3;
            itemPositions = data2.positions;
          }
        });
      });
    });

    if (returnFormat === 'itemAndPositions') {
      return {
        item: item,
        positions: itemPositions,
      };
    }

    return question;
  };

  const checkQuestionType = (refQuestionId: string): string => {
    const question = getQuestionDataByRefQuestionId(refQuestionId, form.model.positions);
    const locationQuestion = getQuestionDataByRefQuestionId(refQuestionId, form.model.locationQuestions);

    if (question !== undefined) {
      return TYPE_DEFAULT_QUESTION;
    }

    if (locationQuestion !== undefined) {
      return TYPE_LOCATION_QUESTION;
    }

    return '';
  };

  const checkType = (refQuestionId: string): any => {
    const question = getQuestionDataByRefQuestionId(refQuestionId, form.model.positions);
    if (question !== undefined) {
      return question.questionType;
    }

    const locationQuestion = getQuestionDataByRefQuestionId(refQuestionId, form.model.locationQuestions);
    return locationQuestion !== undefined ? locationQuestion.questionType : undefined;
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const visitQuestionAnswerIdMap = getVisitQuestionIdToVisitQuestionAnswerIdMap();
    const data = [];
    const dataLocations = [];

    const getDictionaryMultipleSaveData = (refQuestionId: string, options: any) => {
      if (options === undefined || options === '') {
        return [];
      }
      const values: Array<any> = [];
      options.map((data: any) => {
        values.push({
          refQuestionAnswerId: data.refQuestionAnswerId,
          value: data.value,
        });
      });

      return {
        isRequired: getRequiredValue(refQuestionId),
        refQuestionId: refQuestionId,
        values: values,
      };
    };

    const getRequiredValue = (refQuestionId: string) => {
      const type = checkQuestionType(refQuestionId);
      let question: any;
      let data: any;
      if (type === TYPE_LOCATION_QUESTION) {
        question = getQuestionDataByRefQuestionId(refQuestionId, form.model.locationQuestions);
        data = getQuestionDataByRefQuestionId(refQuestionId, form.model.locationQuestions, 'itemAndPositions');
      } else {
        question = getQuestionDataByRefQuestionId(refQuestionId, form.model.positions);
        data = getQuestionDataByRefQuestionId(refQuestionId, form.model.positions, 'itemAndPositions');
      }

      if (checkIsDisabled(data.item, data.positions)) {
        return false;
      }

      return question.isRequired;
    };

    for (const key in state) {
      let dataAdd;

      const type = checkType(key);
      if (type === 'DICTIONARY_MULTIPLE_ANSWERS_QUESTION') {
        dataAdd = getDictionaryMultipleSaveData(key, state[key]);
      } else if (type === 'PHOTO_QUESTION') {
        dataAdd = {
          isRequired: getRequiredValue(key),
          refQuestionId: key,
          values: state[key]?.url !== undefined && state[key]?.url.length ? state[key]['url'] : [],
        };
      } else {
        let value = state[key];
        if (type === DATE_QUESTION) {
          value = `${value}`;
        }

        dataAdd = {
          isRequired: getRequiredValue(key),
          refQuestionId: key,
          values: [
            {
              value: value,
              refQuestionAnswerId: visitQuestionAnswerIdMap[key] !== undefined ? visitQuestionAnswerIdMap[key] : '',
            },
          ],
        };
      }

      if (checkQuestionType(key) === 'TYPE_LOCATION_QUESTION') {
        dataLocations.push(dataAdd);
      } else {
        data.push(dataAdd);
      }
    }

    const dataSave = {
      questions: data,
      locationQuestions: dataLocations,
    };

    handleToast(
      InstancesConnection.updateInstance(id, visitId, dataSave),
      setErrors,
      'Uzupełnij wymagane pola',
    ).then(() => history.push(`/projects/${id}/visits`));
  };

  if (form === 'loading' || state === 'loading' || data === 'loading') return null;

  const checkIsDisabled = (item: any, positions: any): boolean => {
    if (item.question === '') {
      return false;
    }

    if (!item.question.isEdit) {
      return true;
    }

    if (item.question.dependOnQuestion.id === '') {
      return false;
    }

    const dependentOn = positions.filter((data: any) => data.question.id === item.question.dependOnQuestion.id)[0];
    const refQuestionId = dependentOn.question.refQuestionId;

    const checkDependOnDictionaryIsDisabled = (): boolean => {
      return (
        item.question.dependOnQuestion.options.filter((dataLoop: any) => {
          return dataLoop.id === state[refQuestionId];
        }).length <= 0
      );
    };

    const checkDependedOnMultipleAnswersDictionaryIsDisabled = (): boolean => {
      if (!Array.isArray(state[refQuestionId])) {
        return true;
      }

      const selectedAnswers: Array<string> = [];
      state[refQuestionId].map((data: any) => {
        selectedAnswers.push(data.value);
      });
      return (
        item.question.dependOnQuestion.options.filter((dataLoop: any) => {
          return selectedAnswers.includes(dataLoop.id);
        }).length <= 0
      );
    };

    let isDisabled = true;
    switch (dependentOn.question.questionType) {
      case TEXT_QUESTION:
        isDisabled = state[refQuestionId].length < 1;
        break;
      case BOOLEAN_QUESTION:
        isDisabled = parseInt(state[refQuestionId]) !== 1;
        break;
      case DICTIONARY_QUESTION:
        isDisabled = checkDependOnDictionaryIsDisabled();
        break;
      case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
        isDisabled = checkDependedOnMultipleAnswersDictionaryIsDisabled();
        break;
    }

    return isDisabled;
  };

  const getForm = (data: Array<any>) => {
    return (
      <>
        {data.map((item: any, idx: number) => (
          <WhiteCard
            style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', paddingTop: '24px' }}
            key={idx}
          >
            <Heading title={item.name} subTitle={item.subName} />
            <SeparatorEmpty />
            {item.positions.map((subitem: any, index: any) => {
              const isDisabledSubItem = checkIsDisabled(subitem, item.positions);
              if (subitem.positions && subitem.positions.length)
                return (
                  <div className={styles.aaa}>
                    <Heading title={subitem.name} classNames={styles.radiusContainer2} />
                    <SeparatorEmpty size={0.5} />
                    {subitem.positions.map((subsubitem: any, index2: any) => {
                      const isDisabledSubSubItem = checkIsDisabled(subsubitem, subitem.positions);
                      return (
                        <div
                          className={`${styles.container} ${index2 === 0 ? styles.radiusTopContainer : ''}`}
                          key={subsubitem.question.id}
                        >
                          <div className={styles.nameErrorContainer}>
                            <span>
                              {subsubitem.name}
                              {!!subsubitem.question &&
                                !!subsubitem.question.pointsValue &&
                                ` (${subsubitem.question.pointsValue}) `}
                              {subsubitem.question.isRequired && <span style={{ color: 'red' }}>*</span>}
                            </span>
                            {returnConstraints(
                              subsubitem.question.questionType,
                              parseInt(subsubitem.question.minValue),
                              parseInt(subsubitem.question.maxValue),
                            )}
                            <span className={styles.errors}>{errors && errors[subsubitem.question.refQuestionId]}</span>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}>
                            {questionMapper(state, setState, subsubitem.question, isDisabledSubSubItem)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              else
                return (
                  <div className={`${styles.container2}`}>
                    <div
                      className={`${styles.container3} ${index === 0 ? styles.radiusTopContainer : ''}`}
                      key={subitem.question.id}
                    >
                      <div className={styles.nameErrorContainer}>
                        <span>
                          {subitem.name}
                          {subitem.question.isRequired && <span style={{ color: 'red' }}>*</span>}
                        </span>
                        {returnConstraints(
                          subitem.question.questionType,
                          parseInt(subitem.question.minValue),
                          parseInt(subitem.question.maxValue),
                        )}
                        <span className={styles.errors}>{errors && errors[subitem.question.refQuestionId]}</span>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'flex-start', width: '50%' }}>
                        {questionMapper(state, setState, subitem.question, isDisabledSubItem)}
                      </div>
                    </div>
                  </div>
                );
            })}
          </WhiteCard>
        ))}
      </>
    );
  };

  const getDocuments = (data: Array<any>) => {
    if (data && data.length)
      return (
        <>
          <WhiteCard
            style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', paddingTop: '24px' }}
            key={0}
          >
            <Heading title={'Faktury'} subTitle={''} />
            <SeparatorEmpty />
            {data.map((item: any, idx: number) => {
              return (
                <>
                  <div className={`${styles.container} ${idx === 0 ? styles.radiusTopContainer : ''}`} key={item.id}>
                    <DocumentLink projectId={item.projectId} id={item.id} name={item.documentNumber} />
                  </div>
                </>
              );
            })}
          </WhiteCard>
        </>
      );
  };

  const getOrders = (data: Array<any>) => {
    if (data && data.length)
      return (
        <>
          <WhiteCard
            style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', paddingTop: '24px' }}
            key={0}
          >
            <Heading title={'Zamówienia'} subTitle={''} />
            <SeparatorEmpty />
            {data.map((item: any, idx: number) => {
              return (
                <>
                  <div className={`${styles.container} ${idx === 0 ? styles.radiusTopContainer : ''}`} key={item.id}>
                    <OrderLink projectId={item.projectId} id={item.id} name={item.documentNumber} />
                  </div>
                </>
              );
            })}
          </WhiteCard>
        </>
      );
  };

  const getStoreDocuments = (data: Array<any>) => {
    if (data && data.length)
      return (
        <>
          <WhiteCard
            style={{ marginTop: '32px', display: 'flex', flexDirection: 'column', paddingTop: '24px' }}
            key={0}
          >
            <Heading title={'Dokumenty magazynowe'} subTitle={''} />
            <SeparatorEmpty />
            {data.map((item: any, idx: number) => {
              return (
                <>
                  <div className={`${styles.container} ${idx === 0 ? styles.radiusTopContainer : ''}`} key={item.id}>
                    <StoreDocumentLink projectId={item.projectId} id={item.id} name={item.documentNumber} />
                  </div>
                </>
              );
            })}
          </WhiteCard>
        </>
      );
  };

  if (loading) return <Spinner />;

  return (
    <>
      <WhiteCard padding={true} style={{ paddingTop: '24px' }}>
        <Heading title={'Dane ogólne'} margin={'0 0 20px'} />
        <Form handleSubmit={() => null}>
          <div style={{ display: 'grid', rowGap: '24px', gridColumnGap: '24px', gridTemplateColumns: '1fr 1fr' }}>
            <Input
              type={'text'}
              style={{ gridColumn: 'span 2' }}
              placeholder={'Lokalizacja'}
              name={'locationName'}
              value={data.locationName}
              state={data}
              setState={setData}
              disabled={true}
            />
            <Input
              type={'text'}
              placeholder={'Data rozpoczęcia'}
              name={'startDate'}
              value={data.startDate}
              state={data}
              setState={setData}
              disabled={true}
            />
            <Input
              type={'text'}
              placeholder={'Status'}
              name={'statusName'}
              value={data.statusName}
              state={data}
              setState={setData}
              disabled={true}
            />
            <Input
              type={'text'}
              placeholder={'Okres ważności [dni]'}
              name={'expirationDays'}
              value={data.expirationDays}
              state={data}
              setState={setData}
              disabled={true}
            />
            <Input
              type={'text'}
              placeholder={'Sieć'}
              name={'networkName'}
              value={data.networkName}
              state={data}
              setState={setData}
              disabled={true}
            />
          </div>
        </Form>
      </WhiteCard>

      <WhiteCard padding={true} style={{ marginTop: '32px', paddingTop: '24px' }}>
        <Heading title={'Przypisani użytkownicy'} margin={'0 0 20px'} />
        <Form handleSubmit={() => null}>
          <div style={{ display: 'grid', rowGap: '24px', gridColumnGap: '24px', gridTemplateColumns: '1fr 1fr' }}>
            {data.users.map((user: any) => (
              <Input
                key={user.visitUserId}
                type={'text'}
                placeholder={
                  user.reporting ? user.userRole.role.roleName + ' [raportujący]' : user.userRole.role.roleName
                }
                name={user.userRole.role.roleName}
                value={user.userRole.user.username}
                state={data}
                setState={setData}
                disabled={true}
              />
            ))}
          </div>
        </Form>
      </WhiteCard>

      {form.model.locationQuestions[0].positions.length > 0 && getForm(form.model.locationQuestions)}

      {getForm(form.model.positions)}

      {getDocuments(form.model.documents)}
      {getOrders(form.model.orders)}
      {getStoreDocuments(form.model.storeDocuments)}

      <Button
        style={{ position: 'fixed', bottom: '20px', right: '40px', width: '150px' }}
        onClick={handleSubmit}
        disabled={form?.disabledElements?.includes(DISABLED_FORM_SAVE_BUTTON)}
      >
        Zapisz
      </Button>
    </>
  );
};

export default VisitsEdit;
