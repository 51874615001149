import { default as axios } from '../AxiosHelper';
import { getOrganizationId } from '../helpers';
import { ISubmitOperation } from '../../views/projects/components/editProject/LocationsOperations';

const ProjectLocationUsersConnection = {
  getLocationReplaceFormData: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/users/replace/form`,
    );
  },
  operation: (projectId: string, data: ISubmitOperation) => {
    return axios.patch(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/users/replace`,
      data,
    );
  },
};
export default ProjectLocationUsersConnection;
