import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Button from '../../../../global/atoms/Button';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import DayPickerInputCustom from '../../../../global/atoms/DayPickerInputCustom';
import Section from '../../../../global/atoms/section/Section';
import Input from '../../../../global/atoms/Input';
import Select from '../../../../global/atoms/Select';
import VisitsReportConnection from '../../../../utils/connections/visitsReport';
import { handleToast } from '../../../../utils/helpers';
import SeparatorLine from '../../../../global/atoms/separators/SeparatorLine';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import List from '../../../../global/table/List';
import BadgeStatus from '../../../../global/atoms/badge/BadgeStatus';
import Pagination from '../../../../global/atoms/Pagination';
import AlertInfo from '../../../../global/atoms/alert/AlertInfo';
import { ToggleAllCheckbox, ToggleSingleCheckbox } from '../../../../utils/toggleToDeleteHelper';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import ButtonOutlined from '../../../../global/atoms/ButtonOutlined';

export interface IFilters {
  startDate: number;
  endDate: number;
  locationName: string;
  nip: string;
  street: string;
  city: string;
  community: string;
  district: string;
  voivodeshipId: string;
  networkId: string;
  groupId: string;
  userRoleId: string;
  projectId: string;
  statusId: string;
  locationId: string;
}

interface ISelectVisitsList {
  form: any;
  chosenVisits: string[];
  setChosenVisits: any;
  visitsCount: number;
  setVisitsCount: any;
  filters: IFilters;
  setFilters: any;
}

interface IVisit {
  id: string;
  locationName: string;
  date: string;
  status: string;
  reportingUser: string;
  statusColor: string;
}

export const getDefaultFiltersState = (projectId: string): IFilters => {
  return {
    startDate: 0,
    endDate: 0,
    locationName: '',
    nip: '',
    street: '',
    city: '',
    community: '',
    district: '',
    voivodeshipId: '',
    networkId: '',
    groupId: '',
    userRoleId: '',
    projectId: projectId,
    statusId: '',
    locationId: '',
  };
};

const LIMIT = 10;
export const MAX_VISITS_RESPONSE = 10000;

const SelectVisitsList: FunctionComponent<ISelectVisitsList> = ({
  form,
  chosenVisits,
  setChosenVisits,
  visitsCount,
  setVisitsCount,
  filters,
  setFilters,
}) => {
  const [visits, setVisits] = useState<Array<IVisit>>([]);
  const [page, setPage] = useState<number>(1);

  const loadData = () => {
    handleToast(VisitsReportConnection.getVisits(filters)).then((response) => {
      setVisits(response.data.data);
      setVisitsCount(response.data.count);
      if (response.data.count <= MAX_VISITS_RESPONSE) {
        const allVisitsIds = response.data.data.map((visit: IVisit) => visit.id);
        setChosenVisits(allVisitsIds);
      } else {
        setChosenVisits([]);
      }
    });
  };

  const getUsersByRole = (): Array<any> => {
    return form.users.options.filter((user: any) => user.roleId === filters.groupId);
  };

  const getCountPages = () => Math.ceil(visits.length / LIMIT);

  const getDataSlicedByPage = () => {
    const startSliceFrom = (page - 1) * LIMIT;
    return visits.slice(startSliceFrom, startSliceFrom + LIMIT);
  };

  return (
    <WhiteCard padding={true}>
      <div style={{ position: 'relative', zIndex: 101 }}>
        <Section title={'Lokalizacja'}>
          <Row>
            <Col xs={3}>
              <Input
                name={'locationId'}
                value={filters.locationId}
                placeholder={'Id lokalizacji'}
                setState={setFilters}
                state={filters}
              />
            </Col>
            <Col xs={3}>
              <Input
                name={'locationName'}
                value={filters.locationName}
                placeholder={'Nazwa'}
                setState={setFilters}
                state={filters}
              />
            </Col>
            <Col xs={3}>
              <Input name={'nip'} value={filters.nip} placeholder={'NIP'} setState={setFilters} state={filters} />
            </Col>
          </Row>
          <SeparatorEmpty />
          <Row>
            <Col xs={3}>
              <Select
                placeholder={'Sieć'}
                name={'networkId'}
                value={filters.networkId}
                options={form.networks.options}
                state={filters}
                setState={setFilters}
              />
            </Col>
            <Col xs={3}>
              <Select
                placeholder={'Grupa'}
                name={'groupId'}
                value={filters.groupId}
                options={form.projectMerchRoles.options}
                state={filters}
                setState={setFilters}
              />
            </Col>
            <Col xs={3}>
              <Select
                placeholder={'Grupa -> Użytkownik'}
                name={'userRoleId'}
                value={filters.userRoleId}
                options={getUsersByRole()}
                state={filters}
                setState={setFilters}
              />
            </Col>
          </Row>
        </Section>
        <SeparatorEmpty size={2} />
        <Section title={'Lokalizacja -> adres'}>
          <Row>
            <Col xs={3}>
              <Input
                name={'street'}
                value={filters.street}
                placeholder={'Ulica'}
                setState={setFilters}
                state={filters}
              />
            </Col>
            <Col xs={3}>
              <Input name={'city'} value={filters.city} placeholder={'Miasto'} setState={setFilters} state={filters} />
            </Col>
          </Row>
          <SeparatorEmpty />
          <Row>
            <Col xs={3}>
              <Input
                name={'community'}
                value={filters.community}
                placeholder={'Gmina'}
                setState={setFilters}
                state={filters}
              />
            </Col>
            <Col xs={3}>
              <Input
                name={'district'}
                value={filters.district}
                placeholder={'Powiat'}
                setState={setFilters}
                state={filters}
              />
            </Col>
            <Col xs={3}>
              <Select
                placeholder={'Województwo'}
                name={'voivodeshipId'}
                value={filters.voivodeshipId}
                options={form.voivodeships.options}
                state={filters}
                setState={setFilters}
              />
            </Col>
          </Row>
        </Section>
        <SeparatorEmpty size={2} />
        <Section title={'Wizyta'}>
          <Row>
            <Col xs={3}>
              <DayPickerInputCustom
                value={filters.startDate}
                placeholder={'Data od'}
                name={'startDate'}
                setState={setFilters}
                state={filters}
              />
            </Col>
            <Col xs={3}>
              <DayPickerInputCustom
                value={filters.endDate}
                placeholder={'Data do'}
                name={'endDate'}
                setState={setFilters}
                state={filters}
              />
            </Col>
            <Col xs={3}>
              <Select
                placeholder={'Status'}
                name={'statusId'}
                value={filters.statusId}
                options={form.visitStatus.options}
                state={filters}
                setState={setFilters}
              />
            </Col>
          </Row>
        </Section>
      </div>
      <SeparatorEmpty size={1.5} />
      <Row>
        <Col xs={3}>
          <ActionsContainer align={'left'}>
            <Button onClick={loadData}>Szukaj</Button>
          </ActionsContainer>
        </Col>
      </Row>
      <SeparatorLine size={3} />
      <ActionsContainer align={'left'}>
        {visitsCount > 0 && (
          <>
            <ButtonOutlined disabled={true}>
              Wybrano: {visitsCount > MAX_VISITS_RESPONSE ? visitsCount : chosenVisits.length}
            </ButtonOutlined>
            <ButtonOutlined disabled={true}>Znaleziono: {visitsCount}</ButtonOutlined>
          </>
        )}
      </ActionsContainer>
      <SeparatorEmpty />
      {visitsCount > MAX_VISITS_RESPONSE && (
        <AlertInfo>
          Brak możliwości filtrowania znalezionych wizyt dla liczby większej od {MAX_VISITS_RESPONSE}.
        </AlertInfo>
      )}
      <List data={getDataSlicedByPage()} count={getDataSlicedByPage().length}>
        <Column width={40} align="left">
          <HeaderCell depth={0} width={0}>
            <ToggleAllCheckbox data={visits} state={chosenVisits} setState={setChosenVisits} keyIdName={'id'} />
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => (
              <ToggleSingleCheckbox state={chosenVisits} setState={setChosenVisits} selectedId={rowData.id} />
            )}
          </Cell>
        </Column>
        <Column flexGrow={1} width={130} align="left">
          <HeaderCell depth={0} width={0}>
            Lokalizacja
          </HeaderCell>
          <Cell width={100} depth={0} dataKey={'locationName'} />
        </Column>
        <Column width={180} align="left">
          <HeaderCell depth={0} width={0}>
            Raportujący
          </HeaderCell>
          <Cell width={100} depth={0} dataKey={'reportingUser'} />
        </Column>
        <Column width={180} align="left">
          <HeaderCell depth={0} width={0}>
            Data
          </HeaderCell>
          <Cell width={100} depth={0} dataKey={'date'} />
        </Column>
        <Column width={180} align="left">
          <HeaderCell depth={0} width={0}>
            Status
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => <BadgeStatus color={rowData.statusColor}>{rowData.status}</BadgeStatus>}
          </Cell>
        </Column>
      </List>
      <SeparatorEmpty />
      <Pagination countPages={getCountPages()} setPage={setPage} currentPage={page} />
    </WhiteCard>
  );
};

export default SelectVisitsList;
