import React, { FunctionComponent, useEffect, useState } from 'react';
import { IAddTask, IEditTimeTableState } from 'utils/models';
import {
  createTaskState,
  createTimeTableState,
  manualSettingsState,
  recurringSettingsState,
  singleSettingsState,
} from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import TasksConnection from 'utils/connections/tasks';
import { handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Select from 'global/atoms/Select';
import EditTimeTable from '../timeTable/EditTimeTable';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Form from 'global/form/Form';
import { useDispatch } from 'react-redux';
import TimeTableToUpdate from '../timeTable/TimeTableToUpdate';
import SeparatorEmpty from 'global/atoms/separators/SeparatorEmpty';
import Spinner from 'global/atoms/Spinner/Spinner';
import SaveTimeTable from './SaveTimeTable';

interface IEditTask {}

const EditTask: FunctionComponent<IEditTask> = () => {
  const [data, setData] = useState<IAddTask>(createTaskState);
  const [form, setForm] = useState<any | 'loading'>('loading');
  const { id, taskId } = useParams<{ id: string; taskId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    TasksConnection.getFormEditTask(id, taskId).then((data) => {
      setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });

      let timeTableState: IEditTimeTableState;
      if (data.data.timeTableModel === null) {
        timeTableState = createTimeTableState;
      } else {
        timeTableState = {
          recurringSettings:
            data.data.timeTableModel.recurringSettings.length !== 0
              ? data.data.timeTableModel.recurringSettings
              : [recurringSettingsState],
          manualSettings: data.data.timeTableModel.manualSettings ?? manualSettingsState,
          singleSettings: data.data.timeTableModel.singleSettings ?? singleSettingsState,
          type: data.data.timeTableModel.type,
          isApproved: data.data.timeTableModel.isApproved,
        };
      }

      const createTaskStateFilled: IAddTask = {
        description: data.data.model.description,
        name: data.data.model.name,
        taskStatusId: data.data.model.taskStatus.id,
        expirationDays: data.data.model.expirationDays,
        timeTable: timeTableState,
      };

      setData(createTaskStateFilled);
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const dataClone = { ...data };
    const expirationDays: any = dataClone.expirationDays;
    dataClone.expirationDays = parseInt(expirationDays);

    handleToast(
      TasksConnection.updateTaskForProject(id, taskId, {
        ...dataClone,
      }),
    ).then(() => {
      redirectTasksList();
    });
  };

  const redirectTasksList = () => history.push(`/projects/${id}/tasks`);

  if (form === 'loading') return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input type={'text'} placeholder={'Nazwa'} name={'name'} value={data.name} state={data} setState={setData} />
          <Textarea
            placeholder={'Opis'}
            name={'description'}
            value={data.description}
            state={data}
            setState={setData}
          />
          <Select
            placeholder={'Status zadania'}
            name={'taskStatusId'}
            value={data.taskStatusId}
            options={form.taskStatuses.options}
            state={data}
            setState={setData}
          />
          <Input
            type={'number'}
            name="expirationDays"
            value={data.expirationDays}
            placeholder={'Okres ważności [dni]'}
            state={data}
            setState={setData}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
      <WhiteCard style={{ marginTop: '24px' }}>
        <EditTimeTable parentData={data} parentSetData={setData} />
        <SeparatorEmpty size={2} />
        <SaveTimeTable form={form} data={data} />
        <SeparatorEmpty />
      </WhiteCard>
      <TimeTableToUpdate />
    </>
  );
};

export default EditTask;
