import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId, parseDateForBackend } from '../helpers';
import { ICreateLocation, ICreateProject, IGetProjectLocations, IGetProjectProducts, IGetProjects } from '../models';
import { getSaveAddressData } from './locations';

const ProjectsConnection = {
  getProjects: (data: IGetProjects) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/list`,
      { ...data, startDate: parseDateForBackend(data.startDate), endDate: parseDateForBackend(data.endDate) },
      {
        headers: { ...getAuthHeader() },
      },
    );
  },
  getProject: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}`, {
      headers: getAuthHeader(),
    });
  },
  createProject: (data: ICreateProject) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects`,
      { ...data, startDate: parseDateForBackend(data.startDate), endDate: parseDateForBackend(data.endDate) },
      { headers: getAuthHeader() },
    );
  },
  updateProject: (data: ICreateProject, id: string) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}`,
      { ...data, startDate: parseDateForBackend(data.startDate), endDate: parseDateForBackend(data.endDate) },
      {
        headers: getAuthHeader(),
      },
    );
  },
  assignUsersToProject: (
    projectId: string,
    data: { roles: Array<{ roleId: string; assigned: Array<{ id: string }>; unassigned: Array<{ id: string }> }> },
  ) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/users/roles`,
      data,
      { headers: getAuthHeader() },
    );
  },
  unassignUsersToProject: (projectId: string, roleId: string, data: Array<{ userId: string }>) => {
    return axios.delete(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/roles/${roleId}/detach`,
      { headers: getAuthHeader(), data: { users: data } },
    );
  },
  getAssignedProducts: (id: string, data: IGetProjectProducts) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/items/assigned/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getUnassignedProducts: (id: string, data: IGetProjectProducts) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/items/unassigned/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  assignProductsToProject: (projectId: string, data: Array<{ itemId: string }>) => {
    return axios.patch(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/items/assign`,
      { items: data },
      { headers: getAuthHeader() },
    );
  },
  unassignProductsFromProject: (projectId: string, data: Array<{ itemId: string }>) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/items/detach`,
      { headers: getAuthHeader(), data: { items: data } },
    );
  },
  getFiltersFormProducts: () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/items/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getAssignedLocations: (id: string, data: IGetProjectLocations) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/locations/assigned/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getUnassignedLocations: (id: string, data: IGetProjectLocations) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/locations/unassigned/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  assignLocations: (id: string, data: Array<string>) => {
    return axios.patch(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/locations/assign`,
      { locations: data.map((item: string) => ({ locationId: item })) },
      {
        headers: getAuthHeader(),
      },
    );
  },
  unassignLocations: (id: string, data: Array<string>) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/locations/detach`,
      { headers: getAuthHeader(), data: { locations: data.map((item: string) => ({ locationId: item })) } },
    );
  },
  disableLocations: (id: string, data: Array<string>) => {
    return axios.patch(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${id}/locations/reporting/disable`,
      { locations: data.map((item: string) => ({ locationId: item })) },
      {
        headers: getAuthHeader(),
      },
    );
  },
  enableLocations: (id: string, data: Array<string>) => {
    return axios.patch(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/locations/reporting/enable`,
      { locations: data.map((item: string) => ({ locationId: item })) },
      {
        headers: getAuthHeader(),
      },
    );
  },
  enableAllLocations: (id: string) => {
    return axios.patch(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${id}/locations/reporting/all/enable`,
      {},
      {
        headers: getAuthHeader(),
      },
    );
  },
  detachAllLocations: (id: string) => {
    return axios.patch(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${id}/locations/reporting/all/detach`,
      {},
      {
        headers: getAuthHeader(),
      },
    );
  },
  getFormCreateProject: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/form`);
  },
  getFormFilterProjects: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditProject: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormFilterLocations: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getAssignedRoles: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/roles/assigned`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getAssignedUsers: (projectId: string, roleId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/roles/${roleId}/assigned`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getUnassignedUsers: (projectId: string, roleId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/roles/${roleId}/unassigned`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getAssignedRolesToLocation: (projectId: string, locationId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/${locationId}/users/roles/assigned`,
      { headers: getAuthHeader() },
    );
  },
  getFormLocationAssignedUsers: (projectId: string, locationId: string, roleId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/${locationId}/users/roles/${roleId}/assigned`,
      { headers: getAuthHeader() },
    );
  },
  getFormLocationUnassignedUsers: (projectId: string, locationId: string, roleId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/${locationId}/users/roles/${roleId}/unassigned`,
      { headers: getAuthHeader() },
    );
  },
  assignUsersToLoaction: (
    projectId: string,
    locationId: string,
    data: { roles: Array<{ roleId: string; assigned: Array<{ id: string }>; unassigned: Array<{ id: string }> }> },
  ) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/locations/${locationId}/users/roles`,
      data,
      { headers: getAuthHeader() },
    );
  },
  unassignUsersFromLoaction: (
    projectId: string,
    locationId: string,
    roleId: string,
    data: Array<{ userId: string }>,
  ) => {
    return axios.delete(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/locations/${locationId}/users/roles/${roleId}/detach`,
      { headers: getAuthHeader(), data: { users: data } },
    );
  },
  getFormEditLocation: (projectId: string, locationId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/${locationId}/form`,
      { headers: getAuthHeader() },
    );
  },
  getFormEditRoles: (projectId: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${projectId}/roles/form`,
      { headers: getAuthHeader() },
    );
  },
  assignRolesToProject: (projectId: string, data: any) => {
    return axios.patch(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${projectId}/roles/assign`,
      data,
      { headers: getAuthHeader() },
    );
  },
  updateProjectLocation: (projectId: string, locationId: string, data: ICreateLocation) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/locations/${locationId}`,
      getSaveAddressData(data),
      {
        headers: { ...getAuthHeader() },
      },
    );
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects`, {
      headers: { ...getAuthHeader() },
      data: { projects: data },
    });
  },
};

export default ProjectsConnection;
