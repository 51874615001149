import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Connection from '../../../../utils/connections/visitReviews';
import { IPhotoReviewFeedback } from '../../../../utils/models';
import styles from '../../../../global/common.module.scss'
import ReviewsForm from './ReviewsForm';
import { photoReviewErrorDef } from '../../../../utils/states';
import { getUserId } from '../../../../utils/helpers';

interface ISupervisorReview {
  identifier: string;
  hidden: boolean;
}

const SupervisorReview: FunctionComponent<ISupervisorReview> = (props) => {
  const feedbackStatesRef = useRef<IPhotoReviewFeedback[]>([]);
  const [loaded, setLoaded] = useState<boolean | null>(false);
  const myUserId = getUserId();

  useEffect(() => {
    if (!props.hidden && props.identifier != '') {
      Connection.getFeedbackAnswers(props.identifier)
        .then((response) => {
          feedbackStatesRef.current = response.data.filter((irf) => {
            return irf.reviewer?.user.id !== myUserId; // skip my reviews
          });
          setLoaded(true);
        })
        .catch(() => {
          setLoaded(null);
        });
    }
  }, [props.hidden]);

  if (loaded === false) {
    return (
      <div hidden={props.hidden} style={{ marginTop: '20px' }} className={styles.loadingSmall}>
        Ładowanie ocen...
      </div>
    );
  }

  if (loaded === null) {
    return (
      <div hidden={props.hidden} style={{ marginTop: '20px' }} className={styles.loadingSmallErr}>
        Wystąpił błąd lub utracono połączenie.
      </div>
    );
  }

  if (!feedbackStatesRef.current.length) {
    return (
      <div style={{ marginTop: '20px' }} className={styles.loadingSmall}>
        Brak ocen innych użytkowników.
      </div>
    );
  }

  return (
    <div hidden={props.hidden}>
      <ReviewsForm
        key={`readOnly_reviewForm`}
        statesRef={feedbackStatesRef}
        identifier={'fake-identifier'}
        readOnly={true}
        errors={photoReviewErrorDef}
      />
      <br />
    </div>
  );
};

export default SupervisorReview;
