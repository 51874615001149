import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateQuestion } from 'utils/models';
import { createQuestionState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import { handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import QuestionsConnection from 'utils/connections/questions';
import Input from 'global/atoms/Input';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Form from 'global/form/Form';
import { useDispatch } from 'react-redux';
import Select from 'global/atoms/Select';
import CheckBox from 'global/atoms/checkbox/CheckBox';
import QuestionEditAnswers from './QuestionEditAnswers';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IQuestionsEdit {}

const INTEGER_QUESTION = 'INTEGER_QUESTION';
const DECIMAL_QUESTION = 'DECIMAL_QUESTION';
const TEXT_QUESTION = 'TEXT_QUESTION';
const TEXTAREA_QUESTION = 'TEXTAREA_QUESTION';
const PHOTO_QUESTION = 'PHOTO_QUESTION';
const DICTIONARY_QUESTION = 'DICTIONARY_QUESTION';
const DICTIONARY_MULTIPLE_ANSWERS_QUESTION = 'DICTIONARY_MULTIPLE_ANSWERS_QUESTION';

export interface IQuestionsEditState {
  answer: string;
  isDefault: boolean;
  isAnswerToDepend: boolean;
  editId: number | null;
}

export const defaultQuestionEditState: IQuestionsEditState = {
  answer: '',
  isDefault: false,
  editId: null,
  isAnswerToDepend: false,
};

const QuestionsEdit: FunctionComponent<IQuestionsEdit> = () => {
  const [data, setData] = useState<ICreateQuestion>(createQuestionState);
  const [, setErrors] = useState<any>({});
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<IQuestionsEditState>(defaultQuestionEditState);

  useEffect(() => {
    QuestionsConnection.getQuestionsFormEdit(id).then((data) => {
      setData({
        activityTypeId: data.data.model.activityType.id,
        questionTypeId: data.data.model.questionType.id,
        questionType: data.data.model.questionType.type,
        customerId: data.data.model.customer.id,
        description: data.data.model.description,
        name: data.data.model.name,
        isRequired: data.data.model.required,
        pointsValue: data.data.model.pointsValue,
        minValue: data.data.model.minValue,
        maxValue: data.data.model.maxValue,
        answers: data.data.model.answers,
        isEdit: data.data.model.edit,
      });
      setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const dataClone = { ...data };
    const minValue: any = dataClone.minValue;
    const maxValue: any = dataClone.maxValue;
    const pointsValue: any = dataClone.pointsValue;
    dataClone.minValue = minValue !== null ? parseInt(minValue) : null;
    dataClone.maxValue = maxValue !== null ? parseInt(maxValue) : null;
    dataClone.pointsValue = parseInt(pointsValue);

    dataClone.answers.map((data, index) => {
      dataClone.answers[index].order = index + 1;
    });

    handleToast(QuestionsConnection.updateQuestion(id, dataClone), setErrors).then(() =>
      history.push('/datasets/questions'),
    );
  };

  const handleChangeRequired = () => {
    const required = !data.isRequired;
    setData({ ...data, ...{ isRequired: required } });
  };

  const handleChangeEdit = () => {
    const isEdit = !data.isEdit;
    setData({ ...data, ...{ isEdit: isEdit } });
  };

  if (form === 'loading') return <Spinner />;

  const checkMinMaxShouldDisplay = (type: string) => {
    const availableTypes = [
      INTEGER_QUESTION,
      DECIMAL_QUESTION,
      TEXT_QUESTION,
      TEXTAREA_QUESTION,
      PHOTO_QUESTION,
      DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
    ];
    return availableTypes.includes(type);
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input type={'text'} placeholder={'Nazwa'} name={'name'} value={data.name} state={data} setState={setData} />
          <Input
            type={'text'}
            placeholder={'Opis'}
            name={'description'}
            value={data.description}
            state={data}
            setState={setData}
          />
          <Select
            options={form.customers.options}
            disabled={true}
            placeholder={'Klient'}
            name={'customerId'}
            value={data.customerId}
            state={data}
            setState={setData}
          />
          <Select
            options={form.activityTypes.options}
            disabled={true}
            placeholder={'Typ aktywności'}
            name={'activityTypeId'}
            value={data.activityTypeId}
            state={data}
            setState={setData}
          />
          <Select
            options={form.questionTypes.options}
            disabled={true}
            placeholder={'Typ pytania'}
            name={'questionTypeId'}
            value={data.questionTypeId}
            state={data}
            setState={setData}
          />
          <CheckBox isChecked={data.isRequired} onClick={handleChangeRequired}>
            Pytanie wymagane
          </CheckBox>
          <CheckBox isChecked={data.isEdit} onClick={handleChangeEdit}>
            Możliwość edycji
          </CheckBox>
          {checkMinMaxShouldDisplay(data.questionType) && (
            <>
              <Input
                type={'number'}
                placeholder={'Min'}
                name={'minValue'}
                value={data.minValue}
                state={data}
                setState={setData}
              />
              <Input
                type={'number'}
                placeholder={'Max'}
                name={'maxValue'}
                value={data.maxValue}
                state={data}
                setState={setData}
              />
            </>
          )}
          <Input
            type={'number'}
            placeholder={'Punkty'}
            name={'pointsValue'}
            value={data.pointsValue}
            state={data}
            setState={setData}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
      {(data.questionType === DICTIONARY_QUESTION || data.questionType === DICTIONARY_MULTIPLE_ANSWERS_QUESTION) && (
        <QuestionEditAnswers
          state={state}
          setState={setState}
          data={data}
          setData={setData}
          handleSubmit={handleSubmit}
          form={form}
        />
      )}
    </>
  );
};

export default QuestionsEdit;
