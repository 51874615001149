import React, { FunctionComponent } from 'react';
import Popover from '../../../../../global/atoms/Popover';
import SeparatorLine from '../../../../../global/atoms/separators/SeparatorLine';
import styles from '../../../../../global/atoms/atoms.module.scss';
import SeparatorEmpty from '../../../../../global/atoms/separators/SeparatorEmpty';
import editIconUrl from '../../../../../assets/svg/edit.svg';
import { defaultPopoverDataState, ITimeTableDetailsState, VIEW_TYPE_LOCALISATION } from './TimeTableDetails';
import ReactDOM from 'react-dom';
import { MONTHS, WEEK_DAYS } from '../../../../../utils/dictionaries';
import { IAddTimeTable, IEditTimeTableState } from '../../../../../utils/models';
import { manualSettingsState, recurringSettingsState, singleSettingsState } from '../../../../../utils/states';
import TimeTableConnection from '../../../../../utils/connections/timetable';
import { useParams } from 'react-router-dom';
import TablePreviewBodyButton from './TablePreviewBodyButton';
import { isTouchDevice } from '../../../../../utils/helpers';

interface IDateDetailsPopoverProps {
  state: ITimeTableDetailsState;
  setState: any;
  setLoadData: any;
}

const DateDetailsPopover: FunctionComponent<IDateDetailsPopoverProps> = ({ state, setState, setLoadData }) => {
  if (state.popoverData.target === null) {
    return <></>;
  }

  const popoverWidth = 400;

  const getOffset = (el: any) => {
    const rect = el.getBoundingClientRect();
    return { left: rect.left - popoverWidth / 2 + 10, top: rect.top + 30 };
  };

  const handleClosePopover = () => {
    setState({ ...state, ...{ popoverData: defaultPopoverDataState } });
  };

  const handleOpenEditTimeTable = (data: any) => {
    const timeTableUpdateData: IEditTimeTableState = {
      type: data.type,
      singleSettings: data.singleSettings !== null ? data.singleSettings : singleSettingsState,
      manualSettings: data.manualSettings !== null ? data.manualSettings : manualSettingsState,
      recurringSettings: data.recurringSettings !== null ? data.recurringSettings : [recurringSettingsState],
      isApproved: data.isApproved,
    };
    setState({
      ...state,
      editData: {
        show: true,
        type: 'update',
        updateId: data.id,
        day: null,
        userId: data.user.id,
        locationId: data.location.id,
        taskId: data.task.id,
        projectId: data.project.id,
        disabled: ['project', 'task'],
        timeTableUpdate: timeTableUpdateData,
      },
    });
  };

  const { id, taskId } = useParams<{ id: string; taskId: string }>();
  const handleSetTimeTableIsApproved = (data: any) => {
    const addTimeTableData: IAddTimeTable = {
      isApproved: true,
      type: data.type,
      default: false,
      taskId: taskId,
      projectId: id,
      locationId: data.location.id,
      userId: data.user.id,
      singleSettings: data.singleSettings,
      manualSettings: data.manualSettings,
      recurringSettings: data.recurringSettings,
    };

    TimeTableConnection.updateTimeTable(data.id, addTimeTableData).then(() => {
      setLoadData(true);
    });
  };

  const getHeader = (timeTable: any) => {
    const monthName = MONTHS[state.popoverData.selectedDate.getMonth()];
    const dayNumber = state.popoverData.selectedDate.getDate();
    const dayName = WEEK_DAYS[state.popoverData.selectedDate.getDay()];

    let headerName = timeTable.user.firstName + ' ' + timeTable.user.lastName;
    if (state.viewType === VIEW_TYPE_LOCALISATION) {
      headerName = timeTable.location.name;
    }
    return (
      <>
        <div className={styles.timeTablePopoverDate}>
          {dayNumber} {monthName}, {dayName}
        </div>
        <SeparatorEmpty size={0.5} />
        <div className={styles.timeTablePopoverDateLocalisation}>{headerName}</div>
      </>
    );
  };

  const onMouseLeave = () => {
    if (isTouchDevice()) return false;
    setState({ ...state, ...{ popoverData: defaultPopoverDataState } });
  };

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'absolute',
        left: `${getOffset(state.popoverData.target).left}px`,
        top: `${getOffset(state.popoverData.target).top}px`,
        width: '400px',
        height: '600px',
      }}
      onMouseLeave={onMouseLeave}
    >
      <Popover width={popoverWidth} onClose={handleClosePopover}>
        {getHeader(state.popoverData.timeTables[0])}
        <div
          style={{
            maxHeight: '400px',
            overflowY: 'auto',
          }}
        >
          {state.popoverData.timeTables.map((data: any, index) => {
            const userName = data.user.firstName + ' ' + data.user.lastName;
            let name = data.location.name;
            if (state.viewType === VIEW_TYPE_LOCALISATION) {
              name = userName;
            }
            return (
              <div key={index}>
                <SeparatorEmpty size={0.5} />
                <SeparatorLine color={'dark'} />
                <SeparatorEmpty />
                <div className={styles.timeTablePopoverFullName}>
                  <TablePreviewBodyButton timeTablesDisplay={[data]} />
                  {name}
                </div>
                <SeparatorEmpty size={0.5} />
                <div className={styles.timeTablePopoverTaskName}>{data.task.name}</div>
                <SeparatorEmpty size={0.5} />
                <div>
                  <div className={styles.timeTablePopoverButton} onClick={handleOpenEditTimeTable.bind(null, data)}>
                    <img alt={'Edycja'} src={editIconUrl} /> Edytuj
                  </div>
                  {!data.isApproved && (
                    <div
                      className={styles.timeTablePopoverButton}
                      onClick={handleSetTimeTableIsApproved.bind(null, data)}
                    >
                      Zatwierdź wpis
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>,
    window.document.getElementsByTagName('body')[0],
  );
};

export default DateDetailsPopover;
