import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';

interface IActivitiesListHeader {}

const ActivitiesListHeader: FunctionComponent<IActivitiesListHeader> = () => {
  return (
    <section className={styles.container}>
      <div className={`${styles.col} ${styles.colName}`}>
        <span style={{ cursor: 'pointer' }}>NAZWA</span>
      </div>
      <div className={`${styles.col} ${styles.colActions}`}>
        <span style={{ cursor: 'pointer' }}>AKCJE</span>
      </div>
    </section>
  );
};

export default ActivitiesListHeader;
