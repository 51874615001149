import React, { FunctionComponent, useEffect, useState } from 'react';
import { IEditUser, IChangePass } from 'utils/models';
import { createUserDetails, editUserState, passChangeState } from 'utils/states';
import UserConnection from 'utils/connections/user';
import WhiteCard from 'global/atoms/WhiteCard';
import { handleSetErrors, handleToast } from 'utils/helpers';
import { useHistory, useParams } from 'react-router-dom';
import Input from 'global/atoms/Input';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Form from 'global/form/Form';
import CustomRoles from '../customRoles/CustomRoles';
import Spinner from 'global/atoms/Spinner/Spinner';
import EditUserCommonFields from '../usersAdd/EditUserCommonFields';
import HeaderButtons from '../../../../global/atoms/headerButtons/HeaderButtons';
import { confirmModal } from '../../../../redux/store';
import Button from '../../../../global/atoms/Button';

interface IEditUserComponent {}

const EditUser: FunctionComponent<IEditUserComponent> = () => {
  const [data, setData] = useState<IEditUser>(editUserState);
  const [passData, setPassData] = useState<IChangePass>(passChangeState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | 'loading'>('loading');
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    UserConnection.getFormEditUser(id)
      .then((data) => {
        setData({
          firstName: data.data.model.firstName,
          lastName: data.data.model.lastName,
          username: data.data.model.username,
          userStatusId: data.data.model.userStatus.id,
          roles: data.data.model.roles.map((item: any) => ({
            roleId: item.role.roleId,
            default: item.default,
          })),
          salePrefix: data.data.model.salePrefix,
          customerId: data.data.model.customer?.id !== undefined ? data.data.model.customer?.id : '',
          userDetails: data.data.model.userDetails ?? createUserDetails,
        });
        setForm(data.data);
      })
      .catch((err) => handleSetErrors(err, setErrors));
  }, []);

  const handleSubmit = (event: any) => {
      event.preventDefault();

      handleToast(UserConnection.editUser(id, data), setErrors).then(() => history.push('/admin/users'));
    },
    handleSubmitPassChange = (event: any) => {
      event.preventDefault();

      handleToast(
        UserConnection.changePassword(id, passData),
        setErrors,
        undefined,
        'Hasło zostało zmienione',
      ).then(() => history.push('/admin/users'));
    };

  if (form === 'loading') return <Spinner />;

  const handleRemoveToken = () => {
    handleToast(UserConnection.logoutUser(id));
  };

  return (
    <>
      <HeaderButtons>
        <Button onClick={confirmModal('czy napewno chcesz usunąć?', handleRemoveToken)}>Usuń token urządzenia</Button>
      </HeaderButtons>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <EditUserCommonFields data={data} setData={setData} errors={errors} form={form} />
          <Input
            type={'salePrefix'}
            placeholder="Prefix dokumentów projektu"
            name="saleRoles"
            value={data.salePrefix}
            disabled={true}
          />
          <CustomRoles errors={errors} state={data} setState={setData} options={form.roles.options} />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
      <WhiteCard style={{ marginTop: '24px' }} padding={true}>
        <Form handleSubmit={handleSubmitPassChange}>
          {/*<Input*/}
          {/*  type={'password'}*/}
          {/*  placeholder={'Stare hasło'}*/}
          {/*  name={'oldPassword'}*/}
          {/*  value={passData.oldPassword}*/}
          {/*  state={passData}*/}
          {/*  setState={setPassData}*/}
          {/*  errors={errors}*/}
          {/*/>*/}
          <Input
            type={'password'}
            placeholder={'Nowe hasło'}
            name={'newPassword'}
            value={passData.newPassword}
            state={passData}
            setState={setPassData}
            errors={errors}
          />
          <Input
            type={'password'}
            placeholder={'Powtórz nowe hasło'}
            name={'repeatNewPassword'}
            value={passData.repeatNewPassword}
            state={passData}
            setState={setPassData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default EditUser;
