import React, { FunctionComponent } from 'react';
import styles from '../../../../../global/atoms/atoms.module.scss';
import CheckBox from '../../../../../global/atoms/checkbox/CheckBox';
import { getSelectedMonths } from './Table';
import {
  CELL_TYPE_ACTIVITY_INFO,
  CELL_TYPE_REPEAT_INFO,
  ITimeTableDetailsState,
  VIEW_TYPE_LOCALISATION,
  VIEW_TYPE_USER,
} from './TimeTableDetails';
import { MANUAL_TYPE, RECURRING_TYPE, SINGLE_TYPE } from '../EditTimeTable';
import { IAddTimeTableRecurringSettings } from '../../../../../utils/models';
import RecurringRulesManager from '../utils/RecurringRulesManager';
import TablePreviewBodyButton from './TablePreviewBodyButton';
import { isTouchDevice } from '../../../../../utils/helpers';
import ActivityInfoButton from './ActivityInfoButton';

interface ICheckBoxesBody {
  state: ITimeTableDetailsState;
  setState: any;
  id: string;
  loadedFilters: any;
  viewData: any;
}

interface ITimeTableWorkingDay {
  timeTableId: string;
  day: number;
}

const CheckBoxesBody: FunctionComponent<ICheckBoxesBody> = ({ state, setState, id, loadedFilters, viewData }) => {
  const getUserTimeTables = (timeTables: Array<any>, userId: string) => {
    const userTimeTables: Array<any> = [];
    timeTables.map((timeTable: any) => {
      if (timeTable.user.id === userId) {
        userTimeTables.push(timeTable);
      }
    });

    return userTimeTables;
  };

  const getLocationTimeTables = (timeTables: Array<any>, locationId: string) => {
    const locationTimeTables: Array<any> = [];
    timeTables.map((timeTable: any) => {
      if (timeTable.location.id === locationId) {
        locationTimeTables.push(timeTable);
      }
    });

    return locationTimeTables;
  };

  const getWorkingDays = (timeTables: Array<any>): Array<ITimeTableWorkingDay> => {
    const workingDays: Array<ITimeTableWorkingDay> = [];
    timeTables.map((timeTableData: any) => {
      let startDate = null;
      let startDateNumber = 0;

      switch (timeTableData.type) {
        case SINGLE_TYPE:
          startDateNumber =
            timeTableData.singleSettings.startDate === 0
              ? timeTableData.createdAt
              : timeTableData.singleSettings.startDate;
          startDate = new Date(startDateNumber * 1000);
          startDate.setHours(0, 0, 0, 0);
          workingDays.push({
            timeTableId: timeTableData.id,
            day: startDate.getTime(),
          });
          break;
        case MANUAL_TYPE:
          timeTableData.manualSettings.days.map((manualDay: number) => {
            const dateLoop = new Date(manualDay * 1000);
            dateLoop.setHours(0, 0, 0, 0);
            workingDays.push({
              timeTableId: timeTableData.id,
              day: dateLoop.getTime(),
            });
          });
          break;
        case RECURRING_TYPE:
          timeTableData.recurringSettings.map((setting: IAddTimeTableRecurringSettings) => {
            const lastMonth = getSelectedMonths(state).slice(-1).pop();
            if (lastMonth === undefined) {
              throw 'Month is not selected';
            }

            const endDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
            const recurringSettingManager = new RecurringRulesManager(setting, endDate);

            recurringSettingManager.getDates().map((recurringDay) => {
              workingDays.push({
                timeTableId: timeTableData.id,
                day: recurringDay,
              });
            });
          });
          break;
      }
    });

    return workingDays;
  };

  const getLocationWorkingDays = (state: any, locationId: string): Array<ITimeTableWorkingDay> => {
    const locationTimeTables = getLocationTimeTables(state.timeTables, locationId);
    return getWorkingDays(locationTimeTables);
  };

  const getUserWorkingDays = (state: any, userId: string): Array<ITimeTableWorkingDay> => {
    const userTimeTables = getUserTimeTables(state.timeTables, userId);
    return getWorkingDays(userTimeTables);
  };

  let workingDays: Array<ITimeTableWorkingDay> = [];
  let timeTablesCheckbox: Array<any> = [];
  let locationId: string | null = null;
  let userId: string | null = null;
  if (state.viewType === VIEW_TYPE_USER) {
    workingDays = getUserWorkingDays(state, id);
    timeTablesCheckbox = getUserTimeTables(state.timeTables, id);
    userId = id;
  } else if (state.viewType === VIEW_TYPE_LOCALISATION) {
    workingDays = getLocationWorkingDays(state, id);
    timeTablesCheckbox = getLocationTimeTables(state.timeTables, id);
    locationId = id;
  }

  const getCheckBoxesBody = () => {
    let index = 0;
    const checkBoxesBody: Array<any> = [];
    getSelectedMonths(state).map((selectedMonth) => {
      const countDays = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate();
      for (let i = 1; i <= countDays; i++) {
        const loopDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), i);
        loopDate.setHours(0, 0, 0, 0);

        const loopDateNumber = loopDate.getTime();
        const workingDaysFound: Array<ITimeTableWorkingDay> = workingDays.filter((data) => {
          return data.day === loopDateNumber;
        });

        const timeTablesDisplay: any = [];
        workingDaysFound.map((data) => {
          timeTablesCheckbox.map((timeTable: any) => {
            if (timeTable.id === data.timeTableId) {
              timeTablesDisplay.push(timeTable);
            }
          });
        });

        const checked = workingDaysFound.length > 0;
        let inactiveStyles = {};
        if (!checked) {
          inactiveStyles = {
            backgroundColor: 'transparent',
          };
        }

        let button: any = <div />;
        if (state.tableType === 'preview' && checked) {
          const onClick = (event: any) => {
            setState({
              ...state,
              popoverData: {
                target: event.currentTarget,
                timeTables: timeTablesDisplay,
                selectedDate: loopDate,
              },
            });
          };

          const onMouseEnter = (event: any) => {
            if (isTouchDevice()) return false;
            onClick(event);
          };

          if (state.cellType === CELL_TYPE_REPEAT_INFO) {
            button = (
              <TablePreviewBodyButton
                timeTablesDisplay={timeTablesDisplay}
                onMouseEnter={onMouseEnter}
                onClick={onClick}
              />
            );
          } else if (state.cellType === CELL_TYPE_ACTIVITY_INFO) {
            button = (
              <ActivityInfoButton timeTablesDisplay={timeTablesDisplay} onMouseEnter={onMouseEnter} onClick={onClick} />
            );
          }
        } else if (state.tableType === 'edit') {
          button = (
            <CheckBox
              styleContainer={{ justifyContent: 'center' }}
              style={{ marginRight: 0, ...inactiveStyles }}
              isChecked={checked}
              onClick={(event: any) => {
                if (checked) {
                  setState({
                    ...state,
                    popoverData: {
                      target: event.currentTarget,
                      timeTables: timeTablesDisplay,
                      selectedDate: loopDate,
                    },
                  });
                } else {
                  const taskOption = viewData.tasks.options.filter((data: any) => {
                    return data.id === loadedFilters.taskId;
                  });
                  const taskProjectId = taskOption.length > 0 ? taskOption[0].projectId : null;
                  setState({
                    ...state,
                    editData: {
                      show: true,
                      type: 'new',
                      updateId: null,
                      day: loopDate,
                      userId: userId,
                      locationId: locationId,
                      projectId: loadedFilters.projectId !== null ? loadedFilters.projectId : taskProjectId,
                      taskId: loadedFilters.taskId !== null ? loadedFilters.taskId : viewData.tasks.options[0].id,
                      disabled: [],
                    },
                  });
                }
              }}
            />
          );
        }

        checkBoxesBody.push(
          <div className={styles.timeTableTableBodyTdContainer} key={`${index}-${loopDateNumber}`}>
            {button}
          </div>,
        );
        index++;
      }
    });

    return checkBoxesBody;
  };

  return <>{getCheckBoxesBody()}</>;
};

export default CheckBoxesBody;
