import { default as axios } from '../AxiosHelper';
import { getOrganizationId, getAuthHeader } from '../helpers';
import { ICreateCustomer, IGetCustomers } from '../models';
import { AxiosPromise } from 'axios';

const CustomersConnection = {
  getCustomers: (data: IGetCustomers) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/customers/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createCustomer: (data: ICreateCustomer) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/customers`, data, {
      headers: getAuthHeader(),
    });
  },
  updateCustomer: (id: string, data: ICreateCustomer) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/customers/${id}`, data, {
      headers: getAuthHeader(),
    });
  },

  getCustomersFiltersForm: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/customers/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getCustomersFormAdd: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/customers/form`, {
      headers: getAuthHeader(),
    });
  },
  getCustomersFormEdit: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/customers/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  getListForm: (): AxiosPromise => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/customers/list/form`);
  },
};

export default CustomersConnection;
