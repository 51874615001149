import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRoot } from '../../redux/models';
import { useLocation } from 'react-router-dom';
import styles from './atoms.module.scss';
import CSS from 'csstype';
import hint from '../../assets/svg/hint.svg';

interface IMainRouteName {
  style?: CSS.Properties;
  itemTitle?: string;
  itemSubTitle?: string;
  showHint?: boolean;
  name?: string;
  subName?: string;
}

const MainRouteName: FunctionComponent<IMainRouteName> = ({
  style,
  itemTitle,
  itemSubTitle,
  showHint,
  name,
  subName,
}) => {
  const [toggleTooltip, setToggleTooltip] = useState<boolean>(false);
  const [hintContent, setHintContent] = useState<string>('');
  const location = useLocation();
  const menu = useSelector((state: IRoot) => state.menu);

  useEffect(() => {
    menu.positions.map((item) => {
      if (item.url === location.pathname) {
        setHintContent(item.hint);
      }
    });
  }, [location.pathname]);

  if (name && itemTitle && subName && itemSubTitle) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <span className={styles.smallSubRouteName} style={style}>
            {name + ': '}
          </span>
          <span className={styles.smallMainRouteName} style={style}>
            {itemTitle}
          </span>
        </div>
        <div style={{ display: 'flex' }}>
          <span className={styles.subRouteName} style={style}>
            {subName + ': '}
          </span>
          <span className={styles.mainRouteName} style={style}>
            {itemSubTitle}
            <div
              className={styles.tooltipContainer}
              onMouseEnter={() => {
                setToggleTooltip(true);
              }}
              onMouseLeave={() => {
                setToggleTooltip(false);
              }}
              onClick={() => {
                setToggleTooltip((state) => !state);
              }}
            >
              {showHint && <img className={styles.hint} src={hint} alt={'hint'} />}
              {toggleTooltip && (
                <div className={styles.tooltip}>
                  <p>{hintContent}</p>
                </div>
              )}
            </div>
          </span>
        </div>
      </div>
    );
  }

  if (name && itemTitle && subName) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <span className={styles.smallSubRouteName} style={style}>
            {name + ': '}
          </span>
          <span className={styles.smallMainRouteName} style={style}>
            {itemTitle}
          </span>
        </div>
        <div>
          <span className={styles.mainRouteName} style={style}>
            {subName}
            <div
              className={styles.tooltipContainer}
              onMouseEnter={() => {
                setToggleTooltip(true);
              }}
              onMouseLeave={() => {
                setToggleTooltip(false);
              }}
              onClick={() => {
                setToggleTooltip((state) => !state);
              }}
            >
              {showHint && <img className={styles.hint} src={hint} alt={'hint'} />}
              {toggleTooltip && (
                <div className={styles.tooltip}>
                  <p>{hintContent}</p>
                </div>
              )}
            </div>
          </span>
        </div>
      </div>
    );
  }

  if (name && subName) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>
          <span className={styles.mainRouteName} style={style}>
            {name}
          </span>
        </div>
        <div>
          <span className={styles.smallMainRouteName} style={style}>
            {subName}
            <div
              className={styles.tooltipContainer}
              onMouseEnter={() => {
                setToggleTooltip(true);
              }}
              onMouseLeave={() => {
                setToggleTooltip(false);
              }}
              onClick={() => {
                setToggleTooltip((state) => !state);
              }}
            >
              {showHint && <img className={styles.hint} src={hint} alt={'hint'} />}
              {toggleTooltip && (
                <div className={styles.tooltip}>
                  <p>{hintContent}</p>
                </div>
              )}
            </div>
          </span>
        </div>
      </div>
    );
  }

  if (name && itemTitle) {
    return (
      <>
        <span className={styles.subRouteName} style={style}>
          {name + ': '}
        </span>
        <span className={styles.mainRouteName} style={style}>
          {itemTitle}
          <div
            className={styles.tooltipContainer}
            onMouseEnter={() => {
              setToggleTooltip(true);
            }}
            onMouseLeave={() => {
              setToggleTooltip(false);
            }}
            onClick={() => {
              setToggleTooltip((state) => !state);
            }}
          >
            {showHint && <img className={styles.hint} src={hint} alt={'hint'} />}
            {toggleTooltip && (
              <div className={styles.tooltip}>
                <p>{hintContent}</p>
              </div>
            )}
          </div>
        </span>
      </>
    );
  }

  if (subName) {
    return (
      <span className={styles.smallMainRouteName} style={style}>
        {subName}
      </span>
    );
  }

  return (
    <span className={styles.mainRouteName} style={style}>
      {name ? name : menu.positions.map((item) => (item.url === location.pathname ? item.name : ''))}
      <div
        className={styles.tooltipContainer}
        onMouseEnter={() => {
          setToggleTooltip(true);
        }}
        onMouseLeave={() => {
          setToggleTooltip(false);
        }}
        onClick={() => {
          setToggleTooltip((state) => !state);
        }}
      >
        {showHint && <img className={styles.hint} src={hint} alt={'hint'} />}
        {toggleTooltip && (
          <div className={styles.tooltip}>
            <p>{hintContent}</p>
          </div>
        )}
      </div>
    </span>
  );
};

export default MainRouteName;
