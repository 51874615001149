import React, { FunctionComponent } from 'react';
import styles from '../atoms.module.scss';

interface ISection {
  title: string;
  className?: any;
  style?: any;
}

const Section: FunctionComponent<ISection> = ({ title, children, className, style }) => {
  let styleCustom: any = {
    border: '1px solid rgba(93, 92, 92, 0.16)',
    borderRadius: '8px',
    padding: '16px',
    position: 'relative',
    paddingTop: '25px',
  };
  styleCustom = { ...styleCustom, ...style };

  return (
    <>
      <div style={styleCustom} className={className}>
        <div className={styles.inputSelectLabel}>{title}</div>
        {children}
      </div>
    </>
  );
};

export default Section;
