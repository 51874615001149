import React, { FunctionComponent } from 'react';
import styles from './atoms.module.scss';
import CSS from 'csstype';

interface IWhiteCard {
  mobileTransparent?: boolean;
  children: React.ReactNode;
  style?: CSS.Properties;
  padding?: boolean;
  marginTop?: boolean;
}

const WhiteCard: FunctionComponent<IWhiteCard> = ({
  style,
  children,
  mobileTransparent,
  padding = true,
  marginTop = false,
}) => {
  const stylesCustom: CSS.Properties = style !== undefined ? style : {};
  if (marginTop) {
    stylesCustom.marginTop = '24px';
  }
  return (
    <div
      className={`${styles.whiteCard} ${mobileTransparent ? styles.whiteCardMobileTransparent : null} ${
        padding ? null : styles.whiteCardPadding
      }`}
      style={stylesCustom}
    >
      {children}
    </div>
  );
};

export default WhiteCard;
