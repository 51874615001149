import React from 'react';
import ReactDOM from 'react-dom';

interface IHeaderButtons {
  children: React.ReactNode;
}

const HeaderButtons: React.FC<IHeaderButtons> = ({ children }) => {
  const HeaderButtonsEl = document.getElementById('header-buttons');
  return <>{HeaderButtonsEl ? ReactDOM.createPortal(children, HeaderButtonsEl) : null}</>;
};

export default HeaderButtons;
