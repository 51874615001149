import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateQuestion } from 'utils/models';
import { createQuestionState } from 'utils/states';
import { useHistory } from 'react-router-dom';
import { handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import QuestionsConnection from 'utils/connections/questions';
import Form from 'global/form/Form';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import ActionsContainer from 'global/atoms/ActionsContainer';
import { useDispatch } from 'react-redux';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IQuestionsAdd {}

const QuestionsAdd: FunctionComponent<IQuestionsAdd> = () => {
  const [data, setData] = useState<ICreateQuestion>(createQuestionState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    QuestionsConnection.getQuestionsFormAdd().then((data) => {
      setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    handleToast(QuestionsConnection.createQuestion(data), setErrors).then((response) =>
      history.push(`/datasets/questions/${response.data.questionId}/edit`),
    );
  };

  if (form === 'loading') return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Opis'}
            name={'description'}
            value={data.description}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            options={form.customers.options}
            disabled={false}
            placeholder={'Klient'}
            name={'customerId'}
            value={data.customerId}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            options={form.activityTypes.options}
            disabled={false}
            placeholder={'Rodzaj aktywności'}
            name={'activityTypeId'}
            value={data.activityTypeId}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            options={form.questionTypes.options}
            disabled={false}
            placeholder={'Typ pytania'}
            name={'questionTypeId'}
            value={data.questionTypeId}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default QuestionsAdd;
