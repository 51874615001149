import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
interface IHeading {
  toLeft?: React.ReactNode;
  toRight?: React.ReactNode;
  title?: string;
  subTitle?: string;
  margin?: string;
  classNames?: string;
}

const Heading: FunctionComponent<IHeading> = ({ toRight, toLeft, title, margin, subTitle, classNames }) => {
  let classNamesCustom = `container ${styles.container} `;
  if (classNames !== undefined) {
    classNamesCustom += classNames;
  }

  if (toLeft && toRight) {
    return (
      <div className={classNamesCustom} style={{ margin: margin }}>
        <div className={styles.wrapper}>{toLeft}</div>
        <div className={styles.wrapperRight}>{toRight}</div>
      </div>
    );
  }

  return (
    <div className={classNamesCustom} style={{ margin: margin }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          {title ? <span className={styles.title}>{title}</span> : null}
          {toRight ? <div className={styles.wrapperRight}>{toRight}</div> : null}
        </div>
        {subTitle !== undefined && subTitle !== '' && <span className={styles.subTitle}>{subTitle}</span>}
      </div>
    </div>
  );
};

export default Heading;
