import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateCustomer } from 'utils/models';
import { createCustomerState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import { getAddressFullName, handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import CustomersConnection from 'utils/connections/customers';
import Input from 'global/atoms/Input';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Form from 'global/form/Form';
import { useDispatch } from 'react-redux';
import Select from 'global/atoms/Select';
import Button from 'global/atoms/Button';
import Spinner from 'global/atoms/Spinner/Spinner';
import AddressesList from '../../../datasets/components/addressesList/AddressesList';
import styles from '../../../projects/components/editProject/styles.module.scss';
import Section from 'global/atoms/section/Section';

interface ICustomersEdit {}

const CustomersEdit: FunctionComponent<ICustomersEdit> = () => {
  const [data, setData] = useState<ICreateCustomer>(createCustomerState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const type: 'add' | 'edit' = id === undefined ? 'add' : 'edit';
  const [viewState, setViewState] = useState<'edit' | 'selectAddress'>('edit');
  const [address, setAddress] = useState<{ id: string; name: string }>({ id: '', name: '' });

  if (type === 'edit') {
    useEffect(() => {
      CustomersConnection.getCustomersFormEdit(id).then((response) => {
        setData({
          name: response.data.model.name,
          www: response.data.model.www,
          email: response.data.model.email,
          fax: response.data.model.fax,
          code: response.data.model.code,
          phone: response.data.model.phone,
          shortName: response.data.model.shortName,
          customerTypeId: response.data.model.customerType.id,
          addressId: response.data.model.address.id,
          nip: response.data.model.nip,
          repoDir: response.data.model.repoDir,
          ftpAddr: response.data.model.ftpAddr,
          ftpUser: response.data.model.ftpUser,
          ftpPass: response.data.model.ftpPass,
          ftpPort: response.data.model.ftpPort,
        });
        setForm(response.data);
        setAddress({
          id: response.data.model.address.id,
          name: getAddressFullName(response.data.model.address),
        });
        response.data?.header && dispatch({ type: 'SET_HEADER', payload: response.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      CustomersConnection.getCustomersFormAdd().then((response) => {
        setForm(response.data);
        response.data?.header && dispatch({ type: 'SET_HEADER', payload: response.data.header });
      });
    }, []);
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const dataSave = { ...data };
    dataSave.addressId = address.id;

    if (type === 'add') {
      handleToast(CustomersConnection.createCustomer(dataSave), setErrors).then(() => history.push('/admin/customers'));
      return true;
    }

    handleToast(CustomersConnection.updateCustomer(id, dataSave), setErrors).then(() =>
      history.push('/admin/customers'),
    );
  };

  const handleSetAddress = (addressData: { id: string; name: string }) => {
    setAddress(addressData);
    setViewState('edit');
  };

  const handleCloseAddressSelect = () => {
    setViewState('edit');
  };

  if (form === 'loading') return <Spinner />;

  if (viewState === 'selectAddress') {
    return (
      <AddressesList
        handleSetAddress={handleSetAddress}
        handleClose={handleCloseAddressSelect}
        selectedAddressId={address.id}
        selectedAddressName={address.name}
      />
    );
  }

  return (
    <div>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Typ'}
            name={'customerTypeId'}
            value={data.customerTypeId}
            options={form.customerTypes.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <div>
            <Input
              value={address.name}
              placeholder={'Adres'}
              type={'text'}
              name={'addressId'}
              disabled={true}
              errors={errors}
            />
            <Button
              type={'button'}
              onClick={() => setViewState('selectAddress')}
              style={{ width: 'auto', marginTop: '12px', marginBottom: '8px' }}
            >
              Przypisz adres
            </Button>
          </div>

          <Section title={'Dane ogólne'} className={styles.optionsSells}>
            <Input
              type={'text'}
              placeholder={'NIP'}
              name={'nip'}
              value={data.nip}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Email'}
              name={'email'}
              value={data.email}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Skrócona nazwa'}
              name={'shortName'}
              value={data.shortName}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Kod'}
              name={'code'}
              value={data.code}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Telefon'}
              name={'phone'}
              value={data.phone}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Fax'}
              name={'fax'}
              value={data.fax}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'WWW'}
              name={'www'}
              value={data.www}
              state={data}
              setState={setData}
              errors={errors}
            />
          </Section>

          <Section title={'FTP'} className={styles.optionsSells}>
            <Input
              type={'text'}
              placeholder={'Adres'}
              name={'ftpAddr'}
              value={data.ftpAddr}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'User'}
              name={'ftpUser'}
              value={data.ftpUser}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Hasło'}
              name={'ftpPass'}
              value={data.ftpPass}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Port'}
              name={'ftpPort'}
              value={data.ftpPort}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              type={'text'}
              placeholder={'Katalog'}
              name={'repoDir'}
              value={data.repoDir}
              state={data}
              setState={setData}
              errors={errors}
            />
          </Section>

          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </div>
  );
};

export default CustomersEdit;
