import React, { FunctionComponent, useEffect, useState } from 'react';
import SwitchButton from '../../../../global/atoms/switchButton/SwitchButton';
import SwitchButtonHorizontalGroup from '../../../../global/atoms/switchButton/SwitchButtonHorizontalGroup';
import { useHistory, useParams } from 'react-router-dom';
import styles from './styles.module.scss';
import Button from '../../../../global/atoms/Button';
import WarehouseConnection from '../../../../utils/connections/warehouse';
import WarehouseList from './WarehouseList';
import WarehouseUserList from './WarehouseUserList';
import WarehouseShiftList from './WarehouseShiftList';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import Spinner from '../../../../global/atoms/Spinner/Spinner';

const GLOBAL_LIST_BUTTON = 'defaultListButton';
const USER_LIST_BUTTON = 'userListButton';
const SHIFT_LIST_BUTTON = 'shiftListButton';

const WarehouseListHeader: FunctionComponent = () => {
  type viewType = 'defaultListButton' | 'userListButton' | 'shiftListButton';
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [selectedView, setSelectedView] = useState<viewType | null>(null);
  const [disabledElements, setDisabledElements] = useState<Array<string>>([]);

  useEffect(() => {
    WarehouseConnection.availabilityFilterForm(id).then((response) => {
      const disabledElements = response.data.disabledElements;
      setDisabledElements(disabledElements);
      const availableButtons = getAvailableButtons(disabledElements);
      if (availableButtons.length <= 0) {
        history.push('/');
      } else {
        setSelectedView(availableButtons[0]);
      }
    });
  }, []);

  const handleChangeListView = (view: viewType) => {
    setSelectedView(view);
  };

  const getAvailableButtons = (disabledElements: Array<string>): Array<viewType> => {
    const allButtons: Array<viewType> = [GLOBAL_LIST_BUTTON, USER_LIST_BUTTON, SHIFT_LIST_BUTTON];
    return allButtons.filter((item: viewType) => {
      return !disabledElements.includes(item);
    });
  };

  if (selectedView === null) return <Spinner />;

  return (
    <>
      <div className={styles.headerContainer}>
        <SwitchButtonHorizontalGroup>
          {!disabledElements.includes(GLOBAL_LIST_BUTTON) && (
            <SwitchButton
              onClick={handleChangeListView.bind(null, GLOBAL_LIST_BUTTON)}
              isChecked={selectedView === GLOBAL_LIST_BUTTON}
            >
              Magazyn
            </SwitchButton>
          )}
          {!disabledElements.includes(USER_LIST_BUTTON) && (
            <SwitchButton
              onClick={handleChangeListView.bind(null, USER_LIST_BUTTON)}
              isChecked={selectedView === USER_LIST_BUTTON}
            >
              Użytkownik
            </SwitchButton>
          )}
          {!disabledElements.includes(SHIFT_LIST_BUTTON) && (
            <SwitchButton
              onClick={handleChangeListView.bind(null, SHIFT_LIST_BUTTON)}
              isChecked={selectedView === SHIFT_LIST_BUTTON}
            >
              Przesunięcia
            </SwitchButton>
          )}
        </SwitchButtonHorizontalGroup>
        <Button onClick={() => history.push(`/projects/${id}/warehouse/add`)}>Operacje</Button>
      </div>
      <SeparatorEmpty />
      {selectedView === GLOBAL_LIST_BUTTON && <WarehouseList />}
      {selectedView === USER_LIST_BUTTON && <WarehouseUserList />}
      {selectedView === SHIFT_LIST_BUTTON && <WarehouseShiftList />}
    </>
  );
};

export default WarehouseListHeader;
