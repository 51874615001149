import projectsRoutes from './projects';
import adminRoutes from './admin';
import datasetsRoutes from './datasets';
import productsRoutes from './products';
import superadminRoutes from './superadmin';
import visitReviewRoutes from './visitReview';

const routes = [projectsRoutes, adminRoutes, datasetsRoutes, productsRoutes, superadminRoutes, visitReviewRoutes];

export default routes.flat();
