import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateRole } from 'utils/models';
import { createRoleState } from 'utils/states';
import { useHistory } from 'react-router-dom';
import { handleToast } from 'utils/helpers';
import RolesConnection from 'utils/connections/roles';
import { useDispatch } from 'react-redux';
import RoleUpdate from 'global/form/RoleUpdate';
import Spinner from 'global/atoms/Spinner/Spinner';

interface ICreateRoleComponent {}

const CreateRole: FunctionComponent<ICreateRoleComponent> = () => {
  const [data, setData] = useState<ICreateRole>(createRoleState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    RolesConnection.getFormAddRole().then((data) => {
      setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(RolesConnection.createRole(data), setErrors).then(() => history.push('/admin/roles'));
  };

  if (form === 'loading') return <Spinner />;

  return <RoleUpdate handleSubmit={handleSubmit} data={data} form={form} setData={setData} errors={errors} />;
};

export default CreateRole;
