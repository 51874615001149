import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ActivitiesConnection from 'utils/connections/activities';
import Button from 'global/atoms/Button';
import WhiteCard from 'global/atoms/WhiteCard';
import ActivitiesListHeader from './ActivitiesListHeader';
import ActivitiesListActivity from './ActivitiesListActivity';
import { ITaskActivity } from 'utils/models';
import { useDispatch } from 'react-redux';
import Heading from 'global/heading/Heading';
import Spinner from 'global/atoms/Spinner/Spinner';

interface ITask {}

const Task: FunctionComponent<ITask> = () => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id, taskId } = useParams<{ id: string; taskId: string }>();

  if (taskId === 'add') return null;

  useEffect(() => {
    ActivitiesConnection.getActivitiesForTask(id, taskId).then((data) => {
      setLoading(false);
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  if (loading) return <Spinner />;

  if (data === null) return null;

  return (
    <>
      <Heading
        toRight={
          <>
            <Button onClick={() => history.push(`/projects/${id}/tasks/${taskId}/activities-edit`)}>
              Edytuj aktywności
            </Button>
          </>
        }
      />
      <WhiteCard mobileTransparent={true} style={{ marginTop: '16px' }}>
        <ActivitiesListHeader />
        {data.activities.data.map((item: ITaskActivity) => (
          <ActivitiesListActivity
            description={item.description}
            projectId={id}
            taskId={taskId}
            activityId={item.activityId}
            type={item.activityTypeName}
            items={item.itemsCount}
            locations={item.locationsCount}
            key={item.activityId}
            name={item.name}
            activity={item}
          />
        ))}
      </WhiteCard>
    </>
  );
};

export default Task;
