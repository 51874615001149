import React, { FunctionComponent, useState } from 'react';
import AddressesConnection from '../../../../utils/connections/addresses';
import SelectAsync from '../../../../global/atoms/select/SelectAsync';
import Input from '../../../../global/atoms/Input';
import Button from '../../../../global/atoms/Button';

interface ISelectStreet {
  errors: any;
  state: any;
  setState: any;
  setData: any;
  data: any;
}

const SelectStreet: FunctionComponent<ISelectStreet> = ({ errors, state, setState, setData, data }) => {
  const [localState, setLocalState] = useState<{
    newInputVisible: boolean;
  }>({
    newInputVisible: false,
  });
  const handleAjaxOptions = (inputValue: any, callback: any) => {
    clearTimeout(state.timeoutStreetId);
    const timeoutId = setTimeout(() => {
      AddressesConnection.findStreet({
        streetName: inputValue,
        cityId: data.city,
      }).then((result) => {
        const options = result.data.map((item: any) => ({ value: item.id, label: item.name, data: item }));
        callback(options);
      });
    }, 400);

    setState({ ...state, ...{ timeoutStreetId: timeoutId } });
  };

  const handleChange = (object: any) => {
    setState({
      ...state,
      ...{
        street: object,
        postcode: {
          label: object.data.postcodeName,
          value: object.data.postcodeId,
        },
      },
    });
    setData({
      ...data,
      ...{
        country: object.data.countryId,
        voivodeship: object.data.voivodeshipId,
        district: object.data.districtId,
        city: object.data.cityId,
        community: object.data.communityId,
      },
    });
  };

  const handleShowNewStreetName = () => {
    setState({ ...state, ...{ newStreetName: '' } });
    setLocalState({ ...localState, ...{ newInputVisible: !localState.newInputVisible } });
  };

  return (
    <>
      {!localState.newInputVisible && (
        <SelectAsync
          onChange={handleChange}
          value={state.street}
          error={errors['street']}
          placeholder={'Ulica'}
          loadOptionsAjax={handleAjaxOptions}
        />
      )}
      <div style={{ display: 'block' }}>
        {localState.newInputVisible && (
          <Input
            type={'text'}
            placeholder={'Nowa ulica'}
            name={'newStreetName'}
            value={state.newStreetName}
            state={state}
            setState={setState}
            errors={errors}
          />
        )}
        <Button onClick={handleShowNewStreetName}>{localState.newInputVisible ? 'Anuluj' : 'Dodaj nowy'}</Button>
      </div>
    </>
  );
};

export default SelectStreet;
