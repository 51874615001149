import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import { Col, Row } from 'react-grid-system';
import Section from '../../../../global/atoms/section/Section';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';
import Checkbox from '../../../../global/atoms/Checkbox';
import styles from './styles.module.scss';
import { deepClone, handleToast } from '../../../../utils/helpers';
import Input from '../../../../global/atoms/Input';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import { useHistory, useParams } from 'react-router-dom';
import VisitsReportConnection from '../../../../utils/connections/visitsReport';
import { unstable_batchedUpdates } from 'react-dom';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import HeaderButtons from '../../../../global/atoms/headerButtons/HeaderButtons';
import { confirmModal } from '../../../../redux/store';
import Button from '../../../../global/atoms/Button';

export interface IVisitsReportTemplateData {
  name: string;
  data: any;
  projectId: string;
}

const getSellerOptions = () => {
  return [
    {
      name: 'name',
      label: 'Nazwa',
      active: false,
    },
    {
      name: 'street',
      label: 'Ulica',
      active: false,
    },
    {
      name: 'houseNumber',
      label: 'Numer budynku',
      active: false,
    },
    {
      name: 'apartmentNumber',
      label: 'Numer mieszkania',
      active: false,
    },
    {
      name: 'postcode',
      label: 'Kod pocztowy',
      active: false,
    },
    {
      name: 'city',
      label: 'Miasto',
      active: false,
    },
    {
      name: 'community',
      label: 'Gmina',
      active: false,
    },
    {
      name: 'district',
      label: 'Powiat',
      active: false,
    },
    {
      name: 'voivodeship',
      label: 'Województwo',
      active: false,
    },
  ];
};

const columnsData = [
  {
    label: 'Dane wizyty',
    name: 'visit',
    active: false,
    options: [
      {
        name: 'ordinalNumber',
        label: 'Liczba porządkowa',
        active: false,
      },
      {
        name: 'id',
        label: 'Id wizyty',
        active: false,
      },
      {
        name: 'user',
        label: 'Wykonawca',
        active: false,
      },
      {
        name: 'supervisor',
        label: 'Supervisor',
        active: false,
      },
      {
        name: 'status',
        label: 'Status',
        active: false,
      },
      {
        name: 'week',
        label: 'Tydzień',
        active: false,
      },
      {
        name: 'month',
        label: 'Miesiąc',
        active: false,
      },
      {
        name: 'visitSendDate',
        label: 'Data wysłania z aplikacji',
        active: false,
      },
      {
        name: 'visitStartDate',
        label: 'Data otwarcia w aplikacji',
        active: false,
      },
      {
        name: 'visitEndDate',
        label: 'Data zamknięcia w aplikacji',
        active: false,
      },
      {
        name: 'visitCreatedDate',
        label: 'Data utworzenia ankiety',
        active: false,
      },
      {
        name: 'visitDate',
        label: 'Data wizyty',
        active: false,
      },
      {
        name: 'visitFillDate',
        label: 'Data wypełnienia',
        active: false,
      },
    ],
  },
  {
    label: 'Dane punktu',
    name: 'location',
    active: false,
    options: [
      {
        name: 'id',
        label: 'Id lokalizacji',
        active: false,
      },
      {
        name: 'code',
        label: 'Kod lokalizacji',
        active: false,
      },
      {
        name: 'nip',
        label: 'Nip',
        active: false,
      },
      ...getSellerOptions(),
    ],
  },
  {
    label: 'Dane nabywcy',
    name: 'payer',
    active: false,
    options: [
      {
        name: 'nip',
        label: 'Nip',
        active: false,
      },
      ...getSellerOptions(),
    ],
  },
  {
    label: 'Dane odbiorcy',
    name: 'recipient',
    active: false,
    options: getSellerOptions(),
  },
  {
    label: 'Zamówienie',
    name: 'order',
    active: false,
    options: [
      {
        name: 'documentNumber',
        label: 'Numer dokumentu',
        active: false,
      },
      {
        name: 'customer',
        label: 'Dystrybutor',
        active: false,
      },
      {
        name: 'user',
        label: 'Wystawił',
        active: false,
      },
      {
        name: 'documentDate',
        label: 'Data dokumentu',
        active: false,
      },
      {
        name: 'deliveryDate',
        label: 'Data dostarczenia',
        active: false,
      },
    ],
  },
  {
    label: 'Faktura',
    name: 'document',
    active: false,
    options: [
      {
        name: 'documentNumber',
        label: 'Numer dokumentu',
        active: false,
      },
      {
        name: 'customer',
        label: 'Dystrybutor',
        active: false,
      },
      {
        name: 'user',
        label: 'Wystawił',
        active: false,
      },
      {
        name: 'documentDate',
        label: 'Data dokumentu',
        active: false,
      },
      {
        name: 'deliveryDate',
        label: 'Data dostarczenia',
        active: false,
      },
      {
        name: 'paymentDate',
        label: 'Data płatności',
        active: false,
      },
      {
        name: 'status',
        label: 'Status',
        active: false,
      },
      {
        name: 'paymentType',
        label: 'Rodzaj płatności',
        active: false,
      },
      {
        name: 'isPaid',
        label: 'Zapłacona',
        active: false,
      },
      {
        name: 'countPrinted',
        label: 'Liczba wydrukowanych',
        active: false,
      },
      {
        name: 'priceAmountTotal',
        label: 'Suma netto',
        active: false,
      },
      {
        name: 'priceTaxTotal',
        label: 'Suma vat',
        active: false,
      },
      {
        name: 'priceAmountGrossTotal',
        label: 'Suma brutto',
        active: false,
      },
    ],
  },
  {
    label: 'Wydanie',
    name: 'storeDocument',
    active: false,
    options: [
      {
        name: 'documentNumber',
        label: 'Numer dokumentu',
        active: false,
      },
      {
        name: 'user',
        label: 'Wystawił',
        active: false,
      },
      {
        name: 'documentDate',
        label: 'Data dokumentu',
        active: false,
      },
      {
        name: 'priceAmountTotal',
        label: 'Suma netto',
        active: false,
      },
      {
        name: 'priceTaxTotal',
        label: 'Suma vat',
        active: false,
      },
      {
        name: 'priceAmountGrossTotal',
        label: 'Suma brutto',
        active: false,
      },
    ],
  },
];

type columnsDataType = Array<{
  label: string;
  name: string;
  active: boolean;
  options: Array<{
    name: string;
    label: string;
    active: boolean;
  }>;
}>;

const VisitsReportTemplateEdit: FunctionComponent = () => {
  const [state, setState] = useState<{
    templateName: string;
  }>({
    templateName: '',
  });
  const [data, setData] = useState<Array<any>>(columnsData);
  const [form, setForm] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedTasks, setSelectedTasks] = useState<Array<string>>([]);

  const { id, templateId } = useParams<{ id: string; templateId: string }>();
  const history = useHistory();
  const redirectUrl = `/projects/${id}/reports`;

  const getMergedColumnsData = (columnsDataLoaded: columnsDataType) => {
    const columnsDataClone: columnsDataType = deepClone(data);
    columnsDataClone.map((item, index) => {
      const itemFound = columnsDataLoaded.find((loadedItem) => loadedItem.name === item.name);
      if (itemFound !== undefined) {
        columnsDataClone[index].active = itemFound.active;
        item.options.map((option, optionIndex) => {
          const optionFound = itemFound.options.find((loadedOption) => loadedOption.name === option.name);
          if (optionFound !== undefined) {
            columnsDataClone[index].options[optionIndex].active = optionFound.active;
          }
        });
      }
    });

    return columnsDataClone;
  };

  if (templateId !== undefined) {
    useEffect(() => {
      Promise.all([
        handleToast(VisitsReportConnection.getGenerateReportViewData(id)),
        handleToast(VisitsReportConnection.getTemplateById(templateId)),
      ]).then((responses) => {
        setForm(responses[0].data);

        const data = responses[1].data;
        // force one render with multiple state changes
        unstable_batchedUpdates(() => {
          setData(getMergedColumnsData(data.data.columns));
          setSelectedTasks(data.data.tasks);

          setState({
            templateName: data.name,
          });
          setLoading(false);
        });
      });
    }, []);
  } else {
    useEffect(() => {
      handleToast(VisitsReportConnection.getGenerateReportViewData(id)).then((response) => {
        setForm(response.data);

        const allTasks: Array<string> = [];
        response.data.tasks.options.map((item: any) => {
          allTasks.push(item.id);
        });
        setSelectedTasks(allTasks);

        makeAllOptionsActive();
        setLoading(false);
      });
    }, []);
  }

  const makeAllOptionsActive = () => {
    const optionsClone = deepClone(columnsData);
    optionsClone.map((item: any, index: number) => {
      optionsClone[index].active = true;
      optionsClone[index].options.map((item2: any, index2: number) => {
        optionsClone[index].options[index2].active = true;
      });
    });
    setData(optionsClone);
  };

  const handleToggleSection = (event: any) => {
    const index = event.currentTarget.dataset.id;
    const dataClone = deepClone(data);
    const nextActiveState = !dataClone[index].active;
    dataClone[index].active = nextActiveState;

    if (nextActiveState) {
      dataClone[index].options.map((item: any, indexLoop: number) => {
        dataClone[index].options[indexLoop].active = true;
      });
    } else {
      dataClone[index].options.map((item: any, indexLoop: number) => {
        dataClone[index].options[indexLoop].active = false;
      });
    }

    setData(dataClone);
  };

  const handleToggleOption = (event: any) => {
    const params = JSON.parse(event.currentTarget.dataset.id);
    const dataClone = deepClone(data);
    dataClone[params.parentId].options[params.optionId].active = !dataClone[params.parentId].options[params.optionId]
      .active;
    setData(dataClone);
  };

  const handleSave = () => {
    if (state.templateName === '') {
      return false;
    }

    const mergedData = {
      columns: data,
      tasks: selectedTasks,
    };

    const dataPost = {
      projectId: id,
      name: state.templateName,
      data: mergedData,
    };

    if (templateId !== undefined) {
      handleToast(VisitsReportConnection.updateTemplate(templateId, dataPost)).then(() => {
        history.push(redirectUrl);
      });
      return true;
    }

    handleToast(VisitsReportConnection.saveTemplate(dataPost)).then(() => {
      history.push(redirectUrl);
    });
  };

  const handleSelectTask = (event: any) => {
    const index = event.currentTarget.dataset.id;
    const taskId = form.tasks.options[index].id;
    let selectedTasksClone = [...selectedTasks];
    if (selectedTasksClone.includes(taskId)) {
      selectedTasksClone = selectedTasksClone.filter((taskIdLoop) => taskIdLoop !== taskId);
    } else {
      selectedTasksClone.push(taskId);
    }

    setSelectedTasks(selectedTasksClone);
  };

  if (loading) return <Spinner />;

  const handleDeleteTemplate = () => {
    handleToast(VisitsReportConnection.deleteTemplate(templateId)).then(() => {
      history.push(redirectUrl);
    });
  };

  return (
    <>
      <HeaderButtons>
        {templateId !== undefined && (
          <Button onClick={confirmModal('Czy na pewno chcesz usunąć ten szablon?', handleDeleteTemplate)}>Usuń</Button>
        )}
      </HeaderButtons>
      <WhiteCard padding={true}>
        <Row>
          <Col xs={12}>
            <Input
              name={'templateName'}
              value={state.templateName}
              placeholder={'Nazwa szablonu'}
              setState={setState}
              state={state}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <Row>
          {data.map((item, index) => {
            return (
              <Col xs={3} key={index} style={{ marginBottom: '30px' }}>
                <Section title={item.label} className={styles.sectionContainer}>
                  <div className={!item.active ? styles.sectionDisabled : ''} />
                  <Checkbox
                    style={{ margin: 0 }}
                    className={styles.topRightCornerCheckbox}
                    isChecked={item.active}
                    onClick={handleToggleSection}
                    id={index}
                  />
                  {item.options.map((option: any, indexOption: any) => {
                    const dataString = JSON.stringify({
                      parentId: index,
                      optionId: indexOption,
                    });
                    return (
                      <CheckBox
                        id={dataString}
                        key={index + ' ' + indexOption}
                        isChecked={option.active}
                        onClick={handleToggleOption}
                      >
                        {option.label}
                      </CheckBox>
                    );
                  })}
                </Section>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col xs={12}>
            <Section title={'Zadania'}>
              {form.tasks.options.map((item: any, index: any) => {
                return (
                  <CheckBox
                    id={index}
                    key={index}
                    isChecked={selectedTasks.includes(item.id)}
                    onClick={handleSelectTask}
                  >
                    {item.name}
                  </CheckBox>
                );
              })}
            </Section>
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <ActionsContainer>
          <ButtonSubmit onClick={handleSave} />
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default VisitsReportTemplateEdit;
