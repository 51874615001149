import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ProjectsConnection from 'utils/connections/projects';
import WhiteCard from 'global/atoms/WhiteCard';
import RoleUsers from './access/RoleUsers';
import { handleToast } from 'utils/helpers';
import Button from 'global/atoms/Button';
import Spinner from 'global/atoms/Spinner/Spinner';
import AlertInfo from '../../../../global/atoms/alert/AlertInfo';

interface IAccess {}

const Access: FunctionComponent<IAccess> = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any | null>(null);
  const history = useHistory();

  useEffect(() => {
    ProjectsConnection.getAssignedRoles(id).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  }, []);

  const handleSubmit = () => {
    handleToast(
      ProjectsConnection.assignUsersToProject(id, {
        roles: data.projectRoles.data.map((item: any) => ({
          roleId: item.roleId,
          assigned: item.users.assigned.map((assigned: any) => ({ id: assigned.id })),
          unassigned: item.users.unassigned.map((unassigned: any) => ({ id: unassigned.id })),
        })),
      }),
    ).then(() => history.push(`/projects/${id}/edit/3`));
  };

  //TODO: clean code for this component and subcomponents

  if (loading) return <Spinner />;

  if (data === null) return null;

  if (data.projectRoles.data.length <= 0) {
    return (
      <WhiteCard padding={true}>
        <AlertInfo>
          Musisz ustawić role projektowe w zakładce &quot;Dane ogólne&quot; aby mieć dostęp do tych ustawień.
        </AlertInfo>
      </WhiteCard>
    );
  }

  return (
    <>
      <WhiteCard padding={true}>
        {data.projectRoles.data.map((item: any) => (
          <RoleUsers
            key={item.roleId}
            roleId={item.roleId}
            roleName={item.roleName}
            assigned={item.users.assigned}
            unassigned={item.users.unassigned}
            setState={setData}
          />
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '24px' }}>
          <Button onClick={handleSubmit}>Zapisz</Button>
        </div>
      </WhiteCard>
    </>
  );
};

export default Access;
