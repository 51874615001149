import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import Button from '../../../../global/atoms/Button';
import Input from '../../../../global/atoms/Input';
import Select from '../../../../global/atoms/Select';
import ButtonOutlined from '../../../../global/atoms/ButtonOutlined';
import Checkbox from '../../../../global/atoms/Checkbox';
import commonStyles from '../../../../global/common.module.scss';
import { deepClone, questionMapper } from '../../../../utils/helpers';
import SeparatorLine from '../../../../global/atoms/separators/SeparatorLine';
import { getValue } from '../visits/visitsEdit/VisitsEdit';

interface IQuestionForm {
  question: any;
  assigned: Array<any>;
  removePicked: () => void;
  setForm: Dispatch<SetStateAction<any>>;
  defaultValueEdit?: boolean;
  type?: 'default' | 'project';
}

const QuestionForm: FunctionComponent<IQuestionForm> = ({
  question,
  assigned,
  removePicked,
  setForm,
  defaultValueEdit = false,
  type = 'default',
}) => {
  const [data, setData] = useState<any>({
    maxValue: question.maxValue,
    minValue: question.minValue,
    pointsValue: question.pointsValue,
    isRequired: question.isRequired,
    dependOnQuestion: null,
    isEdit: question.isEdit,
  });
  const [errors, setErrors] = useState<Array<string>>([]);
  const [defaultValues, setDefaultValues] = useState<any>({});

  useEffect(() => {
    setData({
      maxValue: question.maxValue,
      minValue: question.minValue,
      pointsValue: question.pointsValue,
      isRequired: question.isRequired,
      dependOnQuestion: question.dependOnQuestion,
      isEdit: question.isEdit,
    });

    const defaultValuesInit = {
      [question.refQuestionId]: getValue(question),
    };
    setDefaultValues(defaultValuesInit);
  }, [question.id]);

  const getSelectedValuesData = (): Array<any> => {
    const selectedValue = defaultValues[question.refQuestionId];
    const values = [];

    if (question.questionType === 'DICTIONARY_MULTIPLE_ANSWERS_QUESTION' && selectedValue !== null) {
      selectedValue.map((data: any) => {
        if (data.value !== '') {
          values.push({
            refQuestionAnswerId: data.refQuestionAnswerId,
            value: data.value,
          });
        }
      });
    } else if (
      question.questionType === 'PHOTO_QUESTION' &&
      Array.isArray(selectedValue?.url) &&
      selectedValue !== null
    ) {
      selectedValue.url.map((item: any) => {
        values.push(item);
      });
    } else {
      values.push({
        refQuestionAnswerId: question.values[0] !== undefined ? question.values[0].refQuestionAnswerId : '',
        value: selectedValue,
      });
    }

    return values;
  };

  const handleSaveQuestion = () => {
    const tmp = [];

    if (question.questionType === 'PHOTO_QUESTION') {
      if (data.isRequired && parseInt(data.minValue) === 0)
        tmp.push('Wartość minimalna nie może być równa 0 jeżeli pytanie jest wymagane');
      if (parseInt(data.minValue) > parseInt(data.maxValue))
        tmp.push('Wartość minimalna nie może być większa od wartości maksymalnej');
    }

    if (tmp.length) {
      setErrors(tmp);
      return;
    }

    let valuesUpdate: Array<any> = [];
    if (defaultValueEdit) {
      valuesUpdate = getSelectedValuesData();
    }
    setForm((state: any) => ({
      ...state,
      assignedQuestions: {
        ...state.assignedQuestions,
        data: state.assignedQuestions.data.map((item: any) => {
          if (item.id === question.id)
            return {
              ...item,
              ...data,
              values: valuesUpdate,
            };
          else return item;
        }),
      },
    }));
    removePicked();
  };

  const getDependQuestionObject = () => {
    return assigned.filter((dataLoop) => {
      return dataLoop.id === data.dependOnQuestion?.id;
    })[0];
  };

  const handleChangeDependQuestionAnswers = (answerId: string) => {
    const dependOnQuestionClone = deepClone(data.dependOnQuestion);
    const answersFound = dependOnQuestionClone.options.filter((data: any) => {
      return data.id === answerId;
    });

    if (answersFound.length > 0) {
      dependOnQuestionClone.options = dependOnQuestionClone.options.filter((data: any) => {
        return data.id !== answerId;
      });
      setData({ ...data, ...{ dependOnQuestion: dependOnQuestionClone } });
      return true;
    }

    dependOnQuestionClone.options.push({ id: answerId });
    setData({ ...data, ...{ dependOnQuestion: dependOnQuestionClone } });
  };

  const dependAnswerIsChecked = (answerId: string) => {
    return (
      data.dependOnQuestion.options.filter((data: any) => {
        return data.id === answerId;
      }).length > 0
    );
  };

  const handleChangeDependOnQuestion = (option: any) => {
    const dependOnQuestion = deepClone(question.dependOnQuestion);
    let dependOptions = question.dependOnQuestion.options;
    const dependOn = assigned.filter((dataLoop) => {
      return dataLoop.id === option.value;
    })[0];

    if (dependOptions.length <= 0 && dependOn?.options?.length > 0) {
      dependOptions = [];
      dependOn.options.map((data: any) => {
        if (data.isAnswerToDepend) {
          dependOptions.push({ id: data.id });
        }
      });
    }

    dependOnQuestion.options = dependOptions;
    dependOnQuestion.id = option.value;
    setData({ ...data, ...{ dependOnQuestion: dependOnQuestion } });
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: 'white',
        border: '1px solid rgba(93,92,92,0.16)',
        borderRadius: '8px',
        padding: '16px 12px 12px',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '234px',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          gridRowGap: '16px',
          marginBottom: '16px',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Checkbox
            isChecked={data.isRequired}
            isMixed={false}
            onClick={() => setData((state: any) => ({ ...state, isRequired: !state.isRequired }))}
          />
          <span>Wymagane pytanie</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Checkbox
            isChecked={data.isEdit}
            isMixed={false}
            onClick={() => setData((state: any) => ({ ...state, isEdit: !state.isEdit }))}
          />
          <span>Możliwość edycji</span>
        </div>

        {type !== 'project' && (
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between', gridColumnGap: '12px' }}>
              {(question.questionType.toUpperCase() === 'PHOTO_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'INTEGER_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'DECIMAL_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'DICTIONARY_MULTIPLE_ANSWERS_QUESTION'.toUpperCase()) && (
                <Input
                  type={'number'}
                  placeholder={'Min'}
                  name={'minValue'}
                  value={data.minValue}
                  state={data}
                  setState={setData}
                />
              )}
              {(question.questionType.toUpperCase() === 'PHOTO_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'INTEGER_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'DECIMAL_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'TEXT_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'TEXTAREA_QUESTION'.toUpperCase() ||
                question.questionType.toUpperCase() === 'DICTIONARY_MULTIPLE_ANSWERS_QUESTION'.toUpperCase()) && (
                <Input
                  type={'number'}
                  placeholder={'Max'}
                  name={'maxValue'}
                  value={data.maxValue}
                  state={data}
                  setState={setData}
                />
              )}
              <Input
                type={'number'}
                placeholder={'Punkty'}
                name={'pointsValue'}
                value={data.pointsValue}
                state={data}
                setState={setData}
              />
            </div>
            <Select
              placeholder={'Pokazuj zależnie od odpowiedzi na pytanie'}
              name={'dependQuestionId'}
              value={data.dependOnQuestion?.id}
              options={[
                { id: '', name: 'Brak nadrzędnego pytania' },
                ...assigned
                  .filter((item) => item.id !== question.id)
                  .map((item) => ({ id: item.id, name: item.questionName })),
              ]}
              onChange={handleChangeDependOnQuestion}
            />
            {getDependQuestionObject() !== undefined &&
              (getDependQuestionObject().questionType.toUpperCase() === 'DICTIONARY_QUESTION'.toUpperCase() ||
                getDependQuestionObject().questionType.toUpperCase() ===
                  'DICTIONARY_MULTIPLE_ANSWERS_QUESTION'.toUpperCase()) && (
                <>
                  <section style={{ display: 'block', width: '100%' }}>
                    <div>
                      {getDependQuestionObject().options.map((data: any, index: number) => {
                        return (
                          <>
                            <div className={commonStyles.dragAndDropElement}>
                              <span>{data.name}</span>
                              <Checkbox
                                isMixed={false}
                                key={index}
                                isChecked={dependAnswerIsChecked(data.id)}
                                onClick={handleChangeDependQuestionAnswers.bind(null, data.id)}
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                  </section>
                </>
              )}

            {defaultValueEdit && (
              <>
                <SeparatorLine size={2} />
                {questionMapper(defaultValues, setDefaultValues, question)}
              </>
            )}

            {errors.map((item, idx: number) => (
              <span style={{ color: 'red', fontSize: '13px' }} key={idx}>
                {item}
              </span>
            ))}
          </>
        )}
      </div>
      <ActionsContainer>
        <Button onClick={handleSaveQuestion}>Akceptuj</Button>
        <ButtonOutlined onClick={removePicked}>Anuluj</ButtonOutlined>
      </ActionsContainer>
    </div>
  );
};

export default QuestionForm;
