import React, { FunctionComponent } from 'react';
import styles from '../atoms.module.scss';
import arrowLeft from '../../../assets/svg/arrow_left.svg';
import arrowRight from '../../../assets/svg/arrow_right.svg';

interface ISelectMonthProps {
  selected: Date;
  onChange: (date: Date) => void;
}

export const MONTHS: any = {
  0: 'Styczeń',
  1: 'Luty',
  2: 'Marzec',
  3: 'Kwiecień',
  4: 'Maj',
  5: 'Czerwiec',
  6: 'Lipiec',
  7: 'Sierpień',
  8: 'Wrzesień',
  9: 'Październik',
  10: 'Listopad',
  11: 'Grudzień',
};

const SelectMonth: FunctionComponent<ISelectMonthProps> = ({ onChange, selected }) => {
  const handleClickNext = () => {
    const newDate = addMonths(selected, 1);
    onChange(newDate);
  };

  const handleClickPrevious = () => {
    const newDate = addMonths(selected, -1);
    onChange(newDate);
  };

  const addMonths = (date: Date, months: number) => {
    const d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  };

  return (
    <div className={styles.selectMonth}>
      <img onClick={handleClickPrevious} className={styles.selectMonthButton} alt={'Poprzedni'} src={arrowLeft} />
      <span>
        {MONTHS[selected.getMonth()]} {selected.getFullYear()}
      </span>
      <img onClick={handleClickNext} className={styles.selectMonthButton} alt={'Następny'} src={arrowRight} />
    </div>
  );
};

export default SelectMonth;
