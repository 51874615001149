import React, { FunctionComponent, useEffect, useState } from 'react';
import SLAReviewForm from './Form/SLAReviewForm';
import KPIReviewForm from './Form/KPIReviewForm';
import Section from '../../../../global/atoms/section/Section';
import Textarea from '../../../../global/atoms/Textarea';
import styles from '../styles.module.scss';
import { IPhotoReviewErrors, IPhotoReviewFeedback } from '../../../../utils/models';
import { deepClone, getUserId } from '../../../../utils/helpers';

interface IReviewForm {
  identifier: string;
  statesRef: React.MutableRefObject<IPhotoReviewFeedback[]>;
  errors: IPhotoReviewErrors;
  readOnly?: boolean;
}

const ReviewsForm: FunctionComponent<IReviewForm> = (props) => {
  if (props.identifier === '') return <></>;
  if (props.statesRef.current.length === 0) return <></>;

  const [noteStates, setNoteStates] = useState({
    note_KPI: '',
    note_SLA: '',
  });

  useEffect(() => {
    const tmp = deepClone(noteStates);
    props.statesRef.current.forEach((f) => {
      if (f.feedbackType === 'KPI') tmp.note_KPI = f.note;
      if (f.feedbackType === 'SLA') tmp.note_SLA = f.note;
    });
    setNoteStates(tmp);
  }, []);

  useEffect(() => {
    props.statesRef.current.forEach((f, index) => {
      let noteVal = '';
      switch (f.feedbackType) {
        case 'KPI':
          noteVal = noteStates.note_KPI;
          break;
        case 'SLA':
          noteVal = noteStates.note_SLA;
          break;
      }
      props.statesRef.current[index].note = noteVal;
    });
  }, [noteStates]);

  return (
    <div style={props.readOnly ? { opacity: 0.5 } : {}}>
      {props.statesRef.current.map((feedback, index) => {
        let label = 'Twoja ocena';
        const isEdit = feedback.id !== '';

        if (isEdit) label += '*';

        if (feedback.reviewer && feedback.reviewer.user.id !== getUserId()) {
          if (feedback.reviewer.user) label = feedback.reviewer.user.fullName;
          if (feedback.reviewer.role.roleName) {
            label += ` (${feedback.reviewer.role.roleName})`;
          }
        }

        return (
          <Section key={`reviewFormSection_${index}`} title={label} style={{ marginTop: '25px' }}>
            <div className={`${styles.reviewTypeWatermark} ${isEdit && styles.active}`}>{feedback.feedbackType}</div>

            {feedback.feedbackType === 'SLA' && (
              <SLAReviewForm
                key={`sla_form_${props.identifier}`}
                statesRef={props.statesRef}
                stateIndex={index}
                identifier={props.identifier}
                errors={props.errors.SLA}
                readOnly={props.readOnly}
              />
            )}

            {feedback.feedbackType === 'KPI' && (
              <KPIReviewForm
                key={`kpi_form_${props.identifier}`}
                statesRef={props.statesRef}
                stateIndex={index}
                identifier={props.identifier}
                errors={props.errors.KPI}
                readOnly={props.readOnly}
              />
            )}
            {!props.readOnly && (
              <div style={{ minHeight: '145px' }}>
                <Textarea
                  max={256}
                  name={`note_${feedback.feedbackType}`}
                  placeholder={'Komentarz'}
                  state={noteStates}
                  setState={setNoteStates}
                  value={feedback.feedbackType === 'SLA' ? noteStates.note_SLA : noteStates.note_KPI}
                  errors={feedback.feedbackType === 'SLA' ? props.errors.SLA : props.errors.SLA}
                />
              </div>
            )}
            {props.readOnly && feedback.note && (
              <div className={styles.readOnlyComment}>Komentarz: {feedback.note}</div>
            )}
          </Section>
        );
      })}
    </div>
  );
};

export default ReviewsForm;
