import React, { FunctionComponent, useState } from 'react';
import styles from '../editProject/access/styles.module.scss';
import left from '../../../../assets/svg/left_big.svg';
import right from '../../../../assets/svg/right_big.svg';
import ItemsList from './ItemsList';
import Heading from '../../../../global/heading/Heading';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import { arrayMove } from 'react-sortable-hoc';

interface IItems {
  activityId: string;
  setForm: any;
  unassigned: any;
  assigned: any;
  all: boolean;
}

const Items: FunctionComponent<IItems> = ({ assigned, unassigned, setForm, all }) => {
  const [editView] = useState<boolean>(true);
  const [checked, setChecked] = useState<{ assigned: Array<string>; unassigned: Array<string> }>({
    assigned: [],
    unassigned: [],
  });

  const handleToggleAssigned = (event: any) => {
      if (checked.assigned.includes(event.currentTarget.dataset.id)) {
        setChecked({
          ...checked,
          assigned: checked.assigned.filter((item) => item !== event.currentTarget.dataset.id),
        });
      } else {
        setChecked({ ...checked, assigned: [...checked.assigned, event.currentTarget.dataset.id] });
      }
    },
    handleToggleAllAssigned = () => {
      if (checked.assigned.length) {
        setChecked({
          ...checked,
          assigned: [],
        });
      } else {
        setChecked({ ...checked, assigned: assigned.map((item: any) => item.id) });
      }
    },
    handleToggleUnassigned = (event: any) => {
      if (checked.unassigned.includes(event.currentTarget.dataset.id)) {
        setChecked({
          ...checked,
          unassigned: checked.unassigned.filter((item) => item !== event.currentTarget.dataset.id),
        });
      } else {
        setChecked({ ...checked, unassigned: [...checked.unassigned, event.currentTarget.dataset.id] });
      }
    },
    handleToggleAllUnassigned = () => {
      if (checked.unassigned.length) {
        setChecked({
          ...checked,
          unassigned: [],
        });
      } else {
        setChecked({ ...checked, unassigned: unassigned.map((item: any) => item.id) });
      }
    },
    handleAssign = () => {
      setForm((state: any) => {
        return {
          ...state,
          unassignedItems: {
            ...state.unassignedItems,
            data: state.unassignedItems.data.filter((item: any) => !checked.unassigned.includes(item.id)),
          },
          assignedItems: {
            ...state.assignedItems,
            data: [
              ...state.assignedItems.data,
              ...state.unassignedItems.data.filter((item: any) => checked.unassigned.includes(item.id)),
            ],
          },
        };
      });
      setChecked({ ...checked, unassigned: [] });
    },
    handleUnassign = () => {
      setForm((state: any) => {
        return {
          ...state,
          assignedItems: {
            ...state.assignedItems,
            data: state.assignedItems.data.filter((item: any) => !checked.assigned.includes(item.id)),
          },
          unassignedItems: {
            ...state.unassignedItems,
            data: [
              ...state.unassignedItems.data,
              ...state.assignedItems.data.filter((item: any) => checked.assigned.includes(item.id)),
            ],
          },
        };
      });
      setChecked({ ...checked, assigned: [] });
    },
    handleChangeAllTrue = () => {
      setForm((state: any) => {
        return {
          ...state,
          activityProperties: {
            ...state.activityProperties,
            options: { ...state.activityProperties.options, itemAll: true },
          },
        };
      });
    },
    handleChangeAllFalse = () => {
      setForm((state: any) => {
        return {
          ...state,
          activityProperties: {
            ...state.activityProperties,
            options: { ...state.activityProperties.options, itemAll: false },
          },
        };
      });
    };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    setForm((state: any) => {
      return {
        ...state,
        assignedItems: {
          ...state.assignedItems,
          data: arrayMove(state.assignedItems.data, oldIndex, newIndex),
        },
      };
    });
  };

  return (
    <div>
      <Heading title={'Produkty'} />
      <SeparatorEmpty />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '14px',
          paddingLeft: '8px',
        }}
      >
        <div className={styles.radioContainer}>
          <span onClick={handleChangeAllTrue} className={all ? styles.radioButtonActive : styles.radioButton} />
          <label className={styles.radioLabel}>Wybierz wszystkie produkty</label>
          <span onClick={handleChangeAllFalse} className={!all ? styles.radioButtonActive : styles.radioButton} />
          <label className={styles.radioLabel}>Wybierz produkty ręcznie</label>
        </div>
      </div>
      {!all && (
        <>
          <div className={editView ? styles.usersGrid : ''}>
            {editView && (
              <>
                <ItemsList
                  handleToggleCheckbox={handleToggleAssigned}
                  handleToggleMainCheckbox={handleToggleAllAssigned}
                  editView={editView}
                  checked={checked.assigned}
                  data={assigned}
                  column={'Przypisane'}
                  counter={assigned.length}
                  draggable={true}
                  onSortEnd={onSortEnd}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <button
                    style={{ marginBottom: '12px', backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
                    type={'button'}
                    onClick={handleAssign}
                  >
                    <img src={left} />
                  </button>
                  <button
                    style={{ backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
                    type={'button'}
                    onClick={handleUnassign}
                  >
                    <img src={right} />
                  </button>
                </div>
                <ItemsList
                  handleToggleCheckbox={handleToggleUnassigned}
                  handleToggleMainCheckbox={handleToggleAllUnassigned}
                  editView={editView}
                  checked={checked.unassigned}
                  data={unassigned}
                  column={'Nieprzypisane'}
                  counter={unassigned.length}
                  draggable={false}
                  onSortEnd={onSortEnd}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Items;
