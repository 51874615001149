import React, { CSSProperties } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as SpinnerIcon } from 'assets/svg/spinner.svg';

interface ISpinnerProps {
  style?: CSSProperties;
}

const SpinnerSmall: React.FC<ISpinnerProps> = ({ style }) => (
  <div style={style} className={styles.wrapperSpinnerSmall}>
    <SpinnerIcon className={styles.spinnerSmall} style={{ width: '90px', height: '90px' }} />
  </div>
);

export default SpinnerSmall;
