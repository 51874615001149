import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from '../../../../../global/atoms/WhiteCard';
import { Col, Row } from 'react-grid-system';
import Section from '../../../../../global/atoms/section/Section';
import CheckBox from '../../../../../global/atoms/checkbox/CheckBox';
import Checkbox from '../../../../../global/atoms/Checkbox';
import styles from '../../visitsReport/styles.module.scss';
import { deepClone, handleToast } from '../../../../../utils/helpers';
import Input from '../../../../../global/atoms/Input';
import SeparatorEmpty from '../../../../../global/atoms/separators/SeparatorEmpty';
import ButtonSubmit from '../../../../../global/atoms/ButtonSubmit';
import ActionsContainer from '../../../../../global/atoms/ActionsContainer';
import { useHistory, useParams } from 'react-router-dom';
import { unstable_batchedUpdates } from 'react-dom';
import Spinner from '../../../../../global/atoms/Spinner/Spinner';
import ReportTemplateConnection from '../../../../../utils/connections/reportTemplate';

export interface IReportTemplateData {
  name: string;
  data: any;
  projectId: string;
  type: number;
}

type columnsDataType = Array<{
  label: string;
  name: string;
  active: boolean;
  options: Array<{
    name: string;
    label: string;
    active: boolean;
  }>;
}>;

export interface IBaseTemplateState {
  templateName: string;
  columnsData: columnsDataType;
}

export interface IBaseTemplate {
  state: IBaseTemplateState;
  setState: any;
  type: number;
}

const BaseTemplate: FunctionComponent<IBaseTemplate> = ({ state, setState, children, type }) => {
  const [loading, setLoading] = useState<boolean>(true);

  const { id, templateId } = useParams<{ id: string; templateId: string }>();
  const history = useHistory();

  const getMergedColumnsData = (columnsDataLoaded: columnsDataType) => {
    const columnsDataClone: columnsDataType = deepClone(state.columnsData);
    columnsDataClone.map((item, index) => {
      const itemFound = columnsDataLoaded.find((loadedItem) => loadedItem.name === item.name);
      if (itemFound !== undefined) {
        columnsDataClone[index].active = itemFound.active;
        item.options.map((option, optionIndex) => {
          const optionFound = itemFound.options.find((loadedOption) => loadedOption.name === option.name);
          if (optionFound !== undefined) {
            columnsDataClone[index].options[optionIndex].active = optionFound.active;
          }
        });
      }
    });

    return columnsDataClone;
  };

  if (templateId !== undefined) {
    useEffect(() => {
      Promise.all([handleToast(ReportTemplateConnection.getTemplateById(templateId))]).then((responses) => {
        const data = responses[0].data;
        // force one render with multiple state changes
        unstable_batchedUpdates(() => {
          setState({
            templateName: data.name,
            columnsData: getMergedColumnsData(data.data.columnsData),
          });
          setLoading(false);
        });
      });
    }, []);
  } else {
    useEffect(() => {
      setLoading(false);
    }, []);
  }

  const handleToggleSection = (event: any) => {
    const index = event.currentTarget.dataset.id;
    const dataClone = deepClone(state.columnsData);
    dataClone[index].active = !dataClone[index].active;
    setState({ ...state, columnsData: dataClone });
  };

  const handleToggleOption = (event: any) => {
    const params = JSON.parse(event.currentTarget.dataset.id);
    const dataClone = deepClone(state.columnsData);
    dataClone[params.parentId].options[params.optionId].active = !dataClone[params.parentId].options[params.optionId]
      .active;
    setState({ ...state, columnsData: dataClone });
  };

  const handleSave = () => {
    if (state.templateName === '') {
      return false;
    }

    const dataPost = {
      projectId: id,
      name: state.templateName,
      data: state,
      type: type,
    };
    const redirectUrl = `/projects/${id}/data-exports`;

    if (templateId !== undefined) {
      handleToast(ReportTemplateConnection.updateTemplate(templateId, dataPost)).then(() => {
        history.push(redirectUrl);
      });
      return true;
    }

    handleToast(ReportTemplateConnection.saveTemplate(dataPost)).then(() => {
      history.push(redirectUrl);
    });
  };

  if (loading) return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Row>
          <Col xs={12}>
            <Input
              name={'templateName'}
              value={state.templateName}
              placeholder={'Nazwa szablonu'}
              setState={setState}
              state={state}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <Row>
          {state.columnsData.map((item, index) => {
            return (
              <Col xs={3} key={index}>
                <Section title={item.label} className={styles.sectionContainer}>
                  <div className={!item.active ? styles.sectionDisabled : ''} />
                  <Checkbox
                    style={{ margin: 0 }}
                    className={styles.topRightCornerCheckbox}
                    isChecked={item.active}
                    onClick={handleToggleSection}
                    id={index}
                  />
                  {item.options.map((option: any, indexOption: any) => {
                    const dataString = JSON.stringify({
                      parentId: index,
                      optionId: indexOption,
                    });
                    return (
                      <CheckBox
                        id={dataString}
                        key={index + ' ' + indexOption}
                        isChecked={option.active}
                        onClick={handleToggleOption}
                      >
                        {option.label}
                      </CheckBox>
                    );
                  })}
                </Section>
              </Col>
            );
          })}
        </Row>
        {children}
        <SeparatorEmpty size={1.5} />
        <ActionsContainer>
          <ButtonSubmit onClick={handleSave} />
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default BaseTemplate;
