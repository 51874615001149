import React, { FunctionComponent, useState } from 'react';
import AddressesConnection from 'utils/connections/addresses';
import SelectAsync from 'global/atoms/select/SelectAsync';
import Input from 'global/atoms/Input';
import Button from 'global/atoms/Button';

interface ISelectPostcode {
  errors: any;
  state: any;
  setState: any;
}

const SelectPostcode: FunctionComponent<ISelectPostcode> = ({ errors, state, setState }) => {
  const [localState, setLocalState] = useState<{
    newInputVisible: boolean;
  }>({
    newInputVisible: false,
  });
  const handleAjaxOptions = (inputValue: any, callback: any) => {
    clearTimeout(state.timeoutPostcodeId);
    const timeoutId = setTimeout(() => {
      AddressesConnection.findPostcode({ postcodeName: inputValue }).then((result) => {
        const options = result.data.map((item: any) => ({
          value: item.id,
          label: item.name,
          data: item,
        }));
        callback(options);
      });
    }, 400);

    setState({ ...state, ...{ timeoutPostcodeId: timeoutId } });
  };

  const handleChange = (data: any) => {
    setState({ ...state, ...{ postcode: data } });
  };

  const handleShowNewInput = () => {
    setState({ ...state, ...{ newPostcodeName: '' } });
    setLocalState({ ...localState, ...{ newInputVisible: !localState.newInputVisible } });
  };

  return (
    <>
      {!localState.newInputVisible && (
        <SelectAsync
          onChange={handleChange}
          value={state.postcode}
          error={errors['postcode']}
          placeholder={'Kod pocztowy'}
          loadOptionsAjax={handleAjaxOptions}
        />
      )}
      <div style={{ display: 'block' }}>
        {localState.newInputVisible && (
          <Input
            type={'text'}
            placeholder={'Nowa kod pocztowy'}
            name={'newPostcodeName'}
            value={state.newPostcodeName}
            state={state}
            setState={setState}
            customError={errors['postcode']}
          />
        )}
        <Button onClick={handleShowNewInput}>{localState.newInputVisible ? 'Anuluj' : 'Dodaj nowy'}</Button>
      </div>
    </>
  );
};

export default SelectPostcode;
