import React from 'react';
import BaseComponent from '../BaseComponent';
import { IBaseComponentProps } from '../../../utils/models';
import styles from '../atoms.module.scss';

interface IWeekDayButton extends IBaseComponentProps {
  name: string;
  onClick?: (event: any) => void;
  isChecked: boolean;
  id: any;
}

export default class WeekDayButton extends BaseComponent<IWeekDayButton, any> {
  renderView(): JSX.Element {
    let classNames = styles.weekDayButton;
    if (this.props.isChecked) {
      classNames += ` ${styles.weekDayButtonActive}`;
    }
    return (
      <div onClick={this.props.onClick} data-id={this.props.id} data-name={this.props.name} className={classNames}>
        {this.props.name}
      </div>
    );
  }
}
