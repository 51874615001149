import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import Select from '../../../../global/atoms/Select';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import Button from '../../../../global/atoms/Button';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import Input from '../../../../global/atoms/Input';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import { useHistory, useParams } from 'react-router-dom';
import ReportTemplateConnection from '../../../../utils/connections/reportTemplate';
import { ISelectViewResponse } from '../../../../utils/models';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import { format } from 'date-fns';
import { toast } from 'react-hot-toast';
import AsyncReportResults from './AsyncReportResults';
import { handleToast } from '../../../../utils/helpers';

export interface IReportGenerateData {
  templateId: string;
  startDate: string;
  endDate: string;
  projectId: string;
  format: 'xlsx' | 'csv';
  type: number;
  templateOptions: [];
}

interface IViewData {
  templates: any;
  templateTypes: ISelectViewResponse;
  asyncTypes: number[];
}

const Reports: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const defaultState: IReportGenerateData = {
    templateOptions: [],
    templateId: '',
    startDate: '',
    endDate: '',
    projectId: id,
    format: 'xlsx',
    type: 0,
  };
  const [state, setState] = useState<IReportGenerateData>(defaultState);
  const [form, setForm] = useState<IViewData | null>(null);
  const history = useHistory();
  const [reloadReports, setReloadReports] = useState<boolean>(false);

  useEffect(() => {
    ReportTemplateConnection.getGenerateReportViewData(id).then((response) => {
      setForm(response.data);
    });
  }, []);

  if (form === null) return <Spinner />;

  const handleEditTemplate = () => {
    history.push(`/projects/${id}/report-template/${state.type}/edit/${state.templateId}`);
  };

  const handleAddTemplate = () => {
    history.push(`/projects/${id}/report-template/${state.type}/add`);
  };

  const filterTemplateOptionsByType = () => {
    return form.templates.options.filter((item: any) => item.type === state.type);
  };

  const handleSubmit = () => {
    if (state.templateId === '') {
      toast.error('Wybierz szablon');
      return false;
    }

    if (form.asyncTypes.includes(state.type)) {
      handleToast(ReportTemplateConnection.generateAsyncReport(state)).then(() => {
        setReloadReports(true);
      });
      return true;
    }

    const selectedType = getSelectedTypeData();
    const dateTimeNow = format(new Date(), 'yyyy-MM-dd');
    let fileName = '';
    fileName += selectedType !== undefined ? selectedType.name : 'raport';
    fileName += ' ' + dateTimeNow;
    fileName += '.' + state.format;
    ReportTemplateConnection.getFile(fileName, state);
  };

  const handleChangeType = (object: any) => {
    const value = object.value;
    const newState = { ...defaultState };
    newState.type = value;

    const templates = form.templates.options.filter((item: any) => item.type === state.type);
    newState.templateOptions = templates;
    if (templates && templates[0]) {
      // default template select todo:
      newState.templateId = templates[0].id;
    }

    setState(newState);
  };

  const getSelectedTypeData = (): any => {
    return form.templateTypes.options.find((item) => item.id === state.type);
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Row>
          <Col xs={6}>
            <Select
              placeholder={'Wybierz typ raportu'}
              name={'type'}
              value={state.type}
              options={form.templateTypes.options}
              onChange={handleChangeType}
              state={state}
            />
          </Col>
        </Row>
        <div hidden={state.type === 0}>
          <SeparatorEmpty size={1.5} />
          <Row>
            <Col xs={3}>
              <Select
                key={'template_' + (state.type ?? 'none')}
                placeholder={'Wybierz szablon'}
                name={'templateId'}
                value={state.templateId}
                options={filterTemplateOptionsByType()}
                state={state}
                setState={setState}
              />
            </Col>

            <Col xs={3}>
              <ActionsContainer>
                {state.templateId !== '' && <Button onClick={handleEditTemplate}>Edytuj</Button>}
                <Button onClick={handleAddTemplate}>Dodaj nowy</Button>
              </ActionsContainer>
            </Col>
          </Row>
          <SeparatorEmpty size={1.5} />
          <Row>
            <Col xs={3}>
              <Input
                type={'date'}
                name={'startDate'}
                value={state.startDate}
                placeholder={'Data rozpoczęcia'}
                setState={setState}
                state={state}
              />
            </Col>
            <Col xs={3}>
              <Input
                type={'date'}
                name={'endDate'}
                value={state.endDate}
                placeholder={'Data zakończenia'}
                setState={setState}
                state={state}
              />
            </Col>
          </Row>
          <SeparatorEmpty size={1.5} />
          <Row>
            <Col xs={3}>
              <Button onClick={handleSubmit}>Wygeneruj raport</Button>
            </Col>
            <Col xs={3}>
              <Select
                style={{ zIndex: 100 }}
                placeholder={'Format'}
                name={'format'}
                value={state.format}
                options={[
                  { id: 'xlsx', name: 'xlsx' },
                  { id: 'csv', name: 'csv' },
                ]}
                setState={setState}
                state={state}
              />
            </Col>
          </Row>
        </div>
      </WhiteCard>
      <SeparatorEmpty />
      {form.asyncTypes.includes(state.type) && (
        <AsyncReportResults reportType={state.type} setReloadReports={setReloadReports} reloadReports={reloadReports} />
      )}
    </>
  );
};

export default Reports;
