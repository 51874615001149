import React, { FunctionComponent } from 'react';
import CSS from 'csstype';
import styles from './atoms.module.scss';

interface ITextarea {
  style?: CSS.Properties;
  placeholder: string;
  name: string;
  onChange?: (event: any) => void;
  value: string;
  setState?: any;
  state?: any;
  errors?: any;
  max?: number;
  disabled?: boolean;
  debugData?: any;
}

const Textarea: FunctionComponent<ITextarea> = ({
  style,
  placeholder,
  value,
  onChange,
  name,
  setState,
  state,
  errors,
  max,
  disabled = false,
  debugData,
}) => {
  const handleChange = (event: any) => {
    setState({ ...state, [name]: event.currentTarget.value });
  };

  return (
    <>
      <div className={styles.textareaContainer} style={style}>
        <label className={styles.inputLabel} htmlFor={name}>
          {placeholder}
        </label>
        <textarea
          onChange={onChange ? onChange : handleChange}
          value={value}
          className={styles.textarea}
          name={name}
          maxLength={max}
          disabled={disabled}
          data-debug={debugData}
        />
        {errors && <span className={styles.errors}>{errors[name]}</span>}
      </div>
    </>
  );
};

export default Textarea;
