import { default as axios } from '../AxiosHelper';
import { getAuthHeader } from '../helpers';
import { ICreateAddress, IFindPostcode, IFindStreet, IGetAddresses } from '../models';

const AddressesConnection = {
  getAddresses: (data: IGetAddresses) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/addresses/list`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  getAddress: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/addresses/${id}`, {
      headers: { ...getAuthHeader() },
    });
  },
  createAddress: (data: ICreateAddress) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/addresses`, data, { headers: { ...getAuthHeader() } });
  },
  updateAddress: (id: string, data: ICreateAddress) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/addresses/${id}`, data, { headers: { ...getAuthHeader() } });
  },
  getFormCreateAddress: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/addresses/form`, {
      headers: { ...getAuthHeader() },
    });
  },
  getFormEditAddress: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/addresses/${id}/form`, {
      headers: { ...getAuthHeader() },
    });
  },
  getFormFilterAddress: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/addresses/list/form`, {
      headers: { ...getAuthHeader() },
    });
  },
  findStreet: (data: IFindStreet) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/addresses/street/find`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  findPostcode: (data: IFindPostcode) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/addresses/postcode/find`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/addresses`, {
      headers: { ...getAuthHeader() },
      data: { addresses: data },
    });
  },
};

export default AddressesConnection;
