import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import { ICreateAddress } from 'utils/models';
import { createAddressState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import AddressesConnection from 'utils/connections/addresses';
import { handleToast } from 'utils/helpers';
import Form from 'global/form/Form';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import { useDispatch } from 'react-redux';
import SelectStreet from './SelectStreet';
import SelectPostcode from './SelectPostcode';
import SelectCity from './SelectCity';
import SelectCommunity from './SelectCommunity';
import SelectDistrict from './SelectDistrict';
import Spinner from 'global/atoms/Spinner/Spinner';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';
import { AxiosPromise } from 'axios';

interface IAddressAdd {
  addressId?: string;
  saveExitCallback?: any;
}

const AddressAdd: FunctionComponent<IAddressAdd> = ({ addressId, saveExitCallback }) => {
  const [state, setState] = useState<{
    street: any;
    postcode: any;
    newStreetName: string;
    newPostcodeName: string;
    newCityName: string;
    newDistrictName: string;
    newCommunityName: string;
    timeoutStreetId: any;
    timeoutPostcodeId: any;
  }>({
    newStreetName: '',
    timeoutStreetId: 0,
    street: null,
    newPostcodeName: '',
    postcode: null,
    timeoutPostcodeId: 0,
    newCityName: '',
    newDistrictName: '',
    newCommunityName: '',
  });
  const [data, setData] = useState<ICreateAddress>(createAddressState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const dispatch = useDispatch();
  let { id } = useParams<{ id: string }>();

  if (addressId !== undefined) {
    id = addressId;
  }

  if (id !== undefined) {
    const getIdOrEmptyString = (model: any) => {
      return model?.id !== undefined ? model.id : '';
    };
    useEffect(() => {
      AddressesConnection.getFormEditAddress(id).then((data) => {
        let postcodeOption = null;
        if (data.data.model.postCode.id !== undefined) {
          postcodeOption = {
            label: data.data.model.postCode.name,
            value: data.data.model.postCode.id,
          };
        }

        let streetOption = null;
        if (data.data.model.street.id !== undefined) {
          streetOption = {
            label: data.data.model.street.name,
            value: data.data.model.street.id,
          };
        }

        setState({
          ...state,
          ...{
            postcode: postcodeOption,
            street: streetOption,
          },
        });
        setData({
          houseNumber: data.data.model.houseNumber,
          apartmentNumber: data.data.model.apartmentNumber,
          street: getIdOrEmptyString(data.data.model.street),
          postcode: getIdOrEmptyString(data.data.model.postCode),
          city: getIdOrEmptyString(data.data.model.city),
          country: getIdOrEmptyString(data.data.model.country),
          voivodeship: getIdOrEmptyString(data.data.model.voivodeship),
          district: getIdOrEmptyString(data.data.model.district),
          community: getIdOrEmptyString(data.data.model.community),
          verified: data.data.model.verified,
        });
        setForm(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
      });
    }, []);
  } else {
    useEffect(() => {
      AddressesConnection.getFormCreateAddress().then((data) => {
        setForm(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
      });
    }, []);
  }

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const saveData = { ...data };

    saveData.street = state.street?.value;
    if (state.newStreetName !== '') {
      saveData.street = state.newStreetName;
    }

    saveData.postcode = state.postcode?.value;
    if (state.newPostcodeName !== '') {
      saveData.postcode = state.newPostcodeName;
    }

    if (state.newCityName !== '') {
      saveData.city = state.newCityName;
    }
    if (state.newDistrictName !== '') {
      saveData.district = state.newDistrictName;
    }
    if (state.newCommunityName !== '') {
      saveData.community = state.newCommunityName;
    }

    let savePromise: AxiosPromise;
    if (id !== undefined) {
      savePromise = AddressesConnection.updateAddress(id, saveData);
    } else {
      savePromise = AddressesConnection.createAddress(saveData);
    }
    handleToast(savePromise, setErrors).then(() => {
      if (saveExitCallback !== undefined) {
        saveExitCallback();
      } else {
        history.push('/datasets/addresses');
      }
    });
  };

  if (form === 'loading') return <Spinner />;

  const handleVerified = () => {
    const dataClone = { ...data };
    dataClone.verified = !dataClone.verified;
    setData(dataClone);
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form>
          <SelectCity errors={errors} form={form} setData={setData} data={data} setState={setState} state={state} />
          {(data.city !== '' || state.newCityName !== '') && (
            <>
              <SelectStreet errors={errors} setState={setState} state={state} setData={setData} data={data} />
              <Input
                type={'text'}
                placeholder={'Numer budynku'}
                name={'houseNumber'}
                value={data.houseNumber}
                state={data}
                setState={setData}
                errors={errors}
              />
              <Input
                type={'text'}
                placeholder={'Numer lokalu'}
                name={'apartmentNumber'}
                value={data.apartmentNumber}
                state={data}
                setState={setData}
                errors={errors}
              />
              <SelectPostcode errors={errors} setState={setState} state={state} />
              <SelectCommunity
                errors={errors}
                form={form}
                setData={setData}
                data={data}
                setState={setState}
                state={state}
              />
              <SelectDistrict
                errors={errors}
                form={form}
                setData={setData}
                data={data}
                setState={setState}
                state={state}
              />
              <Select
                placeholder={'Województwo'}
                name={'voivodeship'}
                value={data.voivodeship}
                options={form.voivodeships.options}
                disabled={false}
                state={data}
                setState={setData}
                errors={errors}
              />
              <Select
                placeholder={'Kraj'}
                name={'country'}
                value={data.country}
                options={form.countries.options}
                disabled={false}
                state={data}
                setState={setData}
                errors={errors}
              />
              <CheckBox isChecked={data.verified} onClick={handleVerified}>
                Zweryfikowany?
              </CheckBox>
            </>
          )}
          <ActionsContainer>
            <ButtonSubmit onClick={handleSubmit} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default AddressAdd;
