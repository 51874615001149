import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import Button from 'global/atoms/Button';
import ButtonOutlined from 'global/atoms/ButtonOutlined';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import SeparatorEmpty from 'global/atoms/separators/SeparatorEmpty';
import SwitchButtonHorizontalGroup from 'global/atoms/switchButton/SwitchButtonHorizontalGroup';
import SwitchButton from 'global/atoms/switchButton/SwitchButton';
import Select from 'global/atoms/Select';
import SelectMonth from 'global/atoms/select/SelectMonth';
import SelectQuarter, { ISelectQuarterState } from 'global/atoms/select/SelectQuarter';
import AlertInfo from 'global/atoms/alert/AlertInfo';
import Table from './Table';
import TimeTableConnection from 'utils/connections/timetable';
import { headerViewResponseState, selectViewResponseState, timeTableFilterState } from 'utils/states';
import { IEditTimeTableState, IFilterTimeTable, IHeaderViewResponse, ISelectViewResponse } from 'utils/models';
import { useParams } from 'react-router-dom';
import Filters from 'global/filters/Filters';
import FiltersTimeTableDetails from './FiltersTimeTableDetails';
import SelectedFilters from './SelectedFilters';
import EditTimeTableCustom from './EditTimeTableCustom';
import LegendCircle from './LegendCircle';
import Spinner from 'global/atoms/Spinner/Spinner';
import HeaderButtons from '../../../../../global/atoms/headerButtons/HeaderButtons';
import { deepClone } from '../../../../../utils/helpers';
import Pagination from '../../../../../global/atoms/Pagination';

export interface ITimeTableDetailsState {
  viewRange: number;
  viewType: number;
  timeTables: Array<any>;
  quarter: ISelectQuarterState;
  month: Date;
  popoverData: { target: any; timeTables: Array<any>; selectedDate: Date };
  editData: ITimeTableEditDataState;
  tableType: 'edit' | 'preview';
  projectId: string | null;
  cellType: number;
}

export interface ITimeTableEditDataState {
  show: boolean;
  type: 'new' | 'update';
  updateId: string | null;
  day: Date;
  userId: null | string;
  locationId: null | string;
  taskId: null | string;
  projectId: null | string;
  disabled: Array<any>;
  timeTableUpdate?: IEditTimeTableState;
}

export interface ITimeTableDetailsFiltersState {
  locationId: string | null;
  userId: string | null;
  networkId: string | null;
  projectId: string | null;
  taskId: string | null;
}

export interface ITimeTableDetailsViewDataState extends IHeaderViewResponse {
  locations: ISelectViewResponse;
  users: ISelectViewResponse;
  networks: ISelectViewResponse;
  projects: ISelectViewResponse;
  tasks: ISelectViewResponse;
}

export const VIEW_RANGE_MONTH = 0;
export const VIEW_RANGE_QUARTER = 1;

export const VIEW_TYPE_USER = 0;
export const VIEW_TYPE_LOCALISATION = 1;

export const viewTypeName: any = {
  [VIEW_TYPE_USER]: 'Raportujący',
  [VIEW_TYPE_LOCALISATION]: 'Lokalizacja',
};

export const CELL_TYPE_REPEAT_INFO = 1;
export const CELL_TYPE_ACTIVITY_INFO = 2;

export const cellType: any = {
  [CELL_TYPE_REPEAT_INFO]: 'Cykliczność',
  [CELL_TYPE_ACTIVITY_INFO]: 'Zadanie',
};

export const defaultPopoverDataState = { target: null, timeTables: [], selectedDate: new Date() };

export const defaultEditDataState: ITimeTableEditDataState = {
  show: false,
  type: 'new',
  updateId: null,
  day: new Date(),
  disabled: [],
  locationId: null,
  userId: null,
  projectId: null,
  taskId: null,
};

interface ITimeTableDetails {
  type: 'project' | 'task';
}

const LIMIT_PER_PAGE = 20;

const TimeTableDetails: FunctionComponent<ITimeTableDetails> = ({ type = 'task' }) => {
  const { id } = useParams<{
    id: string;
  }>();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<ITimeTableDetailsState>({
    viewRange: 0,
    viewType: VIEW_TYPE_USER,
    timeTables: [],
    quarter: { year: new Date().getFullYear(), quarter: 0 },
    month: new Date(),
    popoverData: defaultPopoverDataState,
    editData: defaultEditDataState,
    tableType: 'preview',
    projectId: null,
    cellType: CELL_TYPE_REPEAT_INFO,
  });

  const initialFiltersData: any = {
    locationId: null,
    userId: null,
    networkId: null,
    projectId: null,
    taskId: null,
  };
  const [filters, setFilters] = useState<ITimeTableDetailsFiltersState>(initialFiltersData);
  const [loadedFilters, setLoadedFilters] = useState<ITimeTableDetailsFiltersState>(initialFiltersData);
  const [pagination, setPagination] = useState<{ page: number; count: number }>({
    page: 1,
    count: 0,
  });

  const [viewData, setViewData] = useState<ITimeTableDetailsViewDataState>({
    ...headerViewResponseState,
    locations: selectViewResponseState,
    networks: selectViewResponseState,
    users: selectViewResponseState,
    projects: selectViewResponseState,
    tasks: selectViewResponseState,
  });

  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [loadData, setLoadData] = useState<boolean>(true);

  const handleViewChange = (option: { value: any }) => {
    setState({ ...state, ...{ viewRange: option.value, popoverData: defaultPopoverDataState } });
  };

  const handleChangeViewType = (event: any) => {
    const viewType = parseInt(event.currentTarget.dataset.id);
    setState({ ...state, ...{ viewType: viewType, popoverData: defaultPopoverDataState } });
  };

  const handleChangeCellType = (typeId: number) => {
    setState({ ...state, cellType: typeId, popoverData: defaultPopoverDataState });
  };

  const handleToggleFilters = () => {
    setToggleFilters((state) => !state);
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoadedFilters(filters);
    setLoadData(true);
  };

  const handleClear = () => {
    setFilters(initialFiltersData);
  };

  const checkAnyFilterLoaded = () => {
    let anyFilterLoaded = false;
    let filterName: 'locationId' | 'userId' | 'networkId' | 'projectId' | 'taskId';
    for (filterName in loadedFilters) {
      const filterValue = loadedFilters[filterName];
      if (filterValue !== null) anyFilterLoaded = true;
    }

    return anyFilterLoaded;
  };

  if (type === 'task') {
    useEffect(() => {
      TimeTableConnection.getDetailsViewData(id).then((response) => {
        setLoading(false);
        setViewData(response.data);
        setFilters({
          ...filters,
          ...{
            projectId: response.data.projects.options[0].id,
            taskId: response.data.tasks.options[0].id,
          },
        });
        setLoadedFilters({
          ...loadedFilters,
          ...{
            projectId: response.data.projects.options[0].id,
            taskId: response.data.tasks.options[0].id,
          },
        });
      });
    }, []);
  } else {
    useEffect(() => {
      TimeTableConnection.getDetailsProjectViewData().then((response) => {
        setLoading(false);
        setViewData(response.data);
        setFilters({ ...filters, ...{ projectId: response.data.projects.options[0].id } });
        setLoadedFilters({ ...loadedFilters, ...{ projectId: response.data.projects.options[0].id } });
      });
    }, []);
  }

  useEffect(() => {
    let shouldDataBeLoaded = loadData;
    if (type === 'project') {
      shouldDataBeLoaded = loadData && checkAnyFilterLoaded();
    }

    if (shouldDataBeLoaded) {
      let projectIdFilter: string | null = id;
      if (type === 'project') {
        projectIdFilter = loadedFilters.projectId;
      }

      const filtersQuery: IFilterTimeTable = deepClone(timeTableFilterState);
      filtersQuery.isDefault = false;
      filtersQuery.requestPaginate.limit = LIMIT_PER_PAGE;
      filtersQuery.requestPaginate.page = pagination.page;
      filtersQuery.taskId = loadedFilters.taskId;
      filtersQuery.projectId = projectIdFilter;
      filtersQuery.locationId = loadedFilters.locationId;
      filtersQuery.userId = loadedFilters.userId;

      TimeTableConnection.findTimeTable(filtersQuery).then((response) => {
        setLoading(false);
        setState({
          ...state,
          ...{
            timeTables: response.data.data,
            popoverData: defaultPopoverDataState,
          },
        });
        setPagination({ ...pagination, count: response.data.count });
        setLoadData(false);
      });
    }
  }, [...Object.values(loadedFilters), loadData]);

  if (state.editData.show) {
    return (
      <EditTimeTableCustom
        parentState={state}
        parentSetState={setState}
        viewData={viewData}
        setLoadData={setLoadData}
      />
    );
  }

  const handleAddTimeTable = () => {
    setState({
      ...state,
      editData: {
        show: true,
        type: 'new',
        updateId: null,
        day: new Date(),
        userId: null,
        locationId: null,
        projectId: null,
        taskId: null,
        disabled: [],
      },
    });
  };

  const handleToggleEditView = () => {
    const displayView = state.tableType === 'edit' ? 'preview' : 'edit';
    setState({ ...state, tableType: displayView });
  };

  const handleChangePage = (page: number) => {
    setPagination({ ...pagination, page: page });
    setLoadData(true);
  };

  if (loading) return <Spinner />;

  return (
    <>
      <HeaderButtons>
        <ButtonOutlined onClick={handleToggleEditView}>
          {state.tableType === 'edit' ? 'Zamknij edycję masową' : 'Otwórz edycję masową'}
        </ButtonOutlined>
        <ButtonOutlined onClick={handleToggleFilters}>Pokaż filtry</ButtonOutlined>
        <Button onClick={handleAddTimeTable}>Dodaj</Button>
      </HeaderButtons>
      <WhiteCard padding={true}>
        {checkAnyFilterLoaded() && (
          <>
            <Row>
              <Col xs={2}>
                <h5>Filtrowanie:</h5>
              </Col>
              <Col xs={10}>
                <SelectedFilters
                  viewData={viewData}
                  loadedFilters={loadedFilters}
                  setFilters={setFilters}
                  setLoadedFilters={setLoadedFilters}
                  setLoadData={setLoadData}
                />
              </Col>
            </Row>
            <SeparatorEmpty size={2} />
          </>
        )}
        <Row>
          <Hidden xs={true} sm={true}>
            <Col xs={2} style={{ display: 'flex' }}>
              <h5 style={{ alignSelf: 'center' }}>Tryb widoku:</h5>
            </Col>
          </Hidden>
          <Col xs={12} sm={12} md={4}>
            <SwitchButtonHorizontalGroup>
              {Object.keys(viewTypeName).map((typeId) => {
                return (
                  <>
                    <SwitchButton
                      id={typeId}
                      onClick={handleChangeViewType}
                      isChecked={state.viewType === parseInt(typeId)}
                    >
                      {viewTypeName[typeId]}
                    </SwitchButton>
                  </>
                );
              })}
            </SwitchButtonHorizontalGroup>
          </Col>
          <Visible xs={true} sm={true}>
            <SeparatorEmpty size={2} />
          </Visible>
          <Col xs={12} sm={12} md={3}>
            <Select
              placeholder={'Widok'}
              name={'type'}
              value={state.viewRange}
              options={[
                { id: VIEW_RANGE_MONTH, name: 'Miesięczny' },
                { id: VIEW_RANGE_QUARTER, name: 'Kwartalny' },
              ]}
              onChange={handleViewChange}
            />
          </Col>
          <Visible xs={true} sm={true}>
            <SeparatorEmpty size={2} />
          </Visible>
          <Col xs={12} sm={12} md={3}>
            {state.viewRange === 0 && (
              <SelectMonth
                selected={state.month}
                onChange={(date) => {
                  setState({ ...state, ...{ month: date, popoverData: defaultPopoverDataState } });
                }}
              />
            )}
            {state.viewRange === 1 && (
              <SelectQuarter
                selected={state.quarter}
                onChange={(quarter, year) => {
                  setState({
                    ...state,
                    ...{ quarter: { year: year, quarter: quarter }, popoverData: defaultPopoverDataState },
                  });
                }}
              />
            )}
          </Col>
        </Row>
        <SeparatorEmpty size={2} />
        <Row>
          <Hidden xs={true} sm={true}>
            <Col xs={2} style={{ display: 'flex' }}>
              <h5 style={{ alignSelf: 'center' }}>Typ informacji komórki:</h5>
            </Col>
          </Hidden>
          <Col xs={12} sm={12} md={4}>
            <SwitchButtonHorizontalGroup>
              {Object.keys(cellType).map((typeId) => {
                const typeIdInt: number = parseInt(typeId);
                return (
                  <>
                    <SwitchButton
                      id={typeId}
                      onClick={handleChangeCellType.bind(null, typeIdInt)}
                      isChecked={state.cellType === typeIdInt}
                    >
                      {cellType[typeId]}
                    </SwitchButton>
                  </>
                );
              })}
            </SwitchButtonHorizontalGroup>
          </Col>
        </Row>
        <SeparatorEmpty size={2} />
        <Row>
          <Col xs={12} col-md={8}>
            <AlertInfo>
              Zdarzenia oznaczone na czerwono
              <Hidden xs={true} sm={true}>
                <LegendCircle size={1} color={'transparent'} redBorder={true} />
              </Hidden>
              czekają na zatwierdzenie. Kliknij w zdarzenie i zaakceptuj.
            </AlertInfo>
          </Col>
        </Row>
        <SeparatorEmpty size={2} />
        <Table
          state={state}
          setState={setState}
          setLoadData={setLoadData}
          loadedFilters={loadedFilters}
          viewData={viewData}
        />
        <SeparatorEmpty size={2} />
        <Pagination
          countPages={Math.ceil(pagination.count / LIMIT_PER_PAGE)}
          setPage={handleChangePage}
          currentPage={pagination.page}
        />
      </WhiteCard>
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmit}
        handleClear={handleClear}
      >
        <FiltersTimeTableDetails viewData={viewData} filters={filters} setFilters={setFilters} />
      </Filters>
    </>
  );
};

export default TimeTableDetails;
