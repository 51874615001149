import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateProduct } from 'utils/models';
import { createProductState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import { handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import ProductsConnection from 'utils/connections/products';
import Form from 'global/form/Form';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IAddProducts {}

const AddProducts: FunctionComponent<IAddProducts> = () => {
  const [data, setData] = useState<ICreateProduct>(createProductState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  if (id !== undefined) {
    useEffect(() => {
      ProductsConnection.getFormEditProduct(id).then((data) => {
        setData({
          customerId: data.data.model.customer.id,
          amount: data.data.model.price.amount,
          brandId: data.data.model.brand.id,
          currency: data.data.model.price.currency,
          gtin: data.data.model.gtin,
          height: data.data.model.dimension.height,
          length: data.data.model.dimension.length,
          width: data.data.model.dimension.width,
          name: data.data.model.name,
          itemGroupId: data.data.model.itemGroup.id,
          itemUnitId: data.data.model.itemUnit.id,
          itemVatRateId: data.data.model.itemVatRate.id,
          itemCategoryId: data.data.model.itemCategory?.id,
        });
        setForm(data.data);
      });
    }, []);
  } else {
    useEffect(() => {
      const getDefaultValue = (data: Array<any>) => data.find(() => true).id;
      ProductsConnection.getFormAddProduct().then((data) => {
        const formData = data.data;
        setForm(formData);
        setData({
          ...createProductState,
          itemGroupId: getDefaultValue(formData.itemGroups.options),
          itemUnitId: getDefaultValue(formData.itemUnits.options),
          itemVatRateId: getDefaultValue(formData.itemVatRates.options),
        });
      });
    }, []);
  }

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (id !== undefined) {
      handleToast(ProductsConnection.updateProduct(id, data), setErrors).then(() => history.push('/products/product'));
    } else {
      handleToast(ProductsConnection.createProduct(data), setErrors).then(() => history.push('/products/product'));
    }
  };

  if (form === 'loading') return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Klient'}
            name={'customerId'}
            value={data.customerId}
            options={form.customers.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'number'}
            placeholder={'Cena netto'}
            name={'amount'}
            value={data.amount}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Waluta'}
            name={'currency'}
            value={data.currency}
            options={form.currencies.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'number'}
            placeholder={'Długość'}
            name={'length'}
            value={data.length}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'number'}
            placeholder={'Szerokość'}
            name={'width'}
            value={data.width}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'number'}
            placeholder={'Wysokość'}
            name={'height'}
            value={data.height}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'number'}
            placeholder={'GTIN(EAN)'}
            name={'gtin'}
            value={data.gtin}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Kategoria'}
            name={'itemCategoryId'}
            value={data.itemCategoryId}
            options={form.itemCategories.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Marka'}
            name={'brandId'}
            value={data.brandId}
            options={form.brands.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Grupa'}
            name={'itemGroupId'}
            value={data.itemGroupId}
            options={form.itemGroups.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Jednostka'}
            name={'itemUnitId'}
            value={data.itemUnitId}
            options={form.itemUnits.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Stawka VAT'}
            name={'itemVatRateId'}
            value={data.itemVatRateId}
            options={form.itemVatRates.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default AddProducts;
