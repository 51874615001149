import React, { FunctionComponent } from 'react';
import styles from './atoms.module.scss';
import CSS from 'csstype';

interface IButton {
  style?: CSS.Properties;
  onClick?: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
  disabled?: boolean;
  className?: string;
  disabledTitle?: string;
  attrName?: string;
}

const Button: FunctionComponent<IButton> = ({
  children,
  style,
  onClick,
  type = 'button',
  disabled = false,
  className,
  disabledTitle = '',
  attrName = 'submitBtn',
}) => {
  let onClickSet = onClick;
  if (disabled) {
    onClickSet = null;
  }

  const classNames = [];
  if (disabled) {
    classNames.push(styles.buttonDisabled);
  } else {
    classNames.push(styles.button);
  }

  if (className !== undefined) {
    classNames.push(className);
  }

  return (
    <button
      title={disabledTitle}
      onClick={onClickSet}
      className={classNames.join(' ')}
      style={style}
      type={type}
      name={attrName}
    >
      {children}
    </button>
  );
};

export default Button;
