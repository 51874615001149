import React, { FunctionComponent } from 'react';
import { defaultQuestionEditState, IQuestionsEditState } from './QuestionsEdit';
import { ICreateQuestion, ICreateQuestionAnswer } from '../../../../utils/models';
import Form from '../../../../global/form/Form';
import styles from './styles.module.scss';
import Input from '../../../../global/atoms/Input';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';
import Button from '../../../../global/atoms/Button';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import drag from '../../../../assets/svg/drag.svg';
import IconButton from '../../../../global/atoms/IconButton';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import RadioButton from '../../../../global/atoms/radio/RadioButton';

interface IQuestionEditAnswers {
  state: IQuestionsEditState;
  setState: any;
  data: ICreateQuestion;
  setData: any;
  handleSubmit: any;
  form: any;
}

const DragHandle = SortableHandle(() => <img alt={'Zmień kolejność'} src={drag} />);
const SortableItem = SortableElement(
  ({
    item,
    itemKey,
    state,
    handleChangeName,
    handleChangeIsDefault,
    handleEditButtonClick,
    handleChangeIsDeleted,
  }: {
    item: ICreateQuestionAnswer;
    itemKey: number;
    state: any;
    handleChangeName: any;
    handleChangeIsDefault: any;
    handleEditButtonClick: any;
    handleChangeIsDeleted: any;
  }) => (
    <div className={styles.draggableContainer}>
      <span className={styles.draggableHandleButtonContainer}>
        <DragHandle />
      </span>
      <span style={{ flex: 1 }}>
        {state.editId === itemKey ? (
          <>
            <Input
              type={'text'}
              placeholder={''}
              name={'changeName'}
              value={item.name}
              onChange={handleChangeName.bind(this, itemKey)}
            />
          </>
        ) : (
          item.name
        )}
      </span>
      <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>
        <RadioButton
          value={item.isDefault ? itemKey : -1}
          id={itemKey}
          onChange={handleChangeIsDefault.bind(null, itemKey)}
        />
      </div>
      <div className={styles.draggableActionButtonsContainer}>
        <IconButton size={24} onClick={handleEditButtonClick.bind(null, itemKey)} icon={'edit'} />
        <IconButton size={24} onClick={handleChangeIsDeleted.bind(null, itemKey)} icon={'trash'} />
      </div>
    </div>
  ),
);
const SortableList = SortableContainer(
  ({
    answers,
    handleChangeIsDefault,
    handleChangeIsDeleted,
    state,
    handleChangeName,
    handleEditButtonClick,
  }: {
    answers: any;
    handleChangeIsDefault: any;
    handleChangeIsDeleted: any;
    state: any;
    handleChangeName: any;
    handleEditButtonClick: any;
  }) => (
    <div>
      {answers.map((item: any, index: number) => {
        if (item.isDeleted) {
          return null;
        }
        return (
          <SortableItem
            itemKey={index}
            index={index}
            item={item}
            key={index}
            handleChangeIsDefault={handleChangeIsDefault}
            handleChangeIsDeleted={handleChangeIsDeleted}
            handleChangeName={handleChangeName}
            handleEditButtonClick={handleEditButtonClick}
            state={state}
          />
        );
      })}
    </div>
  ),
);

const QuestionEditAnswers: FunctionComponent<IQuestionEditAnswers> = ({
  state,
  setState,
  data,
  setData,
  handleSubmit,
  form,
}) => {
  const handleChangeNewAnswerIsDefault = () => {
    const isDefault = !state.isDefault;
    setState({ ...state, ...{ isDefault: isDefault } });
  };

  const handleAddNewAnswer = () => {
    if (state.answer.length < 1) {
      return false;
    }

    const dataClone = { ...data };
    if (state.isDefault) {
      dataClone.answers.map((data, index) => {
        dataClone.answers[index].isDefault = false;
      });
    }
    dataClone.answers.push({
      isAnswerToDepend: state.isAnswerToDepend,
      isDefault: state.isDefault,
      name: state.answer,
      isDeleted: false,
      id: '',
      order: dataClone.answers.length + 1,
    });
    setData(dataClone);
    setState(defaultQuestionEditState);
  };

  const handleChangeIsDefault = (index: number) => {
    const dataClone = { ...data };
    dataClone.answers.map((data, index) => {
      dataClone.answers[index].isDefault = false;
    });
    dataClone.answers[index].isDefault = !dataClone.answers[index].isDefault;
    setData(dataClone);
  };

  const handleChangeIsDeleted = (index: number) => {
    const dataClone = { ...data };
    if (dataClone.answers[index].id === '') {
      delete dataClone.answers[index];
      setData(dataClone);
      return true;
    }

    dataClone.answers[index].isDeleted = true;
    setData(dataClone);
  };

  const handleEditButtonClick = (index: number) => {
    const stateClone = { ...state };
    stateClone.editId = stateClone.editId === null ? index : null;
    setState(stateClone);
  };

  const handleChangeName = (index: number, event: any) => {
    const dataClone = { ...data };
    dataClone.answers[index].name = event.currentTarget.value;
    setData(dataClone);
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (data !== null) {
      setData((data: any) => ({
        ...data,
        answers: arrayMove(data.answers, oldIndex, newIndex),
      }));
    }
  };

  return (
    <>
      <WhiteCard padding={true} style={{ marginTop: '24px' }}>
        <Form handleSubmit={handleSubmit}>
          <div className={styles.draggableHeaderContainer}>
            <div style={{ width: '50px' }} />
            <span style={{ flex: 1 }}>Nazwa</span>
            <div style={{ width: '300px', display: 'flex', justifyContent: 'center' }}>Odpowiedź domyślna</div>
            <div style={{ width: '70px' }}>Akcje</div>
          </div>
          <SortableList
            onSortEnd={onSortEnd}
            useDragHandle={true}
            answers={data.answers}
            handleEditButtonClick={handleEditButtonClick}
            state={state}
            handleChangeName={handleChangeName}
            handleChangeIsDeleted={handleChangeIsDeleted}
            handleChangeIsDefault={handleChangeIsDefault}
          />
          <div
            className={styles.draggableContainer}
            style={{
              padding: '30px 12px',
              height: 'auto',
              display: 'block',
            }}
          >
            <Input
              type={'text'}
              placeholder={'Treść odpowiedzi'}
              name={'answer'}
              value={state.answer}
              state={state}
              setState={setState}
            />
            <CheckBox isChecked={state.isDefault} onClick={handleChangeNewAnswerIsDefault}>
              Odpowiedź domyślna
            </CheckBox>
            <Button onClick={handleAddNewAnswer}>Dodaj odpowiedź</Button>
          </div>
          <ActionsContainer>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default QuestionEditAnswers;
