import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './security.module.scss';
import UserConnection from '../../utils/connections/user';
import MenuConnection from '../../utils/connections/menu';
import App from '../../App';
import { useDispatch, useSelector } from 'react-redux';
import { SET_APP, SET_MENU } from '../../redux/actions';
import { IRoot } from '../../redux/models';
import Input from '../../global/atoms/Input';
import Button from '../../global/atoms/Button';
import logo from '../../assets/images/logo.png';
import { handleToast } from '../../utils/helpers';

interface ISignIn {}

const Security: FunctionComponent<ISignIn> = () => {
  const [login, setLogin] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errors, setErrors] = useState<any | null>(null);
  const logged = useSelector((state: IRoot) => state.app.logged);
  const dispatch = useDispatch();

  useEffect(() => {
    MenuConnection.getMain()
      .then((data) => {
        dispatch({ type: SET_MENU, payload: data.data });
        dispatch({ type: SET_APP, payload: { logged: true } });
      })
      .catch(() => dispatch({ type: SET_APP, payload: { logged: false } }));
  }, []);

  const handleChangeLogin = (event: { target: { value: React.SetStateAction<string> } }) => {
    setLogin(event.target.value);
  };

  const handleChangePassword = (event: { target: { value: React.SetStateAction<string> } }) => {
    setPassword(event.target.value);
  };

  const handleSubmitForm = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    handleToast(UserConnection.login({ username: login, password: password, from: 'WEB' }))
      .then((data) => {
        localStorage.setItem('user', JSON.stringify(data.data));
      })
      .then(() => {
        handleToast(MenuConnection.getMain())
          .then((data) => {
            dispatch({ type: SET_MENU, payload: data.data });
            dispatch({ type: SET_APP, payload: { logged: true } });
          })
          .catch(() => dispatch({ type: SET_APP, payload: { logged: false } }));
      })
      .catch((err) => setErrors(err.response.data));
  };

  if (logged === undefined) {
    return null;
  } else if (logged) {
    return <App />;
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmitForm}>
        <img src={logo} alt={'logo'} className={styles.logo} />
        <Input onChange={handleChangeLogin} value={login} name={'login'} placeholder={'Login'} />
        {errors &&
          errors.errors &&
          errors.errors.username &&
          errors.errors.username.map((item: string, idx: number) => (
            <span key={idx} className={styles.errors}>
              {item}
            </span>
          ))}
        <Input
          onChange={handleChangePassword}
          value={password}
          style={{ marginTop: '36px' }}
          name={'password'}
          placeholder={'Hasło'}
          type={'password'}
        />
        {errors &&
          errors.errors &&
          errors.errors.password &&
          errors.errors.password.map((item: string, idx: number) => (
            <span key={idx} className={styles.errors}>
              {item}
            </span>
          ))}
        {errors && errors.message && <span className={styles.errors}>{errors.message}</span>}
        <Button style={{ marginTop: '72px' }} type={'submit'}>
          Zaloguj
        </Button>
      </form>
    </div>
  );
};

export default Security;
