import { SET_HEADER } from '../actions';
import { headerState } from '../states';

const headerReducer = (state = headerState, action: any) => {
  switch (action.type) {
    case SET_HEADER:
      return action.payload;
    default:
      return state;
  }
};

export default headerReducer;
