import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';

const MenuConnection = {
  getMain: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/menus/main`, {
      headers: getAuthHeader(),
    });
  },
};

export default MenuConnection;
