import React, { FunctionComponent, useEffect, useState } from 'react';
import { IGetNotifications, INotification, INotifications } from '../../../../utils/models';
import { getNotificationsState } from '../../../../utils/states';
import NotificationsConnection from '../../../../utils/connections/notifications';
import HeaderButtons from '../../../../global/atoms/headerButtons/HeaderButtons';
import ButtonAddNew from '../../../../global/atoms/ButtonAddNew';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import Pagination from '../../../../global/pagination/Pagination';
import { handleClearFilters } from '../../../../utils/filterHelper';
import Input from '../../../../global/atoms/Input';
import Filters from '../../../../global/filters/Filters';
import { deepClone } from '../../../../utils/helpers';
import FiltersStorage from '../../../../utils/FiltersStorage';
import { useDispatch } from 'react-redux';
import ButtonFilters from '../../../../global/atoms/ButtonFilters';
import Select from '../../../../global/atoms/Select';
import styles from '../usersList/styles.module.scss';
import { useHistory } from 'react-router-dom';
import IconButton from '../../../../global/atoms/IconButton';
import List5 from "../../../../global/table/List5";
import SpinnerSmall from "../../../../global/atoms/Spinner/SpinnerSmall";

interface INotificationList {}
interface UniversalProperty {
  id: string;
  name: string;
  status: string;
  color: string;
  isDefault: boolean;
}

const NotificationList: FunctionComponent<INotificationList> = () => {
  const [data, setData] = useState<INotifications | null>(null);
  const [filters, setFilters] = useState<IGetNotifications>(getNotificationsState);
  const [toggleFilters, setToggleFilters] = useState<boolean>(true);
  const [filtersForm, setFiltersForm] = useState<any>(null);
  const defaultFilters = deepClone(getNotificationsState);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  let filterDom = null;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleRowClick = (notification: INotification) => {
    history.push(
      `/admin/notifications/${notification.id}/` + (notification.status != 'PREPARE' ? 'view' : 'edit'),
    );
  };

  const UniversalPropRender = (matchValue: string, options: any) => {
    let obj: UniversalProperty = { id: '', name: '', status: '', color: '', isDefault: true };
    options.some((val: UniversalProperty) => {
      const res = val.id === matchValue;
      if (res) obj = val;
      return res;
    });

    return (
      <span className={styles.status} style={{ color: obj.color, borderColor: obj.color }}>
        {obj.name}
      </span>
    );
  };

  const reload = (init = false) => {

    setLoading(true);

    if (init) {
      NotificationsConnection.getFilterForm().then((response) => {
        setFiltersForm(response.data);
      });
    }

    setFilters((state: IGetNotifications) => ({
      ...state,
      requestPaginate: {
        page: state.requestPaginate.page,
        limit: state.requestPaginate.limit,
      },
    }));


    const resolvePromise = (notifications: INotifications | undefined) => {
      console.log('reload data', notifications);
    };

    NotificationsConnection.getNotifications(filters).then((data: { data: INotifications }) => {
      setLoading(false);
      setData(data.data);
      resolvePromise(data.data);
    });

    return new Promise<INotifications>(() => resolvePromise);
  };

  const handleSubmitFilters = (event: any) => {
    event.preventDefault();
    new FiltersStorage().save(filters);

    setToggleFilters(false);
    setFilters((state: IGetNotifications) => ({
      ...state,
      requestPaginate: { page: 1, limit: state.requestPaginate.limit },
    }));

    reload().then((notifications) => {
      notifications?.header && dispatch({ type: 'SET_HEADER', payload: notifications.header });
    });
  };

  let init = true;
  useEffect(() => {
    reload(init);
    init = false;
  }, [filters.requestPaginate.limit, filters.requestPaginate.page]);

  if (data === null) return null;
  if (init && filtersForm) {
    filterDom = (
      <>
        <Filters
          toggleFilters={toggleFilters}
          handleToggleFilters={() => setToggleFilters((state: boolean) => !state)}
          handleSubmit={handleSubmitFilters}
          handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, reload)}
        >
          <Input
            value={filters.subject}
            placeholder={'Temat'}
            name={'subject'}
            type={'text'}
            setState={setFilters}
            state={filters}
          />
          <Select
            placeholder={'Status'}
            name={'status'}
            value={filters.status}
            options={filtersForm.statuses.options}
            setState={setFilters}
            state={filters}
          />
          <Select
            placeholder={'Źródło'}
            name={'sourceType'}
            value={filters.sourceType}
            options={filtersForm.sourceTypes.options}
            setState={setFilters}
            state={filters}
          />
          <Select
            placeholder={'Kanał docelowy'}
            name={'sendChannel'}
            value={filters.sendChannel}
            options={filtersForm.sendChannels.options}
            setState={setFilters}
            state={filters}
          />
        </Filters>
      </>
    );
  }

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={() => setToggleFilters((state: boolean) => !state)} />
        <ButtonAddNew form={filtersForm} />
      </HeaderButtons>
      <WhiteCard padding={false} mobileTransparent={true}>

        {loading ? <SpinnerSmall /> :
          <>
            <List5 data={data.data} count={data.data.length} rowClick={handleRowClick}>
              <Column flexGrow={1} align="left">
                <HeaderCell depth={0} width={0}>
                  Temat
                </HeaderCell>
                <Cell width={100} depth={0} dataKey="subject" />
              </Column>

              <Column width={180} align="center">
                <HeaderCell depth={0} width={0}>
                  Kanał docelowy
                </HeaderCell>
                <Cell width={180} depth={0}>
                  {(rowData) => {
                    return UniversalPropRender(rowData.sendChannel, filtersForm?.sendChannels.options ?? []);
                  }}
                </Cell>
              </Column>

              <Column flexGrow={1} align="center">
                <HeaderCell depth={0} width={0}>
                  źródło
                </HeaderCell>
                <Cell width={50} depth={0}>
                  {(rowData) => {
                    return UniversalPropRender(rowData.sourceType, filtersForm?.sourceTypes.options ?? []);
                  }}
                </Cell>
              </Column>

              <Column width={180} >
                <HeaderCell depth={0} width={0}>
                  status
                </HeaderCell>
                <Cell width={180} depth={0}>
                  {(rowData) => {
                    return UniversalPropRender(rowData.status, filtersForm?.statuses.options ?? []);
                  }}
                </Cell>
              </Column>

              <Column verticalAlign={'middle'} resizable={true} align="right">
                <HeaderCell depth={0} width={50}>Akcje</HeaderCell>
                <Cell depth={0} width={50} style={{textAlign: 'right'}}>
                  {(rowData: INotification) => {
                    return (
                      <IconButton
                        inline={true}
                        size={rowData.status != 'PREPARE' ? 20 : 30}
                        onClick={() => {
                          history.push(
                            `/admin/notifications/${rowData.id}/` + (rowData.status != 'PREPARE' ? 'view' : 'edit'),
                          );
                        }}
                        icon={rowData.status != 'PREPARE' ? 'view' : 'edit'}
                      />
                    );
                  }}
                </Cell>
              </Column>
            </List5>
          </>}
      </WhiteCard>
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
      {filterDom}
    </>
  );
};

export default NotificationList;
