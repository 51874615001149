import React, { FunctionComponent } from 'react';
import styles from '../atoms.module.scss';

interface ISwitchButtonProps {
  onClick?: any;
  isChecked?: boolean;
  id?: any;
}

const SwitchButton: FunctionComponent<ISwitchButtonProps> = ({ isChecked = false, onClick, children, id }) => {
  return (
    <div data-id={id} className={isChecked ? styles.switchButtonActive : styles.switchButton} onClick={onClick}>
      {children}
    </div>
  );
};

export default SwitchButton;
