import React from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import Form from 'global/form/Form';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';

interface IRoleUpdate {
  handleSubmit: (event: any) => void;
  data: any;
  form: any | 'loading';
  setData: (data: any) => void;
  errors: any;
}

const RoleUpdate: React.FC<IRoleUpdate> = ({ handleSubmit, data, form, setData, errors }) => (
  <WhiteCard padding={true}>
    <Form handleSubmit={handleSubmit}>
      <Input
        type={'text'}
        placeholder={'Nazwa'}
        name={'name'}
        value={data.name}
        state={data}
        setState={setData}
        errors={errors}
      />
      <Select
        placeholder={'Rodzaj grupy'}
        name={'roleTypeId'}
        value={data.roleTypeId}
        state={data}
        options={form.roleTypes.options}
        setState={setData}
        disabled={false}
        errors={errors}
      />
      <ActionsContainer>
        <ButtonSubmit form={form} />
      </ActionsContainer>
    </Form>
  </WhiteCard>
);

export default RoleUpdate;
