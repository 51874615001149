import React, { FunctionComponent } from 'react';
import ListingNetworkEdit from './ListingNetworkEdit';

interface IListingNetworkItemEdit {
  activityId: string;
  parentSetState: any;
}

const ListingNetworkItemEdit: FunctionComponent<IListingNetworkItemEdit> = ({ activityId, parentSetState }) => {
  return <ListingNetworkEdit activityId={activityId} listingType={'product'} parentSetState={parentSetState} />;
};

export default ListingNetworkItemEdit;
