import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { IChangePass, ICreateUser, IEditUser, IGetUsers } from '../models';
import { AxiosPromise } from 'axios';

interface ILogin {
  username: string;
  password: string;
  from: string;
}

const UserConnection = {
  login: (data: ILogin) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, data);
  },
  logout: () => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/logout`, { headers: getAuthHeader() });
  },
  createUser: (data: ICreateUser) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users`, data, {
      headers: getAuthHeader(),
    });
  },
  editUser: (id: string, data: IEditUser) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  changePassword: (id: string, data: IChangePass) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/user/change-pass/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  addRoles: (userId: string, data: { roles: Array<{ roleId: string }> }) => {
    return axios.patch(`${process.env.REACT_APP_API_URL}/organizations/users/${userId}/roles/assign`, data, {
      headers: getAuthHeader(),
    });
  },
  getRoles: (userId: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/${userId}/roles`, {
      headers: getAuthHeader(),
    });
  },
  getUsers: (data: IGetUsers) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/list`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  getUsersForMultiselect: () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/list_for_multiselect`,
      {
        headers: { ...getAuthHeader() },
      },
    );
  },
  getFormCreateUser: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditUser: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormChangePassword: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/password/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormFilters: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  switchRole: (data: { roleId: string }) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/session/switch-role`, data, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users`, {
      headers: { ...getAuthHeader() },
      data: { users: data },
    });
  },
  logoutUser: (userId: string): AxiosPromise => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/users/${userId}/logout/device`);
  },
};

export default UserConnection;
