import React, { FunctionComponent, useEffect, useState } from 'react';
import StarRatingComponent from 'react-star-rating-component';
import styles from '../../styles.module.scss';
import { IPhotoReviewFeedback } from '../../../../../utils/models';
import stylesAtom from '../../../../../global/atoms/atoms.module.scss';

interface IKPIReviewForm {
  identifier: string;
  stateIndex: number;
  statesRef: React.MutableRefObject<IPhotoReviewFeedback[]>;
  readOnly?: boolean;
  errors?: any;
}

const KPIReviewForm: FunctionComponent<IKPIReviewForm> = (props) => {
  const [starValue, setStarValue] = useState<number>(0);
  const [starEditing, setStarEditing] = useState<boolean>(true);

  useEffect(() => {
    if (props.statesRef.current[props.stateIndex].feedbackDetails.length > 0) {
      const val = parseInt(props.statesRef.current[props.stateIndex].feedbackDetails[0].value);
      if (val > 0) setStarEditing(false);
      setStarValue(val);
    }
  }, []);

  const onStarClick = (value: number) => {
    if (props.readOnly) return false;

    setStarValue(value);
    setStarEditing(false);

    props.statesRef.current[props.stateIndex].feedbackDetails[0].value = value.toString();
  };

  const handleStarRatingReset = () => {
    if (props.readOnly) return false;
    setStarEditing(true);
    setStarValue(0);
    props.statesRef.current[props.stateIndex].feedbackDetails[0].value = '';
  };

  return (
    <>
      <div className={`${styles.starRatingGrandWrapper}`}>
        <div>
          <div>{props.statesRef.current[props.stateIndex].feedbackDetails[0].feedbackQuestion.name}</div>
          <span className={`${styles.starRatingWrapper}`}>
            <StarRatingComponent
              name={'kpi_stars_' + props.identifier}
              value={starValue}
              editing={starEditing}
              starColor={'#fcaf39'}
              emptyStarColor={'#686869'}
              onStarClick={onStarClick}
              onStarHover={(value) => setStarValue(value)}
              onStarHoverOut={() => setStarValue(0)}
            />
            &nbsp;
            {!props.readOnly && (
              <strong
                onClick={handleStarRatingReset}
                style={{ opacity: starEditing ? 0 : 1, cursor: 'pointer', color: 'red' }}
              >
                ×
              </strong>
            )}
          </span>
          {props.errors && props.errors.positions && (
            <div className={stylesAtom.errors}>{props.errors.positions[0].value}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default KPIReviewForm;
