import React, { FunctionComponent, useState } from 'react';
import Select from '../../../../global/atoms/Select';
import Input from '../../../../global/atoms/Input';
import Button from '../../../../global/atoms/Button';

interface ISelectCity {
  form: any;
  data: any;
  setData: any;
  errors: any;
  state: any;
  setState: any;
}

const SelectCity: FunctionComponent<ISelectCity> = ({ form, errors, setData, data, setState, state }) => {
  const [localState, setLocalState] = useState<{
    newInputVisible: boolean;
  }>({
    newInputVisible: false,
  });

  const handleShowNewInput = () => {
    setState({ ...state, ...{ newCityName: '' } });
    setLocalState({ ...localState, ...{ newInputVisible: !localState.newInputVisible } });
  };

  const filterOptions = (data: any, query: any) => {
    if (query.length < 2) {
      return false;
    }
    const dataSplit = data.label.split('(');
    const cityLower = dataSplit[0].toLowerCase();
    return cityLower.indexOf(query.toLowerCase()) >= 0;
  };

  return (
    <>
      {!localState.newInputVisible && (
        <Select
          placeholder={'Miasto'}
          name={'city'}
          value={data.city}
          options={form.cities.options}
          disabled={false}
          state={data}
          setState={setData}
          errors={errors}
          limit={100}
          filterOptionsCustom={filterOptions}
        />
      )}
      <div style={{ display: 'block' }}>
        {localState.newInputVisible && (
          <Input
            type={'text'}
            placeholder={'Nowe miasto'}
            name={'newCityName'}
            value={state.newCityName}
            state={state}
            setState={setState}
            errors={errors}
          />
        )}
        <Button onClick={handleShowNewInput}>{localState.newInputVisible ? 'Anuluj' : 'Dodaj nowy'}</Button>
      </div>
    </>
  );
};

export default SelectCity;
