import React from 'react';
import BaseComponent from './BaseComponent';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { IBaseComponentProps } from '../../utils/models';
import Helmet from 'react-helmet';

interface IDayPickerCustomProps extends IBaseComponentProps {
  onDayClick?: any;
  selectedDays?: Array<Date>;
}

export default class DayPickerCustom extends BaseComponent<IDayPickerCustomProps, any> {
  renderView(): JSX.Element {
    return (
      <div>
        <Helmet>
          <style>
            {`
            .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
              background-color: #ffaf38 !important;
            }
          `}
          </style>
        </Helmet>
        <DayPicker
          weekdaysShort={WEEKDAYS_SHORT}
          weekdaysLong={WEEKDAYS_LONG}
          months={MONTHS}
          onDayClick={this.props.onDayClick}
          selectedDays={this.props.selectedDays}
        />
      </div>
    );
  }
}

export const MONTHS = [
  'Styczeń',
  'Luty',
  'Marzec',
  'Kwiecień',
  'Maj',
  'Czerwiec',
  'Lipiec',
  'Sierpień',
  'Wrzesień',
  'Październik',
  'Listopad',
  'Grudzień',
];

export const WEEKDAYS_LONG = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];

export const WEEKDAYS_SHORT = ['Niedz', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'];
