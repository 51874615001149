import React, { FunctionComponent, useState } from 'react';
import {
  defaultEditDataState,
  defaultPopoverDataState,
  ITimeTableDetailsState,
  ITimeTableDetailsViewDataState,
} from './TimeTableDetails';
import {
  IAddTimeTable,
  IEditTimeTableGeneralDataState,
  IEditTimeTableState,
  ISelectViewResponse,
} from '../../../../../utils/models';
import { createTimeTableState } from '../../../../../utils/states';
import WhiteCard from '../../../../../global/atoms/WhiteCard';
import EditTimeTable from '../EditTimeTable';
import ButtonSubmit from '../../../../../global/atoms/ButtonSubmit';
import ButtonGoBack from '../../../../../global/atoms/ButtonGoBack';
import ActionsContainer from '../../../../../global/atoms/ActionsContainer';
import TimeTableConnection from '../../../../../utils/connections/timetable';
import { handleSetErrors, parseTimeForBackend } from '../../../../../utils/helpers';

interface IEditTimeTableCustomProps {
  parentState: ITimeTableDetailsState;
  parentSetState: any;
  viewData: ITimeTableDetailsViewDataState;
  setLoadData: any;
}

interface IEditTimeTableCustomState {
  timeTable: IEditTimeTableState;
  data: any;
}

export const getFilteredDataByProject = (data: ISelectViewResponse, projectId: string) => {
  const dataCLone = { ...data };
  dataCLone.options = data.options.filter((dataFilter: any) => dataFilter.projectId === projectId);
  return dataCLone;
};

const EditTimeTableCustom: FunctionComponent<IEditTimeTableCustomProps> = ({
  parentState,
  parentSetState,
  viewData,
  setLoadData,
}) => {
  if (parentState.editData.day === undefined) {
    return <></>;
  }

  let initialState: IEditTimeTableCustomState;
  const selectedProjectId =
    parentState.editData.projectId !== null ? parentState.editData.projectId : viewData.projects.options[0].id;
  const tasksFiltered = getFilteredDataByProject(viewData.tasks, selectedProjectId);
  const generalData: IEditTimeTableGeneralDataState = {
    locations: getFilteredDataByProject(viewData.locations, selectedProjectId),
    users: getFilteredDataByProject(viewData.users, selectedProjectId),
    disabled: parentState.editData.disabled,
    projects: viewData.projects,
    tasks: tasksFiltered,
    selectedProject: selectedProjectId,
    selectedTask: parentState.editData.taskId !== null ? parentState.editData.taskId : tasksFiltered.options[0].id,
    selectedLocation: parentState.editData.locationId,
    selectedUser: parentState.editData.userId,
    type: parentState.editData.type,
  };
  if (parentState.editData.updateId !== null) {
    const timeTableState =
      parentState.editData.timeTableUpdate !== undefined ? parentState.editData.timeTableUpdate : createTimeTableState;
    initialState = {
      timeTable: {
        ...timeTableState,
        generalData: generalData,
      },
      data: viewData,
    };
  } else {
    initialState = {
      timeTable: {
        ...{
          ...createTimeTableState,
          singleSettings: { startDate: parseTimeForBackend(parentState.editData.day.getTime()) },
        },
        generalData: generalData,
      },
      data: viewData,
    };
  }
  const [state, setState] = useState<IEditTimeTableCustomState>(initialState);
  const [, setErrors] = useState<any>(null);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (state.timeTable.generalData === undefined) {
      return false;
    }

    const addTimeTableData: IAddTimeTable = {
      default: false,
      taskId: state.timeTable.generalData.selectedTask,
      projectId: state.timeTable.generalData.selectedProject,
      type: state.timeTable.type,
      userId:
        state.timeTable.generalData?.selectedUser !== undefined ? state.timeTable.generalData?.selectedUser : null,
      locationId:
        state.timeTable.generalData?.selectedLocation !== undefined
          ? state.timeTable.generalData?.selectedLocation
          : null,
      manualSettings: state.timeTable.manualSettings,
      recurringSettings: state.timeTable.recurringSettings,
      singleSettings: state.timeTable.singleSettings,
      isApproved: state.timeTable.isApproved,
    };

    if (parentState.editData.updateId !== null) {
      TimeTableConnection.updateTimeTable(parentState.editData.updateId, addTimeTableData)
        .then(() => {
          parentSetState({ ...parentState, editData: defaultEditDataState });
          setLoadData(true);
        })
        .catch((err) => handleSetErrors(err, setErrors));
    } else {
      TimeTableConnection.createTimeTable(addTimeTableData)
        .then(() => {
          parentSetState({ ...parentState, editData: defaultEditDataState });
          setLoadData(true);
        })
        .catch((err) => handleSetErrors(err, setErrors));
    }
  };

  const handleGoBack = () => {
    parentSetState({
      ...parentState,
      editData: defaultEditDataState,
      popoverData: defaultPopoverDataState,
    });
  };

  return (
    <>
      <WhiteCard padding={true}>
        <EditTimeTable parentData={state} parentSetData={setState} />
        <ActionsContainer>
          <ButtonGoBack onClick={handleGoBack} />
          <ButtonSubmit onClick={handleSubmit} />
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default EditTimeTableCustom;
