import React, { FunctionComponent } from 'react';
import styles from './atoms.module.scss';
import closeImageUrl from '../../assets/svg/close.svg';

interface IPopover {
  visible?: boolean;
  width?: number;
  onClose: () => void;
}

const Popover: FunctionComponent<IPopover> = ({ onClose, width = 400, visible = true, children }) => {
  if (!visible) {
    return <></>;
  }
  return (
    <div style={{ width: width }} className={`popover ${styles.popover}`}>
      <img alt={'Zamknij'} src={closeImageUrl} onClick={onClose} className={styles.popoverClose} />
      {children}
    </div>
  );
};

export default Popover;
