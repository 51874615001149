import React, { FunctionComponent } from 'react';
import styles from '../../../../../global/atoms/atoms.module.scss';

export type LegendCircleColorsType = 'transparent' | 'orange' | 'purple' | 'green' | 'blue' | 'mixed' | 'gray';

interface ILegendCircleProps {
  redBorder?: boolean;
  color: LegendCircleColorsType;
  size: 1 | 2 | 3;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

const LegendCircle: FunctionComponent<ILegendCircleProps> = ({
  size = 1,
  color,
  redBorder = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const colors: any = {
    blue: '#32c5ff',
    orange: '#ffc34d',
    purple: '#c44dff',
    green: '#29ccae',
    red: '#f55963',
    transparent: 'rgba(0, 0, 0, 0)',
    gray: '#aeaeaf',
  };

  let innerDivClassname: any;
  let borderWidth: number;
  switch (size) {
    case 1:
      borderWidth = 5;
      if (color === 'mixed') {
        innerDivClassname = styles.timeTableLegendMixedSize1;
      }
      break;
    case 2:
      borderWidth = 8;
      if (color === 'mixed') {
        innerDivClassname = styles.timeTableLegendMixedSize2;
      }
      break;
    case 3:
      borderWidth = 10;
      if (color === 'mixed') {
        innerDivClassname = styles.timeTableLegendMixedSize2;
      }
      break;
  }

  let innerDivStyles: any = {
    borderWidth: borderWidth + 'px',
    borderRadius: borderWidth + 'px',
    borderStyle: 'solid',
    borderColor: colors[color],
  };

  if (color === 'mixed') {
    innerDivStyles = {};
  }

  const outerDivStyles: any = { display: 'inline-block' };
  if (onClick !== undefined) {
    outerDivStyles.cursor = 'pointer';
  }

  const gapDivStyles: any = {};
  if (redBorder) {
    outerDivStyles.border = `2px solid ${colors['red']}`;
    outerDivStyles.borderRadius = '100%';

    gapDivStyles.border = `1px solid ${colors['transparent']}`;
    gapDivStyles.borderRadius = '100%';
  }

  return (
    <>
      <div
        style={{
          width: '25px',
          height: '25px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: onClick !== undefined ? 'pointer' : 'default',
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div style={outerDivStyles}>
          <div style={gapDivStyles}>
            <div className={innerDivClassname} style={innerDivStyles} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LegendCircle;
