import React, { FunctionComponent } from 'react';
import styles from './atoms.module.scss';

interface IBadgeStatus {
  color: string;
}

const BadgeStatus: FunctionComponent<IBadgeStatus> = ({ color, children }) => {
  return (
    <>
      <span
        style={{
          color: color,
          borderColor: color,
        }}
        className={styles.badgeStatus}
      >
        {children}
      </span>
    </>
  );
};

export default BadgeStatus;
