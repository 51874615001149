import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import WhiteCard from 'global/atoms/WhiteCard';
import { Col, Row } from 'react-grid-system';
import Select from 'global/atoms/Select';
import { ISelectViewResponse } from 'utils/models';
import Input from 'global/atoms/Input';
import SeparatorEmpty from 'global/atoms/separators/SeparatorEmpty';
import SeparatorLine from 'global/atoms/separators/SeparatorLine';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import List from 'global/table/List';
import DocumentsConnection from 'utils/connections/documents';
import Spinner from 'global/atoms/Spinner/Spinner';
import styles from '../orders/styles.module.scss';

export interface IDocumentDetailsForm {
  users: ISelectViewResponse;
  paymentTypes: ISelectViewResponse;
  saleTypes: ISelectViewResponse;
  model: any;
}

export const SUMMARY_TITLE = 'Podsumowanie';

export const createSummaryRowData = (model: any) => {
  return {
    itemName: SUMMARY_TITLE,
    price: {
      amount: '',
      tax: '',
      amountGross: '',
      discount: model.price.discount,
      amountTotal: model.price.amountTotal,
      taxTotal: model.price.taxTotal,
      amountGrossTotal: model.price.amountGrossTotal,
    },
    itemVatRate: {
      name: '',
    },
    itemUnit: {
      name: '',
    },
  };
};

export const getCellComponentWithPriceValue = (value: any, rowData: any) => {
  if (value === '') {
    return <span />;
  }
  const priceFormat = Number(value).toFixed(2);
  if (rowData.itemName === SUMMARY_TITLE) {
    return <b>{priceFormat}</b>;
  }
  return <span>{priceFormat}</span>;
};

export const getDocumentPositionsBody = (positions: Array<any>) => {
  return (
    <>
      <List data={positions} count={positions.length}>
        <Column flexGrow={1} align="left">
          <HeaderCell depth={0} width={0}>
            Produkt
          </HeaderCell>
          <Cell width={100} depth={0} dataKey={'itemName'}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <b>{rowData.itemName}</b>;
              }
              return <span>{rowData.itemName}</span>;
            }}
          </Cell>
        </Column>
        <Column width={100} align="left">
          <HeaderCell depth={0} width={0}>
            Netto
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(rowData.price.amount, rowData);
            }}
          </Cell>
        </Column>
        <Column width={100} align="left">
          <HeaderCell depth={0} width={0}>
            Vat
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(rowData.price.tax, rowData);
            }}
          </Cell>
        </Column>
        <Column width={100} align="left">
          <HeaderCell depth={0} width={0}>
            Brutto
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              if (rowData.itemName === SUMMARY_TITLE) {
                return <></>;
              }
              return getCellComponentWithPriceValue(rowData.price.amountGross, rowData);
            }}
          </Cell>
        </Column>
        <Column width={100} align="left">
          <HeaderCell depth={0} width={0}>
            Liczba
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              return (
                <span>
                  {rowData.itemAmount} {rowData.itemUnit.name}
                </span>
              );
            }}
          </Cell>
        </Column>
        <Column width={100} align="left">
          <HeaderCell depth={0} width={0}>
            Rabat
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(rowData.price.discount, rowData);
            }}
          </Cell>
        </Column>
        <Column width={120} align="left">
          <HeaderCell depth={0} width={0}>
            W. Netto
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(rowData.price.amountTotal, rowData);
            }}
          </Cell>
        </Column>
        <Column width={120} align="left">
          <HeaderCell depth={0} width={0}>
            W. Vat
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(rowData.price.taxTotal, rowData);
            }}
          </Cell>
        </Column>
        <Column width={120} align="left">
          <HeaderCell depth={0} width={0}>
            W. Brutto
          </HeaderCell>
          <Cell width={100} depth={0}>
            {(rowData) => {
              return getCellComponentWithPriceValue(rowData.price.amountGrossTotal, rowData);
            }}
          </Cell>
        </Column>
      </List>
    </>
  );
};

const DocumentDetails: FunctionComponent = () => {
  const [form, setForm] = useState<IDocumentDetailsForm | null>(null);
  const { id, documentId } = useParams<{ id: string; documentId: string }>();
  const [positions, setPositions] = useState<Array<any> | null>(null);

  useEffect(() => {
    DocumentsConnection.editForm(documentId, id).then((response) => {
      const dataTableDisplay = response.data.model.positions;
      dataTableDisplay.push(createSummaryRowData(response.data.model));
      setPositions(dataTableDisplay);
      setForm(response.data);
    });
  }, []);

  if (form === null || positions === null) return <Spinner />;

  return (
    <>
      <WhiteCard>
        <SeparatorEmpty size={1.5} className={styles.separator} />

        <Row>
          <Col xs={4}>
            <Input value={form.model.documentNumber} placeholder={'Numer'} name={'documentNumber'} disabled={true} />
          </Col>
          <Col xs={4}>
            <Input value={form.model.user.fullName} placeholder={'Wystawił'} name={'user'} disabled={true} />
          </Col>
          <Col xs={4}>
            <Select
              placeholder={'Rodzaj dokumentu'}
              name={'saleTypeId'}
              value={form.model.saleType.id}
              options={form.saleTypes.options}
              disabled={true}
            />
          </Col>
        </Row>

        <SeparatorEmpty size={1.5} className={styles.separator} />

        <Row>
          <Col xs={4}>
            <Input
              value={form.model.documentDate}
              placeholder={'Data wystawienia'}
              name={'documentDate'}
              type={'text'}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <Input
              value={form.model.deliveryDate}
              placeholder={'Data dostawy'}
              name={'deliveryDate'}
              type={'text'}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <Input value={form.model.paymentDate} placeholder={'Data płatności'} name={'paymentDate'} disabled={true} />
          </Col>
        </Row>

        <SeparatorEmpty size={1.5} className={styles.separator} />

        <Row>
          <Col xs={8}>
            <Input value={form.model.description} placeholder={'Uwagi'} name={'description'} disabled={true} />
          </Col>
          <Col xs={4}>
            <Input
              value={form.model.countPrinted}
              placeholder={'Ilość wydruków'}
              name={'countPrinted'}
              disabled={true}
            />
          </Col>
        </Row>

        <SeparatorLine size={2} className={styles.separator} />

        <Row>
          <Col xs={4}>
            <Input
              value={form.model.documentPayer.name}
              placeholder={'Nabywca'}
              name={'documentPayerName'}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <Input
              value={
                form.model.documentPayer.street +
                ' ' +
                form.model.documentPayer.houseNumber +
                ', ' +
                form.model.documentPayer.postcode +
                ' ' +
                form.model.documentPayer.city
              }
              placeholder={'Address'}
              name={'documentPayerAddress'}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <Input value={form.model.documentPayer.nip} placeholder={'Nip'} name={'documentPayerNip'} disabled={true} />
          </Col>
        </Row>

        <SeparatorLine size={2} className={styles.separator} />

        <Row>
          <Col xs={4}>
            <Input
              value={form.model.documentRecipient.name}
              placeholder={'Odbiorca'}
              name={'documentRecipientName'}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <Input
              value={
                form.model.documentRecipient.street +
                ' ' +
                form.model.documentRecipient.houseNumber +
                ', ' +
                form.model.documentRecipient.postcode +
                ' ' +
                form.model.documentRecipient.city
              }
              placeholder={'Address'}
              name={'documentRecipientAddress'}
              disabled={true}
            />
          </Col>
          {/*<Col xs={4}>*/}
          {/*  <Input*/}
          {/*    value={form.model.documentRecipient.nip}*/}
          {/*    placeholder={'Nip'}*/}
          {/*    name={'documentRecipientNip'}*/}
          {/*    disabled={true}*/}
          {/*  />*/}
          {/*</Col>*/}
        </Row>

        <SeparatorLine size={2} className={styles.separator} />

        {form.model.documentSeller !== null && (
          <>
            <Row>
              <Col xs={4}>
                <Input
                  value={form.model.documentSeller.name}
                  placeholder={'Sprzedawca'}
                  name={'documentSellerName'}
                  disabled={true}
                />
              </Col>
              <Col xs={4}>
                <Input
                  value={
                    form.model.documentSeller.street +
                    ' ' +
                    form.model.documentSeller.houseNumber +
                    ', ' +
                    form.model.documentSeller.postcode +
                    ' ' +
                    form.model.documentSeller.city
                  }
                  placeholder={'Address'}
                  name={'documentSellerAddress'}
                  disabled={true}
                />
              </Col>
              <Col xs={4}>
                <Input
                  value={form.model.documentSeller.nip}
                  placeholder={'Nip'}
                  name={'documentSellerNip'}
                  disabled={true}
                />
              </Col>
            </Row>
          </>
        )}

        <SeparatorLine size={2} className={styles.separator} />

        {getDocumentPositionsBody(positions)}

        <SeparatorLine size={2} className={styles.separator} />
      </WhiteCard>
    </>
  );
};

export default DocumentDetails;
