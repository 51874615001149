import React, { FunctionComponent } from 'react';

interface ICircleMultipleColors {
  colors: string[];
}

const CircleMultipleColors: FunctionComponent<ICircleMultipleColors> = ({ colors }) => {
  const roundNumber = (number: number) => {
    return Math.round(number * 10) / 10;
  };
  const percentPerColor: number = roundNumber(100 / colors.length);

  const backgroundColors: string[] = [];
  let currentPercent = percentPerColor;
  colors.map((color, index) => {
    if (index > 0) {
      backgroundColors.push(`${color} 0 ${currentPercent}%`);
    } else {
      backgroundColors.push(`${color} ${currentPercent}%`);
    }
    currentPercent = roundNumber(currentPercent + percentPerColor);
  });

  let backgroundStyle = `conic-gradient(${backgroundColors.join(',')})`;

  if (colors.length === 1) {
    backgroundStyle = `conic-gradient(${colors[0]} 0 100%)`;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '25px',
            height: '25px',
            background: backgroundStyle,
            borderRadius: '50%',
          }}
        />
      </div>
    </>
  );
};

export default CircleMultipleColors;
