import React, { FunctionComponent, useEffect, useState } from 'react';
import ProjectFilesConnection from '../../../../utils/connections/projectFiles';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import Input from '../../../../global/atoms/Input';
import Form from '../../../../global/form/Form';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import { Col, Row } from 'react-grid-system';
import Textarea from '../../../../global/atoms/Textarea';
import { handleToast } from '../../../../utils/helpers';

export interface IUpdateFileData {
  name: string;
  description: string;
  fileName: string;
}

const FileEdit: FunctionComponent = () => {
  const { id, fileId } = useParams<{ id: string; fileId: string }>();
  const [data, setData] = useState<IUpdateFileData | null>(null);
  const history = useHistory();

  useEffect(() => {
    ProjectFilesConnection.editFormViewData(fileId, id).then((response) => {
      setData(response.data.model);
    });
  }, []);

  if (data === null) return <Spinner />;

  const handleSubmit = () => {
    handleToast(ProjectFilesConnection.updateFile(fileId, id, data)).then(() => {
      history.push(`/projects/${id}/edit/5`);
    });
  };

  return (
    <>
      <WhiteCard>
        <Row>
          <Col xs={12}>
            <Form>
              <Input
                placeholder={'Nazwa pliku'}
                setState={setData}
                state={data}
                name={'fileName'}
                value={data.fileName}
                disabled={true}
              />
              <Input placeholder={'Nazwa własna'} setState={setData} state={data} name={'name'} value={data.name} />
              <Textarea
                placeholder={'Opis'}
                setState={setData}
                state={data}
                name={'description'}
                value={data.description}
              />
            </Form>
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} />
        <ActionsContainer>
          <ButtonSubmit onClick={handleSubmit} />
        </ActionsContainer>
      </WhiteCard>
    </>
  );
};

export default FileEdit;
