import React, { FunctionComponent } from 'react';
import ListingNetworkEdit from './ListingNetworkEdit';

interface IListingNetworkQuestionEdit {
  activityId: string;
  parentSetState: any;
}

const ListingNetworkQuestionEdit: FunctionComponent<IListingNetworkQuestionEdit> = (data) => {
  return (
    <ListingNetworkEdit activityId={data.activityId} listingType={'question'} parentSetState={data.parentSetState} />
  );
};

export default ListingNetworkQuestionEdit;
