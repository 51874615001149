import React, { FunctionComponent, useEffect, useState } from 'react';
import { IActivityAdd, IFormMaker } from 'utils/models';
import { addActivityState } from 'utils/states';
import { useHistory, useParams } from 'react-router-dom';
import ActivitiesConnection from 'utils/connections/activities';
import { defaultStateMapper, formActionsMapper, formMapper } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import { SET_LOADING } from 'redux/actions';
import { useDispatch } from 'react-redux';

interface IActivitiesEdit {}

const ActivitiesEdit: FunctionComponent<IActivitiesEdit> = () => {
  const [form, setForm] = useState<{
    header: { name: string; actions: Array<IFormMaker> };
    fields: Array<IFormMaker>;
    actions: Array<IFormMaker>;
  }>({ header: { name: '', actions: [] }, fields: [], actions: [] });
  const [data, setData] = useState<IActivityAdd>(addActivityState);
  const [error, setError] = useState<any | null>();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_LOADING, payload: { isLoading: true } });
    Promise.all([
      ActivitiesConnection.getFormEditActivity(id)
        .then((data) => {
          setForm(data.data);
          data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
          setData({ ...addActivityState, ...defaultStateMapper(data.data.fields) });
        })
        .catch((err) => setError(err.response)),
    ])
      .then(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    dispatch({ type: SET_LOADING, payload: { isLoading: true } });
    Promise.all([ActivitiesConnection.updateActivity('', '', id, data).then(() => history.push('/datasets/activity'))])
      .then(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      })
      .catch(() => {
        dispatch({ type: SET_LOADING, payload: { isLoading: false } });
      });
  };

  if (!form.fields.length) return null;

  return (
    <>
      <WhiteCard padding={true}>
        <form onSubmit={handleSubmit}>
          {form.fields.map((item) => formMapper(item, data, setData, error))}
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gridColumnGap: '24px' }}>
            {form.actions.map((item) => formActionsMapper(item))}
          </div>
        </form>
      </WhiteCard>
    </>
  );
};

export default ActivitiesEdit;
