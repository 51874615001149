import { default as axios } from '../AxiosHelper';
import { getOrganizationId } from '../helpers';
import { AxiosPromise } from 'axios';
import { UniversalListing } from '../../views/projects/components/taskActivitiesEdit/listing/ListingNetworkEdit';

const productListingBaseUri = `${
  process.env.REACT_APP_API_URL
}/organizations/${getOrganizationId()}/listing_product_network`;
const questionListingBaseUri = `${
  process.env.REACT_APP_API_URL
}/organizations/${getOrganizationId()}/listing_question_network`;

const ListingConnection = {
  // product
  getEditItemForm: (activityId: string): AxiosPromise => {
    return axios.get(`${productListingBaseUri}/view/form/${activityId}`);
  },
  getNetworkItemList: (activityId: string): AxiosPromise => {
    return axios.get(`${productListingBaseUri}/view/list/${activityId}`);
  },
  saveProductListing: (activityId: string, listing: UniversalListing): AxiosPromise => {
    return axios.put(`${productListingBaseUri}`, {
      activityId: activityId,
      listingData: listing,
    });
  },
  // question
  getEditQuestionForm: (activityId: string): AxiosPromise => {
    return axios.get(`${questionListingBaseUri}/view/form/${activityId}`);
  },
  getNetworkQuestionList: (activityId: string): AxiosPromise => {
    return axios.get(`${questionListingBaseUri}/view/list/${activityId}`);
  },
  saveQuestionListing: (activityId: string, listing: UniversalListing): AxiosPromise => {
    return axios.put(`${questionListingBaseUri}`, {
      activityId: activityId,
      listingData: listing,
    });
  },
};

export default ListingConnection;
