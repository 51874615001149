import React, { FunctionComponent } from 'react';
import styles from '../../../../../global/atoms/atoms.module.scss';

interface ITableHeaderCell {
  dayNumber: number;
  dayShortName: string;
}

const TableHeaderCell: FunctionComponent<ITableHeaderCell> = ({ dayNumber, dayShortName }) => {
  return (
    <div className={styles.timeTableHeaderCell}>
      <div className={styles.timeTableHeaderCellDay}>{dayNumber}</div>
      <div className={styles.timeTableHeaderCellDayName}>{dayShortName}</div>
    </div>
  );
};

export default TableHeaderCell;
