import React, { FunctionComponent } from 'react';
import styles from '../../../projects/components/projectsList/styles.module.scss';
import { useHistory } from 'react-router-dom';
import settings from '../../../../assets/svg/settings.svg';
import ColorCircleLabelText from '../../../../global/atoms/ColorCircleLabelText';

interface IActivitiesListActivity {
  name: string;
  projectId: string;
  taskId: string;
  activityId: string;
  description: string;
  type: string;
  locations: string;
  items: string;
  activity: any;
}

const ActivitiesListActivity: FunctionComponent<IActivitiesListActivity> = ({
  name,
  activityId,
  taskId,
  projectId,
  description,
  locations,
  type,
  items,
  activity,
}) => {
  const history = useHistory();

  const handleGoToEdit = () => {
    history.push(`/projects/${projectId}/tasks/${taskId}/activities-edit?picked=${activityId}`);
  };

  return (
    <div className={`${styles.containerProject}`} onClick={handleGoToEdit}>
      <div
        className={`${styles.colProject} ${styles.colName}`}
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          gridRowGap: '6px',
        }}
      >
        <span>{name}</span>
        <span style={{ fontSize: '12px' }}>{description}</span>
      </div>
      <span className={`${styles.colProject} ${styles.colStatus}`} style={{ textAlign: 'center', flex: 2 }}>
        <span>
          <ColorCircleLabelText color={activity.activityGroupColor}>{activity.activityGroupName}</ColorCircleLabelText>
        </span>
      </span>
      <span className={`${styles.colProject} ${styles.colStatus}`} style={{ textAlign: 'center' }}>
        <span>{type}</span>
      </span>
      <span className={`${styles.colProject} ${styles.colStatus}`} style={{ textAlign: 'center' }}>
        <span>{locations}</span>
      </span>
      <span className={`${styles.colProject} ${styles.colStatus}`} style={{ textAlign: 'center' }}>
        <span>{items}</span>
      </span>
      <span className={styles.colActions}>
        <img src={settings} style={{ cursor: 'pointer' }} alt={'Idź do'} onClick={handleGoToEdit} />
      </span>
    </div>
  );
};

export default ActivitiesListActivity;
