import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { IOrderFilters } from '../../views/projects/components/orders/OrdersList';

const OrderConnection = {
  list: (projectId: string, data: IOrderFilters) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${projectId}/sales/orders/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  filterForm: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/orders/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  editForm: (orderId: string, projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/sales/orders/${orderId}/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  sendOrders: (data: { orders: Array<{ id: string }> }, projectId: string) => {
    return axios.patch(
      `
    ${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${projectId}/sales/orders`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  removeOrders: (data: { orders: Array<{ id: string }> }, projectId: string) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/project/${projectId}/sales/orders`,
      {
        headers: getAuthHeader(),
        data,
      },
    );
  },
};

export default OrderConnection;
