import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateProject } from 'utils/models';
import ProjectsConnection from 'utils/connections/projects';
import { useHistory, useParams } from 'react-router-dom';
import { deepClone, handleSetErrors, parseDateForFrontend } from 'utils/helpers';
import { createProjectState } from 'utils/states';
import WhiteCard from 'global/atoms/WhiteCard';
import Button from 'global/atoms/Button';
import Select from 'global/atoms/Select';
import styles from './styles.module.scss';
import Input from 'global/atoms/Input';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Questions from '../taskActivitiesEdit/Questions';
import SeparatorEmpty from 'global/atoms/separators/SeparatorEmpty';
import Section from 'global/atoms/section/Section';
import Spinner from 'global/atoms/Spinner/Spinner';
import AlertInfo from '../../../../global/atoms/alert/AlertInfo';
import Checkbox from '../../../../global/atoms/Checkbox';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';
import { Col, Row } from 'react-grid-system';
import RadioButton from '../../../../global/atoms/radio/RadioButton';
import { DISABLED_FORM_SAVE_BUTTON } from '../../../../utils/disabledElementsConstants';

interface IBasic {}

const ACTION_NEW = 'ACTION_NEW';
const ACTION_UPDATE = 'ACTION_UPDATE';
const ACTION_DELETE = 'ACTION_DELETE';
const ACTION_DO_NOTHING = 'ACTION_DO_NOTHING';

const Basic: FunctionComponent<IBasic> = () => {
  const [data, setData] = useState<ICreateProject>(createProjectState);
  const [form, setForm] = useState<any>({
    header: {
      name: '',
      editItemTitle: '',
      actions: [],
    },
    fields: [],
    actions: [],
    unassignedQuestions: [],
    assignedQuestions: [],
  });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState<any>({});
  const [formRoles, setFormRoles] = useState<any>(null);
  const [selectedRoles, setSelectedRoles] = useState<Array<any>>([]);
  const [addNew, setAddNew] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  useEffect(() => {
    Promise.all([ProjectsConnection.getFormEditProject(id), ProjectsConnection.getFormEditRoles(id)]).then((data) => {
      setLoading(false);

      setData((state: ICreateProject) => {
        const projectSettings =
          data[0].data.model.projectSettings?.id !== undefined
            ? data[0].data.model.projectSettings
            : state.projectSettings;
        if (projectSettings.numberOrderPattern === '') {
          projectSettings.numberOrderPattern = data[0].data.defaultData.numberOrderPattern;
        }
        if (projectSettings.numberDocumentPattern === '') {
          projectSettings.numberDocumentPattern = data[0].data.defaultData.numberDocumentPattern;
        }
        return {
          ...state,
          name: data[0].data.model.name,
          customerId: data[0].data.model.customer.id,
          projectStatusId: data[0].data.model.projectStatus.id,
          startDate: parseDateForFrontend(data[0].data.model.startDate),
          endDate: parseDateForFrontend(data[0].data.model.endDate),
          projectSettings:
            data[0].data.model.projectSettings?.id !== undefined
              ? data[0].data.model.projectSettings
              : state.projectSettings,
        };
      });
      setForm(data[0].data);
      setSelectedRoles(
        data[1].data.projectRoles.options.map((item: any) => ({
          id: item.id,
          roleId: item.roleId,
          name: item.roleName,
          reporting: item.reporting,
          actionType: item.id === '' ? ACTION_DO_NOTHING : ACTION_UPDATE,
        })),
      );
      setFormRoles(data[1].data);
    });
  }, []);

  const handleSubmit = (event: any) => {
      event.preventDefault();
      const dataClone = deepClone(data);
      dataClone.locationQuestions = {
        all: true,
        assignedCount: form.assignedQuestions.data.length,
        assigned: form.assignedQuestions.data,
        unassigned: form.unassignedQuestions.data,
      };

      dataClone.projectSettings.showOrderCount = parseInt(dataClone.projectSettings.showOrderCount);
      dataClone.projectSettings.showDocumentCount = parseInt(dataClone.projectSettings.showDocumentCount);
      dataClone.projectSettings.photoQuality = parseInt(dataClone.projectSettings.photoQuality);
      dataClone.projectSettings.photoResolution = parseInt(dataClone.projectSettings.photoResolution);
      dataClone.projectSettings.photoMaxSize = parseFloat(dataClone.projectSettings.photoMaxSize);
      dataClone.projectSettings.prepareDocumentCount = parseInt(dataClone.projectSettings.prepareDocumentCount);

      ProjectsConnection.updateProject(dataClone, id)
        .then(() => {
          history.push(`/projects/${id}/edit/2`);
        })
        .catch((err) => {
          handleSetErrors(err, setErrors);
        });
    },
    handleToggleAddNew = () => {
      setAddNew((state) => !state);
    };

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const name = event.currentTarget.name;
    setData({
      ...data,
      projectSettings: {
        ...data.projectSettings,
        [name]: event.currentTarget.value,
      },
    });
  };

  if (loading) return <Spinner />;

  if (!formRoles) return null;

  const handleAddOrRemoveRole = (roleIndex: number) => {
    const selectedRoleData = deepClone(selectedRoles[roleIndex]);
    const selectedRolesClone = deepClone(selectedRoles);
    if (selectedRoleData.id === '' && selectedRoleData.actionType === ACTION_DO_NOTHING) {
      selectedRolesClone[roleIndex].actionType = ACTION_NEW;
    }

    if (selectedRoleData.id === '' && selectedRoleData.actionType === ACTION_NEW) {
      selectedRolesClone[roleIndex].actionType = ACTION_DO_NOTHING;
    }

    if (selectedRoleData.id !== '' && selectedRoleData.actionType === ACTION_UPDATE) {
      selectedRolesClone[roleIndex].actionType = ACTION_DELETE;
    }

    if (selectedRoleData.id !== '' && selectedRoleData.actionType === ACTION_DELETE) {
      selectedRolesClone[roleIndex].actionType = ACTION_UPDATE;
    }

    setSelectedRoles(selectedRolesClone);
  };

  const checkRoleIsActive = (roleIndex: number): boolean => {
    const selectedRoleData = selectedRoles[roleIndex];
    return (
      (selectedRoleData.id !== '' && selectedRoleData.actionType !== ACTION_DELETE) ||
      (selectedRoleData.id === '' && selectedRoleData.actionType === ACTION_NEW)
    );
  };

  const handleChangeIsReporting = (roleIndex: number) => {
    const selectedRolesClone = deepClone(selectedRoles);
    selectedRolesClone.map((item: any) => {
      item.reporting = false;
    });
    selectedRolesClone[roleIndex].reporting = !selectedRolesClone[roleIndex].reporting;
    setSelectedRoles(selectedRolesClone);
  };

  const handleChangeProjectBoolSettings = (name: string) => {
    const projectSettings: any = { ...data.projectSettings };
    projectSettings[name] = !projectSettings[name];
    setData({ ...data, ...{ projectSettings: projectSettings } });
  };

  const handleProjectSettingsSelectChange = (object: any, selectProps: any) => {
    const projectSettings: any = { ...data.projectSettings };
    const name = selectProps.name;
    projectSettings[name] = object.value;
    setData({ ...data, ...{ projectSettings: projectSettings } });
  };

  return (
    <>
      <WhiteCard padding={true}>
        <form onSubmit={handleSubmit}>
          <div className={styles.wrapperFields}>
            <Input
              style={{ gridColumn: 'span 2' }}
              type={'text'}
              name={'name'}
              value={data.name}
              placeholder={'Nazwa'}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Select
              placeholder={'Klient'}
              disabled={false}
              name={'customerId'}
              value={data.customerId}
              options={form.customers.options}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Select
              placeholder={'Status'}
              disabled={false}
              name={'projectStatusId'}
              value={data.projectStatusId}
              options={form.projectStatuses.options}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Input
              type={'date'}
              name={'startDate'}
              value={data.startDate}
              placeholder={'Data rozpoczęcia'}
              setState={setData}
              state={data}
              errors={errors}
            />
            <Input
              type={'date'}
              name={'endDate'}
              value={data.endDate}
              placeholder={'Data zakończenia'}
              setState={setData}
              state={data}
              errors={errors}
            />
          </div>
          <SeparatorEmpty />
          <div className={styles.wrapperOperationsSells}>
            <Section title={'Opcje wizyty'} className={styles.optionsSells}>
              <CheckBox
                isChecked={data.projectSettings.isEnableTimer}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableTimer')}
              >
                Pomiar czasu wizyty
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableDocumentDump}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableDocumentDump')}
              >
                Logowanie dokumentów
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableSynchroGps}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableSynchroGps')}
              >
                Synchronizacja Gps
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableOneTimeVisits}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableOneTimeVisits')}
              >
                Wizyty jednorazowe
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableTaskGrouping}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableTaskGrouping')}
              >
                Grupowanie zadań na wizycie
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableDownloadPhotoFromDisk}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableDownloadPhotoFromDisk')}
              >
                Pobieranie zdjęć z dysku
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableOnlyLocationFromActivity}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableOnlyLocationFromActivity')}
              >
                Pobieranie lokalizacji tylko z aktywności
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableSaveDocumentWithoutPrint}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableSaveDocumentWithoutPrint')}
              >
                Możliwość zapisu dokumentów bez wymogu wydrukowania
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableItemNetworkListing}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableItemNetworkListing')}
              >
                Listing produkty - sieci
              </CheckBox>
              <CheckBox
                isChecked={data.projectSettings.isEnableQuestionNetworkListing}
                onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableQuestionNetworkListing')}
              >
                Listing pytania - sieci
              </CheckBox>
            </Section>
          </div>

          <SeparatorEmpty />
          <div className={styles.wrapperOperationsSells}>
            <Section title={'Opcje zdjęć'} className={styles.optionsSells}>
              <Input
                type={'number'}
                name="photoQuality"
                value={data.projectSettings.photoQuality}
                placeholder={'Kompresja [%]'}
                onChange={handleInputChange}
                state={data}
                errors={errors.projectSettings}
              />
              <Input
                type={'number'}
                name="photoResolution"
                value={data.projectSettings.photoResolution}
                placeholder={'Rozdzielczość [px]'}
                onChange={handleInputChange}
                state={data}
                errors={errors.projectSettings}
              />
              <Input
                type={'number'}
                name="photoMaxSize"
                value={data.projectSettings.photoMaxSize}
                placeholder={'Maksymalny Rozmiar [Mb]'}
                onChange={handleInputChange}
                state={data}
                errors={errors.projectSettings}
              />
            </Section>
          </div>

          <SeparatorEmpty />
          <AlertInfo>
            Numerację należy zbudować wykorzystując następujące elementy rozdzielane znakiem <b>/</b> :<br />
            <b>PREFIX</b> - dowolny ciąg znaków alfanumerycznych
            <br />
            <b>NR</b> - kolejny numer dokumentu
            <br />
            <b>MM</b> - miesiąc
            <br />
            <b>RRRR</b> - rok
            <br />
            <b>RR</b> - rok (skrócony)
            <br />
            <b>TYPE</b> - nazwa rodzaju dokumentu (FV, PAR, ZAM)
            <br />
            <b>USER</b> - unikalny kod użytkownika, który wystawił dokument
            <br />
            Przykłady schematów numeracji: NR/RRRR, PREFIX/USER/NR/MM/RR
          </AlertInfo>
          <SeparatorEmpty size={1.5} className={styles.separator} />

          <Section title={'Opcje sprzedaży'}>
            <SeparatorEmpty size={0.5} className={styles.separator} />

            {/*<CheckBox*/}
            {/*  isChecked={data.projectSettings.isEnableOrder}*/}
            {/*  onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableOrder')}*/}
            {/*>*/}
            {/*  Zamówienia*/}
            {/*</CheckBox>*/}
            {/*<CheckBox*/}
            {/*  isChecked={data.projectSettings.isEnableInvoice}*/}
            {/*  onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableInvoice')}*/}
            {/*>*/}
            {/*  Faktury*/}
            {/*</CheckBox>*/}
            {/*<CheckBox*/}
            {/*  isChecked={data.projectSettings.isEnableBill}*/}
            {/*  onClick={handleChangeProjectBoolSettings.bind(null, 'isEnableBill')}*/}
            {/*>*/}
            {/*  Paragony*/}
            {/*</CheckBox>*/}
            <Row>
              <Col xs={6}>
                <Input
                  name="salePrefix"
                  value={data.projectSettings.salePrefix}
                  placeholder={'Nazwa skrócona projektu (PREFIX)'}
                  onChange={handleInputChange}
                  state={data}
                  errors={errors.projectSettings}
                />
              </Col>
            </Row>
            <SeparatorEmpty />
            <Row>
              <Col xs={6}>
                <Input
                  name="numberDocumentPattern"
                  value={data.projectSettings.numberDocumentPattern}
                  placeholder={'Szablon numeracji dokumentów'}
                  onChange={handleInputChange}
                  state={data}
                  errors={errors.projectSettings}
                />
              </Col>
              <Col xs={6}>
                <Input
                  type={'number'}
                  name="showDocumentCount"
                  value={data.projectSettings.showDocumentCount}
                  placeholder={'Wyświetlana ilość dokumentów historycznych'}
                  onChange={handleInputChange}
                  state={data}
                  errors={errors.projectSettings}
                />
              </Col>
            </Row>
            <SeparatorEmpty />
            <Row>
              <Col xs={6}>
                <Input
                  name="numberOrderPattern"
                  value={data.projectSettings.numberOrderPattern}
                  placeholder={'Szablon numeracji zamówień'}
                  onChange={handleInputChange}
                  state={data}
                  errors={errors.projectSettings}
                />
              </Col>
              <Col xs={6}>
                <Input
                  type="number"
                  name="showOrderCount"
                  value={data.projectSettings.showOrderCount}
                  placeholder={'Wyświetlana ilość zamówień historycznych'}
                  onChange={handleInputChange}
                  state={data}
                  errors={errors.projectSettings}
                />
              </Col>
            </Row>
            <SeparatorEmpty />
            <Row>
              <Col xs={6}>
                <Select
                  placeholder={'Podatek w dokumentach obliczany od:'}
                  name={'calcDocumentType'}
                  value={data.projectSettings.calcDocumentType}
                  options={form.calcDocumentTypes.options}
                  errors={errors}
                  onChange={handleProjectSettingsSelectChange}
                />
              </Col>
              <Col xs={6}>
                <Input
                  type="number"
                  name="prepareDocumentCount"
                  value={data.projectSettings.prepareDocumentCount}
                  placeholder={'Maksymalna ilość możliwych do wystawienia dokumentów sprzedaży'}
                  onChange={handleInputChange}
                  state={data}
                  errors={errors.projectSettings}
                />
              </Col>
            </Row>
          </Section>
          {/*<SeparatorEmpty />*/}
          {/*<AlertInfo>Dodane pytania zostaną skopiowane na wszystkie lokalizacje projektu</AlertInfo>*/}
          <SeparatorEmpty />

          <Questions
            unassigned={form.unassignedQuestions.data}
            assigned={form.assignedQuestions.data}
            setForm={setForm}
            title={'Pytania do lokalizacji'}
            defaultValueEdit={true}
            type={'project'}
          />
          <ActionsContainer style={{ marginTop: '16px' }}>
            <ButtonSubmit form={form} />
          </ActionsContainer>
        </form>
      </WhiteCard>
      <WhiteCard padding={true} style={{ marginTop: '24px', paddingTop: '10px' }}>
        <div className={styles.basicWrapperHeader}>
          <div style={{ width: '100px' }}>Aktywna</div>
          <span style={{ flex: 1 }}>GRUPA</span>
          <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>RAPORTUJĄCY</div>
          <div style={{ width: '50px' }} />
        </div>
        <form onSubmit={handleSubmit}>
          {selectedRoles.map((item: any, index) => (
            <div className={styles.selectedRoles} key={index}>
              <div style={{ width: '100px' }}>
                <Checkbox isChecked={checkRoleIsActive(index)} onClick={handleAddOrRemoveRole.bind(null, index)} />
              </div>
              <span style={{ flex: 1 }}>{item.name}</span>
              <div style={{ width: '100px', display: 'flex', justifyContent: 'center' }}>
                <RadioButton
                  checked={selectedRoles[index].reporting}
                  onChange={handleChangeIsReporting.bind(null, index)}
                />
              </div>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'flex-end', gridColumnGap: '16px', marginTop: '24px' }}>
            <Button style={{ width: 'auto' }} onClick={handleToggleAddNew}>
              {!addNew ? 'Dodaj' : 'Cofnij'}
            </Button>
            <Button
              disabled={form?.disabledElements?.includes(DISABLED_FORM_SAVE_BUTTON)}
              onClick={() => {
                ProjectsConnection.assignRolesToProject(id, { roles: selectedRoles }).then(() =>
                  history.push(`/projects/${id}/edit/2`),
                );
              }}
            >
              Zapisz
            </Button>
          </div>
        </form>
      </WhiteCard>
    </>
  );
};

export default Basic;
