import { default as axios } from '../AxiosHelper';
import { getAuthHeader } from '../helpers';

const OrganizationsConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getOrganizations: (data: any) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/list`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createOrganization: (data: any) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateOrganization: (id: string, data: any) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/organizations/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersOrganizationsList: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddOrganization: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditOrganization: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  getLogo: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/logo`, {
      headers: getAuthHeader(),
    });
  },
};

export default OrganizationsConnection;
