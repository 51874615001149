import { default as axios } from '../AxiosHelper';
import { getAuthHeader } from '../helpers';

const CountriesConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getCountries: (data: any) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/countries/list`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createCountry: (data: any) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/countries`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateCountry: (id: string, data: any) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/countries/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersCountriesList: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/countries/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddCountry: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/countries/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditCountry: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/countries/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
};

export default CountriesConnection;
