import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { ICreateCategory, IGetCategories } from '../models';

const baseUrl = `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/items/categories`;

const createUpdateData = (data: ICreateCategory) => {
  return {
    ...data,
    ...{
      name: data.name,
    },
  };
};

const CategoriesConnection = {
  getCategories: (data: IGetCategories) => {
    return axios.post(`${baseUrl}/list`, data, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${baseUrl}`, {
      headers: { ...getAuthHeader() },
      data: { itemCategories: data },
    });
  },
  createCategory: (data: ICreateCategory) => {
    return axios.post(`${baseUrl}`, createUpdateData(data), {
      headers: getAuthHeader(),
    });
  },
  updateCategory: (id: string, data: ICreateCategory) => {
    return axios.put(`${baseUrl}/${id}`, createUpdateData(data), {
      headers: getAuthHeader(),
    });
  },
  getFormEditCategory: (id: string) => {
    return axios.get(`${baseUrl}/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddCategory: () => {
    return axios.get(`${baseUrl}/form`, {
      headers: getAuthHeader(),
    });
  },
};

export default CategoriesConnection;
