import React, { FunctionComponent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { handleToastErrorCallback } from '../../../utils/helpers';
import PhotoReviewThumb, { IPhotoThumbState } from './PhotoReviewThumb';
import {
  IReviewPhotoSimple,
  IVisitPhotoReviewEntity,
  IPhotoReviewRequestData,
  IPhotoReviewFormState,
  IPhotoReviewModal,
  IReviewVisit,
  IFiltersPhotoReview,
  IPhotoReviewFeedback,
  IPhotoReviewErrors,
} from '../../../utils/models';

import List5 from '../../../global/table/List5';
import Column5 from '../../../global/table/Column5';
import { Cell, HeaderCell } from 'rsuite-table5';
import BadgeStatus from '../../../global/atoms/badge/BadgeStatus';
import ActionsContainer from '../../../global/atoms/ActionsContainer';
import IconButton from '../../../global/atoms/IconButton';
import WhiteCard from '../../../global/atoms/WhiteCard';
import Spinner from '../../../global/atoms/Spinner/Spinner';
import { useHistory } from 'react-router-dom';
import connection from '../../../utils/connections/visitReviews';
import VisitReviewsConnection from '../../../utils/connections/visitReviews';
import {
  photoReviewRequestDataState,
  photoReviewModalState,
  photoReviewFilterState,
  photoReviewErrorDef,
} from '../../../utils/states';
import PhotoReviewModal from './PhotoReviewModal';
import Pagination from '../../../global/pagination/Pagination';
import { handleClearFilters, loadFilters } from '../../../utils/filterHelper';
import Filters from '../../../global/filters/Filters';
import Select from '../../../global/atoms/Select';
import FiltersStorage from '../../../utils/FiltersStorage';
import HeaderButtons from '../../../global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from '../../../global/atoms/ButtonFilters';
import SelectedFilter from '../../../global/atoms/filter/SelectedFilter';
import styles from './styles.module.scss';
import { ReactSmartScroller } from 'react-smart-scroller';
import Section from "../../../global/atoms/section/Section";
import DayPickerInputCustom from "../../../global/atoms/DayPickerInputCustom";
import {Col, Row} from "react-grid-system";
import {format} from "date-fns";

const VisitPhotoReview: FunctionComponent = () => {
  const FILTER_STORAGE_KEY = 'visitPhotoReview6';

  const [reviewData, setReviewData] = useState<IVisitPhotoReviewEntity[] | null>(null);
  const [modalData, setModalData] = useState<IPhotoReviewModal | null>(null);
  const history = useHistory();
  let thumbsRef: { [index: string]: React.MutableRefObject<null> } = {};
  const scrollerRef = useRef(null);
  const [formErrors, setFormErrors] = useState<IPhotoReviewErrors>(photoReviewErrorDef);

  const photoThumbStates: {
    [index: string]: { get: IPhotoThumbState; set: React.Dispatch<React.SetStateAction<IPhotoThumbState>> };
  } = {};

  // filters
  const FilterStorage = new FiltersStorage();
  const filterRef = useRef(false);
  const [filters, setFilters] = useState<IFiltersPhotoReview>(photoReviewFilterState);
  const [requestData, setRequestData] = useState<IPhotoReviewRequestData>(
    loadFilters(photoReviewRequestDataState, FILTER_STORAGE_KEY),
  );
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);

  const loadData = () => {
    thumbsRef = {};
    setReviewData(null);
    filterRef.current = false;

    connection.getPhotoReviewData(requestData).then((result) => {
      setReviewData(result.data.result);
    });
  };

  useEffect(() => {
    //loadData(); -> useEffect requestPaginate
    connection.getPhotoReviewFilter().then((result) => {
      setFilters({
        ...filters,
        ...result.data,
      });

      // default date range
      setRequestData({
        ...requestData,
        dateRange: result.data.dateRange
      })
    });
  }, []);

  // pagination change + init
  useEffect(() => {
    loadData();
  }, [requestData.requestPaginate]);

  // filter clear
  useEffect(() => {
    if (filterRef.current) loadData();
  }, [requestData]);

  if (!reviewData) return <Spinner />;

  const photoReview = (ps: IReviewPhotoSimple, visit: IReviewVisit, state: IPhotoThumbState): void => {
    setModalData({
      ...photoReviewModalState,
      open: true,
      photoSimple: ps,
      visit: visit,
      toggler: Date.now(),
      isReviewed: state.hasSLAFeedback || state.hasKPIFeedback,
    });
    setFormErrors(photoReviewErrorDef);
  };

  const submitReviews = (identifier: string, photoFeedbacks: IPhotoReviewFeedback[], openNext = false): void => {
    const promises: Promise<void>[] = [];

    photoFeedbacks.forEach((feed) => {
      const _state: IPhotoReviewFormState = {
        type: feed.feedbackType,
        note: feed.note,
        positions: feed.feedbackDetails.map((fd) => {
          return {
            id: fd.id,
            value: fd.value ?? '0', // todo: excluduj te z nullem (puste kpi)
            feedbackQuestionId: fd.feedbackQuestion.id,
          };
        }),
      };

      let req: Promise<any>;
      if (feed.id) {
        // edit
        req = VisitReviewsConnection.submitPhotoReviewEdit(identifier, feed.id, _state);
      } else {
        req = VisitReviewsConnection.submitPhotoReview(identifier, _state);
      }

      const res = handleToastErrorCallback(req, (errors) => {
        if (errors) {
          setFormErrors({
            ...formErrors,
            [feed.feedbackType]: errors,
          });
        }
      }).then(() => {
        const tmp = photoThumbStates[identifier].get;
        if (_state.type === 'KPI') tmp.hasKPIFeedback = true;
        if (_state.type === 'SLA') tmp.hasSLAFeedback = true;
        photoThumbStates[identifier].set(tmp);
      });

      promises.push(res);
    });

    Promise.all(promises).then(() => {
      setModalData(null);
      if (openNext) triggerThumbReview('next', identifier);
    });
  };

  const triggerThumbReview = (type: 'next' | 'prev', identifier: string): boolean => {
    const tr_keys = Object.keys(thumbsRef);
    if (tr_keys[0] === identifier && type === 'prev') return true;
    if (tr_keys[tr_keys.length - 1] === identifier && type === 'next') return true;

    let currenHit = false;
    let prevPid: string | null = null;
    let targetPid: string | null = null;
    let wallReached = false;

    for (const pid in thumbsRef) {
      if (type === 'next' && currenHit) {
        targetPid = pid;
        break;
      }
      if (pid === identifier) currenHit = true;
      if (type === 'prev' && currenHit && prevPid) {
        targetPid = prevPid;
        break;
      }
      prevPid = pid;
    }

    if (targetPid && thumbsRef[targetPid]) {
      // open review modal
      reviewData.forEach((rd) => {
        rd.photoSimple.forEach((ps) => {
          if (ps.value.refQuestionAnswerId === targetPid) {
            photoReview(ps, rd.visit, photoThumbStates[targetPid].get);
          }
        });
      });

      if (type === 'prev' && targetPid === tr_keys[0]) wallReached = true;
      if (type === 'next' && targetPid === tr_keys[tr_keys.length - 1]) wallReached = true;
    }

    return wallReached;
  };

  const photosContainer = (rowData: IVisitPhotoReviewEntity): ReactNode => {
    const photos: Array<ReactElement> = [];

    rowData.photoSimple.forEach((obj: IReviewPhotoSimple) => {
      thumbsRef[obj.value.refQuestionAnswerId] = useRef(null);
      const id: string = obj.value.refQuestionAnswerId;
      const [sget, sset] = useState<IPhotoThumbState>({
        hasKPIFeedback: obj.hasKPIFeedback,
        hasSLAFeedback: obj.hasSLAFeedback,
      });
      photoThumbStates[id] = { get: sget, set: sset };

      photos.push(
        <PhotoReviewThumb
          reference={thumbsRef[id]}
          photoReview={photoReview}
          reviewPhotoSimple={obj}
          visit={rowData.visit}
          state={photoThumbStates[id].get}
          key={id}
        />,
      );
    });

    let dynamicWidth = '30';
    if (photos.length > 30) dynamicWidth = '20';
    else if (photos.length >= 20) dynamicWidth = '30';
    else if (photos.length >= 15) dynamicWidth = '45';
    else if (photos.length >= 10) dynamicWidth = '60';
    else if (photos.length <= 5) dynamicWidth = '80';

    return (
      <div className={`${styles.photoReviewThumbContainer} visit-thumbs-container-${rowData.visit.id}`}>
        <ReactSmartScroller
          ref={scrollerRef}
          thumb={<div className={styles.smartScrollHandle} style={{ width: `${dynamicWidth}%` }} />}
          draggable
        >
          {photos}
        </ReactSmartScroller>
      </div>
    );
  };

  const handleEdit = (projectId: string, visitId: string) => {
    history.push(`/projects/${projectId}/visits/${visitId}/edit`);
  };

  const handleToggleFilters = () => {
    setToggleFilters((state: boolean) => !state);
  };
  const handleSubmitFilters = (event?: any) => {
    if (typeof event === 'object') event.preventDefault();
    FilterStorage.save({ ...requestData }, FILTER_STORAGE_KEY);
    setToggleFilters(false);
    loadData();
  };

  const handleSingleFilterRemove = (field: string) => {
    filterRef.current = true;
    const tmpRequestData = { ...requestData, [field]: '' };
    FilterStorage.save(tmpRequestData, FILTER_STORAGE_KEY);
    setRequestData(tmpRequestData);
  };

  const filterTopSelect = [
    { name: 'Projekt', key: 'projectId', filterKey: 'projects' },
    { name: 'Zadania', key: 'activityId', filterKey: 'tasks' },
    { name: 'Pytania', key: 'questionId', filterKey: 'questions' },
    { name: 'Grupy użytkowników', key: 'userRoleId', filterKey: 'merchRoles' },
    { name: 'Użytkownicy', key: 'userId', filterKey: 'users' },
  ];

  const filterDateOnChange = (date: Date, from: boolean) => {
    const tmp = requestData.dateRange;
    try {
      tmp[from?'dateFrom':'dateTo'] = format(date, 'yyyy-LL-dd');
    } catch(err) {
      console.log('invalid date - skip');
    }

    setRequestData({
      ...requestData,
      dateRange: tmp
    });
  }

  return (
    <>
      <HeaderButtons>
        {/*{requestData.projectId != '' && <div onClick={() => {*/}
        {/*  filterRef.current = true;*/}
        {/*  setRequestData({...requestData, projectId: ''});*/}
        {/*}} className={styles.activeFilterLabel}>{requestData.projectId}</div>}*/}
        <div className={styles.activeFilterLabelWrapper}>
          {filterTopSelect.map((obj) => {
            // @ts-ignore
            const value = filters[obj.filterKey].options.find((p) => p.id === requestData[obj.key]);
            // @ts-ignore
            if (requestData[obj.key] && requestData[obj.key] != '') {
              return (
                <SelectedFilter
                  name={obj.name}
                  value={value?.name ?? value?.value}
                  onRemoveClick={handleSingleFilterRemove.bind(null, obj.key)}
                />
              );
            }
          })}
        </div>

        <ButtonFilters onClick={handleToggleFilters} />
      </HeaderButtons>

      <WhiteCard mobileTransparent={true} padding={false}>
        <List5
          data={reviewData}
          count={reviewData.length}
          rowExpandedHeight={220}
          defaultExpandAllRows={true}
          renderRowExpanded={photosContainer}
          rowClassName={'photo-review'}
        >
          <Column5 resizable={true} align="left" width={1} flexGrow={1}>
            <HeaderCell depth={0} width={0}>
              Lokalizacja
            </HeaderCell>
            <Cell width={100} depth={0} height={55}>
              {(rowData: IVisitPhotoReviewEntity) => rowData.visit.locationName}
            </Cell>
          </Column5>
          <Column5 resizable={true} align="left" width={1} flexGrow={1}>
            <HeaderCell depth={0} width={0}>
              Raportujący
            </HeaderCell>
            <Cell width={100} depth={0} height={55}>
              {(rowData: IVisitPhotoReviewEntity) => rowData.visit.reportingUserName}
            </Cell>
          </Column5>

          <Column5 verticalAlign={'middle'} align="left" width={150}>
            <HeaderCell depth={0} width={0}>
              Sieć
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData: IVisitPhotoReviewEntity) => rowData.visit.networkName}
            </Cell>
          </Column5>

          <Column5 resizable={true} align="left" width={150}>
            <HeaderCell depth={0} width={0}>
              Data
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData: IVisitPhotoReviewEntity) => rowData.visit.startDate}
            </Cell>
          </Column5>

          <Column5 resizable={true} width={150} align="right">
            <HeaderCell depth={0} width={0}>
              Status
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData: IVisitPhotoReviewEntity) => {
                return <BadgeStatus color={rowData.visit.status.color}>{rowData.visit.status.name}</BadgeStatus>;
              }}
            </Cell>
          </Column5>

          <Column5 align="right" width={90} fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData: IVisitPhotoReviewEntity) => (
                <ActionsContainer>
                  <IconButton
                    onClick={handleEdit.bind(null, rowData.visit.projectId, rowData.visit.id)}
                    icon={'settings'}
                  />
                </ActionsContainer>
              )}
            </Cell>
          </Column5>
        </List5>
      </WhiteCard>

      {modalData !== null && (
        <PhotoReviewModal
          onNavArrowClick={triggerThumbReview}
          // @ts-ignore
          setModalData={setModalData}
          modalData={modalData}
          submitReview={submitReviews}
          errors={formErrors}
        />
      )}

      <Pagination
        count={reviewData.length}
        page={requestData.requestPaginate.page.toString()}
        limit={requestData.requestPaginate.limit.toString()}
        setState={setRequestData}
      />

      <Filters
        toggleFilters={toggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={() => {
          handleClearFilters(
            setRequestData,
            photoReviewRequestDataState,
            () => {
              filterRef.current = true;
              setToggleFilters(false);
            },
            FILTER_STORAGE_KEY,
          );
        }}
        handleToggleFilters={handleToggleFilters}
      >
        <Select
          placeholder={'Projekty'}
          name={'projectId'}
          value={requestData.projectId}
          options={filters?.projects.options}
          state={requestData}
          setState={setRequestData}
        />

        <Select
          placeholder={'Zadania'}
          name={'activityId'}
          value={requestData.activityId}
          options={filters?.tasks.options}
          state={requestData}
          setState={setRequestData}
        />

        <Select
          placeholder={'Pytania'}
          name={'questionId'}
          value={requestData.questionId}
          options={filters?.questions.options.map((q) => ({ id: q.id, name: q.value }))}
          state={requestData}
          setState={setRequestData}
        />

        <Select
          placeholder={'Grupy użytkowników'}
          name={'userRoleId'}
          value={requestData.userRoleId}
          options={filters?.merchRoles.options}
          state={requestData}
          setState={setRequestData}
        />

        <Select
          placeholder={'Użytkownicy'}
          name={'userId'}
          value={requestData.userId}
          options={filters?.users.options}
          state={requestData}
          setState={setRequestData}
        />

        <Section title={'Data wykonania wizyty'}>
          <Row>
            <Col xs={12}>
              <DayPickerInputCustom placeholder={'od'}
                                    valueType={'yyyy-mn-dd'}
                                    onChange={(date) => filterDateOnChange(date, true)}
                                    value={requestData.dateRange.dateFrom}/>
            </Col>
            <br /><br />
            <Col xs={12}>
              <DayPickerInputCustom placeholder={'do'}
                                    valueType={'yyyy-mn-dd'}
                                    onChange={(date) => filterDateOnChange(date, false)}
                                    value={requestData.dateRange.dateTo}/>
            </Col>
          </Row>
        </Section>

      </Filters>
    </>
  );
};

export default VisitPhotoReview;
