interface IFiltersStorage {
  get(customKey?: string): any;
  save(data: any): void;
  remove(customKey?: string): void;
  storageKey: string;
}

export default class implements IFiltersStorage {
  storageKey;

  constructor() {
    this.storageKey = `[FILTER][${window.location.pathname}]`;
  }

  get(customKey?: string): any {
    const key = customKey !== undefined ? customKey : this.storageKey;
    let dataReturn = null;
    try {
      dataReturn = localStorage.getItem(key);
      if (dataReturn !== null) {
        dataReturn = JSON.parse(dataReturn);
      }
    } catch (exception) {
      console.log(exception);
    }
    return dataReturn;
  }

  save(data: any, customKey?: string): void {
    const key = customKey !== undefined ? customKey : this.storageKey;
    localStorage.setItem(key, JSON.stringify(data));
  }

  remove(customKey?: string): void {
    const key = customKey !== undefined ? customKey : this.storageKey;
    localStorage.removeItem(key);
  }
}
