import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import Checkbox from '../../../../global/atoms/Checkbox';
import { IRole } from '../../../../utils/models';
import styles from './styles.module.scss';

interface ICustomRoles {
  state: any;
  setState: Dispatch<SetStateAction<any>>;
  options: Array<any>;
  errors?: any;
}

const CustomRoles: FunctionComponent<ICustomRoles> = ({ state, setState, options, errors }) => {
  const handleToggleRole = (event: any) => {
      const mapped = state.roles.map((item: { roleId: string; default: boolean }) => ({
        roleId: item.roleId,
        default: item.default,
      }));
      //TODO: udoskonalić, jeśli usunie się rolę która ma default, to niech default przjedzie na jakąś inną
      const mapped2 = state.roles.map((item: { roleId: string; default: boolean }) => item.roleId);
      if (mapped2.includes(event.currentTarget.dataset.id)) {
        const filtered = mapped.filter(
          (item: { roleId: string; default: boolean }) => item.roleId !== event.currentTarget.dataset.id,
        );
        setState({ ...state, roles: filtered.map((item: any) => ({ roleId: item.roleId, default: item.default })) });
      } else if (state.roles.length) {
        setState({ ...state, roles: [...state.roles, { roleId: event.currentTarget.dataset.id, default: false }] });
      } else {
        setState({ ...state, roles: [...state.roles, { roleId: event.currentTarget.dataset.id, default: true }] });
      }
    },
    handleToggleRoleRadio = (event: any) => {
      setState({
        ...state,
        roles: state.roles.map((item: any) => {
          if (item.roleId === event.currentTarget.dataset.id) {
            return { roleId: item.roleId, default: true };
          } else {
            return { roleId: item.roleId, default: false };
          }
        }),
      });
    },
    handleToggleRoleAll = () => {
      if (state.roles.length) {
        setState({ ...state, roles: [] });
      } else {
        setState({
          ...state,
          roles: options.map((item: IRole, idx: number) => {
            if (idx) {
              return { roleId: item.roleId, default: false };
            } else {
              return { roleId: item.roleId, default: true };
            }
          }),
        });
      }
    };

  return (
    <section>
      <div className={styles.container}>
        <div className={styles.containerHeader}>
          <Checkbox isChecked={state.roles.length} isMixed={true} onClick={handleToggleRoleAll} />
          Nazwa Grupy
        </div>
        Grupa Domyślna
      </div>
      {options.map((item: IRole) => (
        <div key={item.roleId} className={styles.wrapper}>
          <div className={styles.flex}>
            <Checkbox
              id={item.roleId}
              isMixed={false}
              isChecked={state.roles.map((item: any) => item.roleId).includes(item.roleId)}
              onClick={handleToggleRole}
            />
            {item.roleName}
          </div>
          {state.roles.map((stateItem: any) => {
            if (stateItem.roleId === item.roleId) {
              return (
                <span
                  onClick={handleToggleRoleRadio}
                  data-id={item.roleId}
                  data-name={name}
                  className={stateItem.default ? styles.radioButtonActive : styles.radioButton}
                />
              );
            }
          })}
        </div>
      ))}
      {errors && <span className={styles.errors}>{errors['roles']}</span>}
    </section>
  );
};

export default CustomRoles;
