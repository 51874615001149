import React, { FunctionComponent, useState } from 'react';
import InstancesConnection from '../../../../../utils/connections/instances';
import styles from './styles.module.scss';
import { TOGGLE_LOADING_SCREEN } from '../../../../../redux/actions';
import { useDispatch } from 'react-redux';
import ReactDOM from 'react-dom';
import stylesLayout from '../../../../../global/layout/layout.module.scss';
import commonStyles from '../../../../../global/common.module.scss';
import ActionsContainer from '../../../../../global/atoms/ActionsContainer';
import Button from '../../../../../global/atoms/Button';

interface IVisitShowFullImage {
  questionAnswerId: string;
}

const VisitShowFullImage: FunctionComponent<IVisitShowFullImage> = ({ questionAnswerId }) => {
  type stateType = 'default' | 'showFullImage';
  const [state, setState] = useState<stateType>('default');
  const [imageData, setImageData] = useState<{ id: string; value: string }>({ id: '', value: '' });
  const dispatch = useDispatch();

  const loadImage = () => {
    dispatch({ type: TOGGLE_LOADING_SCREEN, payload: { show: true } });
    InstancesConnection.getVisitImage(questionAnswerId)
      .then((response) => {
        setImageData(response.data);
        setState('showFullImage');
      })
      .finally(() => {
        dispatch({ type: TOGGLE_LOADING_SCREEN, payload: { show: false } });
      });
  };

  return (
    <>
      {state === 'showFullImage' && (
        <>
          {ReactDOM.createPortal(
            <>
              <div className={stylesLayout.blackOverlay}>
                <div className={commonStyles.popupActionsContainer}>
                  <ActionsContainer>
                    <Button onClick={() => setState('default')}>Zamknij</Button>
                  </ActionsContainer>
                </div>
                <div className={styles.popupImageContainer}>
                  <div>
                    <img src={imageData.value} />
                  </div>
                </div>
              </div>
            </>,
            document.getElementById('layout-portal')!,
          )}
        </>
      )}
      <div onClick={loadImage} className={styles.showFullImageButton} />
    </>
  );
};

export default VisitShowFullImage;
