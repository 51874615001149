import { default as axios } from '../AxiosHelper';
import { getAuthHeader } from '../helpers';

const TranslationsConnection = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getTranslations: (data: any) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/translates/translates/list`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  createTranslation: (data: any) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/translates/translates`, data, {
      headers: getAuthHeader(),
    });
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateTranslation: (id: string, data: any) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/translates/translates/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersTranslationsList: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/translates/translates/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddTranslation: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/translates/translates/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditTranslation: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/translates/translates/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
};

export default TranslationsConnection;
