import React, { FunctionComponent, useEffect, useState } from 'react';
import { IChains, IGetChains } from 'utils/models';
import { getChainsState } from 'utils/states';
import ChainsConnection from 'utils/connections/chains';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import WhiteCard from 'global/atoms/WhiteCard';
import Input from 'global/atoms/Input';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import arrow from 'assets/svg/arrow_down_small.svg';
import settings from 'assets/svg/settings.svg';
import List from 'global/table/List';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import Spinner from 'global/atoms/Spinner/Spinner';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import {
  DeleteSelectedButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import { deepClone, handleToast } from '../../../../utils/helpers';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import { handleClearFilters, loadFilters } from '../../../../utils/filterHelper';
import FiltersStorage from '../../../../utils/FiltersStorage';

interface IChainsList {}

const ChainsList: FunctionComponent<IChainsList> = () => {
  const [data, setData] = useState<IChains | null | 'error'>(null);
  const defaultFilters = deepClone(getChainsState);
  const [filters, setFilters] = useState<IGetChains>(loadFilters(defaultFilters));
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [form, setForm] = useState<any>({});

  useEffect(() => {
    ChainsConnection.getFilterForm().then((response) => {
      setForm(response.data);
    });
  });

  const reloadData = () => {
    ChainsConnection.getChains(filters).then((data) => {
      setLoading(false);
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  };

  useEffect(() => {
    setFilters((state: IGetChains) => ({
      ...state,
      requestPaginate: {
        page: 1,
        limit: state.requestPaginate.limit,
      },
    }));
    reloadData();
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    reloadData();
  }, [filters.requestPaginate.page, filters.requestOrder]);

  const handleToggleSort = (event: any) => {
      if (filters.requestOrder.field === event.currentTarget.dataset.field) {
        setFilters({
          ...filters,
          requestOrder: {
            field: filters.requestOrder.field,
            order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
          },
        });
      } else {
        setFilters({
          ...filters,
          requestOrder: {
            field: event.currentTarget.dataset.field,
            order: 'asc',
          },
        });
      }
    },
    handleToggleFilters = () => setToggleFilters((state: boolean) => !state),
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      const filtersStorage = new FiltersStorage();
      filtersStorage.save(filters);

      ChainsConnection.getChains({
        ...filters,
        requestPaginate: {
          page: 1,
          limit: filters.requestPaginate.limit,
        },
      }).then((data) => {
        setLoading(false);
        setToggleFilters(false);
        setData(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
        setFilters((state: IGetChains) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleEdit = (event: any) => {
      history.push(`/datasets/networks/${event.currentTarget.dataset.id}/edit`);
    };

  if (loading) return <Spinner />;

  if (data === null) return null;

  if (data === 'error') return <div>Błąd pobierania danych</div>;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(ChainsConnection.batchDelete(toDelete)).then(() => {
      setChosenToDelete([]);
      reloadData();
    });
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
        <ButtonAddNew form={form} />
        <DeleteSelectedButton chosenToDelete={chosenToDelete} callback={handleDeleteSelected} form={form} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox data={data.data} state={chosenToDelete} setState={setChosenToDelete} />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox state={chosenToDelete} setState={setChosenToDelete} selectedId={rowData.id} />
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              <span
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={handleToggleSort}
                data-field={'name'}
              >
                Nazwa
                {filters.requestOrder.field === 'name' &&
                  (filters.requestOrder.order === 'asc' ? (
                    <img src={arrow} alt={'sort'} />
                  ) : (
                    <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
                  ))}
              </span>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/datasets/networks/${rowData.id}/edit`}>
                  {rowData.name}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <>
                  <ActionsContainer>
                    <img
                      src={settings}
                      style={{ cursor: 'pointer' }}
                      alt={'Idź do'}
                      data-id={rowData.id}
                      onClick={handleEdit}
                    />
                    <DeleteSingleButton callback={handleDeleteSelected.bind(null, rowData.id)} form={form} />
                  </ActionsContainer>
                </>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, reloadData)}
      >
        <Input
          value={filters.name}
          placeholder={'Nazwa'}
          name={'name'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
      </Filters>
    </>
  );
};

export default ChainsList;
