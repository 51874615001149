import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import { createUserState } from 'utils/states';
import { ICreateUser } from 'utils/models';
import UserConnection from 'utils/connections/user';
import { handleToast } from 'utils/helpers';
import { useHistory } from 'react-router-dom';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import ActionsContainer from 'global/atoms/ActionsContainer';
import Form from 'global/form/Form';
import Input from 'global/atoms/Input';
import CustomRoles from '../customRoles/CustomRoles';
import { useDispatch } from 'react-redux';
import Spinner from 'global/atoms/Spinner/Spinner';
import EditUserCommonFields from './EditUserCommonFields';

interface ICreateUserComponent {}

const CreateUser: FunctionComponent<ICreateUserComponent> = () => {
  const [data, setData] = useState<ICreateUser>(createUserState);
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    UserConnection.getFormCreateUser().then((data) => {
      setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(UserConnection.createUser(data), setErrors).then(() => history.push('/admin/users'));
  };

  if (form === 'loading') return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <EditUserCommonFields data={data} setData={setData} errors={errors} form={form} />
          <Input
            type={'password'}
            placeholder={'Hasło'}
            name={'password'}
            value={data.password}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'password'}
            placeholder={'Powtórz hasło'}
            name={'repeatPassword'}
            value={data.repeatPassword}
            state={data}
            setState={setData}
            errors={errors}
          />
          <CustomRoles state={data} setState={setData} options={form.roles.options} errors={errors} />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default CreateUser;
