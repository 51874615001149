import React, { FunctionComponent } from 'react';
import Spinner from '../../atoms/Spinner/Spinner';
import { useSelector } from 'react-redux';
import { IRoot } from '../../../redux/models';
import styles from '../layout.module.scss';

const LockedLoadingScreen: FunctionComponent = () => {
  const loadingScreenData = useSelector((state: IRoot) => state.loadingScreen);
  if (!loadingScreenData.show) return null;
  return (
    <div className={styles.blackOverlay}>
      <Spinner />
    </div>
  );
};

export default LockedLoadingScreen;
