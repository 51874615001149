import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { handleToast, useQuery } from 'utils/helpers';
import ActivitiesConnection from 'utils/connections/activities';
import WhiteCard from 'global/atoms/WhiteCard';
import ActionsContainer from 'global/atoms/ActionsContainer';
import Button from 'global/atoms/Button';
import ActivityAdd from './ActivityAdd';
import styles from './styles.module.scss';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import drag from 'assets/svg/drag.svg';
import edit from 'assets/svg/edit.svg';
import trash from 'assets/svg/trash.svg';
import lockClosed from 'assets/svg/lock_closed.svg';
import lockOpened from 'assets/svg/lock_open.svg';
import arrayMove from 'array-move';
import Questions from './Questions';
import Form from 'global/form/Form';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import ButtonGoBack from 'global/atoms/ButtonGoBack';
import Items from './Items';
import Locations from './Locations';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Select from 'global/atoms/Select';
import { useDispatch } from 'react-redux';
import Heading from 'global/heading/Heading';
import Spinner from 'global/atoms/Spinner/Spinner';
import { confirmModal } from '../../../../redux/store';
import { TOGGLE_LOADING_SCREEN } from '../../../../redux/actions';
import ListingNetworkItemEditButton from './listing/ListingNetworkItemEditButton';
import AlertInfo from '../../../../global/atoms/alert/AlertInfo';
import ListingNetworkQuestionEditButton from './listing/ListingNetworkQuestionEditButton';
import ColorCircleLabelText from '../../../../global/atoms/ColorCircleLabelText';
import { IProjectSettings } from '../../../../utils/models';

interface ITaskActivitiesRefactored {}

const DragHandle = SortableHandle(() => <img src={drag} />);

const TYPE_ITEM = 'ITEM';
const TYPE_SALE = 'SALE';
const TYPE_ORDER = 'ORDER';
const TYPE_STORE = 'STORE';

const SortableItem = SortableElement(
  ({
    item,
    handleSetPicked,
    picked,
    form,
    setForm,
    handleSubmit,
    handleRemove,
    state,
    setState,
    handleLock,
    isSaveButtonVisible,
    setIsSaveButtonVisible,
    projectSettings,
  }: {
    item: any;
    handleSetPicked: (event: any) => void;
    picked: string;
    form: any | 'loading';
    setForm: Dispatch<SetStateAction<any>>;
    handleSubmit: (event: any) => void;
    state: any | 'loading';
    setState: Dispatch<SetStateAction<any>>;
    handleLock: (event: any) => void;
    handleRemove: any;
    isSaveButtonVisible: boolean;
    setIsSaveButtonVisible: any;
    projectSettings: IProjectSettings;
  }) => {
    const getSubmitConfirmInfo = () => {
      return (
        <>
          <AlertInfo>Po zmianach przypisania lokalizacji, pamiętaj o zaktualizowaniu harmonogramu.</AlertInfo>
        </>
      );
    };

    return (
      <>
        <li className={styles.containerActivity}>
          <section
            className={styles.containerActivityHeader}
            style={{
              borderBottom:
                picked === item.activityId && form !== 'loading' && state !== 'loading'
                  ? '1px solid rgba(93,92,92, 0.16)'
                  : 'none',
              marginBottom: picked === item.activityId && form !== 'loading' && state !== 'loading' ? '24px' : '',
            }}
          >
            <div className={styles.activityHandlers}>
              <DragHandle />
              {item.locked ? (
                <img src={lockClosed} data-id={item.activityId} onClick={handleLock} />
              ) : (
                <img src={lockOpened} data-id={item.activityId} onClick={handleLock} />
              )}
            </div>
            <span style={{ flex: 6, cursor: 'pointer' }} onClick={handleSetPicked} data-id={item.activityId}>
              {item.name}
            </span>
            <span style={{ flex: 2, textAlign: 'center' }}>
              <ColorCircleLabelText color={item.activityGroupColor}>{item.activityGroupName}</ColorCircleLabelText>
            </span>
            <span style={{ flex: 1, textAlign: 'center' }}>{item.activityTypeName}</span>
            <span style={{ flex: 1, textAlign: 'center' }}>{item.locationsCount}</span>
            <span style={{ flex: 1, textAlign: 'center' }}>{item.itemsCount}</span>
            <div className={styles.activityActions}>
              <button
                data-id={item.activityId}
                onClick={handleSetPicked}
                style={{ border: 'none', cursor: 'pointer', backgroundColor: 'white' }}
              >
                <img src={edit} />
              </button>
              <button
                data-id={item.activityId}
                onClick={confirmModal('Czy usunąć aktywność?', handleRemove.bind(null, item.activityId))}
                style={{ border: 'none', cursor: 'pointer', backgroundColor: 'white' }}
              >
                <img src={trash} />
              </button>
            </div>
          </section>
          {picked === item.activityId && form !== 'loading' && state !== 'loading' ? (
            <Form>
              <Input
                type={'text'}
                placeholder={'Nazwa'}
                name={'name'}
                value={state.name}
                state={state}
                setState={setState}
              />
              <Select
                placeholder={'Typ aktywności'}
                name={'activityTypeId'}
                value={state.activityTypeId}
                disabled={true}
                options={form.activityTypes.options}
                state={state}
                setState={setState}
              />
              <Select
                placeholder={'Grupa'}
                name={'activityGroupId'}
                value={state.activityGroupId}
                options={form.activityGroups.options}
                disabled={false}
                state={state}
                setState={setState}
              />
              <Textarea
                placeholder={'Opis'}
                name={'description'}
                value={state.description}
                state={state}
                setState={setState}
              />
              {form.model.activityTypeType.toUpperCase() !== TYPE_STORE &&
                form.model.activityTypeType.toUpperCase() !== TYPE_SALE &&
                form.model.activityTypeType.toUpperCase() !== TYPE_ORDER && (
                  <Questions
                    unassigned={form.unassignedQuestions.data}
                    assigned={form.assignedQuestions.data}
                    setForm={setForm}
                  />
                )}
              {(form.model.activityTypeType.toUpperCase() === TYPE_STORE ||
                form.model.activityTypeType.toUpperCase() === TYPE_ITEM ||
                form.model.activityTypeType.toUpperCase() === TYPE_ORDER ||
                form.model.activityTypeType.toUpperCase() === TYPE_SALE) && (
                <Items
                  activityId={picked}
                  unassigned={form.unassignedItems.data}
                  assigned={form.assignedItems.data}
                  setForm={setForm}
                  all={form.activityProperties.options.itemAll}
                />
              )}
              <Locations
                activityId={picked}
                setForm={setForm}
                form={form}
                setIsSaveButtonVisible={setIsSaveButtonVisible}
              />
              <ActionsContainer style={{ marginBottom: '24px' }} align={'space-between'}>
                <ActionsContainer>
                  {form.model.activityTypeType.toUpperCase() === TYPE_ITEM && (
                    <>
                      {projectSettings.isEnableItemNetworkListing && (
                        <ListingNetworkItemEditButton activityId={picked} />
                      )}
                      {projectSettings.isEnableQuestionNetworkListing && (
                        <ListingNetworkQuestionEditButton activityId={picked} />
                      )}
                    </>
                  )}
                </ActionsContainer>
                <ActionsContainer>
                  {isSaveButtonVisible && <ButtonSubmit onClick={confirmModal(getSubmitConfirmInfo(), handleSubmit)} />}
                  <ButtonGoBack onClick={handleSetPicked} />
                </ActionsContainer>
              </ActionsContainer>
            </Form>
          ) : null}
        </li>
      </>
    );
  },
);

const SortableList = SortableContainer(({ children }: { children: React.ReactNode }) => <ul>{children}</ul>);

const TaskActivitiesRefactored: FunctionComponent<ITaskActivitiesRefactored> = () => {
  const [data, setData] = useState<any>(null);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [picked, setPicked] = useState<string>('');
  const [form, setForm] = useState<any | 'loading'>('loading');
  const [state, setState] = useState<any | 'loading'>('loading');
  const [loading, setLoading] = useState(true);
  const [projectSettings, setProjectSettings] = useState<IProjectSettings>({
    isEnableItemNetworkListing: false,
    isEnableQuestionNetworkListing: false,
  });
  const [isSaveButtonVisible, setIsSaveButtonVisible] = useState<boolean>(false);

  const { id, taskId } = useParams<{ id: string; taskId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    ActivitiesConnection.getFormActivitiesEdit(id, taskId).then((data) => {
      setLoading(false);
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
    if (query.get('picked') && query.get('picked') === 'add') {
      setAddNew(true);
    } else if (query.get('picked')) {
      // @ts-ignore
      setPicked(query.get('picked'));
    }
  }, []);

  useEffect(() => {
    if (picked) {
      ActivitiesConnection.getFormTaskActivityEdit(id, taskId, picked).then((data) => {
        setLoading(false);
        setState({
          name: data.data.model.name,
          description: data.data.model.description,
          activityTypeId: data.data.model.activityTypeId,
          activityGroupId: data.data.model.activityGroupId,
        });
        setForm(data.data);
        setProjectSettings(data.data.projectSettings);
      });
    }
  }, [picked]);

  const handleAddNew = () => {
      setAddNew(true);
    },
    handleDisableAddNew = () => {
      setAddNew(false);
    },
    handleSubmitSorted = () => {
      if (data !== null) {
        ActivitiesConnection.updateActivityOrder(
          id,
          taskId,
          data.taskActivities.data.map((item: any) => ({
            activityId: item.activityId,
            active: true,
            locked: item.locked,
          })),
        ).then(() => history.push(`/projects/${id}/tasks/${taskId}`));
      }
    },
    handleDeleteActivity = (activityId: string) => {
      ActivitiesConnection.deleteActivity(id, taskId, activityId).then(() => {
        const updatedactivity = data.taskActivities.data.filter((activite: any) => activite.activityId !== activityId);
        setData({
          ...data,
          taskActivities: {
            data: updatedactivity,
          },
        });
      });
    },
    handleLockItem = (event: any) => {
      setData({
        ...data,
        taskActivities: {
          ...data.taskActivities,
          data: data.taskActivities.data.map((item: any) => {
            if (item.activityId === event.currentTarget.dataset.id) return { ...item, locked: !item.locked };
            else return item;
          }),
        },
      });
    },
    onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      if (data !== null) {
        setData((state: any) => ({
          ...state,
          taskActivities: {
            ...state.taskActivities,
            data: arrayMove(state.taskActivities.data, oldIndex, newIndex),
          },
        }));
      }
    },
    handleSetPicked = (event: any) => {
      if (picked === event.currentTarget.dataset.id) {
        setPicked('');
        setForm('loading');
      } else if (event.currentTarget.dataset.id) {
        setForm('loading');
        setPicked(event.currentTarget.dataset.id);
      } else {
        setForm('loading');
        setPicked('');
      }
    },
    handleSubmitActivity = () => {
      const dataUpdate = {
        name: state.name,
        description: state.description,
        activityTypeId: state.activityTypeId,
        activityGroupId: state.activityGroupId,
        questions: {
          all: false,
          assignedCount: form.assignedQuestions.data.length,
          assigned: form.assignedQuestions.data.map((item: any) => ({
            id: item.id,
            dependOnQuestion: item.dependOnQuestion,
            maxValue: item.maxValue,
            minValue: item.minValue,
            isEdit: item.isEdit,
            pointsValue: parseInt(item.pointsValue),
            isRequired: !!item.isRequired,
          })),
          unassigned: form.unassignedQuestions.data.map((item: any) => ({ id: item.id })),
        },
        locations: {
          all: form.activityProperties.options.locationAll,
          assignedCount: form.assignedLocations.length,
          assigned: form.assignedLocations.map((locationId: string) => ({ id: locationId })),
          unassigned: [],
        },
        items: {
          all: form.activityProperties.options.itemAll,
          assignedCount: form.activityProperties.options.itemAll
            ? form.assignedItems.data.length + form.unassignedItems.data.length
            : form.assignedItems.data.length,
          assigned: form.activityProperties.options.itemAll
            ? [
                ...form.assignedItems.data.map((item: any) => ({ id: item.id })),
                ...form.unassignedItems.data.map((item: any) => ({ id: item.id })),
              ]
            : form.assignedItems.data.map((item: any) => ({ id: item.id })),
          unassigned: form.activityProperties.options.itemAll
            ? []
            : form.unassignedItems.data.map((item: any) => ({ id: item.id })),
        },
      };

      dispatch({ type: TOGGLE_LOADING_SCREEN, payload: { show: true } });
      handleToast(ActivitiesConnection.updateActivity(id, taskId, picked, dataUpdate)).then(() =>
        handleToast(ActivitiesConnection.getFormActivitiesEdit(id, taskId)).then((data) => {
          setPicked('');
          setData(data.data);
          data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
          dispatch({ type: TOGGLE_LOADING_SCREEN, payload: { show: false } });
        }),
      );
    };

  if (loading) return <Spinner />;

  if (data === null) return null;

  return (
    <>
      <Heading
        toRight={
          <ActionsContainer>
            <Button onClick={handleAddNew}>Dodaj nową</Button>
            <Button onClick={handleSubmitSorted}>Zapisz kolejność</Button>
          </ActionsContainer>
        }
      />
      <WhiteCard padding={true} style={{ marginTop: '16px' }}>
        {addNew && (
          <ActivityAdd
            setPicked={setPicked}
            setData={setData}
            projectId={id}
            taskId={taskId}
            handleDisableAddNew={handleDisableAddNew}
          />
        )}
        <section className={styles.containerHeader}>
          <div style={{ width: '100px' }} />
          <span style={{ flex: 6 }}>NAZWA AKTYWNOŚCI</span>
          <span style={{ flex: 2, textAlign: 'center' }}>Grupa</span>
          <span style={{ flex: 1, textAlign: 'center' }}>TYP</span>
          <span style={{ flex: 1, textAlign: 'center' }}>LOKALIZACJE</span>
          <span style={{ flex: 1, textAlign: 'center' }}>PRODUKTY</span>
          <div style={{ width: '100px' }} />
        </section>
        <SortableList onSortEnd={onSortEnd} useDragHandle={true}>
          {data.taskActivities.data.map((item: any, idx: number) => (
            <SortableItem
              key={item.activityId}
              handleRemove={handleDeleteActivity}
              index={idx}
              item={item}
              handleSetPicked={handleSetPicked}
              picked={picked}
              form={form}
              setForm={setForm}
              handleSubmit={handleSubmitActivity}
              state={state}
              setState={setState}
              handleLock={handleLockItem}
              isSaveButtonVisible={isSaveButtonVisible}
              setIsSaveButtonVisible={setIsSaveButtonVisible}
              projectSettings={projectSettings}
            />
          ))}
        </SortableList>
      </WhiteCard>
    </>
  );
};

export default TaskActivitiesRefactored;
