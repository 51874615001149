import React, { FunctionComponent, useEffect, useState } from 'react';
import OrderConnection from 'utils/connections/orders';
import { useParams } from 'react-router-dom';
import WhiteCard from 'global/atoms/WhiteCard';
import { Col, Row } from 'react-grid-system';
import Select from 'global/atoms/Select';
import { ISelectViewResponse } from 'utils/models';
import Input from 'global/atoms/Input';
import SeparatorEmpty from 'global/atoms/separators/SeparatorEmpty';
import SeparatorLine from 'global/atoms/separators/SeparatorLine';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import List from 'global/table/List';
import { getAddressFullName } from 'utils/helpers';
import styles from './styles.module.scss';
import Spinner from 'global/atoms/Spinner/Spinner';
import { createSummaryRowData, getCellComponentWithPriceValue, SUMMARY_TITLE } from '../documents/DocumentDetails';

interface IOrderDetailsForm {
  customers: ISelectViewResponse;
  orderStatuses: ISelectViewResponse;
  model: any;
}

const OrderDetails: FunctionComponent = () => {
  const [form, setForm] = useState<IOrderDetailsForm | null>(null);
  const [loading, setLoading] = useState(true);
  const { id, orderId } = useParams<{ id: string; orderId: string }>();
  const [positions, setPositions] = useState<Array<any> | null>(null);

  useEffect(() => {
    OrderConnection.editForm(orderId, id).then((response) => {
      const dataTableDisplay = response.data.model.positions;
      dataTableDisplay.push(createSummaryRowData(response.data.model));
      setPositions(dataTableDisplay);

      setForm(response.data);
      setLoading(false);
    });
  }, []);

  if (loading || form === null || positions === null) return <Spinner />;

  return (
    <>
      <WhiteCard>
        <Row>
          <Col xs={4} sm={4}>
            <Input value={form.model.documentNumber} placeholder={'Numer'} name={'documentNumber'} disabled={true} />
          </Col>
          <Col xs={12} sm={4} className={styles.col}>
            <Select
              placeholder={'Dystrybutor'}
              name={'customerId'}
              value={form.customers.value}
              options={form.customers.options}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <Input
              value={getAddressFullName(form.model.customer.address)}
              placeholder={'Adres'}
              name={'addressId'}
              type={'text'}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} className={styles.separator} />
        <Row>
          <Col xs={12} sm={4} className={styles.col}>
            <Input
              value={form.model.documentDate}
              placeholder={'Data zamówienia'}
              name={'documentDate'}
              type={'text'}
              disabled={true}
            />
          </Col>
          <Col xs={12} sm={4} className={styles.col}>
            <Input
              value={form.model.user.fullName}
              placeholder={'Wystawił'}
              name={'user'}
              type={'text'}
              disabled={true}
            />
          </Col>
          <Col xs={12} sm={4} className={styles.col}>
            <Select
              placeholder={'Status'}
              name={'statusId'}
              value={form.orderStatuses.value}
              options={form.orderStatuses.options}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} className={styles.separator} />
        <Row>
          <Col xs={12} sm={4} className={styles.col}>
            <Input
              value={form.model.deliveryDate}
              placeholder={'Data dostawy'}
              name={'deliveryDate'}
              type={'text'}
              disabled={true}
            />
          </Col>
          <Col sm={4} className={styles.col}>
            <Input
              value={form.model.location.name}
              placeholder={'Odbiorca'}
              name={'name'}
              type={'text'}
              disabled={true}
            />
          </Col>
          <Col xs={4}>
            <Input
              value={getAddressFullName(form.model.location.address)}
              placeholder={'Adres'}
              name={'addressId'}
              type={'text'}
              disabled={true}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={1.5} className={styles.separator} />
        <Row>
          <Col xs={12}>
            <Input value={form.model.description} placeholder={'Uwagi'} name={'description'} disabled={true} />
          </Col>
        </Row>

        <SeparatorLine size={4} />
        <List data={positions} count={positions.length}>
          <Column flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              Nazwa
            </HeaderCell>
            <Cell width={100} depth={0} dataKey={'itemName'}>
              {(rowData) => {
                if (rowData.itemName === SUMMARY_TITLE) {
                  return <b>{rowData.itemName}</b>;
                }
                return <span>{rowData.itemName}</span>;
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Netto
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                if (rowData.itemName === SUMMARY_TITLE) {
                  return <></>;
                }
                return getCellComponentWithPriceValue(rowData.price.amount, rowData);
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Vat
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                if (rowData.itemName === SUMMARY_TITLE) {
                  return <></>;
                }
                return getCellComponentWithPriceValue(rowData.price.tax, rowData);
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Brutto
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                if (rowData.itemName === SUMMARY_TITLE) {
                  return <></>;
                }
                return getCellComponentWithPriceValue(rowData.price.amountGross, rowData);
              }}
            </Cell>
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Ilość
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return (
                  <span>
                    {rowData.itemAmount} {rowData.itemUnit.name}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column width={120} align="left">
            <HeaderCell depth={0} width={0}>
              W. Netto
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return getCellComponentWithPriceValue(rowData.price.amountTotal, rowData);
              }}
            </Cell>
          </Column>
          <Column width={120} align="left">
            <HeaderCell depth={0} width={0}>
              W. Vat
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return getCellComponentWithPriceValue(rowData.price.taxTotal, rowData);
              }}
            </Cell>
          </Column>
          <Column width={120} align="left">
            <HeaderCell depth={0} width={0}>
              W. Brutto
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return getCellComponentWithPriceValue(rowData.price.amountGrossTotal, rowData);
              }}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
    </>
  );
};

export default OrderDetails;
