import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OrganizationsConnection from 'utils/connections/organizations';
import { getAddressFullName, handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import Form from 'global/form/Form';
import Spinner from 'global/atoms/Spinner/Spinner';
import Button from '../../../../global/atoms/Button';
import ImageFormSelect from '../../../../global/atoms/ImageFormSelect';
import styles from '../../../projects/components/editProject/styles.module.scss';
import Section from 'global/atoms/section/Section';
import AddressesList from '../../../datasets/components/addressesList/AddressesList';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';

interface IOrganizationsEdit {}

interface IOrganizationsData {
  name: string;
  countryId: string;
  logoUrl: string;
  addressId: string;
  nip: string;
  phone: string;
  fax: string;
  email: string;
  www: string;
  currencyId: string;
  repoDir: string;
  ftpAddr: string;
  ftpUser: string;
  ftpPass: string;
  ftpPort: string;
  isEnableUniqueDevice: boolean;
  isEnableTimeControll: boolean;
  isEnableMobileDeviceNotification: boolean;
  isEnableMobileSaleNotification: boolean;
  isEnableSynchro: boolean;
  isEnableLogRequestFromMobile: boolean;
}

const defaultAddOrganizationState: IOrganizationsData = {
  name: '',
  currencyId: '',
  logoUrl: '',
  fax: '',
  nip: '',
  www: '',
  addressId: '',
  email: '',
  phone: '',
  countryId: '',
  repoDir: '',
  ftpAddr: '',
  ftpUser: '',
  ftpPass: '',
  ftpPort: '',
  isEnableUniqueDevice: false,
  isEnableTimeControll: false,
  isEnableMobileDeviceNotification: false,
  isEnableMobileSaleNotification: false,
  isEnableSynchro: false,
  isEnableLogRequestFromMobile: false,
};

const OrganizationsEdit: FunctionComponent<IOrganizationsEdit> = () => {
  const [data, setData] = useState<IOrganizationsData | 'loading'>('loading');
  const [errors, setErrors] = useState<any | null>(null);
  const [form, setForm] = useState<any>('loading');
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [viewState, setViewState] = useState<'edit' | 'selectAddress'>('edit');
  const [address, setAddress] = useState<{ id: string; name: string }>({ id: '', name: '' });
  const isEdit = !!id;

  useEffect(() => {
    if (isEdit) {
      OrganizationsConnection.getFormEditOrganization(id).then((response) => {
        const data = response.data;
        const model = data.model;
        setData({
          name: model.name,
          countryId: model.country.id,
          logoUrl: model.logoUrl,
          fax: model.fax,
          nip: model.nip,
          www: model.www,
          addressId: model.address?.id,
          email: model.email,
          phone: model.phone,
          currencyId: model.currency?.id,
          repoDir: model.repoDir,
          ftpAddr: model.ftpAddr,
          ftpUser: model.ftpUser,
          ftpPass: model.ftpPass,
          ftpPort: model.ftpPort,
          isEnableUniqueDevice: model.isEnableUniqueDevice,
          isEnableTimeControll: model.isEnableTimeControll,
          isEnableMobileDeviceNotification: model.isEnableMobileDeviceNotification,
          isEnableMobileSaleNotification: model.isEnableMobileSaleNotification,
          isEnableSynchro: model.isEnableSynchro,
          isEnableLogRequestFromMobile: model.isEnableLogRequestFromMobile,
        });
        setAddress({
          id: model.address?.id,
          name: model.address?.id !== undefined ? getAddressFullName(model.address) : '',
        });
        setForm(data);
      });
    } else {
      OrganizationsConnection.getFormAddOrganization().then((data) => {
        const addState = defaultAddOrganizationState;
        addState.currencyId = data.data.currencies.value;
        setData(addState);
        setForm(data.data);
      });
    }
  }, []);

  if (data === 'loading' || form === 'loading') return <Spinner />;

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const dataSave = { ...data };
    dataSave.addressId = address.id;

    const redirectUrl = '/superadmin/organizations';
    if (isEdit) {
      handleToast(OrganizationsConnection.updateOrganization(id, dataSave), setErrors).then(() =>
        history.push(redirectUrl),
      );
    } else {
      handleToast(OrganizationsConnection.createOrganization(dataSave), setErrors).then(() =>
        history.push(redirectUrl),
      );
    }
  };

  const handleSetAddress = (addressData: { id: string; name: string }) => {
    setAddress(addressData);
    setViewState('edit');
  };

  const handleCloseAddressSelect = () => {
    setViewState('edit');
  };

  const handleChangeIsEnableUniqueDevice = () => {
    setData({ ...data, ...{ isEnableUniqueDevice: !data.isEnableUniqueDevice } });
  };

  const handleChangeIsEnableTimeControll = () => {
    setData({ ...data, ...{ isEnableTimeControll: !data.isEnableTimeControll } });
  };

  const handleChangeIsEnableMobileDeviceNotification = () => {
    setData({ ...data, ...{ isEnableMobileDeviceNotification: !data.isEnableMobileDeviceNotification } });
  };

  const handleChangeIsEnableMobileSaleNotification = () => {
    setData({ ...data, ...{ isEnableMobileSaleNotification: !data.isEnableMobileSaleNotification } });
  };

  const handleChangeIsEnableSynchro = () => {
    setData({ ...data, ...{ isEnableSynchro: !data.isEnableSynchro } });
  };

  const handleChangeIsEnableLogRequestFromMobile = () => {
    setData({ ...data, ...{ isEnableLogRequestFromMobile: !data.isEnableLogRequestFromMobile } });
  };

  if (viewState === 'selectAddress') {
    return (
      <AddressesList
        handleSetAddress={handleSetAddress}
        handleClose={handleCloseAddressSelect}
        selectedAddressId={address.id}
        selectedAddressName={address.name}
      />
    );
  }

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <div>
            <Input
              value={address.name}
              placeholder={'Adres'}
              type={'text'}
              name={'addressId'}
              disabled={true}
              errors={errors}
            />
            <Button
              type={'button'}
              onClick={() => setViewState('selectAddress')}
              style={{ width: 'auto', marginTop: '12px', marginBottom: '8px' }}
            >
              Przypisz adres
            </Button>
          </div>
          <Section title={'Dane organizacji'} className={styles.optionsSells}>
            <Select
              placeholder={'Kraj'}
              name={'countryId'}
              value={data.countryId}
              options={form.countries.options}
              disabled={false}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Select
              placeholder={'Waluta'}
              name={'currencyId'}
              value={data.currencyId}
              options={form.currencies.options}
              disabled={false}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input placeholder={'Fax'} name={'fax'} value={data.fax} state={data} setState={setData} errors={errors} />
            <Input placeholder={'Nip'} name={'nip'} value={data.nip} state={data} setState={setData} errors={errors} />
            <Input placeholder={'Www'} name={'www'} value={data.www} state={data} setState={setData} errors={errors} />
            <Input
              placeholder={'Numer telefonu'}
              name={'phone'}
              value={data.phone}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              placeholder={'E-mail'}
              name={'email'}
              value={data.email}
              state={data}
              setState={setData}
              errors={errors}
            />
            <ImageFormSelect label={'Wybierz logo'} state={data} setState={setData} name={'logoUrl'} />
          </Section>

          <Section title={'Opcje organizacji'} className={styles.optionsSells}>
            <CheckBox isChecked={data.isEnableUniqueDevice} onClick={handleChangeIsEnableUniqueDevice}>
              Kontrola unikalności urządzenia (blokada aplikacji)
            </CheckBox>

            <CheckBox
              isChecked={data.isEnableMobileDeviceNotification}
              onClick={handleChangeIsEnableMobileDeviceNotification}
            >
              Kontrola unikalności urządzenia (powiadomienia)
            </CheckBox>

            <CheckBox
              isChecked={data.isEnableMobileSaleNotification}
              onClick={handleChangeIsEnableMobileSaleNotification}
            >
              Kontrola dokumentów sprzedaży (powiadomienia)
            </CheckBox>

            <CheckBox isChecked={data.isEnableTimeControll} onClick={handleChangeIsEnableTimeControll}>
              Kontrola daty z serwera (blokada dokumentu)
            </CheckBox>

            <CheckBox isChecked={data.isEnableSynchro} onClick={handleChangeIsEnableSynchro}>
              Wymagana coddzienna synchronizacja aplikacji
            </CheckBox>

            <CheckBox isChecked={data.isEnableLogRequestFromMobile} onClick={handleChangeIsEnableLogRequestFromMobile}>
              Logowanie wizyt z aplikacji
            </CheckBox>
          </Section>

          <Section title={'Repozytorium'} className={styles.optionsSells}>
            <Input
              placeholder={'Ftp adres'}
              name={'ftpAddr'}
              value={data.ftpAddr}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              placeholder={'Ftp user'}
              name={'ftpUser'}
              value={data.ftpUser}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              placeholder={'Ftp pass'}
              name={'ftpPass'}
              value={data.ftpPass}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              placeholder={'Ftp port'}
              name={'ftpPort'}
              value={data.ftpPort}
              state={data}
              setState={setData}
              errors={errors}
            />
            <Input
              placeholder={'Katalog'}
              name={'repoDir'}
              value={data.repoDir}
              state={data}
              setState={setData}
              errors={errors}
            />
          </Section>
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default OrganizationsEdit;
