import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { IWarehouseAvailabilityFilters } from '../models';
import { IWarehouseAddData } from '../../views/projects/components/warehouse/WarehouseAdd';

const WarehouseConnection = {
  availabilityList: (projectId: string, data: IWarehouseAvailabilityFilters) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/stores/availability/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  availabilityFilterForm: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/stores/availability/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  userAvailabilityList: (projectId: string, data: IWarehouseAvailabilityFilters) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/availability/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  userAvailabilityFilterForm: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/availability/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  userShiftList: (projectId: string, data: IWarehouseAvailabilityFilters) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/shift/list`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  userShiftFilterForm: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/shift/filter/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getFormAddWarehouse: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/stores/operations/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getItems: (projectId: string, userFromId: string, userRoleFromId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/users/${userFromId}/roles/${userRoleFromId}/stores/entry/items/form`,
    );
  },
  save: (projectId: string, data: IWarehouseAddData) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/project/${projectId}/users/stores/operations/entry`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
};

export default WarehouseConnection;
