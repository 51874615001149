import React, { FunctionComponent } from 'react';
import CSS from 'csstype';

interface IActionsContainer {
  children: React.ReactNode;
  style?: CSS.Properties;
  align?: 'left' | 'right' | 'space-between';
  gapSize?: number;
}

const ActionsContainer: FunctionComponent<IActionsContainer> = ({ children, style, align, gapSize }) => {
  let justifyContent = 'flex-end';
  if (align === 'left') {
    justifyContent = 'flex-start';
  } else if (align === 'space-between') {
    justifyContent = 'space-between';
  }

  const gapSizeToSet = gapSize !== undefined ? `${gapSize}px` : '12px';
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: justifyContent,
        gridColumnGap: gapSizeToSet,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default ActionsContainer;
