import React, { FunctionComponent } from 'react';

interface IForm {
  children: React.ReactNode;
  handleSubmit?: (event: any) => void;
}

const Form: FunctionComponent<IForm> = ({ children, handleSubmit }) => {
  return (
    <form style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

export default Form;
