import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { IVisitsReportTemplateData } from '../../views/projects/components/visitsReport/VisitsReportTemplateEdit';
import { IVisitsReportSave } from '../../views/projects/components/visitsReport/VisitsReport';
import { IFilters } from '../../views/projects/components/visitsReport/SelectVisitsList';
import { AxiosPromise } from 'axios';
import { format } from 'date-fns';

const VisitsReportConnection = {
  saveTemplate: (data: IVisitsReportTemplateData) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/visits_report/template`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  updateTemplate: (templateId: string, data: IVisitsReportTemplateData) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/visits_report/template/${templateId}`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getGenerateReportViewData: (projectId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/visits_report/view/generate_report/${projectId}`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getTemplateById: (templateId: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/visits_report/template/${templateId}`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  saveReport: (data: IVisitsReportSave) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/visits_report`, data);
  },
  getLatestReports: (projectId: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/visits_report/latest/${projectId}`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getFile: (visitReportResultId: string, fileFormat: string) => {
    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/organizations/${getOrganizationId()}/visits_report/file/${visitReportResultId}`,
        {
          headers: getAuthHeader(),
          responseType: 'blob',
        },
      )
      .then((response) => {
        const dateNow = format(new Date(), 'Y-MM-dd HH:mm:ss');
        const csvURL = window.URL.createObjectURL(response.data);
        const tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', `Raport_Wizyt_${dateNow}.${fileFormat}`);
        tempLink.click();
      });
  },
  getVisits: (filters: IFilters): AxiosPromise => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/visits_report/view/find_visits`,
      filters,
    );
  },
  deleteTemplate: (templateId: string) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/visits_report/template/${templateId}`,
    );
  },
};

export default VisitsReportConnection;
