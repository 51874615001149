import React, { CSSProperties, FunctionComponent } from 'react';
import styles from './atoms.module.scss';
import CSS from 'csstype';
import close from '../../assets/svg/close.svg';
import arrowLeft from '../../assets/svg/arrow_left.svg';
import edit from '../../assets/svg/edit.svg';
import remove from '../../assets/svg/remove.svg';
import trash from '../../assets/svg/trash.svg';
import arrowRightBig from '../../assets/svg/arrow_right_big.svg';
import settings from '../../assets/svg/settings.svg';
import view from '../../assets/svg/view.svg';
import viewDisabled from '../../assets/svg/view_disabled.svg';
import download from '../../assets/svg/download.svg';

interface IIconButton {
  style?: CSS.Properties;
  src?: string;
  onClick: any;
  icon?:
    | 'arrowRightBig'
    | 'close'
    | 'arrowLeft'
    | 'edit'
    | 'remove'
    | 'trash'
    | 'settings'
    | 'view'
    | 'viewDisabled'
    | 'download'
    | undefined;
  size?: number;
  disabled?: boolean;
  inline?: boolean;
}

const IconButton: FunctionComponent<IIconButton> = ({
    src,
    style,
    onClick,
    icon,
    size,
    disabled = false,
    inline = false
  }) => {

  const getSource = () => {
    let iconReturn = src;
    switch (icon) {
      case 'close':
        iconReturn = close;
        break;
      case 'arrowLeft':
        iconReturn = arrowLeft;
        break;
      case 'edit':
        iconReturn = edit;
        break;
      case 'remove':
        iconReturn = remove;
        break;
      case 'trash':
        iconReturn = trash;
        break;
      case 'arrowRightBig':
        iconReturn = arrowRightBig;
        break;
      case 'settings':
        iconReturn = settings;
        break;
      case 'download':
        iconReturn = download;
        break;
      case 'view':
        iconReturn = view;
        break;
      case 'viewDisabled':
        iconReturn = viewDisabled;
        break;
    }
    return iconReturn;
  };

  if (style === undefined) {
    style = {};
  }

  let iconSize = '18px';
  if (size !== undefined) {
    iconSize = `${size}px`;
    style.width = 'auto';
    style.height = 'auto';
  }

  if (icon === 'trash' && size === undefined) {
    iconSize = `27px`;
    style.width = 'auto';
    style.height = 'auto';
  }

  if(inline) {
    style.display = 'inline';
  }

  const imgStyle: CSSProperties = {
    width: iconSize,
    height: iconSize,
  };

  if (disabled) {
    onClick = () => null;
    imgStyle.filter = 'grayscale(100%)';
  }

  return (
    <button onClick={onClick} className={styles.iconButton} style={style} type={'button'}>
      <img style={imgStyle} className={styles.iconButtonImage} src={getSource()} alt={'icon'} />
    </button>
  );
};

export default IconButton;
