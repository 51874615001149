import React, { FunctionComponent } from 'react';
import { Route } from 'react-router-dom';
import routes from 'routes/datasets';

interface IDataSets {}

const Datasets: FunctionComponent<IDataSets> = () => {
  return (
    <>
      {routes.map(({ path, Component }, key) => (
        <Route exact path={path} key={key} component={Component} />
      ))}
    </>
  );
};

export default Datasets;
