import React, { FunctionComponent } from 'react';
import SelectedFilter from '../../../../../global/atoms/filter/SelectedFilter';
import { ITimeTableDetailsFiltersState, ITimeTableDetailsViewDataState } from './TimeTableDetails';

interface ISelectedFilters {
  viewData: ITimeTableDetailsViewDataState;
  loadedFilters: ITimeTableDetailsFiltersState;
  setFilters: any;
  setLoadedFilters: any;
  setLoadData: any;
}

const SelectedFilters: FunctionComponent<ISelectedFilters> = ({
  viewData,
  loadedFilters,
  setLoadedFilters,
  setFilters,
  setLoadData,
}) => {
  type filterNameType = 'locations' | 'users' | 'networks' | 'projects' | 'tasks';
  const getSelectedFilterName = (name: filterNameType) => {
    const selectedFilter = viewData[name]?.options.filter((data) => {
      switch (name) {
        case 'locations':
          return data.id === loadedFilters.locationId;
        case 'users':
          return data.id === loadedFilters.userId;
        case 'networks':
          return data.id === loadedFilters.networkId;
        case 'projects':
          return data.id === loadedFilters.projectId;
        case 'tasks':
          return data.id === loadedFilters.taskId;
      }
    });

    if (selectedFilter === undefined) {
      return null;
    }

    if (selectedFilter.length === 0) {
      return null;
    }

    return selectedFilter[0].name;
  };

  const handleRemoveFilter = (name: 'locationId' | 'userId' | 'networkId' | 'projectId' | 'taskId') => {
    const filtersClone = { ...loadedFilters };
    filtersClone[name] = null;
    setLoadedFilters(filtersClone);
    setFilters(filtersClone);
    setLoadData(true);
  };

  return (
    <>
      {getSelectedFilterName('projects') !== null && (
        <SelectedFilter
          name={'Projekt:'}
          value={getSelectedFilterName('projects')}
          onRemoveClick={() => {
            handleRemoveFilter('projectId');
          }}
        />
      )}
      {getSelectedFilterName('tasks') !== null && (
        <SelectedFilter
          name={'Zadanie:'}
          value={getSelectedFilterName('tasks')}
          onRemoveClick={() => {
            handleRemoveFilter('taskId');
          }}
        />
      )}
      {getSelectedFilterName('locations') !== null && (
        <SelectedFilter
          name={'Lokalizacja:'}
          value={getSelectedFilterName('locations')}
          onRemoveClick={() => {
            handleRemoveFilter('locationId');
          }}
        />
      )}
      {getSelectedFilterName('users') !== null && (
        <SelectedFilter
          name={'Raportujący:'}
          value={getSelectedFilterName('users')}
          onRemoveClick={() => {
            handleRemoveFilter('userId');
          }}
        />
      )}
      {getSelectedFilterName('networks') !== null && (
        <SelectedFilter
          name={'Sieć:'}
          value={getSelectedFilterName('networks')}
          onRemoveClick={() => {
            handleRemoveFilter('networkId');
          }}
        />
      )}
    </>
  );
};

export default SelectedFilters;
