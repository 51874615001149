import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import {
  IAddTimeTable,
  IAddTimeTableManualSettings,
  IAddTimeTableRecurringSettings,
  IAddTimeTableSingleSettings,
  IFilterTimeTable,
} from '../models';
import { AxiosPromise } from 'axios';

const TimeTableConnection = {
  createTimeTable: (data: IAddTimeTable) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table`, data, {
      headers: getAuthHeader(),
    });
  },
  updateTimeTable: (timeTableId: string, data: IAddTimeTable) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/${timeTableId}`,
      data,
      { headers: getAuthHeader() },
    );
  },
  removeTimeTable: (timeTableId: string) => {
    return axios.delete(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/${timeTableId}`,
      { headers: getAuthHeader() },
    );
  },
  findTimeTable: (data: IFilterTimeTable) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/find`, data, {
      headers: getAuthHeader(),
    });
  },
  createRecurringSettings: (timeTableId: string, data: IAddTimeTableRecurringSettings) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/${timeTableId}/recurring_settings`,
      data,
      { headers: getAuthHeader() },
    );
  },
  updateRecurringSettings: (
    timeTableRecurringSettingsId: string,
    timeTableId: string,
    data: IAddTimeTableRecurringSettings,
  ) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/${timeTableId}/recurring_settings/${timeTableRecurringSettingsId}`,
      data,
      { headers: getAuthHeader() },
    );
  },
  removeRecurringSettings: (timeTableRecurringSettingsId: string, timeTableId: string) => {
    return axios.delete(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/${timeTableId}/recurring_settings/${timeTableRecurringSettingsId}`,
      { headers: getAuthHeader() },
    );
  },
  createSingleSettings: (timeTableId: string, data: IAddTimeTableSingleSettings) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/${timeTableId}/single_settings`,
      data,
      { headers: getAuthHeader() },
    );
  },
  updateSingleSettings: (timeTableSingleSettingsId: string, timeTableId: string, data: IAddTimeTableSingleSettings) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/${timeTableId}/single_settings/${timeTableSingleSettingsId}`,
      data,
      { headers: getAuthHeader() },
    );
  },
  createManualSettings: (timeTableId: string, data: IAddTimeTableManualSettings) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/${timeTableId}/manual_settings`,
      data,
      { headers: getAuthHeader() },
    );
  },
  updateManualSettings: (timeTableManualSettingsId: string, timeTableId: string, data: IAddTimeTableManualSettings) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/${timeTableId}/manual_settings/${timeTableManualSettingsId}`,
      data,
      { headers: getAuthHeader() },
    );
  },
  updateAllRecurringSettings: (timeTableId: string, data: Array<IAddTimeTableRecurringSettings>) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/${timeTableId}/recurring_settings/update_all`,
      data,
      { headers: getAuthHeader() },
    );
  },
  getDetailsViewData: (projectId: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/details_view/${projectId}`,
      { headers: getAuthHeader() },
    );
  },
  getDetailsProjectViewData: () => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/details_global_view`,
      { headers: getAuthHeader() },
    );
  },
  getListViewData: (projectId: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/list/form/${projectId}`,
      { headers: getAuthHeader() },
    );
  },
  getTimeTableToUpdate: (projectId: string, taskId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/get_time_table_to_update/${projectId}/${taskId}`,
      { headers: getAuthHeader() },
    );
  },
  updateForNewlyAddedUsersOrLocations: (projectId: string, taskId: string) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/time_table/update_for_new_users_or_locations/${projectId}/${taskId}`,
      {},
      { headers: getAuthHeader() },
    );
  },
  getTaskTimeTableGenerateResult: (taskId: string): AxiosPromise => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/time_table/generate_result/${taskId}`,
    );
  },
};

export default TimeTableConnection;
