import WhiteCard from 'global/atoms/WhiteCard';
import React from 'react';
import Form from './Form';
import Input from 'global/atoms/Input';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import { ICreateChain } from 'utils/models';

interface IChainsUpdate {
  handleSubmit: (event: any) => void;
  data: any;
  setData: (data: ICreateChain) => void;
  errors: any;
  form?: any;
}

const ChainsUpdate: React.FC<IChainsUpdate> = ({ handleSubmit, data, setData, errors, form }) => (
  <WhiteCard padding={true}>
    <Form handleSubmit={handleSubmit}>
      <Input
        type={'text'}
        placeholder={'Nazwa'}
        name={'name'}
        value={data.name}
        state={data}
        setState={setData}
        errors={errors}
      />
      <ActionsContainer>
        <ButtonSubmit form={form} />
      </ActionsContainer>
    </Form>
  </WhiteCard>
);

export default ChainsUpdate;
