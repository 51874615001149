import React, { FunctionComponent } from 'react';

interface ISection {
  className?: any;
  style?: any;
  hidden?: boolean;
}

const SectionEmpty: FunctionComponent<ISection> = ({ children, className, style, hidden = false }) => {
  let styleCustom: any = {
    border: 'none',
    padding: '16px',
    position: 'relative',
    paddingTop: '25px',
    display: hidden ? 'none' : 'initial',
  };
  styleCustom = { ...styleCustom, ...style };

  return (
    <>
      <div style={styleCustom} className={className}>
        {children}
      </div>
    </>
  );
};

export default SectionEmpty;
