import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import VisitsReportConnection from '../../../../utils/connections/visitsReport';
import { useParams } from 'react-router-dom';
import List from '../../../../global/table/List';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import ProgressBar from '../../../../global/atoms/ProgressBar';
import IconButton from '../../../../global/atoms/IconButton';
import WhiteCard from '../../../../global/atoms/WhiteCard';

interface ILatestReports {
  reloadReports: boolean;
  setReloadReports: any;
}

const LatestReports: FunctionComponent<ILatestReports> = ({ reloadReports, setReloadReports }) => {
  const { id } = useParams<{ id: string }>();
  const [reports, setReports] = useState<Array<any>>([]);
  const interval = useRef<any>(null);

  const loadLatestReports = () => {
    VisitsReportConnection.getLatestReports(id).then((response) => {
      setReports(response.data);
    });
  };

  useEffect(() => {
    if (reloadReports) {
      loadLatestReports();
      setReloadReports(false);
    }
  }, [reloadReports]);

  useEffect(() => {
    loadLatestReports();
    interval.current = setInterval(() => {
      loadLatestReports();
    }, 10000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleDownloadFile = (visitReportResultId: string, format: string) => {
    VisitsReportConnection.getFile(visitReportResultId, format);
  };

  return (
    <WhiteCard padding={true}>
      <List data={reports} count={reports.length}>
        <Column minWidth={180} flexGrow={1} align="left">
          <HeaderCell depth={0} width={0}>
            Szablon
          </HeaderCell>
          <Cell width={100} depth={0} dataKey="templateName" />
        </Column>
        <Column width={100} align="left">
          <HeaderCell depth={0} width={0}>
            Format
          </HeaderCell>
          <Cell width={100} depth={0} dataKey="format" />
        </Column>
        <Column width={180} align="left">
          <HeaderCell depth={0} width={0}>
            Progres
          </HeaderCell>
          <Cell width={100} depth={0} dataKey="progress">
            {(rowData) => <ProgressBar completed={rowData.progress} />}
          </Cell>
        </Column>
        <Column width={180} align="left">
          <HeaderCell depth={0} width={0}>
            Data utworzenia
          </HeaderCell>
          <Cell width={100} depth={0} dataKey="createdDate" />
        </Column>
        <Column width={120} align="left">
          <HeaderCell depth={0} width={0}>
            Akcje
          </HeaderCell>
          <Cell width={120} depth={0} dataKey="progress">
            {(rowData) => (
              <>
                {rowData.progress >= 100 && rowData.filePath !== null && (
                  <IconButton
                    onClick={handleDownloadFile.bind(null, rowData.id, rowData.format)}
                    icon={'arrowRightBig'}
                  />
                )}
                {rowData.progress >= 100 && rowData.filePath === null && <span>Brak danych</span>}
              </>
            )}
          </Cell>
        </Column>
      </List>
    </WhiteCard>
  );
};

export default LatestReports;
