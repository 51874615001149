import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Modal from '../../../global/atoms/Modal/Modal';
import ButtonOutlined from '../../../global/atoms/ButtonOutlined';
import { deepClone } from '../../../utils/helpers';
import { IPhotoReviewModal, IPhotoReviewFeedback, IPhotoReviewErrors } from '../../../utils/models';
import styles from './styles.module.scss';
import stylesCommon from '../../../global/common.module.scss';
import ReviewsForm from './Review/ReviewsForm';
import SupervisorReview from './Review/SupervisorReview';
import ButtonSubmit from '../../../global/atoms/ButtonSubmit';
import IconButton from '../../../global/atoms/IconButton';
import Connection from '../../../utils/connections/visitReviews';
import { srcPlaceholder } from './PhotoReviewThumb';
import { photoReviewModalState } from '../../../utils/states';
import download from 'downloadjs';
import { Col, Row } from 'react-grid-system';
import axios from 'axios';
import SpinnerSmall from '../../../global/atoms/Spinner/SpinnerSmall';

interface IPhotoReviewModalComponent {
  submitReview(identifier: string, photoFeedbacks: IPhotoReviewFeedback[], openNext?: boolean): void;
  onNavArrowClick(type: 'prev' | 'next', currentPhotoId: string): boolean;
  setModalData: React.Dispatch<React.SetStateAction<IPhotoReviewModal>>;
  modalData: IPhotoReviewModal;
  errors: IPhotoReviewErrors;
  readOnly?: boolean;
}

const PhotoReviewModal: FunctionComponent<IPhotoReviewModalComponent> = (props) => {
  const [navPrevDisabled, navPrevDisabledSet] = useState(false);
  const [navNextDisabled, navNextDisabledSet] = useState(false);

  const feedbackStatesRef = useRef<IPhotoReviewFeedback[]>([]);
  const [identifier, setIdentifier] = useState<string>('');

  const [fullPhotoSrc, setFullPhotoSrc] = useState<string | null>('');
  const [formLoaded, setFormLoaded] = useState<boolean | null>(false);

  const resetModal = () => {
    clearModal();
    setIdentifier('');
    props.setModalData(deepClone(photoReviewModalState));
  };

  const clearModal = () => {
    setFullPhotoSrc('');
    setFormLoaded(false);
    feedbackStatesRef.current = [];
  };

  const handleCloseModal = () => resetModal();

  // mount
  useEffect(() => {
    if (identifier !== '') {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      // unmount callback
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [identifier]);

  // init - open
  useEffect(() => {
    if (props.modalData.open) {
      clearModal();

      const _identifier = props.modalData.photoSimple?.value.refQuestionAnswerId;
      if (_identifier === undefined) throw 'Identifier cannot be undefined!';
      setIdentifier(_identifier);

      // load full photo
      Connection.getFullSizePhoto(_identifier)
        .then((result) => {
          let src = result.data.value;
          if (src === '') src = srcPlaceholder;
          setFullPhotoSrc(src);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) setFullPhotoSrc(null);
        });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Connection.getFeedbackForm(_identifier)
        .then((result) => {
          feedbackStatesRef.current = Object.values(result.data);
          setFormLoaded(true);
        })
        .catch((e) => {
          if (!axios.isCancel(e)) setFormLoaded(null);
        });
    } else {
      // ...
    }
  }, [props.modalData.open, props.modalData.toggler]);

  const onNavigationChange = (type: 'prev' | 'next') => {
    navPrevDisabledSet(false);
    navNextDisabledSet(false);

    if (type === 'prev') {
      const first = props.onNavArrowClick('prev', identifier);
      if (first) navPrevDisabledSet(true);
    }

    if (type === 'next') {
      const last = props.onNavArrowClick('next', identifier);
      if (last) navNextDisabledSet(true);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    switch (e.key) {
      case 'ArrowLeft':
        onNavigationChange('prev');
        break;
      case 'ArrowRight':
        onNavigationChange('next');
        break;
      case 'Escape':
        handleCloseModal();
        break;
    }
  };

  if (!props.modalData.open) return <></>;

  const navigation = () => {
    if (!props.modalData.photoSimple) return <></>;

    return (
      <>
        <div>
          <div className={`arrowLeft ${styles.navigationArrow}`} style={{ left: '10%' }}>
            <IconButton
              disabled={navPrevDisabled}
              size={150}
              icon={'arrowLeft'}
              onClick={onNavigationChange.bind(null, 'prev')}
            />
          </div>
          <div className={`arrowRight ${styles.navigationArrow}`} style={{ right: '10%', transform: 'rotate(180deg)' }}>
            <IconButton
              disabled={navNextDisabled}
              size={150}
              onClick={onNavigationChange.bind(null, 'next')}
              icon={'arrowLeft'}
            />
          </div>
        </div>
      </>
    );
  };

  const handleButtonSubmitOnClick = (openNext: boolean): void => {
    props.submitReview(identifier, feedbackStatesRef.current, openNext);
  };

  return (
    <>
      <Modal key={'photo_review_modal'} isOpen={props.modalData.open} navigation={navigation}>
        <>
          {fullPhotoSrc === '' ? (
            <div className={stylesCommon.loadingSmall} style={{ marginBottom: '15px', minWidth: '45vw' }}>
              <SpinnerSmall />
              Ładowanie zdjęcia...
            </div>
          ) : fullPhotoSrc === null ? (
            <div className={stylesCommon.loadingSmallErr} style={{ marginBottom: '15px', minWidth: '45vw' }}>
              Wystąpił błąd lub utracono połączenie.
            </div>
          ) : (
            <div style={{ textAlign: 'center', marginBottom: '15px', position: 'relative' }}>
              <img
                width={'100%'}
                src={fullPhotoSrc}
                style={{ borderRadius: '10px', minWidth: '45vw', maxWidth: '800px' }}
                alt={'photo'}
              />
              <div className={styles.modalPhotoDownloadButton}>
                <IconButton
                  onClick={() => download(Connection.downloadPhotoUrl(identifier))}
                  icon={'download'}
                  size={35}
                />
              </div>
            </div>
          )}

          <Row>
            <Col md={6}>
              <p className={`${styles.small}`}>
                <strong>Projekt:</strong> {props.modalData.visit?.projectName ?? '-'}
              </p>
              <p className={`${styles.small}`}>
                <strong>Zadanie:</strong> {props.modalData.photoSimple?.taskName ?? '-'}
              </p>
              <p className={`${styles.small}`}>
                <strong>Aktywność:</strong> {props.modalData.photoSimple?.activityName ?? '-'}
              </p>
              <p className={`${styles.small}`}>
                <strong>Pytanie:</strong> {props.modalData.photoSimple?.questionName ?? '-'}
              </p>
            </Col>

            <Col md={6}>
              <p className={`${styles.small}`}>
                <strong>Raportujący:</strong> {props.modalData.visit?.reportingUserName ?? '-'}
              </p>
              <p className={`${styles.small}`}>
                <strong>Lokalizacja:</strong> {props.modalData.visit?.locationName ?? '-'}
              </p>
              <p className={`${styles.small}`}>
                <strong>Data wizyty:</strong> {props.modalData.visit?.startDate ?? '-'}
              </p>
            </Col>
          </Row>

          {formLoaded === false && (
            <div className={stylesCommon.loadingSmall}>
              <br />
              <SpinnerSmall />
              Ładowanie formularza oceny...
            </div>
          )}

          {formLoaded === null && (
            <div className={stylesCommon.loadingSmallErr}>
              <br />
              Wystąpił błąd lub utracono połączenie.
            </div>
          )}

          {formLoaded && feedbackStatesRef.current.length ? (
            <ReviewsForm
              statesRef={feedbackStatesRef}
              identifier={identifier}
              errors={props.errors}
              key={`reviewForm_${identifier}`}
            />
          ) : (
            <></>
          )}

          {formLoaded && !feedbackStatesRef.current.length ? (
            <div className={stylesCommon.loadingSmall}>
              <br />
              Brak możliwości oceny.
            </div>
          ) : (
            <></>
          )}

          {props.modalData.isReviewed && (
            <>
              <div
                hidden={props.modalData.displayOtherReviews}
                className={styles.displayOtherReviewsButton}
                onClick={() => {
                  props.setModalData({ ...props.modalData, displayOtherReviews: true });
                }}
              >
                Wyświetl oceny innych
              </div>
              <SupervisorReview
                key={`supervisorReview_${identifier}_${props.modalData.toggler}`}
                hidden={!props.modalData.displayOtherReviews}
                identifier={identifier}
              />
              <br />
            </>
          )}

          <div className={styles.modalActionButtons}>
            <ButtonOutlined onClick={handleCloseModal}>Anuluj</ButtonOutlined>&nbsp;
            {props.readOnly !== true && formLoaded && feedbackStatesRef.current.length ? (
              <>
                <ButtonSubmit
                  onClick={() => {
                    handleButtonSubmitOnClick(false);
                  }}
                />
                &nbsp;
                <ButtonSubmit
                  name={'Zapisz i następny'}
                  onClick={() => {
                    handleButtonSubmitOnClick(true);
                  }}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      </Modal>
    </>
  );
};

export default PhotoReviewModal;
