import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { ICreateRole, IGetRoles } from '../models';

const RolesConnection = {
  getRoles: (data: IGetRoles) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/roles/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getFormFilters: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/roles/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddRole: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/roles/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditRole: (roleId: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/roles/${roleId}/form`, {
      headers: getAuthHeader(),
    });
  },
  createRole: (data: ICreateRole) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/users/roles`, data, {
      headers: getAuthHeader(),
    });
  },
  getFilterForm: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/roles/filter/form`);
  },
  // rolesEdit: (data: ICreateRole) => {
  //   return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/components/roles`, data, {
  //     headers: getAuthHeader(),
  //   });
  // },
};

export default RolesConnection;
