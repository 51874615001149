import React from 'react';
import styles from './modal.module.scss';
import ReactDOM from 'react-dom';

interface IModal {
  isOpen: boolean;
  children: React.ReactNode;
  navigation?: any;
  inlineStyles?: any;
}

const modalRoot = document.getElementById('modal-root')!;

const Modal: React.FC<IModal> = ({ isOpen, children, navigation, inlineStyles }) => {
  return ReactDOM.createPortal(
    <>
      {isOpen ? (
        <div className={styles.wrapperModal}>
          {navigation && navigation()}
          <div className={styles.modal} style={inlineStyles ?? {}}>
            {children}
          </div>
        </div>
      ) : null}
    </>,
    modalRoot,
  );
};

export default Modal;
