export const MONTHS: any = {
  0: 'Styczeń',
  1: 'Luty',
  2: 'Marzec',
  3: 'Kwiecień',
  4: 'Maj',
  5: 'Czerwiec',
  6: 'Lipiec',
  7: 'Sierpień',
  8: 'Wrzesień',
  9: 'Październik',
  10: 'Listopad',
  11: 'Grudzień',
};

export const WEEK_DAYS: any = {
  0: 'Niedziela',
  1: 'Poniedziałek',
  2: 'Wtorek',
  3: 'Środa',
  4: 'Czwartek',
  5: 'Piątek',
  6: 'Sobota',
};
