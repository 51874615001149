import { applyMiddleware, combineReducers, createStore, Reducer } from 'redux';
import logger from 'redux-logger';
import menuReducer from './reducers/menuReducer';
import appReducer from './reducers/appReducer';
import loadingReducer from './reducers/loadingReducer';
import headerReducer from './reducers/headerReducer';
import filterReducer from './reducers/filterReducer';
import { TOGGLE_CONFIRM_MODAL, TOGGLE_LOADING_SCREEN } from './actions';
import { confirmModalState, loadingScreenState } from './states';
import { IConfirmModal } from './models';
import { ReactComponentElement } from 'react';

const reducers: Reducer = combineReducers({
  app: appReducer,
  menu: menuReducer,
  loading: loadingReducer,
  header: headerReducer,
  filter: filterReducer,
  confirmModal: (state = confirmModalState, action) => {
    if (action.type === TOGGLE_CONFIRM_MODAL) return action.payload;
    return state;
  },
  loadingScreen: (state = loadingScreenState, action) => {
    if (action.type === TOGGLE_LOADING_SCREEN) return action.payload;
    return state;
  },
});

const store = createStore(reducers, applyMiddleware(logger));

const confirmModalCallback = (question: string, callback: any) => {
  const data: IConfirmModal = {
    open: true,
    callback: callback,
    question: question,
  };
  store.dispatch({
    payload: data,
    type: TOGGLE_CONFIRM_MODAL,
  });
};

export const confirmModal = (question: string | ReactComponentElement<any>, callback: any) => {
  return confirmModalCallback.bind(null, question, callback);
};

export default store;
