import React from 'react';
import BaseComponent from '../../../../global/atoms/BaseComponent';
import {
  IBaseComponentProps,
  IBaseComponentState,
  IEditTimeTableGeneralDataState,
  SelectOptions,
  SelectResult,
} from '../../../../utils/models';
import RadioButton from '../../../../global/atoms/radio/RadioButton';
import RadioHorizontalGroup from '../../../../global/atoms/radio/RadioHorizontalGroup';
import EditTimeTableRecurringSettings from './EditTimeTableRecurringSettings';
import EditTimeTableSingleSettings from './EditTimeTableSingleSettings';
import EditTimeTableManualSettings from './EditTimeTableManualSettings';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import Select from '../../../../global/atoms/Select';
import { deepClone } from '../../../../utils/helpers';
import { Col, Row } from 'react-grid-system';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';
import { getFilteredDataByProject } from './details/EditTimeTableCustom';

interface IEditTimeTableProps extends IBaseComponentProps {
  parentData: any;
  parentSetData: any;
}

interface IEditTimeTableState extends IBaseComponentState {}

export const SINGLE_TYPE = 0;
export const RECURRING_TYPE = 2;
export const MANUAL_TYPE = 1;

export default class EditTimeTable extends BaseComponent<IEditTimeTableProps, IEditTimeTableState> {
  radioChangeHandle(event: any) {
    this.props.parentData.timeTable.type = parseInt(event.currentTarget.dataset.id);
    this.props.parentSetData({ ...this.props.parentData });
  }

  handleChangeUser(data: SelectResult) {
    const parentDataUpdate = deepClone(this.props.parentData);
    parentDataUpdate.timeTable.generalData.selectedUser = data.value;
    this.props.parentSetData({ ...parentDataUpdate });
  }

  handleChangeLocation(data: SelectResult) {
    const parentDataUpdate = deepClone(this.props.parentData);
    parentDataUpdate.timeTable.generalData.selectedLocation = data.value;
    this.props.parentSetData({ ...parentDataUpdate });
  }

  handleChangeProject(data: SelectResult) {
    const parentDataUpdate = deepClone(this.props.parentData);
    parentDataUpdate.timeTable.generalData.selectedProject = data.value;
    parentDataUpdate.timeTable.generalData.locations = getFilteredDataByProject(
      parentDataUpdate.data.locations,
      data.value,
    );
    parentDataUpdate.timeTable.generalData.tasks = getFilteredDataByProject(parentDataUpdate.data.tasks, data.value);
    parentDataUpdate.timeTable.generalData.users = getFilteredDataByProject(parentDataUpdate.data.users, data.value);
    parentDataUpdate.timeTable.generalData.selectedTask = parentDataUpdate.timeTable.generalData.tasks.options[0].id;
    parentDataUpdate.timeTable.generalData.selectedLocation = null;
    parentDataUpdate.timeTable.generalData.selectedUser = null;
    this.props.parentSetData({ ...parentDataUpdate });
  }

  handleChangeTask(data: SelectResult) {
    const parentDataUpdate = deepClone(this.props.parentData);
    parentDataUpdate.timeTable.generalData.selectedTask = data.value;
    this.props.parentSetData({ ...parentDataUpdate });
  }

  handleChangeIsApproved() {
    const parentDataUpdate = deepClone(this.props.parentData);
    parentDataUpdate.timeTable.isApproved = !parentDataUpdate.timeTable.isApproved;
    this.props.parentSetData({ ...parentDataUpdate });
  }

  renderView() {
    const allTypes = [
      { id: SINGLE_TYPE, name: 'Jednorazowe' },
      { id: RECURRING_TYPE, name: 'Cykliczne' },
      { id: MANUAL_TYPE, name: 'Ręczne planowanie' },
    ];

    const generalData: IEditTimeTableGeneralDataState = this.props.parentData.timeTable.generalData;
    const getOptionWithEmptyRow = (options: Array<SelectOptions>): Array<SelectOptions> => {
      return [{ name: 'Wybierz', id: null }, ...options];
    };
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '20px' }}>
          {generalData !== undefined && (
            <>
              <h1>Ogólne</h1>
              <SeparatorEmpty size={0.5} />
              <Row>
                <Col xs={12} md={6}>
                  <Select
                    placeholder={'Projekt'}
                    name={'projectId'}
                    value={generalData.selectedProject}
                    options={generalData.projects.options}
                    onChange={this.handleChangeProject.bind(this)}
                    disabled={generalData.disabled.includes('project')}
                  />
                  <SeparatorEmpty size={1.5} />
                  <Select
                    placeholder={'Zadanie'}
                    name={'taskId'}
                    value={generalData.selectedTask}
                    options={generalData.tasks.options}
                    onChange={this.handleChangeTask.bind(this)}
                    disabled={generalData.disabled.includes('project')}
                  />
                  <SeparatorEmpty size={1.5} />
                  <Select
                    placeholder={'Raportujący'}
                    name={'userId'}
                    value={generalData.selectedUser}
                    options={
                      generalData.type === 'update'
                        ? generalData.users.options
                        : getOptionWithEmptyRow(generalData.users.options)
                    }
                    onChange={this.handleChangeUser.bind(this)}
                    disabled={generalData.disabled.includes('user')}
                  />
                  <SeparatorEmpty size={1.5} />
                  <Select
                    placeholder={'Lokalizacja'}
                    name={'locationId'}
                    value={generalData.selectedLocation}
                    options={
                      generalData.type === 'update'
                        ? generalData.locations.options
                        : getOptionWithEmptyRow(generalData.locations.options)
                    }
                    onChange={this.handleChangeLocation.bind(this)}
                    disabled={generalData.disabled.includes('location')}
                  />
                </Col>
              </Row>
            </>
          )}
          <CheckBox
            isChecked={this.props.parentData.timeTable.isApproved}
            onClick={this.handleChangeIsApproved.bind(this)}
          >
            Czy harmonogram jest zatwierdzony?
          </CheckBox>
          <RadioHorizontalGroup>
            {allTypes.map((data, index) => {
              return (
                <RadioButton
                  key={index}
                  id={data.id}
                  name={data.name}
                  value={this.props.parentData.timeTable.type}
                  onChange={this.radioChangeHandle.bind(this)}
                />
              );
            })}
          </RadioHorizontalGroup>
          <EditTimeTableSingleSettings
            hidden={this.props.parentData.timeTable.type !== SINGLE_TYPE}
            parentData={this.props.parentData}
            parentSetData={this.props.parentSetData}
          />
          <EditTimeTableRecurringSettings
            hidden={this.props.parentData.timeTable.type !== RECURRING_TYPE}
            parentData={this.props.parentData}
            parentSetData={this.props.parentSetData}
          />
          <EditTimeTableManualSettings
            hidden={this.props.parentData.timeTable.type !== MANUAL_TYPE}
            parentData={this.props.parentData}
            parentSetData={this.props.parentSetData}
          />
        </div>
      </>
    );
  }
}
