import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TimeTableConnection from 'utils/connections/timetable';
import WhiteCard from 'global/atoms/WhiteCard';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import List from 'global/table/List';
import AlertInfo from 'global/atoms/alert/AlertInfo';

interface ITimeTableToUpdateState {
  message: string;
  data: Array<{
    user: string;
    location: string;
    type: string;
  }>;
}
const TimeTableToUpdate: FunctionComponent = () => {
  const [state, setState] = useState<ITimeTableToUpdateState>({
    message: '',
    data: [],
  });
  const [loading, setLoading] = useState(true);
  const { id, taskId } = useParams<{ id: string; taskId: string }>();

  useEffect(() => {
    TimeTableConnection.getTimeTableToUpdate(id, taskId).then((response) => {
      setLoading(false);
      setState({ data: response.data.data, message: response.data.message });
    });
  }, []);

  if (loading) return null;

  if (state.data.length <= 0 && state.message === '') {
    return <></>;
  }

  if (state.message !== '') {
    return (
      <WhiteCard style={{ marginTop: '24px' }}>
        <AlertInfo>{state.message}</AlertInfo>
      </WhiteCard>
    );
  }

  return (
    <>
      <WhiteCard style={{ marginTop: '24px' }}>
        <AlertInfo>Lista wymaganych zmian w trakcie aktualizacji</AlertInfo>
        <List data={state.data} count={state.data.length}>
          <Column flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              Użytkownik
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="user" />
          </Column>
          <Column flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              Lokalizacja
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="location" />
          </Column>
          <Column flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              Status
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <span>{rowData.type === 'ADD' ? <span>nowy</span> : <span>do usunięcia</span>}</span>}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
    </>
  );
};

export default TimeTableToUpdate;
