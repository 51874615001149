import React, { FunctionComponent, useState } from 'react';
import ReactDOM from 'react-dom';
import stylesLayout from '../../../../../global/layout/layout.module.scss';
import Button from '../../../../../global/atoms/Button';
import ListingNetworkItemEdit from './ListingNetworkItemEdit';

interface IListingNetworkItemEditButton {
  activityId: string;
}

const ListingNetworkItemEditButton: FunctionComponent<IListingNetworkItemEditButton> = ({ activityId }) => {
  const [state, setState] = useState<'default' | 'edit'>('default');

  return (
    <>
      {state === 'edit' && (
        <>
          {ReactDOM.createPortal(
            <>
              <div className={stylesLayout.blackOverlay}>
                <div>
                  <ListingNetworkItemEdit activityId={activityId} parentSetState={setState} />
                </div>
              </div>
            </>,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            document.getElementById('layout-portal')!,
          )}
        </>
      )}

      <Button onClick={() => setState('edit')}>Ustaw listing produktów</Button>
    </>
  );
};

export default ListingNetworkItemEditButton;
