import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import FileUpload from '../../../../global/atoms/FileUpload';
import { getOrganizationId, handleToast } from '../../../../utils/helpers';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import ProjectFilesConnection from '../../../../utils/connections/projectFiles';
import { Col, Row } from 'react-grid-system';
import Select from '../../../../global/atoms/Select';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import List from '../../../../global/table/List';
import IconButton from '../../../../global/atoms/IconButton';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import { confirmModal } from '../../../../redux/store';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import { DISABLED_ADD_NEW_BUTTON } from '../../../../utils/disabledElementsConstants';

interface IFilesListForm {
  types: any;
  disabledElements: Array<any>;
}

interface IProjectFilesReadModel {
  createdAt: string;
  extension: string;
  id: string;
  name: string;
  size: string;
  type: string;
}

const TYPE_ALL = 'TYPE_ALL';

const FilesList: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [state, setState] = useState<{
    selectedType: string;
  }>({
    selectedType: TYPE_ALL,
  });
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<IFilesListForm>({
    types: [],
    disabledElements: [],
  });
  const [data, setData] = useState<Array<IProjectFilesReadModel>>([]);
  const history = useHistory();

  useEffect(() => {
    Promise.all([ProjectFilesConnection.uploadViewData(id), ProjectFilesConnection.getAllProjectFiles(id)]).then(
      (responses) => {
        setForm(responses[0].data);
        setData(responses[1].data);
        setLoading(false);
      },
    );
  }, []);

  const reloadData = () => {
    handleToast(ProjectFilesConnection.getAllProjectFiles(id)).then((response) => {
      setData(response.data);
    });
  };

  const handleFileUploadResponse = () => {
    reloadData();
  };

  const saveUrl = `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/projects/${id}/files`;

  const getFilteredData = () => {
    if (state.selectedType === TYPE_ALL) {
      return data;
    }

    return data.filter((item) => {
      return item.type === state.selectedType;
    });
  };

  const handleDownloadFile = (fileId: string, name: string) => {
    handleToast(ProjectFilesConnection.download(name, fileId, id));
  };

  const handleRemoveFile = (fileId: string) => {
    handleToast(ProjectFilesConnection.removeFile(fileId, id)).then(() => {
      reloadData();
    });
  };

  const handleRedirectToFileEdit = (fileId: string) => {
    history.push(`/projects/${id}/files/${fileId}/edit`);
  };

  if (loading) return <Spinner />;

  return (
    <>
      <WhiteCard padding={true}>
        <Row>
          <Col xs={6}>
            <Select
              style={{ zIndex: 100 }}
              placeholder={'Kategoria'}
              name={'selectedType'}
              value={state.selectedType}
              options={form.types.options}
              state={state}
              setState={setState}
            />
          </Col>
          <Col xs={6} style={{ textAlign: 'right' }}>
            <FileUpload
              label={'Dodaj pliki'}
              saveUrl={saveUrl}
              savedFileCallback={handleFileUploadResponse}
              disabled={form?.disabledElements?.includes(DISABLED_ADD_NEW_BUTTON)}
            />
          </Col>
        </Row>
        <SeparatorEmpty size={2} />
        <Row>
          <Col xs={12}>
            <List data={getFilteredData()} count={getFilteredData().length}>
              <Column flexGrow={1} minWidth={300} align="left">
                <HeaderCell depth={0} width={0}>
                  Nazwa
                </HeaderCell>
                <Cell width={100} depth={0}>
                  {(rowData) => (
                    <ButtonListRedirect redirectUrl={`/projects/${id}/files/${rowData.id}/edit`}>
                      {rowData.name}
                    </ButtonListRedirect>
                  )}
                </Cell>
              </Column>
              <Column width={300} align="left">
                <HeaderCell depth={0} width={0}>
                  Opis
                </HeaderCell>
                <Cell width={100} depth={0} dataKey="description" />
              </Column>
              <Column width={150} align="left">
                <HeaderCell depth={0} width={0}>
                  Wielkość
                </HeaderCell>
                <Cell width={100} depth={0} dataKey="size" />
              </Column>
              <Column width={180} align="left">
                <HeaderCell depth={0} width={0}>
                  Data utworzenia
                </HeaderCell>
                <Cell width={100} depth={0} dataKey="createdAt" />
              </Column>
              <Column width={150} align="left">
                <HeaderCell depth={0} width={0}>
                  Akcje
                </HeaderCell>
                <Cell width={100} depth={0}>
                  {(rowData) => {
                    return (
                      <>
                        <ActionsContainer align={'left'}>
                          <IconButton
                            onClick={handleDownloadFile.bind(null, rowData.id, rowData.name)}
                            icon={'arrowRightBig'}
                          />
                          <IconButton onClick={handleRedirectToFileEdit.bind(null, rowData.id)} icon={'settings'} />
                          <IconButton
                            onClick={confirmModal(
                              'Czy na pewno chcesz usunąć ten plik?',
                              handleRemoveFile.bind(null, rowData.id),
                            )}
                            icon={'trash'}
                            size={30}
                          />
                        </ActionsContainer>
                      </>
                    );
                  }}
                </Cell>
              </Column>
            </List>
          </Col>
        </Row>
      </WhiteCard>
    </>
  );
};

export default FilesList;
