import React, { useEffect, useState } from 'react';
import styles from './header.module.scss';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import routes from 'routes/routes';
import { Link, useHistory } from 'react-router-dom';
import User from 'global/user/User';
import { useDispatch, useSelector } from 'react-redux';
import { IHeader, IRoot } from 'redux/models';
import { getProjectIdFromUrl } from '../../../utils/helpers';
import { useLocation } from 'react-router';
import ProjectsConnection from '../../../utils/connections/projects';

const Header = () => {
  const [isScrolled, setScrolled] = useState(false);
  const breadcrumbs = useBreadcrumbs(routes);
  const headerStore = useSelector((state: IRoot) => state.header);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();

  const loadProjectHeader = () => {
    const projectIdFromUrl = getProjectIdFromUrl();
    if (projectIdFromUrl === null) {
      dispatch({ type: 'SET_HEADER', payload: { name: '', subName: '' } });
      return false;
    }

    if (headerStore.nameUrl === undefined) {
      headerStore.nameUrl = '';
    }

    const nameUrlParts = headerStore.nameUrl.split('/');
    if (nameUrlParts[2] === projectIdFromUrl) {
      return false;
    }

    ProjectsConnection.getProject(projectIdFromUrl).then((response) => {
      const projectReadModel = response.data;
      const headerData: IHeader = {
        subNameUrl: '',
        subName: '',
        name: projectReadModel.name,
        nameUrl: `/projects/${projectIdFromUrl}/visits`,
      };
      dispatch({ type: 'SET_HEADER', payload: headerData });
    });
  };

  useEffect(() => {
    loadProjectHeader();
  }, [pathname]);

  useEffect(() => {
    const elem = document.querySelector('#content');
    const updateScrolled = () => {
      if (elem) {
        if (elem.scrollTop > 30) setScrolled(true);
        if (elem.scrollTop <= 30) setScrolled(false);
      }
    };
    elem?.addEventListener('scroll', updateScrolled);

    return () => elem?.removeEventListener('scroll', updateScrolled);
  }, []);

  const handleNameClick = () => {
    if (headerStore.nameUrl === '') return false;
    history.push(headerStore.nameUrl);
  };

  const handleSubNameClick = () => {
    if (headerStore.subNameUrl === '') return false;
    history.push(headerStore.nameUrl);
  };

  const maxHeaderNameLength = 50;

  return (
    <header
      className={styles.fluidHeader}
      style={{
        backgroundColor: isScrolled ? '#fff' : '',
        boxShadow: isScrolled ? '0px 5px 16px -6px rgba(0,0,0,0.4)' : '',
      }}
    >
      <div style={{ fontSize: '0.85rem', color: '#aeaeaf', fontWeight: 500 }}>
        {breadcrumbs.map(({ match, breadcrumb }, i) => (
          <>
            <Link key={match.url} to={match.url} style={{ textDecoration: 'none', color: 'inherit' }}>
              {breadcrumb}
            </Link>
            {i < breadcrumbs.length - 1 && <span>{' > '}</span>}
          </>
        ))}
        <div style={{ fontSize: '1.1rem', color: '#131315', fontWeight: 500, position: 'relative', cursor: 'pointer' }}>
          <span onClick={handleNameClick} title={headerStore.name}>
            {headerStore.name.length > maxHeaderNameLength
              ? headerStore.name.substr(0, maxHeaderNameLength) + '...'
              : headerStore.name}
          </span>
          {headerStore.subName && (
            <>
              <span>{' > '}</span>
              <span onClick={handleSubNameClick} title={headerStore.subName}>
                {headerStore.subName.length > maxHeaderNameLength
                  ? headerStore.subName.substr(0, maxHeaderNameLength) + '...'
                  : headerStore.subName}
              </span>
            </>
          )}
        </div>
      </div>
      <div className={styles.flexGrow}>
        <div className={styles.flexGrow} id="header-buttons"></div>
        <div className={styles.computerVisible}>
          <User />
        </div>
      </div>
    </header>
  );
};

export default Header;
