import React, { FunctionComponent } from 'react';
import TableHeaderCell from './TableHeaderCell';
import styles from '../../../../../global/atoms/atoms.module.scss';
import CheckBox from '../../../../../global/atoms/checkbox/CheckBox';
import {
  ITimeTableDetailsState,
  VIEW_RANGE_MONTH,
  VIEW_RANGE_QUARTER,
  VIEW_TYPE_LOCALISATION,
  VIEW_TYPE_USER,
  viewTypeName,
} from './TimeTableDetails';
import { cutTextTripleDot } from '../../../../../utils/helpers';
import { MONTHS } from '../../../../../global/atoms/select/SelectMonth';
import CheckBoxesBody from './CheckBoxesBody';
import DateDetailsPopover from './DateDetailsPopover';
import Legend from './Legend';

interface ITableProps {
  state: ITimeTableDetailsState;
  setState: any;
  setLoadData: any;
  loadedFilters: any;
  viewData: any;
}

export const getSelectedMonths = (state: any): Array<Date> => {
  if (state.viewRange === VIEW_RANGE_MONTH) {
    const month = state.month.getMonth();
    return [new Date(state.month.getFullYear(), month, 1)];
  }

  const months: Array<Date> = [];
  const monthStart = state.quarter.quarter * 3;
  for (let i = 0; i < 3; i++) {
    const month = monthStart + i;
    const date = new Date(state.quarter.year, month, 1);
    months.push(date);
  }

  return months;
};

const Table: FunctionComponent<ITableProps> = ({ state, setState, setLoadData, loadedFilters, viewData }) => {
  const daysShortName: Array<string> = ['ND', 'PN', 'WT', 'ŚR', 'CZ', 'PT', 'SB'];

  type rowDataType = { id: string; name: string };
  const checkIdExistInRowsData = (id: string, rowsData: Array<rowDataType>): boolean => {
    const rowsDataFound = rowsData.filter((data) => data.id === id);
    return rowsDataFound.length > 0;
  };

  const getRowsData = (): Array<rowDataType> => {
    const rowsData: Array<rowDataType> = [];
    state.timeTables.map((timeTable: any) => {
      if (state.viewType === VIEW_TYPE_USER && !checkIdExistInRowsData(timeTable.user.id, rowsData)) {
        rowsData.push({
          id: timeTable.user.id,
          name: timeTable.user.firstName + ' ' + timeTable.user.lastName,
        });
      } else if (
        state.viewType === VIEW_TYPE_LOCALISATION &&
        !checkIdExistInRowsData(timeTable.location.id, rowsData)
      ) {
        rowsData.push({
          id: timeTable.location.id,
          name: timeTable.location.name,
        });
      }
    });
    return rowsData;
  };

  const daysHeader: Array<any> = [];
  const checkBoxesHeader: Array<any> = [];
  getSelectedMonths(state).map((selectedMonth) => {
    const countDays = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate();
    for (let i = 1; i <= countDays; i++) {
      const currentDateLoop = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), i);
      const weekDayNumber = currentDateLoop.getDay();
      daysHeader.push(
        <TableHeaderCell
          key={`${i}-${currentDateLoop.getTime()}`}
          dayNumber={i}
          dayShortName={daysShortName[weekDayNumber]}
        />,
      );

      if (state.tableType === 'edit') {
        checkBoxesHeader.push(
          <CheckBox
            key={currentDateLoop.getTime()}
            styleContainer={{ justifyContent: 'center' }}
            style={{ marginRight: 0 }}
            isChecked={false}
            onClick={() => {
              const taskOption = viewData.tasks.options.filter((data: any) => {
                return data.id === loadedFilters.taskId;
              });
              const taskProjectId = taskOption.length > 0 ? taskOption[0].projectId : null;
              setState({
                ...state,
                editData: {
                  show: true,
                  type: 'new',
                  updateId: null,
                  day: currentDateLoop,
                  userId: null,
                  locationId: null,
                  projectId: loadedFilters.projectId !== null ? loadedFilters.projectId : taskProjectId,
                  taskId: loadedFilters.taskId !== null ? loadedFilters.taskId : viewData.tasks.options[0].id,
                  disabled: [],
                },
              });
            }}
          />,
        );
      }
    }
  });

  const monthsHeader: Array<any> = [];
  getSelectedMonths(state).map((selectedMonth) => {
    const countDays = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate();
    monthsHeader.push(
      <div
        style={{
          gridColumn: `span ${countDays}`,
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        {MONTHS[selectedMonth.getMonth()].toUpperCase()}
      </div>,
    );
  });

  const getCountDays = (state: any): number => {
    let countDaysTotal = 0;
    getSelectedMonths(state).map((selectedMonth) => {
      const countDays = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0).getDate();
      countDaysTotal += countDays;
    });

    return countDaysTotal;
  };

  const leftColumnSize = 7;
  const countGridColumns: number = getCountDays(state) + leftColumnSize;

  const rowsHtml: any = [];
  getRowsData().map((data: rowDataType, index) => {
    rowsHtml.push(
      <div
        key={index}
        data-id={data.id}
        style={{
          gridTemplateColumns: `repeat(${countGridColumns}, 20px)`,
          alignItems: 'center',
        }}
        className={styles.timeTableTableBodyContainer}
      >
        <div className={styles.timeTableLeftColumn} style={{ gridColumn: `1/span ${leftColumnSize}` }}>
          <span className={styles.timeTableLeftColumnText}>{cutTextTripleDot(data.name, 18)}</span>
        </div>
        <CheckBoxesBody
          state={state}
          id={data.id}
          setState={setState}
          loadedFilters={loadedFilters}
          viewData={viewData}
        />
      </div>,
    );
  });

  return (
    <>
      <div style={{ position: 'relative', minHeight: '500px' }}>
        <div className={styles.timeTableTableScrollContainer}>
          <div className={styles.timeTableTableContainer}>
            <div
              style={{
                gridTemplateColumns: `repeat(${countGridColumns}, 20px)`,
                alignItems: 'center',
                marginBottom: state.tableType === 'edit' ? '15px' : '5px',
                position: 'sticky',
                top: 0,
                left: 0,
                alignSelf: 'start',
                backgroundColor: '#fff',
                zIndex: 1,
              }}
              className={styles.timeTableTableHeaderContainer}
            >
              <div
                style={{
                  gridColumn: `1/span ${leftColumnSize}`,
                  gridRow: `1 / span ${state.viewRange === VIEW_RANGE_QUARTER ? 3 : 2}`,
                  fontWeight: 'bold',
                }}
              >
                <span className={[styles.timeTableViewTypeName, styles.timeTableLeftColumn].join(' ')}>
                  {viewTypeName[state.viewType].toUpperCase()}
                </span>
              </div>
              {state.viewRange === VIEW_RANGE_QUARTER ? monthsHeader : null}
              {daysHeader}
              {checkBoxesHeader}
            </div>
            {rowsHtml}
          </div>
        </div>
        {state.tableType === 'preview' && <Legend />}
      </div>
      <DateDetailsPopover state={state} setState={setState} setLoadData={setLoadData} />
    </>
  );
};

export default Table;
