import React, { FunctionComponent } from 'react';
import styles from '../../../projects/components/projectsList/styles.module.scss';

interface IActivitiesListHeader {}

const ActivitiesListHeader: FunctionComponent<IActivitiesListHeader> = () => {
  return (
    <div className={styles.container}>
      <div className={`${styles.col} ${styles.colName}`}>
        <span style={{ cursor: 'pointer' }}>NAZWA AKTYWNOŚCI</span>
      </div>
      <div className={`${styles.col} ${styles.colStatus}`} style={{ textAlign: 'center', flex: 2 }}>
        <span style={{ cursor: 'pointer' }}>GRUPA</span>
      </div>
      <div className={`${styles.col} ${styles.colStatus}`} style={{ textAlign: 'center' }}>
        <span style={{ cursor: 'pointer' }}>TYP</span>
      </div>
      <div className={`${styles.col} ${styles.colStatus}`} style={{ textAlign: 'center' }}>
        <span style={{ cursor: 'pointer' }}>LOKALIZACJE</span>
      </div>
      <div className={`${styles.col} ${styles.colStatus}`} style={{ textAlign: 'center' }}>
        <span style={{ cursor: 'pointer' }}>PRODUKTY</span>
      </div>
      <div className={`${styles.col} ${styles.colActions}`}>AKCJE</div>
    </div>
  );
};

export default ActivitiesListHeader;
