import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IWarehouseAvailabilityFilters, IWarehouseUserShiftData } from 'utils/models';
import { defaultFilterOrderState, defaultFilterPaginateState } from 'utils/states';
import WarehouseConnection from 'utils/connections/warehouse';
import WhiteCard from 'global/atoms/WhiteCard';
import List from 'global/table/List';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import Filters from 'global/filters/Filters';
import Pagination from 'global/pagination/Pagination';
import Select from 'global/atoms/Select';
import BadgeStatus from 'global/atoms/badge/BadgeStatus';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import Spinner from 'global/atoms/Spinner/Spinner';
import {
  handleClearFiltersCustomCacheKey,
  handleSubmitAndSaveFiltersCustomKey,
  loadFilters,
} from '../../../../utils/filterHelper';
import SeparatorLine from '../../../../global/atoms/separators/SeparatorLine';

interface IWarehouseList {}

interface IFilters extends IWarehouseAvailabilityFilters {
  groupId: string;
  userToId: string;
  userFromId: string;
  userRoleFromId: string;
  userRoleToId: string;
}

const FILTERS_STORAGE_KEY = '[FILTER]WarehouseShiftList';

const WarehouseShiftList: FunctionComponent<IWarehouseList> = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<IWarehouseUserShiftData | null | 'error'>(null);
  const [loading, setLoading] = useState(true);
  const [form, setForm] = useState<{
    items: any;
    brands: any;
    itemGroups: any;
    users: any;
    roles: any;
  }>({
    brands: {},
    items: {},
    itemGroups: {},
    users: {},
    roles: {},
  });
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [reloadData, setReloadData] = useState<boolean>(false);

  const defaultFilters: IFilters = {
    brandId: '',
    itemId: '',
    groupId: '',
    userToId: '',
    userFromId: '',
    userRoleFromId: '',
    userRoleToId: '',
    ...defaultFilterOrderState,
    ...defaultFilterPaginateState,
  };
  const [filters, setFilters] = useState<IFilters>(loadFilters(defaultFilters, FILTERS_STORAGE_KEY));

  useEffect(() => {
    WarehouseConnection.userShiftFilterForm(id).then((data) => {
      setLoading(false);
      setForm(data.data);
    });
  }, []);

  const loadData = () => {
    WarehouseConnection.userShiftList(id, filters).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  };

  useEffect(() => loadData(), [filters.requestPaginate]);

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
  }, [reloadData]);

  const handleToggleFilters = () => {
    setToggleFilters((state) => !state);
  };

  const generateUsersFromList = (users: Array<any>) => {
    if (filters.userRoleFromId === '') {
      return [];
    }
    const usersFiltered = users.filter((userData) => userData.rolesId.includes(filters.userRoleFromId));
    return usersFiltered.map((user: any) => ({
      id: user.id,
      name: user.fullName,
    }));
  };

  const generateUsersToList = (users: Array<any>) => {
    if (filters.userRoleToId === '') {
      return [];
    }
    const usersFiltered = users.filter((userData) => userData.rolesId.includes(filters.userRoleToId));
    return usersFiltered.map((user: any) => ({
      id: user.id,
      name: user.fullName,
    }));
  };

  if (loading) return <Spinner />;

  if (data === null || data === 'error' || form.brands.options === undefined) return null;

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} customFiltersStorageKey={FILTERS_STORAGE_KEY} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column flexGrow={2} minWidth={150} align="left">
            <HeaderCell depth={0} width={0}>
              Produkt
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="name" />
          </Column>
          <Column width={100} align="left">
            <HeaderCell depth={0} width={0}>
              Grupa
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="itemGroup" />
          </Column>
          <Column flexGrow={1} minWidth={100} align="left">
            <HeaderCell depth={0} width={0}>
              od kogo
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="userFrom" />
          </Column>
          <Column minWidth={100} align="left">
            <HeaderCell depth={0} width={0}>
              Grupa
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return (
                  <BadgeStatus color={rowData.userRoleFrom.roleType.color}>{rowData.userRoleFrom.roleName}</BadgeStatus>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={1} minWidth={100} align="left">
            <HeaderCell depth={0} width={0}>
              do kogo
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="userTo" />
          </Column>
          <Column flexGrow={1} minWidth={100} align="left">
            <HeaderCell depth={0} width={0}>
              Grupa
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => {
                return (
                  <BadgeStatus color={rowData.userRoleTo.roleType.color}>{rowData.userRoleTo.roleName}</BadgeStatus>
                );
              }}
            </Cell>
          </Column>
          <Column align="left">
            <HeaderCell depth={0} width={0}>
              PZ(s)
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="incoming" />
          </Column>
          <Column width={120} align="left">
            <HeaderCell depth={0} width={0}>
              data
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="operationDate" />
          </Column>
        </List>
      </WhiteCard>
      <Filters
        handleToggleFilters={handleToggleFilters}
        toggleFilters={toggleFilters}
        filtersState={filters}
        setFilters={setFilters}
        handleSubmit={handleSubmitAndSaveFiltersCustomKey.bind(null, setReloadData, filters, FILTERS_STORAGE_KEY)}
        handleClear={handleClearFiltersCustomCacheKey.bind(
          null,
          setFilters,
          defaultFilters,
          setReloadData,
          FILTERS_STORAGE_KEY,
        )}
      >
        <Select
          placeholder={'Produkt'}
          name={'itemId'}
          value={filters.itemId}
          options={form.items.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Marka'}
          name={'brandId'}
          value={filters.brandId}
          options={form.brands.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Grupa produktu'}
          name={'groupId'}
          value={filters.groupId}
          options={form.itemGroups.options}
          state={filters}
          setState={setFilters}
        />
        <SeparatorLine size={2} />
        <Select
          placeholder={'Rola użytkownika'}
          name={'userRoleFromId'}
          value={filters.userRoleFromId}
          options={form.roles.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Od kogo'}
          name={'userFromId'}
          value={filters.userFromId}
          options={generateUsersFromList(form.users.options)}
          state={filters}
          setState={setFilters}
        />
        <SeparatorLine size={2} />
        <Select
          placeholder={'Rola użytkownika'}
          name={'userRoleToId'}
          value={filters.userRoleToId}
          options={form.roles.options}
          state={filters}
          setState={setFilters}
        />
        <Select
          placeholder={'Do kogo'}
          name={'userToId'}
          value={filters.userToId}
          options={generateUsersToList(form.users.options)}
          state={filters}
          setState={setFilters}
        />
      </Filters>
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
    </>
  );
};

export default WarehouseShiftList;
