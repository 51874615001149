import React, { CSSProperties } from 'react';
import styles from './styles.module.scss';
import { ReactComponent as SpinnerIcon } from 'assets/svg/spinner.svg';

interface ISpinnerProps {
  style?: CSSProperties;
}

const Spinner: React.FC<ISpinnerProps> = ({ style }) => (
  <div style={style} className={styles.wrapperSpinner}>
    <SpinnerIcon className={styles.spinner} />
  </div>
);

export default Spinner;
