import React, { FunctionComponent, useEffect, useState } from 'react';
import { IGetQuestions } from 'utils/models';
import { getQuestionsState } from 'utils/states';
import WhiteCard from 'global/atoms/WhiteCard';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import QuestionsConnection from 'utils/connections/questions';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import arrow from 'assets/svg/arrow_down_small.svg';
import settings from 'assets/svg/settings.svg';
import List from 'global/table/List';
import { useHistory } from 'react-router-dom';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import { useDispatch } from 'react-redux';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import Spinner from 'global/atoms/Spinner/Spinner';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import {
  DeleteSelectedButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import { deepClone, handleToast } from '../../../../utils/helpers';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import { handleClearFilters, loadFilters } from '../../../../utils/filterHelper';
import FiltersStorage from '../../../../utils/FiltersStorage';

interface IQuestionsList {}

const QuestionsList: FunctionComponent<IQuestionsList> = () => {
  const [data, setData] = useState<any | null | 'error'>(null);
  const defaultFilters = deepClone(getQuestionsState);
  const [filters, setFilters] = useState<IGetQuestions>(loadFilters(defaultFilters));
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [form, setForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const dispatch = useDispatch();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  useEffect(() => {
    QuestionsConnection.getQuestionsFiltersForm().then((data) => {
      setForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  const reloadData = () => {
    QuestionsConnection.getQuestions(filters).then((data) => {
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  };

  useEffect(() => {
    setFilters((state: IGetQuestions) => ({
      ...state,
      requestPaginate: {
        page: 1,
        limit: state.requestPaginate.limit,
      },
    }));

    reloadData();
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    reloadData();
  }, [filters.requestPaginate.page, filters.requestOrder]);

  const handleToggleSort = (event: any) => {
      if (filters.requestOrder.field === event.currentTarget.dataset.field) {
        setFilters({
          ...filters,
          requestOrder: {
            field: filters.requestOrder.field,
            order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
          },
        });
      } else {
        setFilters({
          ...filters,
          requestOrder: {
            field: event.currentTarget.dataset.field,
            order: 'asc',
          },
        });
      }
    },
    handleToggleFilters = () => setToggleFilters((state: boolean) => !state),
    handleEdit = (event: any) => {
      history.push(`/datasets/questions/${event.currentTarget.dataset.id}/edit`);
    },
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      const filtersStorage = new FiltersStorage();
      filtersStorage.save(filters);

      QuestionsConnection.getQuestions({
        ...filters,
        requestPaginate: {
          page: 1,
          limit: filters.requestPaginate.limit,
        },
      }).then((data) => {
        setToggleFilters(false);
        setData(data.data);
        setFilters((state: IGetQuestions) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    };

  if (data == null || form === 'loading') return <Spinner />;

  if (data === 'error') return <div>Błąd pobierania danych</div>;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(QuestionsConnection.batchDelete(toDelete)).then(() => {
      setChosenToDelete([]);
      reloadData();
    });
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
        <ButtonAddNew form={form} />
        <DeleteSelectedButton chosenToDelete={chosenToDelete} callback={handleDeleteSelected} form={form} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox data={data.data} state={chosenToDelete} setState={setChosenToDelete} />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox state={chosenToDelete} setState={setChosenToDelete} selectedId={rowData.id} />
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} minWidth={250} align="left">
            <HeaderCell depth={0} width={0}>
              <span
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={handleToggleSort}
                data-field={'name'}
              >
                Nazwa
                {filters.requestOrder.field === 'name' &&
                  (filters.requestOrder.order === 'asc' ? (
                    <img src={arrow} alt={'sort'} />
                  ) : (
                    <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
                  ))}
              </span>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/datasets/questions/${rowData.id}/edit`}>
                  {rowData.name}
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} align="left" width={200} resizable={true}>
            <HeaderCell depth={0} width={0}>
              Klient
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => <span>{rowData.customer.name}</span>}
            </Cell>
          </Column>
          <Column flexGrow={1} verticalAlign={'middle'} align="left" minWidth={150} resizable={true}>
            <HeaderCell depth={0} width={0}>
              Typ
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => <span>{rowData.questionType.name}</span>}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} align="left" width={200} resizable={true}>
            <HeaderCell depth={0} width={0}>
              Aktywność
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => <span>{rowData.activityType.name}</span>}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} align="center" fixed={'right'} width={100}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <ActionsContainer>
                  <img
                    src={settings}
                    style={{ cursor: 'pointer' }}
                    alt={'Idź do'}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                  <DeleteSingleButton callback={handleDeleteSelected.bind(null, rowData.id)} form={form} />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, reloadData)}
      >
        <Input
          value={filters.name}
          placeholder={'Nazwa'}
          name={'name'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Klient'}
          name={'customerId'}
          value={filters.customerId}
          options={form.customers.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Typ pytania'}
          name={'questionTypeId'}
          value={filters.questionTypeId}
          options={form.questionTypes.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Aktywność'}
          name={'activityTypeId'}
          value={filters.activityTypeId}
          options={form.activityTypes.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
      </Filters>
    </>
  );
};

export default QuestionsList;
