import React from 'react';
import { Redirect } from 'react-router-dom';
import CountriesAdd from 'views/superadmin/components/countriesAdd/CountriesAdd';
import CountriesList from 'views/superadmin/components/countriesList/CountriesList';
import HintsAdd from 'views/superadmin/components/hintsAdd/HintsAdd';
import HintsEdit from 'views/superadmin/components/hintsEdit/HintsEdit';
import HintsList from 'views/superadmin/components/hintsList/HintsList';
import OrganizationsEdit from 'views/superadmin/components/organizationsEdit/OrganizationsEdit';
import OrganizationsList from 'views/superadmin/components/organizationsList/OrganizationsList';
import TranslationsAdd from 'views/superadmin/components/translationsAdd/TranslationsAdd';
import TranslationsEdit from 'views/superadmin/components/translationsEdit/TranslationsEdit';
import TranslationsList from 'views/superadmin/components/translationsList/TranslationsList';

export default [
  { path: '/superadmin', breadcrumb: null, Component: () => <Redirect to={'/superadmin/organizations'} /> },
  { path: '/superadmin/organizations', breadcrumb: 'Organizacje', Component: OrganizationsList },
  { path: '/superadmin/organizations/add', breadcrumb: 'Dodaj organizację', Component: OrganizationsEdit },
  { path: '/superadmin/organizations/:id', breadcrumb: null },
  { path: '/superadmin/organizations/:id/edit', breadcrumb: 'Edytuj organizację', Component: OrganizationsEdit },
  { path: '/superadmin/translations', breadcrumb: 'Tłumaczenia', Component: TranslationsList },
  { path: '/superadmin/translations/add', breadcrumb: 'Dodaj tłumaczenie', Component: TranslationsAdd },
  { path: '/superadmin/translations/:id', breadcrumb: null },
  { path: '/superadmin/translations/:id/edit', breadcrumb: 'Edytuj tłumaczenie', Component: TranslationsEdit },
  { path: '/superadmin/hints', breadcrumb: 'Podpowiedzi', Component: HintsList },
  { path: '/superadmin/hints/add', breadcrumb: 'Dodaj podpowiedź', Component: HintsAdd },
  { path: '/superadmin/hints/:id', breadcrumb: null },
  { path: '/superadmin/hints/:id/edit', breadcrumb: 'Edytuj podpowiedź', Component: HintsEdit },
  { path: '/superadmin/countries', breadcrumb: 'Kraje', Component: CountriesList },
  { path: '/superadmin/countries/add', breadcrumb: 'Dodaj kraj', Component: CountriesAdd },
  { path: '/superadmin/countries/:id', breadcrumb: null },
  { path: '/superadmin/countries/:id/edit', breadcrumb: 'Edytuj kraj', Component: CountriesAdd },
  { path: '/superadmin/menus', breadcrumb: 'Menu', Component: () => <></> },
];
