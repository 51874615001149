import React, { FunctionComponent, useEffect, useState } from 'react';
import { IGetRoles, IRoles } from 'utils/models';
import { getRolesState } from 'utils/states';
import WhiteCard from 'global/atoms/WhiteCard';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import RolesConnection from 'utils/connections/roles';
import Input from 'global/atoms/Input';
import List from 'global/table/List';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import arrow from 'assets/svg/arrow_down_small.svg';
import settings from 'assets/svg/settings.svg';
import { useHistory } from 'react-router-dom';
import styles from '../usersList/styles.module.scss';
import { useDispatch } from 'react-redux';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IRoleList {}

const RoleList: FunctionComponent<IRoleList> = () => {
  const [data, setData] = useState<IRoles | null>(null);
  const [filters, setFilters] = useState<IGetRoles>(getRolesState);
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [form, setForm] = useState<any>({});

  useEffect(() => {
    RolesConnection.getFilterForm().then((response) => {
      setForm(response.data);
    });
  }, []);

  useEffect(() => {
    setFilters((state) => ({
      ...state,
      requestPaginate: {
        page: 1,
        limit: state.requestPaginate.limit,
      },
    }));

    RolesConnection.getRoles(filters).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    RolesConnection.getRoles(filters).then((data) => {
      setLoading(false);
      setData(data.data);
    });
  }, [filters.requestPaginate.page, filters.requestOrder]);

  const handleToggleSort = (event: any) => {
      if (filters.requestOrder.field === event.currentTarget.dataset.field) {
        setFilters({
          ...filters,
          requestOrder: {
            field: filters.requestOrder.field,
            order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
          },
        });
      } else {
        setFilters({
          ...filters,
          requestOrder: {
            field: event.currentTarget.dataset.field,
            order: 'asc',
          },
        });
      }
    },
    handleToggleFilters = () => setToggleFilters((state: boolean) => !state),
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      RolesConnection.getRoles({
        ...filters,
        requestPaginate: { page: 1, limit: filters.requestPaginate.limit },
      }).then((data) => {
        setLoading(false);
        setToggleFilters(false);
        setData(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
        setFilters((state) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleClearFilters = () => setFilters(getRolesState),
    handleEdit = (event: any) => {
      history.push(`/admin/roles/${event.currentTarget.dataset.id}/edit`);
    },
    handleRowClick = (data: any) => {
      history.push(`/admin/roles/${data.roleId}/edit`);
    };

  if (loading) return <Spinner />;

  if (data === null) return null;

  return (
    <>
      <HeaderButtons>
        <ButtonAddNew form={form} />
      </HeaderButtons>
      <WhiteCard padding={false} mobileTransparent={true}>
        <List data={data.data} count={data.data.length} rowClick={handleRowClick}>
          <Column flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              <span
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={handleToggleSort}
                data-field={'name'}
              >
                Nazwa
                {filters.requestOrder.field === 'name' &&
                  (filters.requestOrder.order === 'asc' ? (
                    <img src={arrow} alt={'sort'} />
                  ) : (
                    <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
                  ))}
              </span>
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="roleName" />
          </Column>
          <Column verticalAlign={'middle'} resizable={true} width={150} align="right">
            <HeaderCell depth={0} width={0}>
              Grupa
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => {
                return (
                  <span
                    className={styles.status}
                    style={{ color: rowData.roleType.color, borderColor: rowData.roleType.color }}
                  >
                    {rowData.roleType.name}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} resizable={true} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <img
                  src={settings}
                  style={{ cursor: 'pointer' }}
                  alt={'Idź do'}
                  data-id={rowData.roleId}
                  onClick={handleEdit}
                />
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters}
      >
        <Input
          value={filters.name}
          placeholder={'Nazwa'}
          name={'name'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
      </Filters>
    </>
  );
};

export default RoleList;
