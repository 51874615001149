import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import UserConnection from 'utils/connections/user';
import Pagination from 'global/pagination/Pagination';
import { IGetUsers, IUsers } from 'utils/models';
import { getUsersState } from 'utils/states';
import Filters from 'global/filters/Filters';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import 'rsuite-table/dist/css/rsuite-table.css';
import List from 'global/table/List';
import styles from './styles.module.scss';
import arrow from 'assets/svg/arrow_down_small.svg';
import settings from 'assets/svg/settings.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import ButtonFilters from 'global/atoms/ButtonFilters';
import Spinner from 'global/atoms/Spinner/Spinner';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import {
  DeleteSelectedButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import { deepClone, handleToast } from '../../../../utils/helpers';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import { handleClearFilters, loadFilters } from '../../../../utils/filterHelper';
import FiltersStorage from '../../../../utils/FiltersStorage';

interface IUsersList {}

const UsersList: FunctionComponent<IUsersList> = () => {
  const [data, setData] = useState<IUsers | null>(null);
  const defaultFilters = deepClone(getUsersState);
  const [filters, setFilters] = useState<IGetUsers>(loadFilters(defaultFilters));
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [filtersForm, setFiltersForm] = useState<any>('loading');
  const ref = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);

  useEffect(() => {
    Promise.all([UserConnection.getFormFilters(), UserConnection.getUsers(filters)]).then((data) => {
      setFiltersForm(data[0].data);
      setData(data[1].data);
    });
  }, []);

  const reloadData = () => {
    UserConnection.getUsers(filters).then((data) => {
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  };

  useEffect(() => {
    if (ref.current) {
      reloadData();

      setFilters((state: IGetUsers) => ({
        ...state,
        requestPaginate: {
          page: 1,
          limit: state.requestPaginate.limit,
        },
      }));
    }
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    if (ref.current) {
      reloadData();
    }
  }, [filters.requestPaginate.page, filters.requestOrder]);

  const handleToggleSort = (event: any) => {
    if (filters.requestOrder.field === event.currentTarget.dataset.field) {
      setFilters({
        ...filters,
        requestOrder: {
          field: filters.requestOrder.field,
          order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
        },
      });
    } else {
      setFilters({
        ...filters,
        requestOrder: {
          field: event.currentTarget.dataset.field,
          order: 'asc',
        },
      });
    }
  };
  const handleToggleFilters = () => setToggleFilters((state: boolean) => !state),
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      const filtersStorage = new FiltersStorage();
      filtersStorage.save(filters);

      UserConnection.getUsers({
        ...filters,
        requestPaginate: { page: 1, limit: filters.requestPaginate.limit },
      }).then((data) => {
        setToggleFilters(false);
        setData(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
        setFilters((state: IGetUsers) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleEdit = (event: any) => {
      history.push(`/admin/users/${event.currentTarget.dataset.id}/edit`);
    };

  if (filtersForm === 'loading') return <Spinner />;
  if (data === null) return null;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(UserConnection.batchDelete(toDelete)).then(() => {
      setChosenToDelete([]);
      reloadData();
    });
  };

  return (
    <div ref={ref}>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
        <ButtonAddNew form={filtersForm} />
        <DeleteSelectedButton chosenToDelete={chosenToDelete} callback={handleDeleteSelected} form={filtersForm} />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List count={data.data.length} data={data.data}>
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox data={data.data} state={chosenToDelete} setState={setChosenToDelete} />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox state={chosenToDelete} setState={setChosenToDelete} selectedId={rowData.id} />
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} resizable={true} minWidth={200} align="left">
            <HeaderCell depth={0} width={0}>
              <span
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={handleToggleSort}
                data-field={'username'}
              >
                Użytkownik
                {filters.requestOrder.field === 'username' &&
                  (filters.requestOrder.order === 'asc' ? (
                    <img src={arrow} alt={'sort'} />
                  ) : (
                    <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
                  ))}
              </span>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ButtonListRedirect redirectUrl={`/admin/users/${rowData.id}/edit`}>
                  {rowData.lastName} {rowData.firstName} ({rowData.username})
                </ButtonListRedirect>
              )}
            </Cell>
          </Column>
          <Column flexGrow={2} verticalAlign={'middle'} minWidth={250} align="left">
            <HeaderCell depth={0} width={0}>
              Grupy
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => {
                return (
                  <>
                    {rowData.roles.map((item: any) => {
                      const classNames = item.default ? styles.roleDefault : styles.role;
                      return (
                        <span key={item.role.roleId} className={classNames}>
                          {item.role.roleName}
                        </span>
                      );
                    })}
                  </>
                );
              }}
            </Cell>
          </Column>{' '}
          <Column verticalAlign={'middle'} resizable={true} width={150} align="right">
            <HeaderCell depth={0} width={0}>
              Status
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => {
                return (
                  <span
                    className={styles.status}
                    style={{ color: rowData.userStatus.color, borderColor: rowData.userStatus.color }}
                  >
                    {rowData.userStatus.name}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <ActionsContainer>
                  <img
                    src={settings}
                    style={{ cursor: 'pointer' }}
                    alt={'Idź do'}
                    data-id={rowData.id}
                    onClick={handleEdit}
                  />
                  <DeleteSingleButton callback={handleDeleteSelected.bind(null, rowData.id)} form={filtersForm} />
                </ActionsContainer>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, reloadData)}
      >
        <Input
          value={filters.username}
          placeholder={'Użytkownik'}
          name={'username'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.email}
          placeholder={'Email'}
          name={'email'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Status'}
          name={'userStatusId'}
          value={filters.userStatusId}
          options={filtersForm.userStatuses.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Grupa'}
          name={'roleId'}
          value={filters.roleId}
          options={filtersForm.roles.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
      </Filters>
    </div>
  );
};

export default UsersList;
