import React, { FunctionComponent } from 'react';
import CircleMultipleColors from './CircleMultipleColors';

interface ITablePreviewBodyButtonProps {
  timeTablesDisplay: Array<any>;
  onClick?: any;
  onMouseEnter?: any;
  onMouseLeave?: any;
}

const ActivityInfoButton: FunctionComponent<ITablePreviewBodyButtonProps> = ({
  timeTablesDisplay,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const colors: string[] = [];
  timeTablesDisplay.map((item) => {
    item.activityColors.map((activityColorItem: any) => {
      if (!colors.includes(activityColorItem.color)) {
        colors.push(activityColorItem.color);
      }
    });
  });

  return (
    <>
      <div
        style={{
          cursor: onClick !== undefined ? 'pointer' : 'default',
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <CircleMultipleColors colors={colors} />
      </div>
    </>
  );
};

export default ActivityInfoButton;
