import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './atoms.module.scss';

interface IButtonListRedirectProps {
  redirectUrl: string;
}

const ButtonListRedirect: FunctionComponent<IButtonListRedirectProps> = ({ redirectUrl, children }) => {
  const history = useHistory();
  const handleRedirect = () => {
    history.push(redirectUrl);
  };
  return (
    <>
      <span className={styles.buttonListName} onClick={handleRedirect}>
        {children}
      </span>
    </>
  );
};

export default ButtonListRedirect;
