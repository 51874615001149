import React, { FunctionComponent, useEffect, useState } from 'react';
import { ICreateLocation } from 'utils/models';
import { createLocationState } from 'utils/states';
import { useHistory } from 'react-router-dom';
import { handleSetErrors, handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import LocationsConnection from 'utils/connections/locations';
import Form from 'global/form/Form';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Select from 'global/atoms/Select';
import ActionsContainer from 'global/atoms/ActionsContainer';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import { useDispatch } from 'react-redux';
import Spinner from 'global/atoms/Spinner/Spinner';
import AddressSelectSection from './AddressSelectSection';
import AddressesList from '../addressesList/AddressesList';

interface ILocationAdd {}

export interface ISelectAddressViewData {
  handleSetAddress: any;
  selectedAddressId: string;
  selectedAddressName: string;
}

const LocationAdd: FunctionComponent<ILocationAdd> = () => {
  const [data, setData] = useState<ICreateLocation>(createLocationState);
  const [errors, setErrors] = useState<any>({});
  const [form, setForm] = useState<any>('loading');
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectAddressViewData, setSelectAddressViewData] = useState<ISelectAddressViewData | null>(null);

  useEffect(() => {
    LocationsConnection.getFormCreateLocation()
      .then((data) => {
        setForm(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
      })
      .catch((err) => handleSetErrors(err, setErrors));
  }, []);

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    handleToast(LocationsConnection.createLocation(data), setErrors).then(() => history.push('/datasets/locations'));
  };

  if (form === 'loading') return <Spinner />;

  const handleCloseAddressSelect = () => {
    setSelectAddressViewData(null);
  };

  if (selectAddressViewData !== null) {
    return (
      <AddressesList
        handleSetAddress={selectAddressViewData.handleSetAddress}
        handleClose={handleCloseAddressSelect}
        selectedAddressId={selectAddressViewData.selectedAddressId}
        selectedAddressName={selectAddressViewData.selectedAddressName}
      />
    );
  }

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Opis'}
            name={'description'}
            value={data.description}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Sieć'}
            name={'networkId'}
            value={data.networkId}
            options={form.networks.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Select
            placeholder={'Typ lokalizacji'}
            name={'locationTypeId'}
            value={data.locationTypeId}
            options={form.locationTypes.options}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Kod'}
            name={'code'}
            value={data.code}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Email'}
            name={'email'}
            value={data.email}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Osoba do kontaktu'}
            name={'contactPerson'}
            value={data.contactPerson}
            state={data}
            setState={setData}
            errors={errors}
          />
          <AddressSelectSection
            errors={errors}
            setData={setData}
            data={data}
            setSelectAddressViewData={setSelectAddressViewData}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default LocationAdd;
