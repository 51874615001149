import React, { FunctionComponent, useEffect, useState } from 'react';
import Modal from '../../../../../global/atoms/Modal/Modal';
import SelectMulti from '../../../../../global/atoms/select/SelectMulti';
import VisitsConnection from '../../../../../utils/connections/visits';
import { useParams } from 'react-router-dom';
import {
  IGetProjectLocations,
  ILocation,
  ILocationPaginated,
  IVisitCreate,
  IVisitForm,
} from '../../../../../utils/models';
import { getProjectsLocationsState } from '../../../../../utils/states';
import { Cell, HeaderCell } from 'rsuite-table5';
import List5 from '../../../../../global/table/List5';
import ButtonOutlined from '../../../../../global/atoms/ButtonOutlined';
import ButtonSubmit from '../../../../../global/atoms/ButtonSubmit';
import { Column } from 'rsuite-table';
import { deepClone, handleToast } from '../../../../../utils/helpers';
import RadioButton from '../../../../../global/atoms/radio/RadioButton';
import Pagination from '../../../../../global/pagination/Pagination';
import styles from '../../../../../global/common.module.scss';
import { Col, Row } from 'react-grid-system';
import DayPickerInputCustom from '../../../../../global/atoms/DayPickerInputCustom';
import Input from '../../../../../global/atoms/Input';
import Select from '../../../../../global/atoms/Select';
import SpinnerSmall from '../../../../../global/atoms/Spinner/SpinnerSmall';
import SectionEmpty from '../../../../../global/atoms/section/SecionEmpty';
import WhiteCard from '../../../../../global/atoms/WhiteCard';

interface IVisitsModalAdd {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  visitAddedCB?: () => void;
}

const VisitsModalAdd: FunctionComponent<IVisitsModalAdd> = (props) => {
  interface IEntitySimplified {
    value: string;
    label: string;
  }

  const { id } = useParams<{ id: string }>();
  const todayStartDate = { startDate: parseInt((Date.now() / 1000).toFixed(0)) };
  const [tasksSelectState, setTasksSelectState] = useState<Array<IEntitySimplified>>([]);
  const [usersSelectState, setUsersSelectState] = useState<Array<IEntitySimplified>>([]);
  const [locationSelected, setLocationSelected] = useState<ILocation | null>(null);
  const [dateStart, setDateStart] = useState<{ startDate: number }>(todayStartDate);

  const [locationRequestData, setLocationRequestData] = useState<IGetProjectLocations>(getProjectsLocationsState);
  const [locations, setLocations] = useState<ILocationPaginated | null>(null);
  const [locationsLoading, setLocationsLoading] = useState<boolean>(true);
  const [visitFormState, setVisitFormState] = useState<IVisitForm | null>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errors, setErrors] = useState();
  const [formSubmitting, setFormSubmitting] = useState<boolean>(false);
  const [locationFilterVisibility, setLocationFilterVisibility] = useState<boolean>(false);

  const loadLocations = () => {
    setLocationsLoading(true);
    setLocations(null);
    VisitsConnection.getLocations(id, locationRequestData).then((res) => {
      setLocations(res.data);
      setLocationsLoading(false);
    });
  };

  useEffect(() => {
    // @ts-ignore
    if (locationSelected?.users.length > 0) {
      let locUsers = locationSelected?.users.map((u) => ({ value: u.id, label: u.name }));
      if (!locUsers) locUsers = deepClone(usersSelectState);
      setUsersSelectState(locUsers ?? []);
    }
  }, [locationSelected]);

  useEffect(() => {
    loadLocations();
  }, [locationRequestData]);

  useEffect(() => {
    if (!props.open) clearModalData();
    else {
      VisitsConnection.getForm(id).then((res) => {
        setVisitFormState(res.data);
      });
      loadLocations();
    }
  }, [props.open]);

  const createVisit = () => {
    setFormSubmitting(true);

    const data: IVisitCreate = {
      locationId: locationSelected?.id ?? '',
      startDate: dateStart.startDate,
      tasks: tasksSelectState?.map((v) => ({ id: v.value })) ?? [],
      users: usersSelectState?.map((v) => ({ id: v.value })) ?? [],
    };

    // handle toast
    handleToast(VisitsConnection.createVisit(id, data), setErrors)
      .then(() => {
        props.setOpen(false);
        if (props.visitAddedCB) props.visitAddedCB();
      })
      .catch(() => {
        setFormSubmitting(false);
      });
  };

  const clearModalData = () => {
    setTasksSelectState([]);
    setUsersSelectState([]);
    setLocationSelected(null);
    setDateStart(todayStartDate);
    setFormSubmitting(false);
    setLocationFilterVisibility(false);
  };

  const onRowClick = (rowData: ILocation) => {
    setLocationSelected(rowData);
  };

  const errorPrintMessage = (key: string) => {
    // @ts-ignore
    if (!errors || !errors[key]) return <></>;
    // @ts-ignore
    return <div className={styles.loadingSmallErr}>{errors[key]}</div>;
  };

  return (
    <>
      <Modal isOpen={props.open}>
        <h3>Dodaj wizytę poza planem</h3>
        <br />
        <div style={{ minWidth: '70vw' }}>
          <div style={{ zIndex: 2, position: 'relative' }}>
            <SelectMulti
              name={'tasksSelect'}
              value={tasksSelectState}
              placeholder={'Wybierz zadania'}
              options={visitFormState?.tasks.data.map((d) => ({ value: d.id, label: d.name })) ?? []}
              onChange={(data: Array<IEntitySimplified>) => {
                console.log('task on change', data);
                setTasksSelectState(data);
              }}
            />
            {errorPrintMessage('tasks')}
          </div>
          <div style={{ marginTop: '35px', marginBottom: '55px', position: 'relative', zIndex: 1 }}>
            <div
              style={{ display: locationFilterVisibility ? 'none' : 'block', marginBottom: '15px', textAlign: 'right' }}
            >
              <ButtonOutlined
                onClick={() => {
                  setLocationFilterVisibility(true);
                }}
              >
                Filtry
              </ButtonOutlined>
            </div>
            <SectionEmpty
              hidden={!locationFilterVisibility}
              style={{ borderBottomLeftRadius: '0', borderBottomRightRadius: '0', position: 'relative', zIndex: 2 }}
            >
              <Row style={{ margin: '0' }}>
                <Col xs={8}>
                  <Input
                    name={'name'}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={'Nazwa lokalizacji'}
                    value={locationRequestData.name}
                  />
                </Col>
                <Col xs={4}>
                  <Select
                    name={'networkId'}
                    placeholder={'Sieć'}
                    options={[{ name: 'Dowolna sieć', id: '' }].concat(visitFormState?.networks.data ?? [])}
                    value={locationRequestData.networkId}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                  />
                </Col>
              </Row>
              <br />
              <Row style={{ margin: '0' }}>
                <Col xs={4}>
                  <Input
                    name={'city'}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={'Miasto'}
                    value={locationRequestData.city}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    name={'street'}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={'Ulica'}
                    value={locationRequestData.street}
                  />
                </Col>
                <Col xs={4}>
                  <Input
                    name={'nip'}
                    state={locationRequestData}
                    setState={setLocationRequestData}
                    placeholder={'NIP'}
                    value={locationRequestData.nip}
                  />
                </Col>
              </Row>
            </SectionEmpty>
            {locationsLoading ? (
              <SpinnerSmall />
            ) : (
              <div style={{ position: 'relative', zIndex: 0 }}>
                <WhiteCard mobileTransparent={true} padding={false}>
                  <List5 rowClick={onRowClick} data={locations?.data} count={locations?.count ?? 0}>
                    <Column width={50}>
                      <HeaderCell>&nbsp;</HeaderCell>
                      <Cell>
                        {(rowData: ILocation) => <RadioButton checked={rowData.id === locationSelected?.id} />}
                      </Cell>
                    </Column>

                    <Column flexGrow={1}>
                      <HeaderCell depth={0}>{'Nazwa'}</HeaderCell>
                      <Cell dataKey={'name'} />
                    </Column>

                    <Column width={150}>
                      <HeaderCell>{'Sieć'}</HeaderCell>
                      <Cell>{(rowData: ILocation) => rowData.network.name}</Cell>
                    </Column>

                    <Column width={150}>
                      <HeaderCell>{'Miasto'}</HeaderCell>
                      <Cell>{(rowData: ILocation) => rowData.address.city.name}</Cell>
                    </Column>

                    <Column width={200}>
                      <HeaderCell>{'Adres'}</HeaderCell>
                      <Cell>
                        {(rowData: ILocation) => (
                          <>
                            <div>
                              Ul.&nbsp;
                              {rowData.address.street.name}&nbsp;
                              {rowData.address.houseNumber}
                              {rowData.address.apartmentNumber ? ` / ${rowData.address.apartmentNumber}` : ''}
                            </div>
                            <div>
                              {rowData.address.postCode.name}&nbsp;
                              {rowData.address.city.name}
                            </div>
                          </>
                        )}
                      </Cell>
                    </Column>

                    <Column width={200} align={'right'}>
                      <HeaderCell>{'Raportujący'}</HeaderCell>
                      <Cell style={{ lineHeight: '1em', height: '56px !important' }}>
                        {(rowData: ILocation) => {
                          return rowData.users.map((u) => <div key={u.id}>{u.name}</div>);
                        }}
                      </Cell>
                    </Column>
                  </List5>
                </WhiteCard>

                <Pagination
                  style={{ marginTop: '10px' }}
                  count={locations?.count ?? 0}
                  allowLimitChange={false}
                  page={locationRequestData.requestPaginate.page.toString()}
                  limit={locationRequestData.requestPaginate.limit.toString()}
                  setState={setLocationRequestData}
                />
              </div>
            )}
          </div>

          <Row>
            <Col xs={9}>
              <SelectMulti
                name={'usersSelect'}
                value={usersSelectState}
                disabled={!locationSelected}
                placeholder={'Wybierz raportujących'}
                options={visitFormState?.users.data.map((d) => ({ value: d.id, label: d.name })) ?? []}
                onChange={(data: IEntitySimplified[]) => {
                  setUsersSelectState(data);
                }}
              />
              {errorPrintMessage('users')}
            </Col>
            <Col xs={3}>
              <DayPickerInputCustom
                name={'startDate'}
                value={dateStart.startDate}
                placeholder={'Data rozpoczęcia'}
                setState={setDateStart}
                state={dateStart}
                disabled={!locationSelected}
              />
            </Col>
          </Row>
          <br />
          <div style={{ textAlign: 'right' }}>
            <ButtonOutlined onClick={() => props.setOpen(false)}>Anuluj</ButtonOutlined>&nbsp;
            <ButtonSubmit disabled={!locationSelected || formSubmitting} onClick={createVisit} name={'Dodaj'} />
          </div>
          {locationSelected ? (
            <></>
          ) : (
            <div className={styles.loadingSmall} style={{ textAlign: 'right' }}>
              <br />
              *Przypisz lokalizacje - kliknij na wybrany wiersz w tabeli powyżej.
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default VisitsModalAdd;
