import WhiteCard from '../../../../global/atoms/WhiteCard';
import Form from '../../../../global/form/Form';
import Input from '../../../../global/atoms/Input';
import Textarea from '../../../../global/atoms/Textarea';
import Select from '../../../../global/atoms/Select';
import Section from '../../../../global/atoms/section/Section';
import UserMultiSelect from '../../../../global/entityMultiSelect/UserMultiSelect';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { INotification, IProject, IRole } from '../../../../utils/models';
import ButtonOutlined from '../../../../global/atoms/ButtonOutlined';
import RolesConnection from '../../../../utils/connections/roles';
import { getProjectsState, getRolesState, notificationState } from '../../../../utils/states';
import ProjectsConnection from '../../../../utils/connections/projects';
import DayPickerInputCustom from '../../../../global/atoms/DayPickerInputCustom';
import FileUpload from '../../../../global/atoms/FileUpload';
import atomStyles from '../../../../global/atoms/atoms.module.scss';
import commonStyles from '../../../../global/common.module.scss';
import {deepClone, getOrganizationId} from '../../../../utils/helpers';
import IconButton from "../../../../global/atoms/IconButton";
import NotificationsConnection from "../../../../utils/connections/notifications";
import download from "downloadjs";

interface INotificationForm {
  handleSubmit: (event: any, assignedUsers: Array<string>) => void;
  form: any;
  setForm: React.Dispatch<any>;
  errors: any;

  assignedUserIds?: Array<string>;
  notification?: INotification;
  readOnly?: boolean;
}

interface INotificationFile {
  filePath: string;
}

const SUBMIT_SEND = 'sendSubmit';
const SUBMIT_PREPARE = 'prepareSubmit';

const NotificationForm: FunctionComponent<INotificationForm> = (props) => {

  const [roles, setRoles] = useState<IRole[]>([]);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [assignedUserIds, setAssignedUserIds] = useState<Array<string>>(props.assignedUserIds ?? []);
  const [notification, setNotification] = useState<INotification>(notificationState);
  const saveNotifyFile = `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/notifications/files`;

  if (notification.scheduleDate === '') {
    notification.scheduleDate = new Date().toDateString();
  }

  useEffect(() => {
    const rolesState = getRolesState;
    rolesState.requestPaginate.limit = 999; // todo: resource
    RolesConnection.getRoles(rolesState).then((res) => {
      setRoles(res.data.data);
    });

    const projectState = getProjectsState;
    projectState.requestPaginate.limit = 999;
    ProjectsConnection.getProjects(projectState).then((res) => {
      setProjects(res.data.data);
    });

    if (props.notification !== undefined) {
      setNotification(props.notification);
    }

    if (notification.scheduleDate === '') {
      setNotification({
        ...notification,
        scheduleDate: new Date().toISOString().split('T')[0],
      });
    }
  }, []);


  const fileUploadCallback = (filePath: string, type: 'attachment' | 'iconPath') => {
    setNotification({ ...notification, [type]: filePath});
  }

  const [fileUploadErrors, setFileUploadErrors] = useState();
  const fileUploadHandleError = (err: any) => {
    setFileUploadErrors(err);
  }

  const getFileName = (keyType: 'attachment'|'iconPath'): string => {
    let fileName = '';
    try {
      if(notification[keyType]) {
        const tmp = notification[keyType].split('/');
        fileName = tmp[tmp.length-1];
      }
    } catch(e) {
      fileName = notification[keyType];
    }
    return fileName;
  }

  const downloadFile = (keyType: 'attachment'|'iconPath') => {
    NotificationsConnection.getFileBlob(notification.id, keyType).then(result => {
      download(result.data, getFileName(keyType));
    });
  }


  const getFileContainer = (type: 'attachment' | 'icon'): ReactElement => {

    let fileTypeData: {key:'attachment'|'iconPath', url: string, label: string};

    switch (type) {
      case 'attachment':
        fileTypeData = {
          key: 'attachment',
          url: saveNotifyFile + '/' + notification.id + '/attachment',
          label: 'Załącznik'
        }
        break;
      case 'icon':
        fileTypeData = {
          key: 'iconPath',
          label: 'Ikona',
          url: saveNotifyFile + '/' + notification.id + '/icon'
        }
        break;
    }


    return (
      <>
        {props.readOnly !== true &&
          <FileUpload
              label={fileTypeData.label}
              saveUrl={saveNotifyFile}
              savedFileCallback={(res: INotificationFile) => { fileUploadCallback(res.filePath, fileTypeData.key) }}
              handleError={fileUploadHandleError}
              maxFileSizeMB={1}
          />
        }

        {
          // @ts-ignore
          notification[fileTypeData.key] &&
          <div>
              <small>
                {fileTypeData.label}:&nbsp;
                {notification.id
                  // @ts-ignore
                  ? <a href="#" onClick={downloadFile.bind(null, fileTypeData.key)}>{getFileName(fileTypeData.key)}</a>
                  : <span className={commonStyles.smallMuted}>{getFileName(fileTypeData.key)}</span>
                }

                {!props.readOnly &&
                  <IconButton style={{position: 'relative', top: '8px'}}
                              inline={true} size={25}
                              icon={'close'} onClick={() => {
                    setNotification({...notification, [fileTypeData.key]: ''})
                  }}/>
                }
              </small>
          </div>
        }
        {fileUploadErrors ? <div className={commonStyles.loadingSmallErr}>{fileUploadErrors}</div> : <></>}
      </>
    );
  };

  return (
    <>
      <WhiteCard>
        <Form
          handleSubmit={(event) => {
            event.preventDefault();

            const tmp = deepClone(notification);
            if (event.nativeEvent.submitter.name === SUBMIT_SEND) {
              tmp.status = 'WAITING';
            } else if (event.nativeEvent.submitter.name === SUBMIT_PREPARE) {
              tmp.status = 'PREPARE';
            }

            props.handleSubmit(tmp, assignedUserIds);
          }}
        >
          <Input
            placeholder={'Temat'}
            name={'subject'}
            value={notification.subject}
            state={notification}
            setState={setNotification}
            errors={props.errors}
            disabled={props.readOnly ?? false}
          />
          <Textarea
            placeholder={'Wiadomość'}
            name={'body'}
            value={notification.body}
            state={notification}
            setState={setNotification}
            errors={props.errors}
            disabled={props.readOnly ?? false}
          />
          <Select
            placeholder={'Kanał docelowy'}
            name={'sendChannel'}
            options={props.form.sendChannels.options}
            value={notification.sendChannel}
            errors={props.errors}
            onChange={(object: any) => {
              setNotification({
                ...notification,
                sendChannel: object.value,
                iconPath: '',
                attachment: ''
              })
            }}
            disabled={props.readOnly ?? false}
          />
          <DayPickerInputCustom
            name={'scheduleDateTimestamp'}
            placeholder={'Data wysłania'}
            value={notification.scheduleDateTimestamp}
            state={notification}
            setState={setNotification}
            disabled={props.readOnly ?? false}
          />

          <div>
            {notification.sendChannel === 'MAIL' && getFileContainer('attachment')}
            {notification.sendChannel === 'PUSH' && getFileContainer('icon')}
          </div>
          <br />

          <Section title={'Odbiorcy powiadomienia'}>
            <UserMultiSelect
              roles={roles}
              projects={projects}
              assignedUserIds={assignedUserIds}
              setAssignedUserIds={setAssignedUserIds}
              disabled={props.readOnly ?? false}
            />
            {props.errors && props.errors.users
              ? <div className={commonStyles.loadingSmallErr}>{props.errors.users}</div>
              : <></>
            }
          </Section>

          <ActionsContainer>
            {props.readOnly !== true ? (
              <>
                <ButtonSubmit attrName={'sendSubmit'} form={props.form} name={'Zapisz i wyślij'} />

                <ButtonSubmit
                  attrName={'prepareSubmit'}
                  className={atomStyles.buttonOutlined}
                  form={props.form}
                  name={'Zapisz wersje roboczą'}
                />
              </>
            ) : (
              <ButtonOutlined onClick={() => history.back()}>{'Wróć'}</ButtonOutlined>
            )}
          </ActionsContainer>

        </Form>
      </WhiteCard>
    </>
  );
};

export default NotificationForm;
