import React, { FunctionComponent } from 'react';
import CSS from 'csstype';
import styles from './atoms.module.scss';

interface ISelectPagination {
  style?: CSS.Properties;
  placeholder: string;
  name: string;
  onChange: (event: any) => void;
  value: string;
  options: Array<{
    id: string;
    name: string;
  }>;
}

const SelectPagination: FunctionComponent<ISelectPagination> = ({ style, placeholder, value, onChange, options }) => {
  return (
    <div className={styles.selectPaginationContainer} style={style}>
      <label className={styles.selectPaginationLabel} htmlFor={name}>
        {placeholder}:
      </label>
      <select onChange={onChange} value={value} className={styles.selectPagination} name={name}>
        <option hidden={true} />
        {options.map((item) => {
          return (
            <option key={item.id} value={item.id}>
              {item.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SelectPagination;
