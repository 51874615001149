import React, { FunctionComponent } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { parseTimeForBackend } from '../../utils/helpers';
import styles from './atoms.module.scss';
import Helmet from 'react-helmet';
import { format } from 'date-fns';
import Input from './Input';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from './DayPickerCustom';

interface IDayPickerInputCustomProps {
  name?: any; // state key item name
  state?: any;
  setState?:  React.Dispatch<React.SetStateAction<any>>;
  value: any;
  placeholder: string;
  disabled?: boolean;
  width?: string;
  valueType?: 'timestamp'|'yyyy-mn-dd'
  onChange?: (date: Date) => void
}

const DayPickerInputCustom: FunctionComponent<IDayPickerInputCustomProps> = ({
  name,
  state,
  setState,
  value,
  placeholder,
  disabled = false,
  width = '100%',
  valueType = 'timestamp',
  onChange
}) => {

  if(onChange === undefined && (!state || !setState || !name)) {
    throw 'Provide onChange callback or state, setState, name!';
  }

  const handleChange = (day: Date) => {
    try {
      day.setHours(0, 0, 0, 0);
      // @ts-ignore
      setState({ ...state, [name]: parseTimeForBackend(day.getTime()) });
    } catch (e) {
      console.log('DayPickerInputCustom - invalid date');
    }
  };

  let valueSet;
  if (value === 0 || value === '' || value === null) {
    valueSet = '';
  } else {

    if(valueType === 'timestamp') {
      const dateValue = new Date();
      dateValue.setTime(value * 1000);
      valueSet = dateValue;
    }

    if(valueType === 'yyyy-mn-dd') {
      valueSet = new Date(value);
    }

  }

  const getDateInCustomFormat = (date: Date) => {
    return format(date, 'yyyy-LL-dd');
  };

  const customStyles: any = {
    width: width,
  };

  const placeholderInput = 'yyyy-mm-dd';
  if (disabled) {
    let value = '';
    if (valueSet instanceof Date) {
      value = getDateInCustomFormat(valueSet);
    }
    return (
      <Input
        name={'disabled'}
        value={value}
        type={'text'}
        disabled={true}
        placeholder={placeholder}
        style={{ width: width }}
      />
    );
  }

  // DayPickerInput-OverlayWrapper
  // DayPickerInput-Overlay
  return (
    <>
      <Helmet>
        <style>
          {`
              .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                background-color: #ffaf38 !important;
              }
              .DayPickerInput > input {
                height: 40px;
                width: 100%;
                border: 1px solid rgba(93, 92, 92, 0.16);
                border-radius: 8px;
                color: #131315;
                font-family: Poppins, sans-serif;
                font-size: 1rem;
                letter-spacing: 0.4px;
                padding: 0 16px;
                background-color: #ffffff;
              }
          `}
        </style>
      </Helmet>

      <div className={`${styles.dayPickerInputWrapper}`}>
        <label className={styles.dayPickerInputLabel}>{placeholder}</label>
        <DayPickerInput
          placeholder={placeholderInput}
          style={customStyles}
          value={valueSet}
          onDayChange={onChange ?? handleChange}
          formatDate={getDateInCustomFormat}
          dayPickerProps={{
            weekdaysShort: WEEKDAYS_SHORT,
            weekdaysLong: WEEKDAYS_LONG,
            months: MONTHS,
          }}
        />
      </div>
    </>
  );
};

export default DayPickerInputCustom;
