import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { IFormMaker, IRole, IUserSessionReadModel } from './models';
import Input from '../global/atoms/Input';
import styles from '../views/admin/components/usersAdd/styles.module.scss';
import Button from '../global/atoms/Button';
import ButtonOutlined from '../global/atoms/ButtonOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import Textarea from '../global/atoms/Textarea';
import RadioButtons from '../global/atoms/RadioButtons';
import Checkbox from '../global/atoms/Checkbox';
import SelectComponent from '../global/atoms/Select';
import { toast } from 'react-hot-toast';
import Select from '../global/atoms/Select';
import close from '../assets/svg/close-accent.svg';
import SelectMulti from '../global/atoms/select/SelectMulti';
import {
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  TIME_QUESTION,
} from '../views/projects/components/visits/visitsEdit/VisitsEdit';
import DayPickerInputCustom from '../global/atoms/DayPickerInputCustom';
import SelectTime from '../global/atoms/select/SelectTime';
import store from '../redux/store';
import { SET_APP } from '../redux/actions';
import VisitShowFullImage from '../views/projects/components/visits/visitsEdit/VisitShowFullImage';

export const handleErrors = (err: any, customAction: (err: any) => any = () => null) => {
  const errorStatus = err.response ? err.response.status : null;
  if (errorStatus === 403) {
    store.dispatch({ type: SET_APP, payload: { logged: false } });
  } else if (errorStatus === 401) {
    toast.error('Brak uprawnień');
  }

  customAction(err);
  throw err;
};

export const handleSetErrors = (err: any, setErrors: any) => {
  handleErrors(err, () => {
    if (setErrors !== undefined) {
      setErrors(err.response.data.errors);
    }
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const handleToast = (
  promise: any,
  setErrors?: Dispatch<SetStateAction<any>>,
  errorMessage?: string,
  successMessage?: string,
): Promise<any> => {
  return toast
    .promise(promise, {
      loading: 'Loading',
      success: successMessage === undefined ? 'Success' : successMessage,
      error: errorMessage === undefined ? 'Error' : errorMessage,
    })
    .catch((err) => {
      handleSetErrors(err, setErrors);
    });
};

export const handleToastErrorCallback = (promise: Promise<any>, callback: (errors?: []) => void): Promise<any> => {
  return toast
    .promise(promise, {
      loading: 'Loading',
      success: 'Success',
      error: 'Error',
    })
    .catch((err) => {
      handleErrors(err, () => {
        callback(err.response.data.errors);
      });
    });
};

export const getAuthHeader = (): { Authorization: string } => {
  const user = localStorage.getItem('user');
  if (user !== null) {
    return { Authorization: 'Bearer ' + JSON.parse(user).token };
  } else {
    return { Authorization: '' };
  }
};

export const clickOutsideMenu = (ref: React.RefObject<any>, setState: (state: boolean) => void): void => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && event.target.id !== 'openProfileButton') {
        setState(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};

export const parseDateForBackend = (date: string) => {
  return parseInt(Date.parse(date).toString().slice(0, -3));
};

export const parseTimeForBackend = (time: number): number => {
  const date = new Date();
  date.setTime(time);
  date.setMilliseconds(0);
  return date.getTime() / 1000;
};

export const parseDateForFrontend = (timestamp: number) => {
  const date = new Date(timestamp * 1000);

  let buildDate = date.getFullYear() + '-';
  if (date.getMonth() < 9) {
    buildDate += '0';
  }
  buildDate += `${date.getMonth() + 1}-`;
  if (date.getDate() < 10) {
    buildDate += '0';
  }
  buildDate += `${date.getDate()}`;

  return buildDate;
};

export const getRoles = (): Array<{ id: string; name: string }> => {
  const user = localStorage.getItem('user');
  if (user !== null) {
    return JSON.parse(user).user.roles;
  } else {
    return [];
  }
};

export const getOrganizationId = (): string => {
  const user = localStorage.getItem('user');
  if (user !== null) {
    return JSON.parse(user).organizationId;
  } else {
    return '';
  }
};

export const getUserUsername = (): string => {
  const user = localStorage.getItem('user');
  if (user !== null) {
    return JSON.parse(user).user.username;
  } else {
    return '';
  }
};

export const getUserId = (): string => {
  const user = localStorage.getItem('user');
  if (user !== null) {
    return JSON.parse(user).user.id;
  } else {
    return '';
  }
};

export const getUserFromSession = (): null | IUserSessionReadModel => {
  const userString = localStorage.getItem('user');
  let userObject: any = null;
  if (userString !== null) {
    userObject = JSON.parse(userString);
  }

  return userObject;
};

export const formRadioMapper = (item: IFormMaker, state: any, setState: any): any => {
  const handleChangeRadio = (event: any) => {
    setState({ ...state, [event.currentTarget.dataset.name]: parseInt(event.currentTarget.dataset.id) });
  };

  switch (item.tagName) {
    case 'radio-buttons':
      return (
        <>
          <RadioButtons
            name={item.attr.name}
            value={state[item.attr.name]}
            options={item.attr.options}
            onChange={handleChangeRadio}
          />
        </>
      );
  }
};

export const formMapper = (item: IFormMaker, state: any, setState: any, errors?: any, setView?: any): any => {
  const handleChangeInput = (event: any) => {
      setState((state: any) => ({ ...state, [event.target.name]: event.target.value }));
    },
    handleChangeTextarea = (event: any) => {
      setState((state: any) => ({ ...state, [event.target.name]: event.target.value }));
    },
    handleChangeSelect = (object: { value: string; label: string }, name: string) => {
      setState((state: any) => ({ ...state, [name]: object.value }));
    },
    handleToggleRole = (event: any) => {
      const mapped = state.roles.map((item: { roleId: string; default: boolean }) => ({
        roleId: item.roleId,
        default: item.default,
      }));
      //TODO: udoskonalić, jeśli usunie się rolę która ma default, to niech default przjedzie na jakąś inną
      const mapped2 = state.roles.map((item: { roleId: string; default: boolean }) => item.roleId);
      if (mapped2.includes(event.currentTarget.dataset.id)) {
        const filtered = mapped.filter(
          (item: { roleId: string; default: boolean }) => item.roleId !== event.currentTarget.dataset.id,
        );
        setState({ ...state, roles: filtered.map((item: any) => ({ roleId: item.roleId, default: item.default })) });
      } else if (state.roles.length) {
        setState({ ...state, roles: [...state.roles, { roleId: event.currentTarget.dataset.id, default: false }] });
      } else {
        setState({ ...state, roles: [...state.roles, { roleId: event.currentTarget.dataset.id, default: true }] });
      }
    },
    handleSetView = (view: number) => {
      setView(view);
    },
    handleToggleRoleRadio = (event: any) => {
      setState({
        ...state,
        roles: state.roles.map((item: any) => {
          if (item.roleId === event.currentTarget.dataset.id) {
            return { roleId: item.roleId, default: true };
          } else {
            return { roleId: item.roleId, default: false };
          }
        }),
      });
    },
    handleToggleRoleAll = () => {
      if (state.roles.length) {
        setState({ ...state, roles: [] });
      } else {
        setState({
          ...state,
          roles: item.attr.options.map((item: IRole, idx: number) => {
            if (idx) {
              return { roleId: item.roleId, default: false };
            } else {
              return { roleId: item.roleId, default: true };
            }
          }),
        });
      }
    };

  switch (item.tagName) {
    case 'select':
      return (
        <>
          <SelectComponent
            placeholder={item.attr.placeholder}
            name={item.attr.name}
            onChange={handleChangeSelect}
            value={state[item.attr.name]}
            options={item.attr.options.map((item: { id: string; name: string }) => ({
              value: item.id,
              label: item.name,
            }))}
            style={{ marginTop: '24px' }}
            disabled={item.attr.disabled}
          />
          {errors && <span className={styles.errors}>{errors[item.attr.name]}</span>}
        </>
      );
    case 'input':
      return (
        <>
          <Input
            name={item.attr.name}
            value={state[item.attr.name]}
            type={item.attr.type}
            placeholder={item.attr.placeholder}
            onChange={handleChangeInput}
            style={{ marginTop: '24px' }}
          />
          {errors && <span className={styles.errors}>{errors[item.attr.name]}</span>}
        </>
      );
    case 'textarea':
      return (
        <>
          <Textarea
            name={item.attr.name}
            value={state[item.attr.name]}
            placeholder={item.attr.placeholder}
            onChange={handleChangeTextarea}
            style={{ marginTop: '24px' }}
          />
          {errors && <span className={styles.errors}>{errors[item.attr.name]}</span>}
        </>
      );
    case 'custom-roles':
      return (
        <section style={{ marginTop: '12px', marginBottom: '-12px' }}>
          <div className={styles.helpersWrapperColumns}>
            <div className={styles.helpersWrapperCheckbox}>
              <Checkbox isChecked={state.roles.length} isMixed={true} onClick={handleToggleRoleAll} />
              {item.attr.columns.roleName}
            </div>
            <span className={styles.helpersColumnDefault}>{item.attr.columns.default}</span>
          </div>
          {item.attr.options.map((item: IRole) => (
            <div className={styles.helpersWrapperOptions} key={item.roleId}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Checkbox
                  id={item.roleId}
                  isMixed={false}
                  isChecked={state.roles.map((item: any) => item.roleId).includes(item.roleId)}
                  onClick={handleToggleRole}
                />
                {item.roleName}
              </div>
              {state.roles.map((stateItem: any) => {
                if (stateItem.roleId === item.roleId) {
                  return (
                    <span
                      onClick={handleToggleRoleRadio}
                      data-id={item.roleId}
                      data-name={name}
                      className={stateItem.default ? styles.radioButtonActive : styles.radioButton}
                    />
                  );
                }
              })}
            </div>
          ))}
          {errors && <span className={styles.errors}>{errors[item.attr.name]}</span>}
        </section>
      );
    case 'custom-add-address':
      return (
        <>
          <Input
            value={state.addressId.name}
            placeholder={item.attr.placeholder}
            type={item.attr.text}
            name={item.attr.name}
            style={{ marginTop: '24px' }}
            disabled={true}
          />
          <Button
            type={'button'}
            onClick={() => handleSetView(item.attr.view)}
            style={{ width: 'auto', marginTop: '12px' }}
          >
            Przypisz adres
          </Button>
          {errors && <span className={styles.errors}>{errors.addressId}</span>}
        </>
      );
  }
};

export const formActionsMapper = (item: IFormMaker, handleClear?: () => void) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  switch (item.tagName) {
    case 'button-submit':
      return <Button style={{ marginTop: '16px', width: 'auto' }}>{item.attr.name}</Button>;
    case 'button-delete':
      return <Button style={{ marginTop: '16px', width: 'auto' }}>{item.attr.name}</Button>;
    case 'button-clear':
      return (
        <ButtonOutlined style={{ marginTop: '16px', width: 'auto' }} type={'button'} onClick={handleClear}>
          {item.attr.name}
        </ButtonOutlined>
      );
    case 'button-back':
      return (
        <ButtonOutlined style={{ marginTop: '16px', width: 'auto' }} type={'button'} onClick={handleGoBack}>
          {item.attr.name}
        </ButtonOutlined>
      );
  }
};

export const defaultStateMapper = (fields: Array<IFormMaker>) => {
  const temp: any = {};
  // eslint-disable-next-line prefer-const
  for (let field of fields) {
    if (field.tagName === 'custom-roles') {
      temp[field.attr.name] = field.attr.value.map((item: any) => ({ roleId: item.roleId, default: item.default }));
    } else if (field.tagName === 'input' && field.attr.type === 'date') {
      temp[field.attr.name] = !field.attr.value ? '' : parseDateForFrontend(field.attr.value);
    } else if (field.tagName === 'input' && field.attr.type === 'number') {
      temp[field.attr.name] = field.attr.value.toString();
    } else if (field.tagName === 'custom-add-address') {
      temp.addressId = {
        id: field.attr.value,
        name: field.attr.address.city
          ? `${field.attr.address.city}, ${field.attr.address.street} ${field.attr.address.houseNumber}${
              field.attr.address.apartmentNumber ? '/' + field.attr.address.apartmentNumber : ''
            }`
          : '',
      };
    } else {
      temp[field.attr.name] = field.attr.value === null ? '' : field.attr.value;
    }
  }
  return temp;
};

export const useQuery = () => {
  const location = useLocation();

  return new URLSearchParams(location.search);
};

export const deepClone = (object: any) => {
  return JSON.parse(JSON.stringify(object));
};

export const cutTextTripleDot = (text: string, maxLength: number) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};

export const questionMapper = (state: any, setState: any, item: any, isDisabled = false) => {
  const handleChange = (event: any) => {
    const file = event.currentTarget.files;
    for (let i = 0; i < file.length; i++) {
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        if (target) {
          setState((state: any) => ({
            ...state,
            [event.target.name]: {
              file: file,
              url:
                state[event.target.name] && state[event.target.name].url
                  ? [...state[event.target.name].url, { refQuestionAnswerId: '', value: target.result }]
                  : [{ refQuestionAnswerId: '', value: target.result }],
            },
          }));
        }
      };
      reader.readAsDataURL(file[i]);
    }
  };

  const handleRemoveImage = (event: any) => {
    setState((state: any) => ({
      ...state,
      [event.target.dataset.id]: {
        ...state[event.target.dataset.id],
        url: state[event.target.dataset.id].url.filter((item: any, idx: number) => idx != event.target.dataset.idx),
      },
    }));
  };

  const handleChangeDictionaryMultiple = (data: any) => {
    setState({ ...state, [item.refQuestionId]: data });
  };

  let debugData: any = {
    refQuestionId: item.refQuestionId,
    questionId: item.id,
    values: item.values,
  };
  debugData = JSON.stringify(debugData);
  switch (item.questionType) {
    case 'INTEGER_QUESTION':
      return (
        <Input
          style={{ width: '200px' }}
          type={'number'}
          placeholder={'Wpisz ilość'}
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          state={state}
          setState={setState}
          disabled={isDisabled}
          debugData={debugData}
          allowNegativeNumbers={false}
        />
      );
    case 'DECIMAL_QUESTION':
      return (
        <Input
          style={{ width: '200px' }}
          type={'decimal'}
          placeholder={'Wpisz ilość'}
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          state={state}
          setState={setState}
          disabled={isDisabled}
          debugData={debugData}
        />
      );
    case 'TEXT_QUESTION':
      return (
        <Input
          style={{ width: '100%' }}
          type={'text'}
          placeholder={'Wpisz odpowiedź'}
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          state={state}
          max={item.maxValue ? item.maxValue : 10000}
          setState={setState}
          disabled={isDisabled}
          debugData={debugData}
        />
      );
    case 'PHOTO_QUESTION':
      return (
        <div
          style={{
            width: '100%',
            display: 'grid',
            flexWrap: 'wrap',
            gridTemplateColumns: 'repeat(auto-fill, 120px)',
            gridColumnGap: '12px',
            gridRowGap: '12px',
            position: 'relative',
            marginBottom: '-20px',
          }}
          data-debug={debugData}
        >
          {state[item.refQuestionId] &&
            state[item.refQuestionId].url &&
            state[item.refQuestionId].url.map((url: any, idx: number) => {
              return (
                <div
                  key={idx}
                  style={{
                    flexGrow: 1,
                    width: '100%',
                    height: '120px',
                    backgroundImage: `url(${url.value})`,
                    borderRadius: '8px',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    position: 'relative',
                  }}
                >
                  {url.refQuestionAnswerId !== '' && <VisitShowFullImage questionAnswerId={url.refQuestionAnswerId} />}
                  <div className={styles.helpersWrapperCloseIcon}>
                    <img
                      width={18}
                      height={18}
                      src={close}
                      alt="close"
                      data-id={item.refQuestionId}
                      data-idx={idx}
                      onClick={handleRemoveImage}
                    />
                  </div>
                </div>
              );
            })}
          <>
            <input
              type={'file'}
              width={120}
              multiple={true}
              accept={'image/x-png,image/jpeg,extFile==jpg,extFile==jpeg,extFile==png'}
              name={item.refQuestionId}
              onChange={handleChange}
              className={isDisabled ? styles.fileInputDisabled : styles.fileInput}
              disabled={isDisabled}
              style={{
                position: 'relative',
              }}
            />
          </>
        </div>
      );
    case 'BOOLEAN_QUESTION':
      return (
        <Select
          style={{ width: '200px' }}
          options={[
            { id: '1', name: 'Tak' },
            { id: '0', name: 'Nie' },
          ]}
          placeholder={'Wybierz'}
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          state={state}
          setState={setState}
          disabled={isDisabled}
          debugData={debugData}
        />
      );
    case 'TEXTAREA_QUESTION':
      return (
        <Textarea
          style={{ width: '100%', height: '60px' }}
          placeholder={'Wpisz odpowiedź'}
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          state={state}
          setState={setState}
          max={item.maxValue ? item.maxValue : 10000}
          disabled={isDisabled}
          debugData={debugData}
        />
      );
    case 'DICTIONARY_QUESTION':
      return (
        <Select
          style={{ width: '100%' }}
          options={item.options}
          placeholder={'Wybierz odpowiedź'}
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          state={state}
          setState={setState}
          disabled={isDisabled}
          debugData={debugData}
        />
      );
    case 'DATE_QUESTION':
      return (
        <DayPickerInputCustom
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          placeholder={'Data rozpoczęcia'}
          setState={setState}
          state={state}
          disabled={isDisabled}
          width={'200px'}
        />
      );
    case DICTIONARY_MULTIPLE_ANSWERS_QUESTION:
      return (
        <SelectMulti
          options={item.options}
          onChange={handleChangeDictionaryMultiple}
          value={state[item.refQuestionId]}
          placeholder={'Wybierz odpowiedzi'}
          name={item.refQuestionId}
          disabled={isDisabled}
          debugData={debugData}
        />
      );

    case TIME_QUESTION:
      return (
        <SelectTime
          name={item.refQuestionId}
          value={state[item.refQuestionId]}
          state={state}
          setState={setState}
          disabled={isDisabled}
          style={{ width: '200px' }}
        />
      );
  }
};

export const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export const getAddressFullName = (addressReadModel: any): string => {
  if (addressReadModel === null || addressReadModel === undefined) {
    return '';
  }

  let address = '';
  if (addressReadModel.street !== null) {
    address += `${addressReadModel.street.name} ${addressReadModel.houseNumber}`;
  }

  if (addressReadModel.apartmentNumber) {
    address += `/${addressReadModel.apartmentNumber}`;
  }

  if (addressReadModel.postCode !== null) {
    address += `, ${addressReadModel.postCode.name}`;
    if (addressReadModel.city !== null) {
      address += ` ${addressReadModel.city.name}`;
    }
  }

  return address;
};

export const getProjectIdFromUrl = () => {
  const path = window.location.pathname;
  const pathParts = path.split('/');
  if (pathParts[2] === undefined) {
    return null;
  }
  if (pathParts[1] === 'projects') {
    return pathParts[2];
  }

  return null;
};
