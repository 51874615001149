import React, { FunctionComponent } from 'react';
import styles from './styles.module.scss';
import goto from '../../../../assets/svg/arrow_right_big.svg';
import settings from '../../../../assets/svg/settings.svg';
import checklist from '../../../../assets/svg/checklist.svg';

interface IActions {
  id?: string;
  goToEdit?: (event: any) => void | null;
  goToList?: (event: any) => void | null;
  goToItem?: (event: any) => void | null;
}

const Actions: FunctionComponent<IActions> = ({ id, goToList = null, goToEdit = null, goToItem = null }) => {
  return (
    <div className={styles.containerActions}>
      {goToItem && (
        <button className={styles.button} onClick={goToItem} data-id={id}>
          <img className={styles.actionIcon} src={goto} alt={'Idź do'} />
        </button>
      )}
      {goToEdit && (
        <button className={styles.button} onClick={goToEdit} data-id={id}>
          <img className={styles.actionIcon} src={settings} alt={'Idź do'} />
        </button>
      )}
      {goToList && (
        <button className={styles.button} onClick={goToList} data-id={id}>
          <img className={styles.actionIcon} src={checklist} alt={'Idź do'} />
        </button>
      )}
    </div>
  );
};

export default Actions;
