import React, { FunctionComponent, useRef, useState } from 'react';
import styles from './user.module.scss';
import { useDispatch } from 'react-redux';
import { SET_APP } from '../../redux/actions';
import { clickOutsideMenu, getUserFromSession } from '../../utils/helpers';
import UserConnection from '../../utils/connections/user';
import Button from 'global/atoms/Button';
import { Col, Row } from 'react-grid-system';
import SeparatorLine from '../atoms/separators/SeparatorLine';
import SwitchButtonHorizontalGroup from '../atoms/switchButton/SwitchButtonHorizontalGroup';
import SwitchButton from '../atoms/switchButton/SwitchButton';
import { IUserRole } from '../../utils/models';

interface IUser {}

const User: FunctionComponent<IUser> = () => {
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  const dispatch = useDispatch();
  const ref = useRef(null);

  const handleToggleMenu = () => {
    setToggleMenu((state) => !state);
  };

  const handleLogOut = () => {
    UserConnection.logout().then(() => {
      localStorage.removeItem('user');
      dispatch({ type: SET_APP, payload: { logged: false } });
    });
  };

  clickOutsideMenu(ref, setToggleMenu);

  const userData = getUserFromSession();
  if (userData === null) return <></>;

  const handleChangeRole = (newRoleId: string) => {
    if (newRoleId === userData.currentRoleId) return false;

    UserConnection.switchRole({ roleId: newRoleId }).then((response) => {
      localStorage.setItem('user', JSON.stringify(response.data));
      window.location.href = '/';
    });
  };

  const getUserInitials = () => {
    return userData.user.firstName.charAt(0).toUpperCase() + userData.user.lastName.charAt(0).toUpperCase();
  };

  return (
    <>
      <span id={'openProfileButton'} className={styles.user} onClick={handleToggleMenu}>
        {getUserInitials()}
      </span>
      <div ref={ref} className={toggleMenu ? styles.toggleMenuShowed : styles.toggleMenuHidden}>
        <Row>
          <Col xs={12}>
            <SwitchButtonHorizontalGroup>
              {userData.user.roles.map((item: IUserRole, index: any) => {
                return (
                  <SwitchButton
                    key={index}
                    isChecked={item.role.roleId === userData.currentRoleId}
                    onClick={handleChangeRole.bind(null, item.role.roleId)}
                  >
                    {item.role.roleName}
                  </SwitchButton>
                );
              })}
            </SwitchButtonHorizontalGroup>
          </Col>
        </Row>
        <SeparatorLine size={2} />
        <Row>
          <Col xs={8} className={styles.toggleMenuLeftCenter}>
            {userData.user.firstName} {userData.user.lastName}, {userData.user.username}
          </Col>
          <Col xs={4} className={styles.toggleMenuRightCenter}>
            <Button onClick={handleLogOut}>Wyloguj</Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default User;
