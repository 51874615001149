import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { handleToast } from '../../../../utils/helpers';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import CountriesConnection from '../../../../utils/connections/countries';
import Input from '../../../../global/atoms/Input';
import Form from '../../../../global/form/Form';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';

interface ICountriesAdd {}

const CountriesAdd: FunctionComponent<ICountriesAdd> = () => {
  const [data, setData] = useState<any>({ name: '', code: '' });
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(CountriesConnection.createCountry(data), setErrors).then(() => history.push('/superadmin/countries'));
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Nazwa'}
            name={'name'}
            value={data.name}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Kod kraju'}
            name={'code'}
            value={data.code}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default CountriesAdd;
