import React, { FunctionComponent, useState } from 'react';
import styles from './styles.module.scss';
import settings from '../../../../../assets/svg/settings.svg';
import { useHistory } from 'react-router-dom';
import Input from '../../../../../global/atoms/Input';

interface IUserList {
  data: any;
  checked?: Array<any>;
  editView: boolean;
  handleToggleCheckbox?: (event: any) => void;
  handleToggleMainCheckbox?: () => void;
  column: string;
  counter: string | number;
}

const UserList: FunctionComponent<IUserList> = ({
  data,
  editView,
  handleToggleCheckbox,
  handleToggleMainCheckbox,
  checked,
  column,
  counter,
}) => {
  const [search, setSearch] = useState<string>('');
  const history = useHistory();

  const handleChangeSearch = (event: any) => {
    setSearch(event.currentTarget.value);
  };

  const classForCheckedCheckbox = () => {
    let cn = styles.checkbox;
    if (checked?.length) {
      cn += ' ' + styles.checked;
    }
    return cn;
  };

  const classForCheckedCheckbox2 = ({ id }: any) => {
    let cn = styles.checkbox2;
    if (checked?.includes(id)) {
      cn += ' ' + styles.checked;
    }
    return cn;
  };

  const getFilteredData = () => {
    if (search === '') {
      return data;
    }

    return data.filter((item: any) => {
      const nameLowercase = item.name.toLowerCase();
      const searchLowercase = search.toLowerCase();
      return nameLowercase.indexOf(searchLowercase) >= 0;
    });
  };

  return (
    <div style={{ marginBottom: '25px' }}>
      <div className={styles.usersListWrapper}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {editView && <span className={classForCheckedCheckbox()} onClick={handleToggleMainCheckbox} />}
          <span className={styles.columnAndCounter}>
            {column} ({counter})
          </span>
        </div>
        <Input
          style={{
            maxWidth: '200px',
          }}
          value={search}
          placeholder={'Szukaj'}
          onChange={handleChangeSearch}
          name={'search'}
        />
      </div>
      <div className={styles.wrapperUserDataList}>
        {getFilteredData().map((item: any) => {
          return (
            <div className={styles.user} key={item.id}>
              <div className={styles.wrapper}>
                {editView && (
                  <span data-id={item.id} onClick={handleToggleCheckbox} className={classForCheckedCheckbox2(item)} />
                )}
                {item.name}
              </div>

              <button className={styles.button} onClick={() => history.push(`/admin/users/${item.id}/edit`)}>
                <img className={styles.actionIcon} src={settings} alt={'Idź do'} />
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserList;
