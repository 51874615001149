import ProjectsList from 'views/projects/components/projectsList/ProjectsList';
import AddProject from 'views/projects/components/addProject/AddProject';
import TasksList from 'views/projects/components/tasksList/TasksList';
import AddTask from 'views/projects/components/addTask/AddTask';
import EditTask from 'views/projects/components/editTask/EditTask';
import Task from 'views/projects/components/task/Task';
import VisitsList from 'views/projects/components/visits/visitsList/VisitsList';
import VisitsEdit from 'views/projects/components/visits/visitsEdit/VisitsEdit';
import TaskActivitiesRefactored from 'views/projects/components/taskActivitiesEdit/TaskActivitiesRefactored';
import TimeTableList from 'views/projects/components/timeTable/list/TimeTableList';
import TimeTableDetails from 'views/projects/components/timeTable/details/TimeTableDetails';
import Basic from 'views/projects/components/editProject/Basic';
import Access from 'views/projects/components/editProject/Access';
import Locations from 'views/projects/components/editProject/Locations';
import EditLocation from 'views/projects/components/editProject/locations/EditLocation';
import Products from 'views/projects/components/editProject/Products';
import WarehouseAdd from '../../views/projects/components/warehouse/WarehouseAdd';
import VisitsReportTemplateEdit from '../../views/projects/components/visitsReport/VisitsReportTemplateEdit';
import VisitsReport from '../../views/projects/components/visitsReport/VisitsReport';
import OrdersList from '../../views/projects/components/orders/OrdersList';
import DocumentsList from '../../views/projects/components/documents/DocumentsList';
import OrderDetails from '../../views/projects/components/orders/OrderDetails';
import DocumentDetails from '../../views/projects/components/documents/DocumentDetails';
import FilesList from '../../views/projects/components/files/FilesList';
import WarehouseListHeader from '../../views/projects/components/warehouse/WarehouseListHeader';
import FileEdit from '../../views/projects/components/files/FileEdit';
import StoreDocumentsList from '../../views/projects/components/storeDocuments/StoreDocumentsList';
import StoreDocumentDetails from '../../views/projects/components/storeDocuments/StoreDocumentDetails';
import OrdersTemplate from '../../views/projects/components/reports/templates/OrdersTemplate';
import Reports from '../../views/projects/components/reports/Reports';
import SalesDocumentsTemplate from '../../views/projects/components/reports/templates/SaleDocumentsTemplate';
import LocationsTemplate from '../../views/projects/components/reports/templates/LocationsTemplate';
import StorageTemplate from '../../views/projects/components/reports/templates/StorageTemplate';
import JpkTemplate from '../../views/projects/components/reports/templates/JpkTemplate';

export default [
  { path: '/', breadcrumb: null },
  { path: '/projects', breadcrumb: 'Projekty', Component: ProjectsList },
  { path: '/projects/add', breadcrumb: 'Dodaj projekt', Component: AddProject },
  { path: '/projects/:id', breadcrumb: null },
  { path: '/projects/:id/tasks', breadcrumb: 'Zadania', Component: TasksList },
  { path: '/projects/:id/tasks/add', breadcrumb: 'Dodaj zadanie', Component: AddTask },
  { path: '/projects/:id/tasks/:taskId/edit', breadcrumb: 'Edytuj zadanie', Component: EditTask },
  { path: '/projects/:id/tasks/:taskId', breadcrumb: 'Aktywności', Component: Task },
  {
    path: '/projects/:id/tasks/:taskId/activities-edit',
    breadcrumb: 'Edycja aktywności',
    Component: TaskActivitiesRefactored,
  },
  { path: '/projects/:id/visits', breadcrumb: 'Realizowane wizyty', Component: VisitsList },
  { path: '/projects/:id/visits/:visitId', breadcrumb: null },
  { path: '/projects/:id/visits/:visitId/edit', breadcrumb: 'Edycja wizyty', Component: VisitsEdit },

  { path: '/projects/:id/timetable', breadcrumb: 'Lista harmonogramów', Component: TimeTableList },
  { path: '/projects/:id/timetable/:timeTableId', breadcrumb: 'Edycja harmonogramu', Component: TimeTableDetails },
  { path: '/projects/:id/edit', breadcrumb: null },
  { path: '/projects/:id/edit/1', breadcrumb: 'Dane ogólne', Component: Basic },
  { path: '/projects/:id/edit/2', breadcrumb: 'Prawa dostępu', Component: Access },
  { path: '/projects/:id/edit/3', breadcrumb: 'Lokalizacje', Component: Locations },
  { path: '/projects/:id/edit/3/:locationId', breadcrumb: 'Edytuj lokalizację', Component: EditLocation },
  { path: '/projects/:id/edit/4', breadcrumb: 'Produkty', Component: Products },
  { path: '/projects/:id/warehouse', breadcrumb: 'Magazyn', Component: WarehouseListHeader },
  { path: '/projects/:id/warehouse/add', breadcrumb: 'Przesunięcia', Component: WarehouseAdd },
  { path: '/projects/:id/orders', breadcrumb: 'Zamówienia', Component: OrdersList },
  { path: '/projects/:id/orders/:orderId/details', breadcrumb: 'Szczegóły', Component: OrderDetails },
  { path: '/projects/:id/orders/:orderId', breadcrumb: null },
  { path: '/projects/:id/documents', breadcrumb: 'Dokumenty', Component: DocumentsList },
  { path: '/projects/:id/store-documents', breadcrumb: 'Dokumenty magazynowe', Component: StoreDocumentsList },
  {
    path: '/projects/:id/store-documents/:documentId/details',
    breadcrumb: 'Dokumenty magazynowe',
    Component: StoreDocumentDetails,
  },
  { path: '/projects/:id/store-documents/:documentId', breadcrumb: null },
  { path: '/projects/:id/documents/:documentId/details', breadcrumb: 'Szczegóły', Component: DocumentDetails },
  { path: '/projects/:id/documents/:documentId', breadcrumb: null },
  {
    path: '/projects/:id/reports-visits/template/add',
    breadcrumb: 'Dodaj szablon raportu',
    Component: VisitsReportTemplateEdit,
  },
  {
    path: '/projects/:id/reports-visits/template/edit/:templateId',
    breadcrumb: 'Edytuj szablon raportu',
    Component: VisitsReportTemplateEdit,
  },
  { path: '/projects/:id/reports-visits/template/edit', breadcrumb: null },
  { path: '/projects/:id/reports-visits/template', breadcrumb: null },
  { path: '/projects/:id/reports', breadcrumb: 'Raport z wizyt', Component: VisitsReport },
  { path: '/projects/:id/edit/5', breadcrumb: 'Pliki', Component: FilesList },
  { path: '/projects/:id/files/:fileId/edit', breadcrumb: 'Edycja pliku', Component: FileEdit },
  {
    path: '/projects/:id/report-template/1/add',
    breadcrumb: 'Dodaj szablon raportu zamówień',
    Component: OrdersTemplate,
  },
  {
    path: '/projects/:id/report-template/1/edit/:templateId',
    breadcrumb: 'Edytuj szablon raportu zamówień',
    Component: OrdersTemplate,
  },
  {
    path: '/projects/:id/report-template/2/add',
    breadcrumb: 'Dodaj szablon dokumentów sprzedażnowych',
    Component: SalesDocumentsTemplate,
  },
  {
    path: '/projects/:id/report-template/2/edit/:templateId',
    breadcrumb: 'Edytuj szablon dokumentów sprzedażnowych',
    Component: SalesDocumentsTemplate,
  },
  {
    path: '/projects/:id/report-template/3/add',
    breadcrumb: 'Dodaj szablon lokalizacji',
    Component: LocationsTemplate,
  },
  {
    path: '/projects/:id/report-template/3/edit/:templateId',
    breadcrumb: 'Edytuj szablon lokalizacji',
    Component: LocationsTemplate,
  },
  {
    path: '/projects/:id/report-template/4/add',
    breadcrumb: 'Dodaj szablon raportu stanów magazynowych',
    Component: StorageTemplate,
  },
  {
    path: '/projects/:id/report-template/4/edit/:templateId',
    breadcrumb: 'Edytuj szablon raportu stanów magazynowych',
    Component: StorageTemplate,
  },
  {
    path: '/projects/:id/report-template/5/add',
    breadcrumb: 'Dodaj szablon raportu jpk',
    Component: JpkTemplate,
  },
  {
    path: '/projects/:id/report-template/5/edit/:templateId',
    breadcrumb: 'Edytuj szablon raportu jpk',
    Component: JpkTemplate,
  },
  { path: '/projects/:id/report-template/:type', breadcrumb: null },
  { path: '/projects/:id/report-template', breadcrumb: null },

  { path: '/projects/:id/data-exports', breadcrumb: 'Raporty', Component: Reports },
];
