import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { confirmModal } from '../../../../redux/store';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';
import { IAddTask, IAddTimeTable } from '../../../../utils/models';
import TimeTableConnection from '../../../../utils/connections/timetable';
import { handleSetErrors, handleToast } from '../../../../utils/helpers';
import { useParams } from 'react-router-dom';

interface ISaveTimeTable {
  form: any;
  data: IAddTask;
}

interface IGenerateResult {
  timeTableGenerateResultId: string;
  percentReady: number;
  isGenerating: boolean;
}

const SaveTimeTable: FunctionComponent<ISaveTimeTable> = ({ data, form }) => {
  const [, setErrors] = useState<any>(null);
  const { id, taskId } = useParams<{ id: string; taskId: string }>();
  const [generateResult, setGenerateResult] = useState<IGenerateResult>({
    isGenerating: false,
    percentReady: 0,
    timeTableGenerateResultId: '',
  });
  const interval = useRef<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const loadGenerateResult = (toast = false) => {
    let promise = TimeTableConnection.getTaskTimeTableGenerateResult(taskId);
    if (toast) {
      promise = handleToast(TimeTableConnection.getTaskTimeTableGenerateResult(taskId));
    }
    promise.then((response) => {
      setGenerateResult(response.data);
      if (loading) {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadGenerateResult();

    interval.current = setInterval(() => {
      loadGenerateResult();
    }, 15000);

    return () => {
      clearInterval(interval.current);
    };
  }, []);

  const handleSaveTimeTable = (taskId: string) => {
    const addTimeTableData: IAddTimeTable = {
      default: true,
      taskId: taskId,
      projectId: id,
      type: data.timeTable.type,
      userId: null,
      locationId: null,
      manualSettings: data.timeTable.manualSettings,
      recurringSettings: data.timeTable.recurringSettings,
      singleSettings: data.timeTable.singleSettings,
      isApproved: data.timeTable.isApproved,
    };

    if (form.timeTableModel === null) {
      TimeTableConnection.createTimeTable(addTimeTableData)
        .then(() => loadGenerateResult(true))
        .catch((err) => handleSetErrors(err, setErrors));
    } else {
      TimeTableConnection.updateTimeTable(form.timeTableModel.id, addTimeTableData)
        .then(() => loadGenerateResult(true))
        .catch((err) => handleSetErrors(err, setErrors));
    }
  };

  const handleResetTimeTable = () => {
    handleSaveTimeTable(taskId);
  };

  const getGenerateInfo = (): string => {
    if (!generateResult.isGenerating) {
      return 'Zapisz harmonogram';
    }

    return `Generowanie harmonogramu ${generateResult.percentReady}%`;
  };

  if (loading) return null;

  return (
    <>
      <ButtonSubmit
        disabled={generateResult.isGenerating}
        onClick={confirmModal('Czy jesteś pewien?', handleResetTimeTable)}
        name={getGenerateInfo()}
      />
    </>
  );
};

export default SaveTimeTable;
