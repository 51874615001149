import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { IActivityAdd, IGetActivities } from '../models';

const ActivitiesConnection = {
  getActivities: (data: IGetActivities) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/activities/list`, data, {
      headers: getAuthHeader(),
    });
  },
  getActivitiesForTask: (projectId: string, taskId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/list`,
      { headers: getAuthHeader() },
    );
  },
  updateActivityOrder: (
    projectId: string,
    taskId: string,
    data: Array<{ activityId: string; locked: boolean; active: boolean }>,
  ) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/order`,
      { activityIds: data },
      { headers: getAuthHeader() },
    );
  },
  createActivity: (data: IActivityAdd) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/activities`, data, {
      headers: getAuthHeader(),
    });
  },
  deleteActivity: (projectId: string, taskId: string, activityId: string) => {
    return axios.delete(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  createActivityForTask: (projectId: string, taskId: string, data: IActivityAdd) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  updateActivity: (projectId: string, taskId: string, activityId: string, data: any) => {
    return axios.put(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}`,
      data,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getFormAddActivity: (projectId: string, taskId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/form`,
      {
        headers: getAuthHeader(),
      },
    );
  },
  getFormEditActivity: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/activities/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormFiltersActivities: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/activities/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormActivitiesEdit: (projectId: string, taskId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/list/form`,
      { headers: getAuthHeader() },
    );
  },
  getFormTaskActivityEdit: (projectId: string, taskId: string, activityId: string) => {
    return axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/form`,
      { headers: getAuthHeader() },
    );
  },
  getActivityAssignedLocations: (projectId: string, taskId: string, activityId: string) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/locations/assigned`,
      [],
    );
  },
  getActivityUnassignedLocations: (projectId: string, taskId: string, activityId: string) => {
    return axios.post(
      `${
        process.env.REACT_APP_API_URL
      }/organizations/${getOrganizationId()}/projects/${projectId}/tasks/${taskId}/activities/${activityId}/locations/unassigned`,
      [],
    );
  },
};

export default ActivitiesConnection;
