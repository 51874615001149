import { IAddTimeTableRecurringSettings } from '../../../../../utils/models';

export default class {
  recurringSetting: IAddTimeTableRecurringSettings;
  lastVisibleMonth: Date;

  constructor(recurringSetting: IAddTimeTableRecurringSettings, lastVisibleMonth: Date) {
    this.recurringSetting = recurringSetting;
    this.lastVisibleMonth = lastVisibleMonth;
  }

  getDates(): Array<number> {
    const matchingDates: Array<number> = [];
    const daysIncrease = 7 * this.recurringSetting.type;
    for (
      let dayLoop = new Date(this.getDateFromClosestMonday());
      dayLoop <= this.getEndDate();
      dayLoop.setDate(dayLoop.getDate() + daysIncrease)
    ) {
      const dateLoopClone = new Date(dayLoop);
      const dateWeekEnd = new Date(dayLoop);
      dateWeekEnd.setDate(dateWeekEnd.getDate() + 6);
      dateLoopClone.setHours(0, 0, 0, 0);
      for (; dateLoopClone <= dateWeekEnd; dateLoopClone.setDate(dateLoopClone.getDate() + 1)) {
        if (
          dateLoopClone.getTime() < this.getDateFrom().getTime() ||
          dateLoopClone.getTime() > this.getEndDate().getTime()
        ) {
          continue;
        }

        if (this.recurringSetting.days.includes(dateLoopClone.getDay())) {
          matchingDates.push(dateLoopClone.getTime());
        }
      }
    }

    return matchingDates;
  }

  getEndDate(): Date {
    let endDate = this.lastVisibleMonth;
    if (
      this.recurringSetting.endDate !== null &&
      this.recurringSetting.endDate !== 0 &&
      this.recurringSetting.endDate * 1000 < this.lastVisibleMonth.getTime()
    ) {
      endDate = new Date(this.recurringSetting.endDate * 1000);
    }

    endDate.setHours(0, 0, 0, 0);

    return endDate;
  }

  getDateFrom(): Date {
    let dateFrom: Date;
    if (this.recurringSetting.startDate === 0 || this.recurringSetting.startDate === null) {
      dateFrom = new Date();
    } else {
      dateFrom = new Date(this.recurringSetting.startDate * 1000);
    }

    dateFrom.setHours(0, 0, 0, 0);

    return dateFrom;
  }

  getDateFromClosestMonday(): Date {
    const dateFrom = new Date(this.getDateFrom());

    if (dateFrom.getDay() !== 1) {
      if (dateFrom.getDay() === 0) {
        dateFrom.setDate(dateFrom.getDate() - 6);
      } else {
        dateFrom.setDate(dateFrom.getDate() - (dateFrom.getDay() - 1));
      }
    }

    dateFrom.setHours(0, 0, 0, 0);

    return dateFrom;
  }
}
