import React, { FunctionComponent } from 'react';
import styles from '../atoms.module.scss';

interface ISelectedFilterProps {
  name?: any;
  value?: any;
  onRemoveClick?: () => any;
}

const SelectedFilter: FunctionComponent<ISelectedFilterProps> = ({ name, value, onRemoveClick }) => {
  const removeButtonVisible = typeof onRemoveClick === 'function';
  const containerStyle: any = {};
  if (removeButtonVisible) {
    containerStyle.paddingRight = '30px';
  }
  return (
    <div className={styles.selectedFilter} style={containerStyle}>
      {name && <span className={styles.selectedFilterName}>{name}</span>}
      {value && <span className={styles.selectedFilterValue}>{value}</span>}
      {removeButtonVisible && <div className={styles.selectedFilterRemoveButton} onClick={onRemoveClick} />}
    </div>
  );
};

export default SelectedFilter;
