import arrow from '../assets/svg/arrow_down_small.svg';
import React, { FunctionComponent } from 'react';

export const handleToggleSort = (filters: any, setFilters: any, event: any) => {
  if (filters.requestOrder.field === event.currentTarget.dataset.field) {
    setFilters({
      ...filters,
      requestOrder: {
        field: filters.requestOrder.field,
        order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
      },
    });
  } else {
    setFilters({
      ...filters,
      requestOrder: {
        field: event.currentTarget.dataset.field,
        order: 'asc',
      },
    });
  }
};

interface IButtonSortProps {
  dataName: string;
  filters: any;
  setFilters: any;
}

export const ButtonSort: FunctionComponent<IButtonSortProps> = ({ children, filters, setFilters, dataName }) => {
  return (
    <span
      style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
      onClick={handleToggleSort.bind(null, filters, setFilters)}
      data-field={dataName}
    >
      {children}
      {filters.requestOrder.field === dataName &&
        (filters.requestOrder.order === 'asc' ? (
          <img src={arrow} alt={'sort'} />
        ) : (
          <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
        ))}
    </span>
  );
};
