import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { ICreateQuestion, IGetQuestions } from '../models';

const QuestionsConnection = {
  getQuestions: (data: IGetQuestions) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createQuestion: (data: ICreateQuestion) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions`, data, {
      headers: getAuthHeader(),
    });
  },
  updateQuestion: (id: string, data: ICreateQuestion) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions/${id}`, data, {
      headers: getAuthHeader(),
    });
  },

  getQuestionsFiltersForm: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getQuestionsFormAdd: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions/form`, {
      headers: getAuthHeader(),
    });
  },
  getQuestionsFormEdit: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  removeQuestions: (data: { questions: Array<{ id: string }> }) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions`, {
      data,
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/questions`, {
      headers: { ...getAuthHeader() },
      data: { questions: data },
    });
  },
};

export default QuestionsConnection;
