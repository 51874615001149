import {
  IActivityAdd,
  IAddTaskState,
  IAddTimeTableRecurringSettings,
  IChangePass,
  ICreateAddress,
  ICreateBrand,
  ICreateCategory,
  ICreateChain,
  ICreateCustomer,
  ICreateLocation,
  ICreateNotification,
  ICreateProduct,
  ICreateProject,
  ICreateQuestion,
  ICreateRole,
  ICreateUser,
  IEditTimeTableState,
  IEditUser,
  IFilterOrder,
  IFilterPaginate,
  IFiltersAddresses,
  IFiltersLocations,
  IFiltersPhotoReview,
  IFiltersProjects,
  IFiltersUsers,
  IFiltersVisitsList,
  IFilterTimeTable,
  IGetActivities,
  IGetAddresses,
  IGetBrands,
  IGetCategories,
  IGetChains,
  IGetCustomers,
  IGetLocations,
  IGetNotifications,
  IGetProducts,
  IGetProjectLocations,
  IGetProjectProducts,
  IGetProjects,
  IGetProjectTasks,
  IGetQuestions,
  IGetRoles,
  IGetUsers,
  IHeaderViewResponse,
  INotification,
  IPhotoReviewErrors,
  IPhotoReviewModal,
  IPhotoReviewRequestData,
  ISelectViewResponse,
} from './models';
import { IFilters } from '../views/projects/components/visitsReport/SelectVisitsList';

const _paginate30 = {
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 30,
  },
};

const filtersState: IFilters = {
  startDate: 0,
  endDate: 0,
  locationName: '',
  nip: '',
  street: '',
  city: '',
  community: '',
  district: '',
  voivodeshipId: '',
  networkId: '',
  groupId: '',
  userRoleId: '',
  projectId: '',
  statusId: '',
  locationId: '',
};

export const createProjectState: ICreateProject = {
  name: '',
  customerId: '',
  projectStatusId: '',
  endDate: '',
  startDate: '',
  projectSettings: {
    id: '',
    isEnableBill: false,
    isEnableDocumentDump: false,
    isEnableInvoice: false,
    isEnableOrder: false,
    isEnableTimer: false,
    isEnableOneTimeVisits: false,
    isEnableSynchroGps: false,
    isEnableTaskGrouping: true,
    isEnableDownloadPhotoFromDisk: true,
    isEnableOnlyLocationFromActivity: false,
    isEnableSaveDocumentWithoutPrint: false,
    isEnableItemNetworkListing: false,
    isEnableQuestionNetworkListing: false,
    salePrefix: '',
    numerationType: '',
    numberDocumentPattern: '',
    showDocumentCount: 0,
    numberOrderPattern: '',
    showOrderCount: 0,
    photoQuality: 0,
    photoResolution: 0,
    photoMaxSize: 0,
    calcDocumentType: '',
    prepareDocumentCount: 0,
  },
};

export const createAddressState: ICreateAddress = {
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  postcode: '',
  city: '',
  country: '',
  voivodeship: '',
  district: '',
  community: '',
  verified: false,
};

export const createUserDetails = {
  idCard: '',
  pesel: '',
  email: '',
  phone: '',
};

const createEditUserStateCommonData = {
  firstName: '',
  lastName: '',
  username: '',
  userStatusId: '',
  roles: [],
  userDetails: createUserDetails,
  customerId: '',
};

export const createUserState: ICreateUser = {
  password: '',
  repeatPassword: '',
  ...createEditUserStateCommonData,
};

export const editUserState: IEditUser = {
  ...createEditUserStateCommonData,
  salePrefix: '',
};

export const passChangeState: IChangePass = {
  oldPassword: '',
  newPassword: '',
  repeatNewPassword: '',
};

export const getUsersState: IGetUsers = {
  username: '',
  email: '',
  userStatusId: '',
  roleId: '',
  requestPaginate: {
    page: 1,
    limit: 10,
  },
  requestOrder: {
    field: '',
    order: '',
  },
};

export const filtersUsersState: IFiltersUsers = {
  username: '',
  userStatusId: '',
  roleId: '',
};

export const getLocationsState: IGetLocations = {
  name: '',
  description: '',
  addressId: '',
  street: '',
  networkId: '',
  voivodeshipId: '',
  city: '',
  community: '',
  district: '',
  nip: '',
  requestPaginate: {
    page: 1,
    limit: 10,
  },
  requestOrder: {
    field: '',
    order: '',
  },
};

export const filtersLocationsState: IFiltersLocations = {
  name: '',
  description: '',
  networkId: '',
  street: '',
};

export const filtersAddressesState: IFiltersAddresses = {
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  postcode: '',
  city: '',
  country: '',
  voivodeshipId: '',
  district: '',
  community: '',
};

export const getAddressesState: IGetAddresses = {
  id: '',
  street: '',
  houseNumber: '',
  apartmentNumber: '',
  postcode: '',
  city: '',
  country: '',
  voivodeshipId: '',
  district: '',
  community: '',
  requestPaginate: {
    page: 1,
    limit: 10,
  },
  requestOrder: {
    field: '',
    order: '',
  },
};

export const getProjectsState: IGetProjects = {
  name: '',
  customerId: '',
  projectStatusId: '',
  startDate: '',
  endDate: '',
  requestPaginate: {
    page: 1,
    limit: 10,
  },
  requestOrder: {
    field: '',
    order: '',
  },
};

export const filtersProjectsState: IFiltersProjects = {
  name: '',
  customerId: '',
  projectStatusId: '',
  startDate: '',
  endDate: '',
};

export const createLocationState: ICreateLocation = {
  name: '',
  description: '',
  networkId: '',
  code: '',
  contactPerson: '',
  email: '',
  invoiceName: '',
  locationTypeId: '',
  addressId: {
    id: '',
    name: '',
  },
  nip: '',
  payerAddressId: {
    id: '',
    name: '',
  },
  payerName: '',
  recipientAddressId: {
    id: '',
    name: '',
  },
  recipientName: '',
};

export const getProjectsLocationsState: IGetProjectLocations = {
  networkId: '',
  reportingUserId: '',
  street: '',
  name: '',
  isActive: true,
  active: true,
  city: '',
  district: '',
  voivodeshipId: '',
  community: '',
  nip: '',
  roleId: '',
  requestPaginate: {
    page: 1,
    limit: 10,
  },
  requestOrder: {
    field: '',
    order: '',
  },
};

export const getActivitesState: IGetActivities = {
  name: '',
  description: '',
  requestPaginate: {
    page: 1,
    limit: 10,
  },
  requestOrder: {
    field: '',
    order: '',
  },
};

export const addActivityState: IActivityAdd = {
  name: '',
  description: '',
  activityTypeId: '',
  activityGroupId: '',
};

export const getProjectTasksState: IGetProjectTasks = {
  name: '',
  description: '',
  taskStatusId: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const recurringSettingsState: IAddTimeTableRecurringSettings = {
  type: 1,
  startDate: 0,
  endDate: 0,
  days: [],
};

export const singleSettingsState = { startDate: 0 };
export const manualSettingsState = { days: [] };

export const createTimeTableState: IEditTimeTableState = {
  type: 0,
  isApproved: true,
  singleSettings: singleSettingsState,
  manualSettings: manualSettingsState,
  recurringSettings: [recurringSettingsState],
};

export const createTaskState: IAddTaskState = {
  name: '',
  description: '',
  taskStatusId: '',
  expirationDays: 0,
  timeTable: createTimeTableState,
};

export const getBrandsState: IGetBrands = {
  name: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const createBrandState: ICreateBrand = {
  name: '',
};

export const getProductsState: IGetProducts = {
  customerId: '',
  name: '',
  gtin: '',
  brandId: '',
  itemCategoryId: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const getCategoriesState: IGetCategories = {
  customerId: '',
  name: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const createProductState: ICreateProduct = {
  amount: '',
  brandId: '',
  currency: '',
  gtin: '',
  height: '',
  length: '',
  width: '',
  name: '',
  itemUnitId: '',
  itemGroupId: '',
  itemVatRateId: '',
  customerId: '',
  itemCategoryId: '',
};

export const createCategoryState: ICreateCategory = {
  name: '',
  customerId: '',
};

export const createChainState: ICreateChain = {
  name: '',
};

export const getChainsState: IGetChains = {
  name: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const getProjectProductsState: IGetProjectProducts = {
  customerId: '',
  name: '',
  gtin: '',
  brandId: '',
  itemGroupId: '',
  itemUnitId: '',
  itemVatRateId: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const getRolesState: IGetRoles = {
  name: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const getNotificationsState: IGetNotifications = {
  subject: '',
  status: '',
  sourceType: '',
  sendChannel: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const notificationState: INotification = {
  id: '',
  subject: '',
  status: '',
  body: '',
  iconPath: '',
  sourceType: '',
  sourceId: '',
  sendChannel: '',
  users: [],
  attachment: '',
  scheduleDate: '',
  scheduleDateTimestamp: parseInt((Date.now() / 1000).toFixed(0))
};

export const createNotificationState: ICreateNotification = {
  subject: '',
  body: '',
  sendChannel: '',
  sourceType: '',
  sourceId: '',
  scheduleDate: '',
};

export const createRoleState: ICreateRole = {
  name: '',
  roleTypeId: '',
};

export const createQuestionState: ICreateQuestion = {
  name: '',
  customerId: '',
  questionTypeId: '',
  activityTypeId: '',
  description: '',
  isRequired: false,
  pointsValue: 0,
  questionType: '',
  minValue: null,
  maxValue: null,
  answers: [],
  isEdit: true,
};

export const getQuestionsState: IGetQuestions = {
  name: '',
  customerId: '',
  questionTypeId: '',
  activityTypeId: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const createCustomerState: ICreateCustomer = {
  name: '',
  shortName: '',
  addressId: 'ca73d254-eea5-4c0b-badb-2f2bbb4193c6',
  code: '',
  customerTypeId: '',
  email: '',
  fax: '',
  phone: '',
  www: '',
  nip: '',
  repoDir: '',
  ftpAddr: '',
  ftpUser: '',
  ftpPass: '',
  ftpPort: '',
};

export const getCustomersState: IGetCustomers = {
  name: '',
  requestOrder: {
    field: '',
    order: '',
  },
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const filtersFormState: any = {
  header: { name: '' },
  countries: [],
  fields: [],
  actions: [],
};

export const defaultFilterPaginateState: IFilterPaginate = {
  requestPaginate: {
    page: 1,
    limit: 10,
  },
};

export const defaultFilterOrderState: IFilterOrder = {
  requestOrder: {
    field: '',
    order: '',
  },
};

export const timeTableFilterState: IFilterTimeTable = {
  isDefault: true,
  taskName: null,
  locationId: null,
  projectId: null,
  taskId: null,
  userId: null,
  ...defaultFilterPaginateState,
};

export const headerViewResponseState: IHeaderViewResponse = {
  header: {
    name: '',
    subName: '',
  },
};

export const selectViewResponseState: ISelectViewResponse = {
  options: [],
  value: null,
  disabled: false,
};

export const photoReviewModalState: IPhotoReviewModal = {
  open: false,
  photoSimple: null,
  visit: null,
  isReviewed: false,
  displayOtherReviews: false,
  toggler: 0,
};

export const photoReviewRequestDataState: IPhotoReviewRequestData = {
  projectId: '',
  questionId: '',
  userId: '',
  activityId: '',
  userRoleId: '',
  dateRange: {
    dateFrom: '',
    dateTo: ''
  },
  ..._paginate30,
};

export const photoReviewFilterState: IFiltersPhotoReview = {
  ...filtersState,
  projects: {
    value: '',
    disabled: false,
    options: [],
  },
  questions: {
    value: '',
    disabled: false,
    options: [],
  },
  tasks: {
    value: '',
    disabled: false,
    options: [],
  },
  merchRoles: {
    value: '',
    disabled: false,
    options: [],
  },
  users: {
    value: '',
    disabled: false,
    options: [],
  },
  dateRange: {
    dateFrom: '',
    dateTo: ''
  }
};

export const photoReviewErrorDef: IPhotoReviewErrors = {
  KPI: {},
  SLA: {},
};

export const visitsListFilterDefault: IFiltersVisitsList = {
  locationId: '',
  locationName: '',
  nip: '',
  networkId: '',
  statusId: '',
  startDate: 0,
  endDate: 0,
  projectId: '',
  voivodeshipId: '',
  city: '',
  street: '',
  district: '',
  community: '',
  groupId: '',
  userRoleId: '',
  ..._paginate30,
};
