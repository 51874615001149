import React, { Component } from 'react';
import { IBaseComponentProps, IBaseComponentState } from '../../utils/models';

export default abstract class BaseComponent<
  P extends IBaseComponentProps,
  S extends IBaseComponentState
> extends Component<P, S> {
  abstract renderView(): JSX.Element;

  render(): JSX.Element {
    if (this.props?.hidden) {
      return <></>;
    }

    return this.renderView();
  }
}
