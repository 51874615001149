import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { ICreateBrand, IGetBrands } from '../models';
import { AxiosPromise } from 'axios';

const BrandsConnection = {
  getBrands: (data: IGetBrands) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands/list`, data, {
      headers: getAuthHeader(),
    });
  },
  createBrand: (data: ICreateBrand) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands`, data, {
      headers: getAuthHeader(),
    });
  },
  updateBrand: (id: string, data: ICreateBrand) => {
    return axios.put(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands/${id}`, data, {
      headers: getAuthHeader(),
    });
  },
  getFiltersBrandsList: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands/filter/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormAddBrand: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands/form`, {
      headers: getAuthHeader(),
    });
  },
  getFormEditBrand: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands/${id}/form`, {
      headers: getAuthHeader(),
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands`, {
      headers: { ...getAuthHeader() },
      data: { brands: data },
    });
  },
  getListForm: (): AxiosPromise => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/brands/list/form`);
  },
};

export default BrandsConnection;
