import React from 'react';
import { Redirect } from 'react-router-dom';
import CustomersEdit from 'views/admin/components/customersEdit/CustomersEdit';
import CustomersList from 'views/admin/components/customersList/CustomersList';
import CreateRole from 'views/admin/components/rolesAdd/CreateRole';
import EditRole from 'views/admin/components/rolesEdit/EditRole';
import RoleList from 'views/admin/components/rolesList/RoleList';
import CreateUser from 'views/admin/components/usersAdd/CreateUser';
import EditUser from 'views/admin/components/usersEdit/EditUser';
import UsersList from 'views/admin/components/usersList/UsersList';
import NotificationsList from '../../views/admin/components/notificationsList/NotificationsList';
import CreateNotification from '../../views/admin/components/notificationsAdd/createNotification';
import EditNotification from '../../views/admin/components/notificationsEdit/editNotification';

export default [
  { path: '/admin', breadcrumb: null, Component: () => <Redirect to="/admin/users" /> },
  { path: '/admin/users', breadcrumb: 'Użytkownicy', Component: UsersList, elo: true },
  { path: '/admin/users/add', breadcrumb: 'Dodaj użytkownika', Component: CreateUser },
  { path: '/admin/users/:id', breadcrumb: null },
  { path: '/admin/users/:id/edit', breadcrumb: 'Edytuj użytkownika', Component: EditUser },
  { path: '/admin/customers', breadcrumb: 'Klienci', Component: CustomersList },
  { path: '/admin/customers/add', breadcrumb: 'Dodaj klienta', Component: CustomersEdit },
  { path: '/admin/customers/:id', breadcrumb: null },
  { path: '/admin/customers/:id/edit', breadcrumb: 'Edytuj klienta', Component: CustomersEdit },
  { path: '/admin/roles', breadcrumb: 'Grupy użytkowników', Component: RoleList },
  { path: '/admin/roles/add', breadcrumb: 'Dodaj grupę', Component: CreateRole },
  { path: '/admin/roles/:id', breadcrumb: null },
  { path: '/admin/roles/:id/edit', breadcrumb: 'Edytuj grupę', Component: EditRole },
  { path: '/admin/notifications', breadcrumb: 'Powiadomienia', Component: NotificationsList },
  { path: '/admin/notifications/add', breadcrumb: 'Dodaj powiadomienie', Component: CreateNotification },
  { path: '/admin/notifications/:id/edit', breadcrumb: 'Edytuj powiadomienie', Component: EditNotification },
  { path: '/admin/notifications/:id/view', breadcrumb: 'Wyświetl powiadomienie', Component: EditNotification },
];
