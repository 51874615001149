import React, { FunctionComponent } from 'react';
import styles from './atoms.module.scss';
import FiltersStorage from '../../utils/FiltersStorage';
import Button from './Button';

interface IButtonFilters {
  onClick: any;
  customFiltersStorageKey?: string;
}

const ButtonFilters: FunctionComponent<IButtonFilters> = ({ onClick, customFiltersStorageKey }) => {
  const filtersStorage = new FiltersStorage();
  const filtersFromStorage = filtersStorage.get(customFiltersStorageKey);

  if (filtersFromStorage !== null) {
    return <Button onClick={onClick}>Filtry</Button>;
  }

  return (
    <button onClick={onClick} className={styles.buttonOutlined}>
      Filtry
    </button>
  );
};

export default ButtonFilters;
