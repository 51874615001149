import { default as axios } from '../AxiosHelper';
import { getAuthHeader, getOrganizationId } from '../helpers';
import { ICreateLocation, IGetLocations } from '../models';

export const getSaveAddressData = (data: ICreateLocation) => {
  return {
    ...data,
    addressId: data.addressId.id,
    payerAddressId: data.payerAddressId.id,
    recipientAddressId: data.recipientAddressId.id,
  };
};

const LocationsConnection = {
  getLocations: (data: IGetLocations) => {
    return axios.post(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/locations/list`, data, {
      headers: { ...getAuthHeader() },
    });
  },
  createLocation: (data: ICreateLocation) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/locations`,
      getSaveAddressData(data),
      {
        headers: { ...getAuthHeader() },
      },
    );
  },
  editLocation: (data: ICreateLocation, id: string) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/locations/${id}`,
      getSaveAddressData(data),
      {
        headers: { ...getAuthHeader() },
      },
    );
  },
  getFormCreateLocation: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/locations/form`, {
      headers: { ...getAuthHeader() },
    });
  },
  getFormEditLocation: (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/locations/${id}/form`, {
      headers: { ...getAuthHeader() },
    });
  },
  getFormFilterLocations: () => {
    return axios.get(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/locations/filter/form`, {
      headers: { ...getAuthHeader() },
    });
  },
  batchDelete: (data: Array<{ id: string }>) => {
    return axios.delete(`${process.env.REACT_APP_API_URL}/organizations/${getOrganizationId()}/locations`, {
      headers: { ...getAuthHeader() },
      data: { locations: data },
    });
  },
};

export default LocationsConnection;
