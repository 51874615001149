import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { handleToast } from 'utils/helpers';
import WhiteCard from 'global/atoms/WhiteCard';
import TranslationsConnection from 'utils/connections/translations';
import Input from 'global/atoms/Input';
import Textarea from 'global/atoms/Textarea';
import Form from 'global/form/Form';
import ButtonSubmit from 'global/atoms/ButtonSubmit';
import ActionsContainer from 'global/atoms/ActionsContainer';

interface ITranslationsAdd {}

const TranslationsAdd: FunctionComponent<ITranslationsAdd> = () => {
  const [data, setData] = useState<any>({ locale: '', field: '', hint: '', text: '' });
  const [errors, setErrors] = useState<any | null>(null);
  const history = useHistory();

  const handleSubmit = (event: any) => {
    event.preventDefault();

    handleToast(TranslationsConnection.createTranslation(data), setErrors).then(() =>
      history.push('/superadmin/translations'),
    );
  };

  return (
    <>
      <WhiteCard padding={true}>
        <Form handleSubmit={handleSubmit}>
          <Input
            type={'text'}
            placeholder={'Język (kod)'}
            name={'locale'}
            value={data.locale}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Input
            type={'text'}
            placeholder={'Pole'}
            name={'field'}
            value={data.field}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Podpowiedź'}
            name={'hint'}
            value={data.hint}
            state={data}
            setState={setData}
            errors={errors}
          />
          <Textarea
            placeholder={'Treść'}
            name={'text'}
            value={data.text}
            state={data}
            setState={setData}
            errors={errors}
          />
          <ActionsContainer>
            <ButtonSubmit />
          </ActionsContainer>
        </Form>
      </WhiteCard>
    </>
  );
};

export default TranslationsAdd;
