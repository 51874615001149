import React, { CSSProperties, FunctionComponent, useState } from 'react';
import styles from '../../../../../global/atoms/atoms.module.scss';
import LegendCircle from './LegendCircle';
import IconButton from '../../../../../global/atoms/IconButton';

const Legend: FunctionComponent = () => {
  const [visible, setVisible] = useState(true);
  const handleToggleLegend = () => setVisible(!visible);
  const containerStyle: CSSProperties = {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#fff',
    zIndex: 2,
    width: '220px',
    padding: '10px 0',
    boxShadow: '0 12px 32px 0 rgba(93, 92, 92, 0.12)',
  };
  if (!visible) {
    containerStyle.width = 'auto';
    containerStyle.padding = 0;
  }

  return (
    <>
      <div style={containerStyle}>
        {visible ? (
          <>
            <IconButton
              style={{
                position: 'absolute',
                right: '10px',
                top: '10px',
              }}
              onClick={handleToggleLegend}
              icon={'close'}
            />
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={2} color={'blue'} />
              <span className={styles.timeTableLegendText}>Jednorazowo</span>
            </div>
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={2} color={'orange'} />
              <span className={styles.timeTableLegendText}>Co tydzień</span>
            </div>
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={2} color={'green'} />
              <span className={styles.timeTableLegendText}>Co dwa tygodnie</span>
            </div>
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={2} color={'purple'} />
              <span className={styles.timeTableLegendText}>Co miesiąc</span>
            </div>
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={2} color={'mixed'} />
              <span className={styles.timeTableLegendText}>Mieszany harmonogram</span>
            </div>

            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={1} color={'transparent'} redBorder={true} />
              <span className={styles.timeTableLegendText}>Oczekuje na akceptację</span>
            </div>
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={1} color={'gray'} />
              <span className={styles.timeTableLegendText}>1 zdarzenie</span>
            </div>
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={2} color={'gray'} />
              <span className={styles.timeTableLegendText}>2-5 zdarzeń</span>
            </div>
            <div className={styles.timeTableLegendCol}>
              <LegendCircle size={3} color={'gray'} />
              <span className={styles.timeTableLegendText}>{'>5 zdarzeń'}</span>
            </div>
          </>
        ) : (
          <>
            <IconButton size={40} onClick={handleToggleLegend} icon={'arrowLeft'} />
          </>
        )}
      </div>
    </>
  );
};

export default Legend;
