import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import { IAddress, IAddresses, IGetAddresses } from 'utils/models';
import AddressesConnection from 'utils/connections/addresses';
import Pagination from 'global/pagination/Pagination';
import { getAddressesState } from 'utils/states';
import Filters from 'global/filters/Filters';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import settings from 'assets/svg/settings.svg';
import List from 'global/table/List';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonFilters from 'global/atoms/ButtonFilters';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import Spinner from 'global/atoms/Spinner/Spinner';
import {
  DeleteSelectedButton,
  DeleteSingleButton,
  ToggleAllCheckbox,
  ToggleSingleCheckbox,
} from '../../../../utils/toggleToDeleteHelper';
import { deepClone, getAddressFullName, handleToast } from '../../../../utils/helpers';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import Checkbox from '../../../../global/atoms/Checkbox';
import ButtonListRedirect from '../../../../global/atoms/ButtonListRedirect';
import { ButtonSort } from '../../../../utils/sortHelper';
import { handleClearFilters, handleSubmitAndSaveFilters, loadFilters } from '../../../../utils/filterHelper';
import ButtonsOverListContainer from '../../../../global/atoms/ButtonsOverListContainer';
import Button from '../../../../global/atoms/Button';
import FiltersStorage from '../../../../utils/FiltersStorage';

interface IAddressesList {
  handleClose?: any;
  handleSetAddress?: any;
  selectedAddressId: string;
  selectedAddressName: string;
}

export type chosenAddressDataType = { id: string; name: string };

const AddressesList: FunctionComponent<IAddressesList> = ({
  handleClose,
  handleSetAddress,
  selectedAddressId,
  selectedAddressName,
}) => {
  type ChoseAddress = { id: string; name: string };
  const defaultFilters: IGetAddresses = deepClone(getAddressesState);
  let filters: IGetAddresses, setFilters;
  let chosenAddress: ChoseAddress, setChosenAddress: any;
  if (selectedAddressId !== '' && selectedAddressName !== '' && selectedAddressId !== undefined) {
    const filtersById: IGetAddresses = deepClone(getAddressesState);
    filtersById.id = selectedAddressId;
    [filters, setFilters] = useState<IGetAddresses>(filtersById);
    [chosenAddress, setChosenAddress] = useState<chosenAddressDataType>({
      id: selectedAddressId,
      name: selectedAddressName,
    });

    useEffect(() => {
      const filtersStorage = new FiltersStorage();
      filtersStorage.save(filters);
    }, []);
  } else {
    [filters, setFilters] = useState<IGetAddresses>(loadFilters(defaultFilters));
    [chosenAddress, setChosenAddress] = useState<chosenAddressDataType>({ id: '', name: '' });
  }

  const [data, setData] = useState<IAddresses | null>(null);
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [form, setForm] = useState<any>('loading');
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  const [chosenToDelete, setChosenToDelete] = useState<Array<string>>([]);
  const [reloadData, setReloadData] = useState<boolean>(false);

  useEffect(() => {
    AddressesConnection.getFormFilterAddress().then((data) => {
      setLoading(false);
      setForm(data.data);
    });
  }, []);

  const loadData = () => {
    AddressesConnection.getAddresses(filters).then((data) => {
      setLoading(false);
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  };

  useEffect(() => {
    if (reloadData) {
      loadData();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    loadData();
  }, [filters.requestPaginate, filters.requestOrder]);

  const handleToggleFilters = () => setToggleFilters((state: boolean) => !state),
    handleEdit = (event: any) => {
      history.push(`/datasets/addresses/${event.currentTarget.dataset.id}/edit`);
    };

  if (loading) return <Spinner />;

  if (data === null || form === 'loading') return null;

  const handleDeleteSelected = (deleteId: string) => {
    let toDelete = chosenToDelete.map((id) => ({ id: id }));
    if (deleteId !== undefined) {
      toDelete = [{ id: deleteId }];
    }
    handleToast(AddressesConnection.batchDelete(toDelete)).then(() => {
      setChosenToDelete([]);
      loadData();
    });
  };

  const handleChooseAddress = (address: IAddress) => {
    setChosenAddress({ id: address.id, name: getAddressFullName(address) });
  };

  const handleSetParentAddress = () => {
    handleSetAddress(chosenAddress);
  };

  const handleRowClassName = (rowData: IAddress): string => {
    if (handleSetAddress === undefined) return '';
    if (rowData === undefined || chosenAddress.id !== rowData.id) return 'tablePointer';
    return `tableRowSelected tablePointer`;
  };

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
        <ButtonAddNew form={form} />
        <DeleteSelectedButton chosenToDelete={chosenToDelete} callback={handleDeleteSelected} form={form} />
      </HeaderButtons>
      {handleClose !== undefined && handleSetAddress !== undefined && (
        <>
          <ButtonsOverListContainer>
            <div />
            <ActionsContainer>
              <Button onClick={handleSetParentAddress}>Przypisz zaznaczony</Button>
              <Button onClick={handleClose}>Powrót</Button>
            </ActionsContainer>
            <div />
          </ButtonsOverListContainer>
        </>
      )}
      <WhiteCard mobileTransparent={true} padding={false}>
        <List
          data={data.data}
          count={data.data.length}
          rowClick={handleChooseAddress}
          rowClassName={handleRowClassName}
        >
          <Column width={40} align="left">
            <HeaderCell depth={0} width={0}>
              <ToggleAllCheckbox data={data.data} state={chosenToDelete} setState={setChosenToDelete} />
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <ToggleSingleCheckbox state={chosenToDelete} setState={setChosenToDelete} selectedId={rowData.id} />
              )}
            </Cell>
          </Column>
          <Column width={150} align="left">
            <HeaderCell depth={0} width={0}>
              <ButtonSort dataName={'city'} filters={filters} setFilters={setFilters}>
                Miasto
              </ButtonSort>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData: IAddress) => (
                <>
                  {handleSetAddress === undefined ? (
                    <ButtonListRedirect redirectUrl={`/datasets/addresses/${rowData.id}/edit`}>
                      {rowData.city.name}
                    </ButtonListRedirect>
                  ) : (
                    <span>{rowData.city.name}</span>
                  )}
                </>
              )}
            </Cell>
          </Column>
          <Column flexGrow={1} minWidth={200} align="left">
            <HeaderCell depth={0} width={0}>
              <ButtonSort dataName={'street'} filters={filters} setFilters={setFilters}>
                Ulica
              </ButtonSort>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData: IAddress) => <span>{rowData.street.name}</span>}
            </Cell>
          </Column>
          <Column width={150} align="left">
            <HeaderCell depth={0} width={0}>
              Numer
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => (
                <span>
                  {rowData.houseNumber && rowData.apartmentNumber
                    ? `${rowData.houseNumber}/${rowData.apartmentNumber}`
                    : rowData.houseNumber
                    ? `${rowData.houseNumber}`
                    : ''}
                </span>
              )}
            </Cell>
          </Column>
          <Column width={160} align="left">
            <HeaderCell depth={0} width={0}>
              <ButtonSort dataName={'postcode'} filters={filters} setFilters={setFilters}>
                Kod pocztowy
              </ButtonSort>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData: IAddress) => {
                return <span>{rowData.postCode.name}</span>;
              }}
            </Cell>
          </Column>
          <Column width={200} align="left">
            <HeaderCell depth={0} width={0}>
              <ButtonSort dataName={'voivodeship'} filters={filters} setFilters={setFilters}>
                województwo
              </ButtonSort>
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <span>{rowData.voivodeship.name}</span>}
            </Cell>
          </Column>
          <Column width={160} align="left">
            <HeaderCell depth={0} width={0}>
              Zweryfikowany
            </HeaderCell>
            <Cell width={100} depth={0}>
              {(rowData) => <Checkbox onClick={() => null} isChecked={rowData.verified} />}
            </Cell>
          </Column>
          <Column verticalAlign={'middle'} resizable={true} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <>
                  <ActionsContainer>
                    <img
                      src={settings}
                      style={{ cursor: 'pointer' }}
                      alt={'Idź do'}
                      data-id={rowData.id}
                      onClick={handleEdit}
                    />
                    <DeleteSingleButton callback={handleDeleteSelected.bind(null, rowData.id)} form={form} />
                  </ActionsContainer>
                </>
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        limit={filters.requestPaginate.limit.toString()}
        page={filters.requestPaginate.page.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitAndSaveFilters.bind(null, setReloadData, filters)}
        handleClear={handleClearFilters.bind(null, setFilters, defaultFilters, setReloadData)}
      >
        <Input
          value={filters.id}
          placeholder={'Identyfikator adresu'}
          name={'id'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.street}
          placeholder={'Ulica'}
          name={'street'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.houseNumber}
          placeholder={'Numer domu'}
          name={'houseNumber'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.apartmentNumber}
          placeholder={'Numer mieszkania'}
          name={'apartmentNumber'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.postcode}
          placeholder={'Kod pocztowy'}
          name={'postcode'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.city}
          placeholder={'Miasto'}
          name={'city'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.district}
          placeholder={'Powiat'}
          name={'district'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Input
          value={filters.community}
          placeholder={'Gmina'}
          name={'community'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Województwo'}
          name={'voivodeshipId'}
          value={filters.voivodeshipId}
          options={form.voivodeships.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Kraj'}
          name={'country'}
          value={filters.country}
          options={form.countries.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
      </Filters>
    </>
  );
};

export default AddressesList;
