import React, { FunctionComponent } from 'react';
import styles from './atoms.module.scss';
import CSS from 'csstype';
import { findErrorByName } from '../../utils/formErrorsHelper';

interface IInput {
  style?: CSS.Properties;
  type?: 'decimal' | 'text' | string;
  placeholder: string;
  name: string;
  onChange?: (event: any) => void;
  value: any;
  disabled?: boolean;
  setState?: any;
  state?: any;
  errors?: any;
  max?: number;
  debugData?: any;
  id?: any;
  pattern?: string;
  customError?: string;
  allowNegativeNumbers?: boolean;
}

const Input: FunctionComponent<IInput> = ({
  style,
  type = 'text',
  placeholder,
  value,
  name,
  disabled = false,
  setState,
  state,
  onChange,
  errors,
  max,
  debugData,
  id,
  pattern,
  customError,
  allowNegativeNumbers = true,
}) => {
  const handleChange = (event: any) => {
    let value = event.currentTarget.value;

    if (!allowNegativeNumbers) {
      value = value < 0 ? 0 : value;
    }

    if (type === 'decimal') {
      value = Number(value).toFixed(2);
      value = value < 0 ? '0.00' : value;
    }

    setState({ ...state, [name]: value });
  };

  let typeSet = type;

  if (type === 'decimal') {
    typeSet = 'number';
  }

  return (
    <div className={styles.inputContainer} style={style}>
      <label className={styles.inputLabel} htmlFor={name}>
        {placeholder}
      </label>
      <input
        onChange={onChange ? onChange : handleChange}
        value={value}
        className={styles.input}
        pattern={pattern}
        type={typeSet}
        name={name}
        disabled={disabled}
        maxLength={max}
        style={disabled ? { backgroundColor: 'hsl(0,0%,95%)' } : {}}
        data-debug={debugData}
        data-id={id}
      />
      {customError && <span className={styles.errors}>{customError}</span>}
      {errors && <span className={styles.errors}>{findErrorByName(name, errors)}</span>}
    </div>
  );
};

export default Input;
