import React, { FunctionComponent, useEffect, useState } from 'react';
import WhiteCard from 'global/atoms/WhiteCard';
import Pagination from 'global/pagination/Pagination';
import Filters from 'global/filters/Filters';
import OrganizationsConnection from 'utils/connections/organizations';
import Input from 'global/atoms/Input';
import Select from 'global/atoms/Select';
import { Cell, Column, HeaderCell } from 'rsuite-table';
import arrow from 'assets/svg/arrow_down_small.svg';
import List from 'global/table/List';
import settings from 'assets/svg/settings.svg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import HeaderButtons from 'global/atoms/headerButtons/HeaderButtons';
import ButtonAddNew from 'global/atoms/ButtonAddNew';
import ButtonFilters from 'global/atoms/ButtonFilters';
import Spinner from 'global/atoms/Spinner/Spinner';

interface IOrganizationsList {}

const OrganizationsList: FunctionComponent<IOrganizationsList> = () => {
  const [data, setData] = useState<any | null>(null);
  const [filters, setFilters] = useState<any>({
    name: '',
    countryId: '',
    requestOrder: {
      field: '',
      order: '',
    },
    requestPaginate: {
      page: 1,
      limit: 10,
    },
  });
  const [toggleFilters, setToggleFilters] = useState<boolean>(false);
  const [filtersForm, setFiltersForm] = useState<any | 'loading'>('loading');
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    OrganizationsConnection.getFiltersOrganizationsList().then((data) => {
      setFiltersForm(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, []);

  useEffect(() => {
    OrganizationsConnection.getOrganizations(filters).then((data) => {
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });

    setFilters((state: any) => ({
      ...state,
      requestPaginate: {
        page: 1,
        limit: state.requestPaginate.limit,
      },
    }));
  }, [filters.requestPaginate.limit]);

  useEffect(() => {
    OrganizationsConnection.getOrganizations(filters).then((data) => {
      setData(data.data);
      data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
    });
  }, [filters.requestPaginate.page, filters.requestOrder]);

  const handleToggleSort = (event: any) => {
    if (filters.requestOrder.field === event.currentTarget.dataset.field) {
      setFilters({
        ...filters,
        requestOrder: {
          field: filters.requestOrder.field,
          order: filters.requestOrder.order === 'asc' ? 'desc' : 'asc',
        },
      });
    } else {
      setFilters({
        ...filters,
        requestOrder: {
          field: event.currentTarget.dataset.field,
          order: 'asc',
        },
      });
    }
  };
  const handleToggleFilters = () => setToggleFilters((state: boolean) => !state),
    handleSubmitFilters = (event: any) => {
      event.preventDefault();

      OrganizationsConnection.getOrganizations({
        ...filters,
        requestPaginate: { page: 1, limit: filters.requestPaginate.limit },
      }).then((data) => {
        setToggleFilters(false);
        setData(data.data);
        data.data?.header && dispatch({ type: 'SET_HEADER', payload: data.data.header });
        setFilters((state: any) => ({
          ...state,
          requestPaginate: { page: 1, limit: state.requestPaginate.limit },
        }));
      });
    },
    handleClearFilters = () => {
      setFilters((state: any) => ({ ...state, name: '', countryId: '' }));
    },
    handleEdit = (event: any) => {
      history.push(`/superadmin/organizations/${event.currentTarget.dataset.id}/edit`);
    },
    handleClickRow = (data: any) => {
      console.log(data);
      history.push(`/superadmin/organizations/${data.id}/edit`);
    };

  if (filtersForm === 'loading') return <Spinner />;
  if (data === null) return null;

  return (
    <>
      <HeaderButtons>
        <ButtonFilters onClick={handleToggleFilters} />
        <ButtonAddNew />
      </HeaderButtons>
      <WhiteCard mobileTransparent={true} padding={false}>
        <List data={data.data} count={data.data.length} rowClick={handleClickRow}>
          <Column flexGrow={1} align="left">
            <HeaderCell depth={0} width={0}>
              <span
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                onClick={handleToggleSort}
                data-field={'name'}
              >
                Nazwa
                {filters.requestOrder.field === 'name' &&
                  (filters.requestOrder.order === 'asc' ? (
                    <img src={arrow} alt={'sort'} />
                  ) : (
                    <img src={arrow} alt={'sort'} style={{ transform: 'rotate(180deg)' }} />
                  ))}
              </span>
            </HeaderCell>
            <Cell width={100} depth={0} dataKey="name" />
          </Column>
          <Column verticalAlign={'middle'} align="right" fixed={'right'}>
            <HeaderCell depth={0} width={0}>
              Akcje
            </HeaderCell>
            <Cell depth={0} width={0}>
              {(rowData) => (
                <img
                  src={settings}
                  style={{ cursor: 'pointer' }}
                  alt={'Idź do'}
                  data-id={rowData.id}
                  onClick={handleEdit}
                />
              )}
            </Cell>
          </Column>
        </List>
      </WhiteCard>
      <Pagination
        count={data.count}
        page={filters.requestPaginate.page.toString()}
        limit={filters.requestPaginate.limit.toString()}
        setState={setFilters}
      />
      <Filters
        toggleFilters={toggleFilters}
        handleToggleFilters={handleToggleFilters}
        handleSubmit={handleSubmitFilters}
        handleClear={handleClearFilters}
      >
        <Input
          value={filters.name}
          placeholder={'Nazwa organizacji'}
          name={'name'}
          type={'text'}
          setState={setFilters}
          state={filters}
        />
        <Select
          placeholder={'Kraj'}
          name={'countryId'}
          value={filters.countryId}
          options={filtersForm.countries.options}
          disabled={false}
          setState={setFilters}
          state={filters}
        />
      </Filters>
    </>
  );
};

export default OrganizationsList;
