import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
// import Button from '../../../../../global/atoms/Button';
import UserList from './UsersList';
import styles from './styles.module.scss';
import left from '../../../../../assets/svg/left_big.svg';
import right from '../../../../../assets/svg/right_big.svg';
import Heading from '../../../../../global/heading/Heading';

interface IRoleUsersHeader {
  roleId: string;
  roleName: string;
  assigned: Array<any>;
  unassigned: Array<any>;
  setState: Dispatch<SetStateAction<any>>;
}

const RoleUsersHeader: FunctionComponent<IRoleUsersHeader> = ({ roleId, roleName, unassigned, assigned, setState }) => {
  const [editView] = useState<boolean>(true);
  const [checked, setChecked] = useState<{ assigned: Array<string>; unassigned: Array<string> }>({
    assigned: [],
    unassigned: [],
  });

  // const handleToggleEditView = () => {
  //     setEditView((state) => !state);
  //   };
  const handleToggleAssigned = (event: any) => {
      if (checked.assigned.includes(event.currentTarget.dataset.id)) {
        setChecked({
          ...checked,
          assigned: checked.assigned.filter((item) => item !== event.currentTarget.dataset.id),
        });
      } else {
        setChecked({ ...checked, assigned: [...checked.assigned, event.currentTarget.dataset.id] });
      }
    },
    handleToggleAllAssigned = () => {
      if (checked.assigned.length) {
        setChecked({
          ...checked,
          assigned: [],
        });
      } else {
        setChecked({ ...checked, assigned: assigned.map((item: any) => item.id) });
      }
    },
    handleToggleUnassigned = (event: any) => {
      if (checked.unassigned.includes(event.currentTarget.dataset.id)) {
        setChecked({
          ...checked,
          unassigned: checked.unassigned.filter((item) => item !== event.currentTarget.dataset.id),
        });
      } else {
        setChecked({ ...checked, unassigned: [...checked.unassigned, event.currentTarget.dataset.id] });
      }
    },
    handleToggleAllUnassigned = () => {
      if (checked.unassigned.length) {
        setChecked({
          ...checked,
          unassigned: [],
        });
      } else {
        setChecked({ ...checked, unassigned: unassigned.map((item: any) => item.id) });
      }
    },
    handleAssign = () => {
      setState((state: any) => ({
        ...state,
        locationRoles: {
          data: state.locationRoles.data.map((item: any) => {
            if (item.roleId === roleId) {
              return {
                ...item,
                users: {
                  assigned: [
                    ...item.users.assigned,
                    ...item.users.unassigned.filter((subItem: any) => checked.unassigned.includes(subItem.id)),
                  ],
                  unassigned: item.users.unassigned.filter((subItem: any) => !checked.unassigned.includes(subItem.id)),
                },
              };
            } else return item;
          }),
        },
      }));
      setChecked((state) => ({ ...state, unassigned: [] }));
    },
    handleUnassign = () => {
      setState((state: any) => ({
        ...state,
        locationRoles: {
          data: state.locationRoles.data.map((item: any) => {
            if (item.roleId === roleId) {
              return {
                ...item,
                users: {
                  unassigned: [
                    ...item.users.unassigned,
                    ...item.users.assigned.filter((subItem: any) => checked.assigned.includes(subItem.id)),
                  ],
                  assigned: item.users.assigned.filter((subItem: any) => !checked.assigned.includes(subItem.id)),
                },
              };
            } else return item;
          }),
        },
      }));
      setChecked((state) => ({ ...state, assigned: [] }));
    };

  return (
    <div>
      <Heading title={`Grupa ${roleName}`} />
      <div className={editView ? styles.usersGrid : ''}>
        {editView && (
          <>
            <UserList
              handleToggleCheckbox={handleToggleAssigned}
              handleToggleMainCheckbox={handleToggleAllAssigned}
              editView={editView}
              checked={checked.assigned}
              data={assigned}
              counter={assigned.length}
              column={'Przypisane'}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                height: '100%',
              }}
            >
              <button
                style={{ marginBottom: '12px', backgroundColor: 'white', border: 'none', cursor: 'pointer' }}
                onClick={handleAssign}
              >
                <img src={left} />
              </button>
              <button style={{ backgroundColor: 'white', border: 'none', cursor: 'pointer' }} onClick={handleUnassign}>
                <img src={right} />
              </button>
            </div>
            <UserList
              handleToggleCheckbox={handleToggleUnassigned}
              handleToggleMainCheckbox={handleToggleAllUnassigned}
              editView={editView}
              checked={checked.unassigned}
              data={unassigned}
              counter={unassigned.length}
              column={'Nieprzypisane'}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default RoleUsersHeader;
