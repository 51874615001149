import React, { FunctionComponent, useEffect, useState } from 'react';
import ProjectLocationUsersConnection from '../../../../utils/connections/projectLocationUsers';
import { useParams } from 'react-router-dom';
import Spinner from '../../../../global/atoms/Spinner/Spinner';
import { ISelectViewResponse } from '../../../../utils/models';
import WhiteCard from '../../../../global/atoms/WhiteCard';
import Select from '../../../../global/atoms/Select';
import { Col, Row } from 'react-grid-system';
import SeparatorEmpty from '../../../../global/atoms/separators/SeparatorEmpty';
import ActionsContainer from '../../../../global/atoms/ActionsContainer';
import ButtonSubmit from '../../../../global/atoms/ButtonSubmit';
import { handleToast } from '../../../../utils/helpers';
import { toast } from 'react-hot-toast';

interface ILocationsOperations {
  selectedLocations: Array<any>;
  setReloadData: any;
}

interface IFormData {
  usersFrom: ISelectViewResponse;
  usersTo: ISelectViewResponse;
  projectRoles: ISelectViewResponse;
  operationTypes: ISelectViewResponse;
}

interface IState {
  operation: string;
  role: string;
  userFrom: string;
  userTo: string;
}

export interface ISubmitOperation {
  operationType: string;
  roleId: string;
  userFromId: string;
  userToId: string;
  positions: Array<{ locationId: string }>;
}

const TYPE_REPLACE_ALL = 'REPLACE_ALL';
const TYPE_REPLACE_SELECTED = 'REPLACE_SELECTED';
const TYPE_ADD_SELECTED = 'ADD_SELECTED';
const TYPE_ADD_ALL = 'ADD_ALL';
const TYPE_REMOVE_SELECTED = 'REMOVE_SELECTED';
const TYPE_REMOVE_ALL = 'REMOVE_ALL';

const LocationsOperations: FunctionComponent<ILocationsOperations> = ({ selectedLocations, setReloadData }) => {
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<IFormData | null>(null);
  const defaultState = {
    operation: '',
    role: '',
    userFrom: '',
    userTo: '',
  };
  const [state, setState] = useState<IState>(defaultState);

  useEffect(() => {
    ProjectLocationUsersConnection.getLocationReplaceFormData(id).then((response) => {
      setFormData(response.data);
    });
  }, []);

  if (formData === null) return <Spinner />;

  const getUsersOptionsByRole = (users: Array<any>) => {
    if (state.role === '') {
      return users.map((item) => ({
        id: item.id,
        name: item.fullName,
      }));
    }

    const usersFilteredByRole = users.filter((item) => item.roleId === state.role);
    return usersFilteredByRole.map((item) => ({
      id: item.id,
      name: item.fullName,
    }));
  };

  const getUsersSelectsFromTo = (showUserFrom = true, showUserTo = true) => {
    return (
      <Row>
        {showUserFrom && (
          <Col xs={6}>
            <Select
              placeholder={'Użytkownik od'}
              name={'userFrom'}
              value={state.userFrom}
              options={getUsersOptionsByRole(formData.usersFrom.options)}
              state={state}
              setState={setState}
            />
          </Col>
        )}
        {showUserTo && (
          <Col xs={6}>
            <Select
              placeholder={'Użytkownik do'}
              name={'userTo'}
              value={state.userTo}
              options={getUsersOptionsByRole(formData.usersTo.options)}
              state={state}
              setState={setState}
            />
          </Col>
        )}
      </Row>
    );
  };

  const getUserSelectsByOperation = () => {
    if (state.operation === TYPE_REPLACE_ALL || state.operation === TYPE_REPLACE_SELECTED) {
      return getUsersSelectsFromTo();
    }

    if (state.operation === TYPE_ADD_ALL || state.operation === TYPE_ADD_SELECTED) {
      return getUsersSelectsFromTo(false, true);
    }

    if (state.operation === TYPE_REMOVE_ALL || state.operation === TYPE_REMOVE_SELECTED) {
      return getUsersSelectsFromTo(true, false);
    }

    return null;
  };

  const handleSubmit = () => {
    if (
      (state.operation === TYPE_REMOVE_SELECTED ||
        state.operation === TYPE_ADD_SELECTED ||
        state.operation === TYPE_REPLACE_SELECTED) &&
      selectedLocations.length <= 0
    ) {
      toast.error('Nie wybrano lokalizacji');
      return false;
    }

    const getDataPost = (): ISubmitOperation => {
      const data = {
        operationType: state.operation,
        roleId: state.role,
        userFromId: state.userFrom,
        userToId: state.userTo,
        positions: selectedLocations.map((item) => ({ locationId: item })),
      };

      if (state.operation === TYPE_ADD_ALL || state.operation === TYPE_ADD_SELECTED) {
        data.userFromId = data.userToId; // set any user as user from just to prevent backend error
      }

      if (state.operation === TYPE_REMOVE_ALL || state.operation === TYPE_REMOVE_SELECTED) {
        data.userToId = data.userFromId; // set any user as user from just to prevent backend error
      }

      return data;
    };

    handleToast(ProjectLocationUsersConnection.operation(id, getDataPost())).then(() => {
      setState(defaultState);
      setReloadData(true);
    });
  };

  return (
    <>
      <WhiteCard
        style={{
          position: 'relative',
          zIndex: 100,
        }}
        marginTop={true}
      >
        {(state.operation === TYPE_REMOVE_SELECTED ||
          state.operation === TYPE_ADD_SELECTED ||
          state.operation === TYPE_REPLACE_SELECTED) && (
          <>
            <span>Zaznaczone lokalizacje ({selectedLocations.length})</span>
            <SeparatorEmpty />
          </>
        )}
        <Row>
          <Col xs={6}>
            <Select
              placeholder={'Operacja'}
              name={'operation'}
              value={state.operation}
              options={formData.operationTypes.options}
              state={state}
              setState={setState}
            />
          </Col>
          <Col xs={6}>
            <Select
              placeholder={'Rola'}
              name={'role'}
              value={state.role}
              options={formData.projectRoles.options}
              state={state}
              setState={setState}
            />
          </Col>
        </Row>
        <SeparatorEmpty />
        {state.role !== '' && state.operation !== '' && (
          <>
            {getUserSelectsByOperation()}
            <SeparatorEmpty />
            <ActionsContainer>
              <ButtonSubmit onClick={handleSubmit} />
            </ActionsContainer>
          </>
        )}
      </WhiteCard>
    </>
  );
};

export default LocationsOperations;
