import React, { FunctionComponent } from 'react';
import TimeTableDetails from '../projects/components/timeTable/details/TimeTableDetails';

interface ITimetables {}

const Timetables: FunctionComponent<ITimetables> = () => {
  return <TimeTableDetails type={'project'} />;
};

export default Timetables;
