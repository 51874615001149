import React from 'react';
import BaseComponent from '../../../../global/atoms/BaseComponent';
import { IAddTimeTableRecurringSettings, IBaseComponentProps } from '../../../../utils/models';
import CheckBox from '../../../../global/atoms/checkbox/CheckBox';
import WeekDaysHorizontalGroup from '../../../../global/atoms/weekDays/WeekDaysHorizontalGroup';
import WeekDayButton from '../../../../global/atoms/weekDays/WeekDayButton';
import Select from '../../../../global/atoms/Select';
import Input from '../../../../global/atoms/Input';
import { deepClone, parseDateForBackend, parseDateForFrontend } from '../../../../utils/helpers';
import { Row, Col } from 'react-grid-system';
import ButtonOutlined from '../../../../global/atoms/ButtonOutlined';

interface IEditTimeTableSingleRecurringSettingProps extends IBaseComponentProps {
  recurringSetting: IAddTimeTableRecurringSettings;
  parentData: any;
  parentSetData: any;
  id: number;
}

interface IEditTimeTableSingleRecurringSettingState {
  selectWeekDaysIsVisible: boolean;
  selectDateRangeIsVisible: boolean;
}

export default class EditTimeTableSingleRecurringSetting extends BaseComponent<
  IEditTimeTableSingleRecurringSettingProps,
  IEditTimeTableSingleRecurringSettingState
> {
  constructor(props: IEditTimeTableSingleRecurringSettingProps) {
    super(props);
    this.state = {
      selectWeekDaysIsVisible: this.props.recurringSetting.days.length > 0,
      selectDateRangeIsVisible: this.props.recurringSetting.endDate > 0 || this.props.recurringSetting.startDate > 0,
    };
  }

  recurringSettingsCloned = deepClone(this.props.recurringSetting);

  weekDayClickHandle(event: any) {
    const dayId = parseInt(event.currentTarget.dataset.id);
    if (this.recurringSettingsCloned.days.includes(dayId)) {
      this.recurringSettingsCloned.days = this.recurringSettingsCloned.days.filter((value: number) => value !== dayId);
    } else {
      this.recurringSettingsCloned.days.push(dayId);
    }

    this.updateRecurringSettingsState();
  }

  weekDaysToggleHandle() {
    this.recurringSettingsCloned.days = [];
    this.updateRecurringSettingsState();

    this.setState({ selectWeekDaysIsVisible: !this.state.selectWeekDaysIsVisible });
  }

  updateRecurringSettingsState() {
    const parentDataUpdate = { ...this.props.parentData };
    parentDataUpdate.timeTable.recurringSettings[this.props.id] = this.recurringSettingsCloned;
    this.props.parentSetData({ ...parentDataUpdate });
  }

  dateFromChangeHandle(event: any) {
    this.recurringSettingsCloned.startDate = parseDateForBackend(event.currentTarget.value);
    this.updateRecurringSettingsState();
  }

  dateToChangeHandle(event: any) {
    this.recurringSettingsCloned.endDate = parseDateForBackend(event.currentTarget.value);
    this.updateRecurringSettingsState();
  }

  datesRangeToggleHandle() {
    this.recurringSettingsCloned.startDate = 0;
    this.recurringSettingsCloned.endDate = 0;
    this.updateRecurringSettingsState();

    this.setState({ selectDateRangeIsVisible: !this.state.selectDateRangeIsVisible });
  }

  typeChangeHandle(option: { value: any; label: any }) {
    this.recurringSettingsCloned.type = parseInt(option.value);
    this.updateRecurringSettingsState();
  }

  deleteTimeTableHandle() {
    let recurringSettingsListUpdated = [...this.props.parentData.timeTable.recurringSettings];
    recurringSettingsListUpdated = recurringSettingsListUpdated.filter(
      (data: any, index: number) => index !== this.props.id,
    );

    const parentDataUpdate = { ...this.props.parentData };
    parentDataUpdate.timeTable.recurringSettings = recurringSettingsListUpdated;
    this.props.parentSetData({ ...this.props.parentData, ...parentDataUpdate });
  }

  renderView(): JSX.Element {
    const recurringSettingsAddedCount = this.props.parentData.timeTable.recurringSettings.length;

    const getDateInput = (dateNumber: number) => {
      if (dateNumber !== 0 && dateNumber !== null) {
        return parseDateForFrontend(dateNumber);
      }

      return '';
    };

    const dayIsChecked = (id: number): boolean => {
      return this.props.recurringSetting.days.includes(id);
    };

    const days = [
      { id: 1, name: 'Poniedziałek' },
      { id: 2, name: 'Wtorek' },
      { id: 3, name: 'Środa' },
      { id: 4, name: 'Czwartek' },
      { id: 5, name: 'Piątek' },
      { id: 6, name: 'Sobota' },
      { id: 0, name: 'Niedziela' },
    ];

    return (
      <>
        <CheckBox isChecked={this.state.selectWeekDaysIsVisible} onClick={this.weekDaysToggleHandle.bind(this)}>
          Wybrane dni tygodnia
        </CheckBox>
        {this.state.selectWeekDaysIsVisible && (
          <>
            <WeekDaysHorizontalGroup>
              {days.map((data, index) => {
                return (
                  <WeekDayButton
                    onClick={this.weekDayClickHandle.bind(this)}
                    key={`${this.props.id}${index}`}
                    name={data.name}
                    isChecked={dayIsChecked(data.id)}
                    id={data.id}
                  />
                );
              })}
            </WeekDaysHorizontalGroup>
          </>
        )}
        <Row>
          <Col xs={12} sm={6}>
            <Select
              placeholder={'Powtarzaj co'}
              name={'type'}
              value={this.props.recurringSetting.type}
              options={[
                { id: 1, name: 'Tydzień' },
                { id: 2, name: 'Dwa tygodnie' },
                { id: 4, name: 'Miesiąc' },
              ]}
              onChange={this.typeChangeHandle.bind(this)}
            />
          </Col>
        </Row>
        <CheckBox isChecked={this.state.selectDateRangeIsVisible} onClick={this.datesRangeToggleHandle.bind(this)}>
          Wskaż zakres początek i koniec generowania
        </CheckBox>
        {this.state.selectDateRangeIsVisible && (
          <>
            <Row>
              <Col xs={12} sm={6} md={6} lg={3}>
                <Input
                  onChange={this.dateFromChangeHandle.bind(this)}
                  value={getDateInput(this.props.recurringSetting.startDate)}
                  placeholder={'Początek'}
                  type={'date'}
                  name={'start'}
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={3}>
                <Input
                  onChange={this.dateToChangeHandle.bind(this)}
                  value={getDateInput(this.props.recurringSetting.endDate)}
                  placeholder={'Koniec'}
                  type={'date'}
                  name={'stop'}
                />
              </Col>
            </Row>
          </>
        )}
        {recurringSettingsAddedCount > 1 && (
          <ButtonOutlined onClick={this.deleteTimeTableHandle.bind(this)}>Usuń harmonogram</ButtonOutlined>
        )}
      </>
    );
  }
}
